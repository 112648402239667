import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { Icon, Wrapper, Text, WrapperColumn, WrapperRow, AbsoluteWrapper} from "../component/layout/layoutStorage";
import PhoneValid from "./phoneValid";

import Back from "../icon/backbutton.png";
import ProfileEditIcon from "../icon/profileEditIcon.png";
import OkayBlue from "../icon/purchasecomplete.png";
import Default from "../img/sehorang.png";
import { ReactComponent as Caution } from "../icon/caution.svg";
import RightArrowG from "../icon/rightArrowG.png";
import colors from "../styles/colors";

const ProfileRevise = ({ setModalStatus, setOpen }) => {
  const [info, setInfo] = useState({
    nickname: "",
    phone: "",
    profile: "",
    login_type: "",
  });

  const { pathname } = useLocation();

  const [available, setAvailable] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [verT, setVerT] = useState("");
  const [phoneB, setPhoneB] = useState("default");

  const usename = localStorage.getItem("user_name");

  const navigate = useNavigate();

  useEffect(() => {
    var token = localStorage.getItem("token");

    const frm = new FormData();
    frm.append("token", token);
    frm.append("mode", "pc_yong_profile_sohwan");

    axios
      .post("https://ai.semos.kr/semos_user/profile/profile_new", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;

        setInfo(Value.data);
        // copyPhone = Value.data.phone;
      });
  }, []);

  const nicknameCheck = () => {
    setLoading(true);
    var token = window.localStorage.getItem("token");
    if (info.nickname?.length > 1 && info.nickname?.length < 16) {
      const frm = new FormData();
      frm.append("mode", "nick_name_duplicate_new");
      frm.append("nickname", info.nickname);
      frm.append("token", token);

      axios
        .post("https://ai.semos.kr/semos_user/profile/register", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var check = data.data.nick_name_status;

          if (check === "success") {
            setAvailable(true);
          } else {
            setAvailable(false);
          }
        });
      setLoading(false);
    } else {
      setAvailable(false);
    }
  };

  useEffect(() => {
    nicknameCheck();
  }, [info.nickname]);

  const ProfileRevise = () => {
    if (phoneB === "finish" && valid) {
      var token = localStorage.getItem("token");

      const frm = new FormData();
      frm.append("mode", "profile_revise_web");
      frm.append("token", token);
      frm.append("nickname", info.nickname);
      frm.append("phone_number", info.phone);
      frm.append("phone_change", info.phone != localStorage.getItem("user_phone_number") ? "yes" : "no");
      frm.append("nickname_change", info.nickname !== localStorage.getItem("user_name") ? "yes" : "no");

      if (info.profile !== localStorage.getItem("user_profile_img") && info.profile && localStorage.getItem("user_profile_img")) {
        frm.append("profile_image", info.profile);
      }

      axios
        .post("https://ai.semos.kr/semos_user/profile/profile_new", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var UserData = Value.user_data;
          var Status = Value.status;
          var StatusMessage = Value.status_message;

          if (Status == "success") {
            localStorage.setItem("user_name", UserData.user_name);
            localStorage.setItem("user_phone_number", UserData.user_phone);
            localStorage.setItem("user_profile_img", UserData.profile_img);

            window.alert("프로필 수정이 완료되었습니다.");
            setModalStatus("profile");
          } else if (Status == "fail") {
            window.alert(StatusMessage);
          } else if (Status == "waring") {
            window.alert(StatusMessage);
          }
        });
    }
  };

  const [imgChange, setImgChange] = useState(false);

  const setImageFromFile = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setInfo((prev) => ({ ...prev, profile: reader.result }));
    };
    setImgChange(true);
  };

  const logout = () => {
    localStorage.clear();
    if (window.location.pathname == "/") {
      window.location.reload();
    } else {
      navigate("/");
    }
  };

  const confirmQuit = () => {
    if (valid) {
      if (window.confirm("변경사항이 저장되지 않았습니다. 취소 하시겠습니까?")) {
        setModalStatus("profile");
        setTimeout(() => {
          setOpen(true);
        }, 0.5);
      }
    } else {
      setModalStatus("profile");
      setTimeout(() => {
        setOpen(true);
      }, 0.5);
    }
  };

  let valid = imgChange || phoneB === "finish" || disabled;

  return (
    <WrapperColumn width="100%" height="100%" jc="space-between">
      <WrapperColumn width="100%">
        <Wrapper width="100%" height="60px" padding="17px 22px" jc="space-between" ai="center">
          <Icon
            src={Back}
            width="24px"
            height="24px"
            style={{ cursor: "pointer" }}
            onClick={() => {
              confirmQuit();
            }}
          />
          <Text size="18px" color="#1A1C1F" weight="600">
            프로필 편집하기
          </Text>
          <Icon src={Back} width="0px" height="0px" />
        </Wrapper>

        <Wrapper width="100%" height="1px" bgc="#F5F5F5" />

        <Wrapper jc="center" margin="34px 0 0 0" style={{ position: "relative" }}>
          <input
            type="file"
            accept="image/*"
            id={"profile"}
            style={{ display: "none" }}
            onChange={({ target: { files } }) => {
              setImageFromFile(files[0]);
              files[0] = "";
            }}
          />
          <label htmlFor="profile" style={{ cursor: "pointer" }}>
            <Icon src={info.profile && info.profile != "가입미완" ? info.profile : Default} width="160px" height="160px" radius="50%" of="cover" />
            <AbsoluteWrapper bottom="0" right="107px">
              <Icon src={ProfileEditIcon} width="38px" height="38px" radius="50%" />
            </AbsoluteWrapper>
          </label>
        </Wrapper>

        <Wrapper jc="center" margin="16px 0 0 0">
          <Text size="12px" weight="600" color="#ACAFB5">
            {info.login_type} 로그인
          </Text>
        </Wrapper>

        <WrapperColumn padding="0 24px">
          <WrapperColumn margin="28px 0 0 0">
            <Text size="14px" weight="500" color="#3c3e41">
              닉네임
            </Text>
            <Wrapper margin="8px 0 0 0" border="1px solid #f5f5f5" radius="8px" bgc={disabled ? "#F6F6F6" : "#ffffff"} jc="space-between">
              <Nickname
                maxLength={20}
                id="nick"
                size="14px"
                color="#3c3e41"
                phColor="#ACAFB5"
                bgc={disabled ? "#F6F6F6" : "#ffffff"}
                style={{ padding: "16px", borderRadius: "8px" }}
                value={info.nickname}
                onChange={(e) => setInfo((prev) => ({ ...prev, nickname: e.target.value }))}
                disabled={disabled}
                autocomplete="off"
              />

              <Wrapper margin="0 8px 0 0">
                <AuthButton
                  status={available && /^[가-힣a-zA-Z]+$/i.test(info.nickname)}
                  disabled={disabled}
                  onClick={() => available && /^[가-힣a-zA-Z]+$/i.test(info.nickname) && setDisabled(true)}
                >
                  {disabled ? "수정완료" : "수정하기"}
                </AuthButton>
              </Wrapper>

              <AbsoluteWrapper bottom="-22.5px" ai="center">
                {disabled && (
                  <>
                    <Icon src={OkayBlue} width="12px" height="12px" style={{ marginRight: "4px" }} />
                    <Text size="12px" color="#66a2ff" weight="600">
                      수정이 완료되었습니다.
                    </Text>
                  </>
                )}
                {!loading && usename !== info.nickname && !disabled ? (
                  available && /^[가-힣a-zA-Z]+$/i.test(info.nickname) ? (
                    <>
                      <Icon src={OkayBlue} width="12px" height="12px" style={{ marginRight: "4px" }} />
                      <Text size="12px" color="#66a2ff" weight="600">
                        수정을 원하시면 수정하기 버튼을 눌러주세요!
                      </Text>
                    </>
                  ) : (
                    <>
                      <Caution fill="#FF9187" width="12px" height="12px" style={{ marginRight: "4px" }} />
                      <Text size="12px" color="#FF9187" weight="600">
                        사용할 수 없는 닉네임입니다.
                      </Text>
                    </>
                  )
                ) : (
                  ""
                )}
              </AbsoluteWrapper>
            </Wrapper>
          </WrapperColumn>

          <PhoneValid info={info} setInfo={setInfo} verT={verT} setVerT={setVerT} phoneB={phoneB} setPhoneB={setPhoneB} />

          <WrapperColumn margin="28px 0 0 0">
            <Text size="14px" weight="500" color="#3c3e41">
              내 정보
            </Text>
            <Wrapper
              cursor="pointer"
              onClick={(e) => {
                e.stopPropagation();
                setModalStatus("userSpec");
              }}
              padding="14px 16px"
              margin="8px 0 0 0"
              border="1px solid #f5f5f5"
              radius="8px"
              // bgc={disabled ? "#F6F6F6" : "#ffffff"}
              jc="space-between"
            >
              <Text size="14px" weight="500" color={colors.Gr03}>
                {info.spec ? "정보 입력 완료" : "정보 입력 필요"}
              </Text>

              <Icon margin="0 0 0 22px" width="20px" height="22px" src={RightArrowG} />
            </Wrapper>
          </WrapperColumn>
        </WrapperColumn>

        <Wrapper padding="0 24px" margin="47px 0 0 0" cursor="pointer" onClick={() => ProfileRevise()}>
          <Wrapper
            padding="16.5px"
            width="100%"
            border="1px solid #dcdcdc"
            bgc={phoneB === "finish" && valid ? colors.Bl07 : colors.Gr01}
            jc="center"
            radius="8px"
          >
            <Text size="16px" weight="600" color={phoneB === "finish" && valid ? colors.white : colors.Gr03}>
              저장
            </Text>
          </Wrapper>
        </Wrapper>
      </WrapperColumn>

      <WrapperRow padding="22px 0" ai="center" width="100%" jc="space-between">
        <Wrapper width="180px" ai="center" jc="center" margin="10px 0">
          <Text
            size="12px"
            color="#818792"
            weight="500"
            // onClick={() => setLogout(true)}
            onClick={() => logout()}
            style={{ cursor: "pointer" }}
          >
            로그아웃
          </Text>
        </Wrapper>

        <Wrapper margin="0 10px" height="24.5px" width="1px" bgc={colors.Gr02} />

        <Wrapper
          width="180px"
          margin="10px 0"
          ai="center"
          jc="center"
          onClick={() => {
            if (pathname === "/unregister") {
              setOpen(false);
            } else {
              navigate("/unregister");
            }
          }}
        >
          <Text
            size="12px"
            color="#818792"
            weight="500"
            // onClick={() => logout()}
            style={{ cursor: "pointer" }}
          >
            회원 탈퇴
          </Text>
        </Wrapper>
      </WrapperRow>
    </WrapperColumn>
  );
};

export default ProfileRevise;

const AuthButton = styled.div`
  padding: 8px 12px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  background-color: ${({ status, disabled }) => (disabled ? "#dcdcdc" : status ? "#0e6dff" : "#dcdcdc")};
  cursor: ${({ status }) => (status ? "pointer" : "default")};
  white-space: nowrap;
`;

const Nickname = styled.input`
  border: none;
  font-size: ${({ size }) => `${size}`};
  text-align: ${({ ta }) => `${ta}`};
  color: ${({ color }) => `${color}`};
  font-weight: ${({ weight }) => `${weight}`};
  white-space: ${({ ws }) => `${ws}`};
  letter-spacing: ${({ ls }) => `${ls}`};
  width: ${({ width }) => `${width}`};
  ::placeholder {
    color: ${({ phColor }) => `${phColor}`};
  }

  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${({ bgc }) => `${bgc}`} inset;
    -webkit-text-fill-color: #000;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;
