import React from "react";
import styled, { css } from "styled-components";
import { Text, Wrapper, Icon, WrapperRow, WrapperColumn } from "./layoutStorage";
import { useNavigate } from "react-router-dom";

import Instagram from "../../icon/insta_icon.png";
import Youtube from "../../icon/youtube_icon.png";
import Facebook from "../../icon/facebook_icon.png";
import Blog from "../../icon/blog_icon.png";

const Mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

const Footer = () => {
  const macOs = true;
  const navigate = useNavigate();

  return (
    <SFooter>
      <WrapperRow display={Mobile ? `inline-block` : ``} width="100%" jc="space-between">
        <WrapperColumn display={Mobile ? `inline-block` : ``} width={Mobile ? `100%` : ``}>
          <Strong>리포츠(주)</Strong>

          <Wrapper display={Mobile ? `inline-block` : ``} ai="center" margin="0 0 8px 0">
            <Text width={Mobile ? `100%` : ``} size={Mobile ? `10px` : `14px`}>대표 : 변민지</Text>
            {Mobile ? "" : <Bar />}
            <Text width={Mobile ? `100%` : ``} size={Mobile ? `10px` : `14px`}>개인정보책임자 : 허재혁</Text>
            {Mobile ? "" : <Bar />}
            <Text width={Mobile ? `100%` : ``} size={Mobile ? `10px` : `14px`}>사업자등록번호 : 206-88-01662</Text>
          </Wrapper>

          <WrapperRow display={Mobile ? `inline-block` : ``} ai="center">
            <Address>본사 : 인천 연수구 송도문화로 119, B1041-5,6 </Address>
            {Mobile ? "" : <Bar />}
            <Address>지사 : 서울 강남구 테헤란로 501, 브이플렉스 502호</Address>
          </WrapperRow>
        </WrapperColumn>

        <WrapperColumn margin={Mobile ? `10px 0 0 0` : ``} width={Mobile ? `100%` : ``} display={Mobile ? `inline-block` : ``}>
          <WrapperRow ai="flex-start" width="100%" jc="space-between">
            <Strong className="black">Contact Us</Strong>
            <WrapperRow gap="11px" jc="center" ai="center">
              <a href="https://www.facebook.com/semossports/" target="_blank" aria-label="세모스 페이스북">
                <Icon src={Facebook} width="24px" height="24px" />
              </a>
              <a href="https://www.instagram.com/semos_kr/" target="_blank" aria-label="세모스 인스타그램">
                <Icon src={Instagram} width="24px" height="24px" />
              </a>
              <a href="https://www.youtube.com/channel/UCJPnOxX_AxNCL11A2ar7liw" target="_blank" aria-label="세모스 유튜브">
                <Icon src={Youtube} width="24px" height="24px" />
              </a>
              <a href="https://blog.naver.com/sports_together" target="_blank" aria-label="세모스 블로그">
                <Icon src={Blog} width="24px" height="24px" />
              </a>
            </WrapperRow>
          </WrapperRow>
          <Text size={Mobile ? `12px` : `14px`} width={Mobile ? `100%` : ``} margin="0 0 8px 0" className="black">
            help@semos.kr
          </Text>
          <Wrapper>
            <Text size={Mobile ? `12px` : `14px`} ws="pre">카카오톡 채널 </Text>
            <a style={{
              fontSize : Mobile ? `12px` : `14px`
            }} className="black" href="http://pf.kakao.com/_YxfVxfK" target="_blank" aria-label="카카오톡 채널">
              @세상의 모든 스포츠, 세모스
            </a>
          </Wrapper>
        </WrapperColumn>
      </WrapperRow>

      {/*<WrapperRow gap="8px" margin="24px 0 0 0">
        <Wrapper onClick={() => navigate("/announcement")} cursor="pointer" radius="4px" bgc="#ffffff" border="1px solid #DCDCDC" padding="6px 12px">
          <Text size={Mobile ? `9px` : `12px`} className="black">
            공지사항
          </Text>
        </Wrapper>
        <Wrapper onClick={() => navigate("/faq")} cursor="pointer" radius="4px" bgc="#ffffff" border="1px solid #DCDCDC" padding="6px 12px">
          <Text size={Mobile ? `9px` : `12px`} className="black">
            자주하는 질문
          </Text>
        </Wrapper>
        <a href="http://pf.kakao.com/_YxfVxfK" target="_blank" aria-label="카카오톡 채널">
          <Wrapper cursor="pointer" radius="4px" bgc="#ffffff" border="1px solid #DCDCDC" padding="6px 12px">
            <Text size={Mobile ? `9px` : `12px`} className="black">
              고객센터 문의
            </Text>
          </Wrapper>
        </a>
      </WrapperRow>*/}

      <WrapperColumn margin={Mobile ? "24px 0 30px 0" : "24px 0 0 0"} width="100%" ai="center" jc="center">
        <Text margin="0 0 16px 0" size={Mobile ? `12px` : `14px`} weight="500" className="blue">
          Copyright © 리포츠 (주). All rights reserved.
        </Text>
        <Text size={Mobile ? `9px` : `12px`} weight="500">
          리포츠는 통신판매 중개자로서 통신판매의 당사자가 아니며 상품의 예약,
        </Text>
        <Text size={Mobile ? `9px` : `12px`} weight="500">
          이용 및 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.
        </Text>
      </WrapperColumn>
    </SFooter>
  );
};

export default Footer;

const SFooter = styled.footer`
  position: relative;
  padding: 77px 0 181px 0;
  border-top: 2px solid #f5f5f5;
  color: #acafb5;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.02em;
  .black {
    color: #3c3e41;
  }
  .blue {
    color: #3383ff;
  }
  a {
    text-decoration: none;
  }

  @media screen and (max-width: 859px) {
    width : 100vw;
    padding: 30px 24px 60px 24px;
  }
`;

const Strong = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 142%;
  margin-bottom: 24px;

  @media screen and (max-width: 859px) {
    font-size: 12px;
    line-height: 142%;
    margin-bottom: 12px;
  }
`;

const Address = styled.address`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  letter-spacing: -0.02em;
  white-space: pre;
  margin-bottom: 0;
  font-style: normal;

  @media screen and (max-width: 859px) {
    font-size: 10px;
    line-height: 142%;
  }
`;

const Bar = styled.div`
  width: 1px;
  height: 16px;
  background-color: #d9d9d9;
  margin: 0 15px;

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  ${ ({ pc }) => pc && css`display: block;`}`
  ;
