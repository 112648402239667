import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

//img
import Arrow from "../icon/arrow.svg";
import colors from "../styles/colors";

//요금 확인하기 플로팅 모달 - 진행 장소 & 인원 dropdown
const DropDown = ({
  open,
  setOpen,
  data,
  changeDataFun,
  option,
  type,
  dropdownType,
  defaultText,
  no,
  keyName,
  can,
  color,
  padding,
  border,
  iconHeight,
  iconWidth,
}) => {
  const ref = useRef();
  const handleClose = (e) => {
    if (open && (!ref.current || !ref.current.contains(e.target))) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [open]);

  const changeValue = (el) => {
    setOpen(false);
    if (dropdownType === "people") {
      changeDataFun(el.value);
    } else {
      changeDataFun({
        id: el.value,
        text: el.text,
      });
    }
  };

  const scrollToTop = (id) => {
    if (window.scrollY < 397.5) {
      const ele = document.getElementById(id);
      const elePosition = window.scrollY + ele.getBoundingClientRect().top - 155;
      window.scrollTo(0, elePosition);
    }
  };

  return (
    <div
      style={{ position: "relative", cursor: "pointer", width: "100%" }}
      onBlur={() => {
        setOpen(false);
      }}
      ref={ref}
    >
      <SelectBox
        data={data}
        color={color}
        className="f13"
        no={no}
        onClick={() => {
          if (dropdownType === "people") {
            if (can) setOpen((prev) => !prev);
          } else {
            setOpen((prev) => !prev);
          }
        }}
        border={border}
        open={open}
      >
        <SelectText padding={padding}>
          {data ? (dropdownType === "people" ? `${data}인` : `${data}`) : defaultText}
          <SelectIcon src={Arrow} open={open} iconHeight={iconHeight} iconWidth={iconWidth} />
        </SelectText>
        {open && (
          <OptionContainer>
            {option.map((el, i) => (
              <Option
                className="f12"
                key={el.text}
                last={option.length - 1 == i}
                onClick={(e) => {
                  e.stopPropagation();
                  changeValue(el);
                  scrollToTop("activityInfo");
                }}
                data={data}
                text={el.text}
              >
                {el.text ? (dropdownType === "people" ? `${el.text}인` : `${el.text}`) : dropdownType === "people" ? `날짜, 시간을 선택해주세요` : ""}
              </Option>
            ))}
          </OptionContainer>
        )}
      </SelectBox>
    </div>
  );
};

export default DropDown;

const SelectBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: ${({ data, open }) => (data ? "1px solid #3383FF" : "1px solid #dcdcdc")};
  margin-right: ${({ no }) => (no ? 0 : 12)}px;
  line-height: 100%;
  background-color: #ffffff;
  width: 100%;
  white-space: nowrap;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  color: ${({ color }) => (color ? color : 12)};
`;

const SelectText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ padding }) => (padding ? padding : "8px 16px")};
`;

const SelectIcon = styled.img`
  width: ${({ iconWidth }) => (iconWidth ? iconWidth : "12px")};
  height: ${({ iconHeight }) => (iconHeight ? iconHeight : "12px")};
  margin-left: 8px;

  transform: ${({ open }) => (open ? "rotate(-180deg)" : "")};
  transition: all 0.7s;
`;

const OptionContainer = styled.div`
  width: 100%;
  top: calc(16px + 1rem * 17 / 14);
  left: 0;
  z-index: 2;
  border-top: 1px solid transparent;
  background-color: #ffffff;
  max-height: 206px;
  overflow: auto;
  border-radius: 0 0 8px 8px;
`;

const Option = styled.div`
  padding: ${({ padding }) => (padding ? padding : "8px 10px 9px 10px")};
  font-weight: 500;
  font-size: 14px;
  border-bottom: ${({ last }) => (last ? "none" : "1px solid #f5f5f5")};
  display: flex;
  align-items: center;
  color: ${({ data, text }) => (data == text ? (text ? colors.Bl07 : colors.Gr04) : colors.Gr04)};
  :hover {
    background-color: ${colors.Gr01};
  }
`;
