import { useEffect } from "react";

// kakao
const { Kakao } = window;

const KakaoChannel = () => { 


    // func
    useEffect(() => { 

      Kakao.Channel.chat({
        channelPublicId: "_YxfVxfK",
      });

      setTimeout(() => {
        window.open('','_self').close();
      }, 5000);

    }, []);

    return (
      <></>
    );


}


export default KakaoChannel;