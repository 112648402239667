import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import { Icon, Wrapper, Text, Input, Radio, AbsoluteWrapper, WrapperColumn, WrapperRow } from "../component/layout/layoutStorage";

import colors from "../styles/colors";
import Back from "../icon/backbutton.png";

import axios from "axios";
import UserSpec from "../component/userSpec";

const ProfileUserSpec = ({ setModalStatus, setOpen }) => {
  // 정보 입력 데이터
  const [userSpec, setUserSpec] = useState({
    name: "",
    gender: "",
    birth: "",
    height: "",
    weight: "",
    foot: "",
  });
  const [oldData, setOldData] = useState({
    name: "",
    gender: "",
    birth: "",
    height: "",
    weight: "",
    foot: "",
  });

  // 숫자 입력
  const patternText = /^[가-힣a-zA-Z]+$/;

  // 버튼 활성화
  let valid =
    patternText.test(userSpec.name) &&
    userSpec.gender !== "" &&
    userSpec.birth?.length === 6 &&
    userSpec.height !== "" &&
    userSpec.weight !== "" &&
    userSpec.foot !== "";

  //뒤로가기 제한
  let validBack =
    patternText.test(userSpec.name) ||
    userSpec.gender !== "" ||
    userSpec.birth.length === 6 ||
    userSpec.height !== "" ||
    userSpec.weight !== "" ||
    userSpec.foot !== "";

  // 성별 데이터
  const GENDER = [
    {
      label: "남",
      value: "1",
    },
    {
      label: "여",
      value: "2",
    },
  ];

  const [loading, setLoading] = useState(false);

  const getData = () => {
    let token = localStorage.getItem("token");

    if (token) {
      setLoading(true);
      const frm = new FormData();
      frm.append("mode", "user_spec");
      frm.append("token", token);
      axios
        .post("https://ai.semos.kr/semos_user/v3/profile/index", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;

          let spec = Value.data;
          if (Value.status === "success") {
            setOldData({
              name: spec.name ? spec.name : "",
              gender: spec.gender ? spec.gender : "",
              birth: spec.birth ? spec.birth : "",
              height: spec.height ? spec.height : "",
              weight: spec.weight ? spec.weight : "",
              foot: spec.foot ? spec.foot : "",
            });
            setUserSpec({
              name: spec.name ? spec.name : "",
              gender: spec.gender ? spec.gender : "",
              birth: spec.birth ? spec.birth : "",
              height: spec.height ? spec.height : "",
              weight: spec.weight ? spec.weight : "",
              foot: spec.foot ? spec.foot : "",
            });
          } else {
            window.alert("정보를 불러올 수 없습니다.");
          }
          setLoading(false);
        });
    }
  };

  // 데이터 변화 체크
  let changeData =
    userSpec.name !== oldData.name ||
    userSpec.gender !== oldData.gender ||
    userSpec.birth !== oldData.birth ||
    userSpec.height !== oldData.height ||
    userSpec.weight !== oldData.weight ||
    userSpec.foot !== oldData.foot;

  //profile save data
  const saveData = () => {
    let token = localStorage.getItem("token");

    if (token) {
      setLoading(true);
      const frm = new FormData();
      frm.append("mode", "spec_update");
      frm.append("token", token);
      frm.append("birth", userSpec.birth);
      frm.append("weight", userSpec.weight);
      frm.append("foot", userSpec.foot);
      frm.append("height", userSpec.height);
      frm.append("name", userSpec.name);
      frm.append("gender", userSpec.gender);
      axios
        .post("https://ai.semos.kr/semos_user/v3/profile/index", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var StatusMessage = Value.status_text;

          if (Value.status === "success") {
          } else {
            window.alert(StatusMessage);
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <WrapperColumn ai="center" jc="space-between" bgc="#ffffff" radius={"18px"} width={"100%"} height="100%">
        <WrapperColumn width="100%" height="100%">
          <Wrapper
            position="relative"
            width="100%"
            style={{
              borderBottom: "1px solid #F5F5F5",
            }}
            height="58px"
            padding="19px 24px"
            ai="center"
            jc="center"
          >
            <>
              <AbsoluteWrapper left="24px">
                <Icon
                  src={Back}
                  width="24px"
                  height="24px"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (validBack && changeData) {
                      if (window.confirm("정보 입력이 취소됩니다!")) {
                        setModalStatus("revise");
                        setTimeout(() => {
                          setOpen(true);
                        }, 0.2);
                      }
                    } else {
                      setModalStatus("revise");
                      setTimeout(() => {
                        setOpen(true);
                      }, 0.2);
                    }
                  }}
                />
              </AbsoluteWrapper>

              <Text ws="pre" size="18px" color="#1A1C1F" weight="600">
                내 정보 입력하기
              </Text>
            </>
          </Wrapper>

          <WrapperColumn padding={"32px 24px"} gap="12px">
            <WrapperColumn margin="0 0 36px 0">
              <Text ws="pre" size="18px" weight="600" ls="-0.02em" color={colors.Gr06}>
                내 정보 입력하기
              </Text>
              <Text margin="8px 0 0 0" ws="pre" size="14px" weight="500" ls="-0.02em" color={colors.Gr04}>
                장비 대여 및 액티비티 이용에
              </Text>
              <Text ws="pre" size="14px" weight="500" ls="-0.02em" color={colors.Gr04}>
                필요한 정보를 미리 입력할 수 있어요
              </Text>
            </WrapperColumn>

            <UserSpec data={userSpec} setData={setUserSpec} />
          </WrapperColumn>
        </WrapperColumn>

        <Wrapper style={{ borderTop: "1px solid #f9f9f9" }} width="100%" gap="12px" padding={"24px"}>
          <Wrapper
            onClick={(e) => {
              e.stopPropagation();
              if (valid) {
                saveData();
                setModalStatus("revise");
              }
            }}
            cursor={valid && changeData && "pointer"}
            jc="center"
            ai="center"
            height="56px"
            width="100%"
            border={"1px solid #dcdcdc"}
            bgc={valid && changeData ? colors.Bl07 : colors.Gr01}
            radius="8px"
          >
            <Text size="16px" color={valid && changeData ? colors.white : colors.Gr03} weight="600">
              저장
            </Text>
          </Wrapper>
        </Wrapper>
      </WrapperColumn>
    </>
  );
};

export default ProfileUserSpec;
