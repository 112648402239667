import styled from "styled-components";

export const LoadingSpinner = ({ loading, title, content }) => {
  return (
    <LoadingPayment loading={loading}>
      <div className="content">
        <div className="text_view">Loading..</div>
      </div>
    </LoadingPayment>
  );
};

const LoadingPayment = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;
  right: 0;
  display: ${({ loading }) => (loading ? "inline-flex" : "none")};
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;

  .dot_container {
    width: 100%;
    /* height: 30px; */
    margin: 120px 0 0 0;
    position: relative;

    #dots {
      position: unset !important;
      transform: unset !important;
    }
  }

  .content {
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 151;
  }

  .sehorang {
    display: inline-block;
    width: 123px;
  }

  .text_view {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    font-weight: 400;
    line-height: 22.72px;
    font-size: 16px;
    color: #cce0ff;
    justify-content: center;
  }
`;
