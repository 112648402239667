import React, { Component } from "react";
import { BrowserRouter as Router, withRouter, Link as domLink, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

//component
import Header from "../component/header";
import DeskTopHeader from "../component/layout/header";

// css
const CrossContainer = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #ffffff;

  position: relative;
  display: inline-block;
  overflow: hidden;
  overflow-y: auto;
  z-index: 15;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
`;

const HeaderContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: none;
  }
  @media screen and (min-width: 860px) {
    display: block;
    margin: 100px 0 0 0;
  }
`;

const CrossContent = styled.div`
  width: 80rem;
  margin: 0 calc((100% - 80rem) / 2);
  min-height: calc(100vh - 13rem);
  min-height: calc((var(--vh, 1vh) * 100) - 13rem);
  display: inline-block;
  padding: 1rem 0;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
`;

const withParams = (Child) => {
  return (props) => {
    const params = useParams();
    const navigate = useNavigate();
    return <Child {...props} params={params} naviGate={navigate} />;
  };
};

class Semos_pay_process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileCheck: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
      thisPage: window.location.pathname.split("/")[1],
    };
  }

  componentDidMount() {}

  render() {
    return (
      <CrossContainer>
        {this.state.mobileCheck ? <Header /> : ""}

        <CrossContent style={{ padding: this.state.mobileCheck ? "5.2rem 1.8rem 8rem 1.8rem" : "1.8rem 1.8rem 8rem 1.8rem" }}>
          {this.state.mobileCheck ? (
            ""
          ) : (
            <>
              <HeaderContainer>
                <DeskTopHeader />
              </HeaderContainer>
              <Header />
            </>
          )}

          <section
            style={{
              fontSize: "13px",
              fontWeight: "500",
              lineHeight: "142%",
            }}
          >
            <b className="semos_small_content semos_font_weight_500">공통 규칙</b>
            <br></br>
            <br></br>
            취소 규정은 이용일 또는 유효기간 기준으로 적용됩니다.<br></br>
            취소수수료 발생 시, 쿠폰 및 포인트와 같은 할인금액을 포함한 최초 예약 금액을 기준으로 계산됩니다.<br></br>
            <br></br>
            취소수수료 계산 기준<br></br>
            부과 순서는 다음과 같습니다.<br></br>
            현금, PG 및 간편결제를 통한 결제 금액<br></br>
            포인트 사용금액<br></br>
            쿠폰 또는 제휴사 포인트 할인금액<br></br>
            <br></br>
            단, 취소수수료가 실 결제금액(현금/PG/간편결제금액 + 포인트 사용금액)을 초과 발생하는 경우, 취소수수료 초과분에 한해 결제 시 사용하신 쿠폰과 각
            제휴사 포인트 반환 정책에 따라 반환 여부가 결정됩니다.<br></br>
            <br></br>
            이용 일자 및 레슨 옵션 등 예약 정보 변경을 위한 예약취소 시에도 동일한 취소 규정이 적용됩니다.<br></br>
            <br></br>
            사용한 쿠폰의 반환<br></br>
            고객 소유의 쿠폰을 사용하신 경우, 취소수수료 발생 여부와 무관하게 예약취소 시점에 고객님 계정으로 반환됩니다.<br></br>
            <br></br>
            <b className="semos_small_content semos_font_weight_500">취소 불가 사항</b>
            <br></br>
            <br></br>
            다음의 경우, 예약취소 및 결제금액 환불이 불가능합니다.<br></br>
            레슨 및 서비스 시간 경과 (No-Show 포함)또는 실제 레슨 및 서비스를 받고 있는 경우 ※ No-Show : 사전 연락없이 예약된 레슨 및 서비스를 이용하지 않음
            <br></br>
            이미 레슨 및 서비스를 받고 있는 경우<br></br>
            이용일 당일에 취소하는 경우인 경우<br></br>
            숙박 포함 레슨 및 서비스의 경우 청소년 보호법에 따라 미성년자(만 19세 미만 청소년)의 경우 혼숙이 금지되며, 법정대리인 동행 없이 혼숙이 불가합니다.
            <br></br>
            주차불가 또는 도보전용 레슨 및 서비스의 경우<br></br>
            취소 불가 시점에 주차불가 사유로 인한 취소처리는 불가합니다.<br></br>
            <br></br>
            해당 사유로 인하여 현장에서 레슨 및 서비스가 불가할 경우, 취소 및 환불이 불가합니다.<br></br>
            특정 결제수단 환불 정책<br></br>
            '휴대폰 결제' 예약 건은 결제 당월에 한해 원 거래 취소 및 환불 처리가 가능합니다. 익월 이후 취소 시, 계좌를 통한 현금으로 환불됩니다.<br></br>
            <br></br>
            <b className="semos_small_content semos_font_weight_500">예외 규정</b>
            <br></br>
            <br></br>
            천재지변 또는 기상악화에 의한 예외 규정<br></br>
            천재지변 또는 기상악화 등 불가항력적인 원인으로 서비스 이용이 불가한 경우 세모스 고객센터를 통해 예외적 취소가 가능합니다.<br></br>
            단, 천재지변의 영향정도(시설의 현장상황, 교통수단 및 이동경로 등)를 증명하는 자료의 제출을 요청하거나, 재난의 정도에 따라 예약취소 및 환불이 불가할
            수 있습니다.<br></br>
            <br></br>
            <b className="semos_small_content semos_font_weight_500">기타 유의사항</b>
            <br></br>
            <br></br>
            실시간 예약의 특성상 동일한 레슨 및 서비스에 중복 예약이 발생할 수 있으며, 이 경우 먼저 결제완료된 예약 건에 우선권이 있으므로 이후 중복 예약 건은
            취소될 수 있습니다.<br></br>
            잘못 등록된 요금으로 고객센터를 통한 안내 후, 예약 취소가 진행될 수 있습니다.<br></br>
            예약 시 표시된 시설정보와 상이하거나 계약내용과 다르게 이행된 점이 확인될 경우, 이용 고객 또는 센터 / 강사의 요청에 따라 예약취소가 진행될 수
            있습니다.<br></br>
            레슨 및 서비스 예약은 각 센터 / 강사의 취소규정에 의해 취소 및 환불이 진행됩니다.<br></br>
            현장에서 발생된 센터 / 강사와의 분쟁으로 인한 취소/환불 요청 시, 당사는 중개플랫폼사로 취소 및 환불처리에 관여하지 않습니다.<br></br>
            리포츠 주식회사는 예약 중개업자로서 천재지변, 센터 / 강사 및 서비스 제공 업체의 파업, 항공사사정, 내란 등 여러 사유로 발생한 직, 간접적인 예약
            고객님의 피해에 대하여 배상의 책임을 지지 않습니다.<br></br>
            <br></br>
            <b className="semos_small_content semos_font_weight_500">레슨 예약 안내</b>
            <br></br>
            <br></br>
            a. 결제 시 강사 확인 후 예약 확정 및 카카오톡 알림톡 발송<br></br>
            b. 24시간 이내 강사 확인 없을 경우 자동 취소 및 환불 처리 (그룹 액티비티 제외)<br></br>
            c. 그룹 액티비티의 경우 액티비티 진행일 기준 1주전까지 최소 진행 인원 미달 시 취소/환불이 진행될 수 있음<br></br>
            d. 결제 후 30분 이내 고객센터(카카오톡 채널 @세모스 , 세상의 모든 스포츠)로 취소 요청 시 전액 환불<br></br>
            <br></br>
            <b className="semos_small_content semos_font_weight_500">레슨 환불 안내</b>
            <br></br>
            <br></br>
            a. 레슨 당일~레슨일 5일 이전 취소 시 : 환불 불가<br></br>
            b. 레슨일 6일~15일 이전 취소 시 : 50% 환불<br></br>
            c. 레슨일 16일 이전 취소 시 : 100% 환불<br></br>
          </section>
        </CrossContent>
      </CrossContainer>
    );
  }
}

export default withParams(Semos_pay_process);
