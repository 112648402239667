import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Naver() {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.localStorage.setItem(
      "naverToken",
      location.hash.split("&")[0].split("=")[1]
    );
    window.localStorage.setItem("type", "네이버");
    navigate(-1);
  }, []);

  return <div>Naver</div>;
}

export default Naver;
