import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Icon, Wrapper, Text, WrapperColumn, AbsoluteWrapper, WrapperRow } from "./layout/layoutStorage";
import axios from "axios";

import Star from "../icon/star.png";
import ReservationClosed from "../icon/reservationClosed.png";

const Item = ({ page, item }) => {

    const [locationModal, setLocationModal] = useState(false);
    const locationRef = useRef();

    const handleClose = (e) => {
        if (locationModal && (!locationRef.current || !locationRef.current.contains(e.target))) {
        setLocationModal(false);
        }
    };

    useEffect(() => {
        window.addEventListener("click", handleClose);

        return () => {
            window.removeEventListener("click", handleClose);
        };
    }, [locationModal]);

  return (
    <>
      <WrapperColumn height="240px" margin={page !== "list" && "0 20px 0 0"} style={{ position: "relative" }}>
        <Link to={`/partner_detail/${item.center_id}`} target="_blank" style={{ textDecoration: "none" }}>
          <Wrapper height="240px">
            <WrapperColumn cursor="pointer">
              <Wrapper width="140px">
                <ReviewCard img={item?.profile}>

                  {/* 예약 마감 */}
                  {item?.status != 1 && (
                    <AbsoluteWrapper radius="8px 8px 0px 0px" jc="center" ai="center" bgc="rgba(0, 0, 0, 0.4)" width="100%" height="100%">
                      <Icon width="90px" height="100px" src={ReservationClosed} />
                    </AbsoluteWrapper>
                  )}
                </ReviewCard>
              </Wrapper>

              <WrapperColumn padding="10px 0 0 0" height="100px" width="140px" bgc="#ffffff" jc="space-between">
                <WrapperColumn gap="5px">
                  <WrapperRow gap="6px" ai="center">
                    <Text weight="600" size="10px" ls="-0.02em" color="#0E6DFF">
                      {item?.sports}
                    </Text>
                    <Wrapper width="1px" height="10px" bgc="#ACAFB5" />
                    <Text weight="600" size="10px" ls="-0.02em" color="#ACAFB5">
                      {item?.days}일 활동
                    </Text>
                  </WrapperRow>

                  <Text weight="600" size="13px" fontFam color="#3C3E41" ai="flex-start">
                    {item?.title} 파트너
                  </Text>
                </WrapperColumn>

                <WrapperColumn margin="0 0 3px 0" position="relative" gap="6px">
                  {/* 키워드 & 별점 */}
                  <WrapperRow width="100%" jc="space-between">
                  </WrapperRow>

                  {/* 가격 */}
                  <WrapperRow>
                    <Wrapper gap="4px" ai="center">
                      <Text weight="600" size="15px" ls="-0.02em" color="#1A1C1F">
                        {item?.activity_count ? `${item.activity_count}개의 액티비티` : ""}
                      </Text>
                    </Wrapper>
                  </WrapperRow>
                </WrapperColumn>

              </WrapperColumn>
            </WrapperColumn>
          </Wrapper>
        </Link>

        {/* // 좋아요 
        <AbsoluteWrapper top="93px" right="8px" onClick={() => JjimStatus(item.activity_id)} style={{ cursor: "pointer" }}>
          <Icon width="20px" height="20px" src={wish ? FullHeart : EmptyHeart} />
        </AbsoluteWrapper>*/}

      </WrapperColumn>
    </>
  );
};

export default Item;

const ReviewCard = styled.div`
    background-image: ${({ img }) => `url(${img})`};
    background-size: 140px 140px;
    background-position: center;
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 8px;
`;

const RainbowBox = styled.div`
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 8px;
    padding: 3px 7px;
    background: radial-gradient(100% 843.76% at 0% 52.38%, #ff6759 0%, #8d4ef2 44.79%, #0e6dff 100%);
`;
