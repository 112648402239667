import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import KakaoMap from "../component/kakao_map";

function KakaoMapForRN() {
  const { address, extra, type } = useParams();

  return (
    <MapContainer>
      <KakaoMap map_address={address} map_address_etc={extra} update={true} />
    </MapContainer>
  );
}

const MapContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

export default KakaoMapForRN;
