import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { useLocation } from "react-router-dom";

const DrawerModal = ({ open, setOpen, children, right }) => {
  const { pathname } = useLocation();
  const ref = useRef();

  useEffect(() => {
    closeModal();
  }, [pathname]);

  const handleClickOutside = (event) => {
    if (open && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      window.addEventListener("click", handleClickOutside);
      return () => {
        window.removeEventListener("click", handleClickOutside);
      };
    }
  }, [open]);

  const closeModal = () => {
    // setOpen(false);
    document.getElementById("root").style.removeProperty("overflow");
    document.getElementById("root").style.pointerEvents = "auto";
  };

  return (
    <>
      <Container right={right} open={open} ref={ref}>
        <ContentBox id="drawer">{children}</ContentBox>
      </Container>
      <Box right={right} open={open} />
    </>
  );
};

export default DrawerModal;

const Container = styled.section`
  background-color: white;
  width: 375px;
  left: ${({ right }) => right && `${right}px`};
  position: fixed;
  top: 75px;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(420px)")};
  transition: transform 0.3s ease;
  box-shadow: ${({ open }) => (open ? "-17px 25px 24px rgba(0, 0, 0, 0.06)" : "")};
  pointer-events: auto !important;
  z-index: 19;
`;
const Box = styled.div`
  background-color: white;
  width: 1000px;
  height: 100%;
  left: ${({ right }) => right && `${right + 380}px`};
  /* opacity: ${({ open }) => (open ? "0" : "1")}; */
  ${({ open }) =>
    open &&
    css`
      opacity: 0;
      transition-delay: 0.3s, 3ms;
    `}
  position: fixed;
  top: 75px;
  z-index: 20;
`;

const ContentBox = styled.div`
  width: 100%;
  height: calc(100vh - 75px);
  overflow: hidden auto;
`;
