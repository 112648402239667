import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import axios from "axios";

// componetns
import { LoadingSpinner } from "../tool/loading";
import colors from "../styles/colors";

// icon
import SpoexBackX from "../icon/spoex/spoexBackX.png";
import SpoexSearch from "../icon/spoex/spoexSearch.png";
import MapModalClose from "../icon/MapModalClose.png";

const SportsPlace = ({ setOpen, setSelected }) => {
  // useState
    const [text, setText] = useState("");
    const [location, setLocation] = useState([]);
    const [Kakao, setKakao] = useState();
    const [KakaoMap, setKakaoMap] = useState();
    const [KakaoSearch, setKakaoSearch] = useState();
    const [KakaoInfoWindow, setKakaoInfoWindow] = useState();
    const [Markers, setMarkers] = useState([]);
    const [MapOpen, setMapOpen] = useState(1);
    const [loading, setLoading] = useState(false);
    const [mapBound, setMapBound] = useState();
    const [mapCenter, setMapCenter] = useState();
    const [mapModal, setMapModal] = useState(false);
    const [selectPlace, setSelectPlace] = useState();
    const [myPlace, setMyPlace] = useState();
    const [curruntPosition, setCurruntPosition] = useState();
    const [overlay, setOverlay] = useState();
    const [overlayList, setOverlayList] = useState([]);

    const GetPlace = (la, lo, bounds, kakao, map, infowindow) => {

      const SW = bounds.getSouthWest();
      const NE = bounds.getNorthEast();

      const SWA = SW.La;
      const SWO = SW.Ma;

      const NEA = NE.La;
      const NEO = NE.Ma;

      const frm = new FormData();
      frm.append("mode", "sports_place");
      frm.append("la", la);
      frm.append("lo", lo);
      frm.append("swa", SWA);
      frm.append("swo", SWO);
      frm.append("nea", NEA);
      frm.append("neo", NEO);
  
      axios
        .post("https://ai.semos.kr/semos_user/v4/activity/SportsPlace", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          let Value = data.data;
          let Status = Value.status;
          let List = Value.list;
          let MyPlace = Value.data;

          const addMarker = (position, idx, title, map, kakao) => {
            var imageSrc =
              "https://ai.semos.kr/img/RNAPP/PlaceMarker.png", // 마커 이미지 url, 스프라이트 이미지를 씁니다
              imageSize = new kakao.maps.Size(32, 32), // 마커 이미지의 크기
              imgOptions = {
                offset: new kakao.maps.Point(11, 15), // 마커 좌표에 일치시킬 이미지 내에서의 좌표
              },
              markerImage = new kakao.maps.MarkerImage(
                imageSrc,
                imageSize,
                imgOptions
              ),
              marker = new kakao.maps.Marker({
                position: position, // 마커의 위치
                image: markerImage,
              });
      
              marker.setMap(map); // 지도 위에 마커를 표출합니다
      
            return marker;
          }

          // 인포윈도우에 장소명을 표시합니다
          function displayInfowindow(marker, title, map, kakao) {

            var content = `<div style='
              font-size: 1.2rem;
              font-weight: 600;
              display: inline-block;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 130px ;
              color : ${colors.Gr05};
              border-radius: 5px;
              padding : 5px;
              position : relative;
              bottom : 15px;
              text-align : center;
              background-color : #ffffff;
              border : 2px solid ${colors.Bl07};
            '>${title}</div>`;

            var customOverlay = new kakao.maps.CustomOverlay({
              map: map,
              position: marker.getPosition(),
              content: content,
              yAnchor: 1 
            });

            setOverlay(customOverlay)
          }

          let MarkersList = []
  
          if (Status == "success") {
            for (let i = 0; i < List.length; i++) {

              // 마커를 생성하고 지도에 표시합니다
              var placePosition = new kakao.maps.LatLng(List[i].y, List[i].x),
                marker = addMarker(placePosition, i, List[i].title, map, kakao);

              MarkersList[MarkersList.length] = marker;

              (function (marker, title, map, kakao) {
                kakao.maps.event.addListener(marker, "click", function () {
                  setMapModal(true)
                  setSelectPlace(List[i])

                  displayInfowindow(marker, title, map, kakao);
                });
      
              })(marker, List[i].title, map, kakao);
            }

            var imageSrc =
              "https://ai.semos.kr/img/RNAPP/myPlace.png", // 마커 이미지 url, 스프라이트 이미지를 씁니다
            imageSize = new kakao.maps.Size(24, 24), // 마커 이미지의 크기
            imgOptions = {
              offset: new kakao.maps.Point(8, 12), // 마커 좌표에 일치시킬 이미지 내에서의 좌표
            },
            markerImage = new kakao.maps.MarkerImage(
              imageSrc,
              imageSize,
              imgOptions
            ),
            markerPosition = new kakao.maps.LatLng(la, lo),
            marker = new kakao.maps.Marker({
              position: markerPosition, // 마커의 위치
              image: markerImage,
            });

            marker.setMap(map); // 지도 위에 마커를 표출합니다

            MarkersList[MarkersList.length] = marker;

            setMarkers(MarkersList);

            setLocation(List)

            setKakaoInfoWindow(infowindow);
            setMyPlace(MyPlace)
          } else {

          }
        });
    }

    const GetLocation = async (kakao) => {

        // 지도 위에 표시되고 있는 마커를 모두 제거합니다
        function removeMarker() {
          for (var i = 0; i < Markers.length; i++) {
            Markers[i].setMap(null);
          }
          setMarkers([]);
        }

        // 지도에 표시되고 있는 마커를 제거합니다
        removeMarker();

        setLoading(true);

        window.navigator.geolocation.getCurrentPosition( (position) => {
          let Lo = 126.9786567;
          let La = 37.566826;
          
          if(position) {
              Lo = position.coords.longitude;
              La = position.coords.latitude;

              setCurruntPosition({
                lo : Lo,
                la : La
              })
          }

          var mapContainer = document.getElementById("map"), // 지도를 표시할 div
            mapOption = {
              center: new kakao.maps.LatLng(La, Lo), // 지도의 중심좌표
              level: 5, // 지도의 확대 레벨
            };

          // 지도를 생성합니다
          var map = new kakao.maps.Map(mapContainer, mapOption);
          // 장소 검색 객체를 생성합니다
          var ps = new kakao.maps.services.Places();
          // 검색 결과 목록이나 마커를 클릭했을 때 장소명을 표출할 인포윈도우를 생성합니다
          var infowindow = new kakao.maps.InfoWindow({ zIndex: 1 });

          var bounds = map.getBounds();
          var center = map.getCenter(); 

          setMapBound(bounds)
          setMapCenter(center)

          GetPlace(La, Lo, bounds, kakao, map, infowindow);

          setKakaoMap(map);
          setKakaoSearch(ps);
          setLoading(false);

        });
    }

  const SearchLocation = () => {
    const { kakao } = window;
    // 키워드 검색을 요청하는 함수입니다

    // 지도 위에 표시되고 있는 마커를 모두 제거합니다
    function removeMarker() {
      for (var i = 0; i < Markers.length; i++) {
        Markers[i].setMap(null);
      }
      setMarkers([]);
    }

    // 인포윈도우에 장소명을 표시합니다
    function displayInfowindow(marker, title) {
      var content = '<div style="padding:5px;z-index:1;">' + title + "</div>";

      KakaoInfoWindow.setContent(content);
      KakaoInfoWindow.open(KakaoMap, marker);
    }

    // 마커를 생성하고 지도 위에 마커를 표시하는 함수입니다
    function addMarker(position, idx, title) {
      var imageSrc =
          "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_number_blue.png", // 마커 이미지 url, 스프라이트 이미지를 씁니다
        imageSize = new kakao.maps.Size(36, 37), // 마커 이미지의 크기
        imgOptions = {
          spriteSize: new kakao.maps.Size(36, 691), // 스프라이트 이미지의 크기
          spriteOrigin: new kakao.maps.Point(0, idx * 46 + 10), // 스프라이트 이미지 중 사용할 영역의 좌상단 좌표
          offset: new kakao.maps.Point(13, 37), // 마커 좌표에 일치시킬 이미지 내에서의 좌표
        },
        markerImage = new kakao.maps.MarkerImage(
          imageSrc,
          imageSize,
          imgOptions
        ),
        marker = new kakao.maps.Marker({
          position: position, // 마커의 위치
          image: markerImage,
        });

        marker.setMap(KakaoMap); // 지도 위에 마커를 표출합니다
        setMarkers([marker, ...Markers]);

      return marker;
    }

    // 검색 결과 목록과 마커를 표출하는 함수입니다
    const displayPlaces = (places) => {
      var bounds = new kakao.maps.LatLngBounds();

      // 지도에 표시되고 있는 마커를 제거합니다
      removeMarker();

      for (let i = 0; i < places.length; i++) {
        // 마커를 생성하고 지도에 표시합니다
        var placePosition = new kakao.maps.LatLng(places[i].y, places[i].x),
          marker = addMarker(placePosition, i);

        // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
        // LatLngBounds 객체에 좌표를 추가합니다
        bounds.extend(placePosition);

        // 마커와 검색결과 항목에 mouseover 했을때
        // 해당 장소에 인포윈도우에 장소명을 표시합니다
        // mouseout 했을 때는 인포윈도우를 닫습니다
        (function (marker, title, overlay) {
          kakao.maps.event.addListener(marker, "mouseover", function () {
            displayInfowindow(marker, title, overlay);
          });

          kakao.maps.event.addListener(marker, "mouseout", function () {
            KakaoInfoWindow.close();
          });
        })(marker, places[i].place_name, overlay);
      }

      // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다
      KakaoMap.setBounds(bounds);
    };

    // 장소검색이 완료됐을 때 호출되는 콜백함수 입니다
    const placesSearchCB = (data, status, pagination) => {
      if (status === kakao.maps.services.Status.OK) {
        displayPlaces(data);
        setLocation(data);
        //SendAddredd(data, S, C, Sports);
      } else if (status === kakao.maps.services.Status.ZERO_RESULT) {
        console.log("검색한 장소와 일치하는 장소가 없어요.");
        return;
      } else if (status === kakao.maps.services.Status.ERROR) {
        console.log("인터넷 연결 상태가 불안정해요.\n검색 결과 중 오류가 발생했습니다.");
        return;
      }
    };

    var keyword = text;
    setText(keyword)

    if (!keyword.replace(/^\s+|\s+$/g, "")) {
      return false;
    }

    // 장소검색 객체를 통해 키워드로 장소검색을 요청합니다
    KakaoSearch.keywordSearch(keyword, placesSearchCB);
  };

  const LoadMap = async () => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://dapi.kakao.com/v2/maps/sdk.js?appkey=4233c0cfe93da40e79ffcd370034aa14&autoload=false&libraries=services";

    document.head.appendChild(script);

    script.onload = async () => {
        const { kakao } = window;

      await kakao.maps.load( async () => {
        await GetLocation(kakao);
        setKakao(kakao);
      });
    };
  };

  const EnterKey = (KeyCode) => {
    if (KeyCode == 13) {
      SearchLocation();
    }
  };

  const SelectLocation = (item) => {
    const roadAddress = item.road_address_name;
    const buildingName = item.place_name;
    const x = item.x;
    const y = item.y;
    setSelected((prev) => ({
      ...prev,
      address: roadAddress,
      location: buildingName,
      x: x,
      y: y,
    }));

    setOpen(0);
  };

  useEffect(() => {
    LoadMap();
  }, []);

  useEffect(() => {

    let WatchId =  window.navigator.geolocation.watchPosition( (position) => {
      let Lo = 126.9786567;
      let La = 37.566826;
      
      if(position) {
          Lo = position.coords.longitude;
          La = position.coords.latitude;

          setCurruntPosition({
            lo : Lo,
            la : La
          })
      }
    },
    (err) => {
        console.error(`ERROR(${err.code}): ${err.message}`);
    },
    {
      timeout: 60000
    }
    )

    return () => window.navigator.geolocation.clearWatch(WatchId);

  }, []);

  useEffect(() => {
    if(curruntPosition) {
      let MarkersList = Markers;

      if(curruntPosition.la && curruntPosition.lo && MarkersList?.length) {

        var imageSrc =
          "https://ai.semos.kr/img/RNAPP/myPlace.png", // 마커 이미지 url, 스프라이트 이미지를 씁니다
        imageSize = new Kakao.maps.Size(24, 24), // 마커 이미지의 크기
        imgOptions = {
          offset: new Kakao.maps.Point(8, 12), // 마커 좌표에 일치시킬 이미지 내에서의 좌표
        },
        markerImage = new Kakao.maps.MarkerImage(
            imageSrc,
            imageSize,
            imgOptions
        ),
        markerPosition = new Kakao.maps.LatLng(curruntPosition.la, curruntPosition.lo),
        marker = new Kakao.maps.Marker({
          position: markerPosition, // 마커의 위치
          image: markerImage,
        });
  
        MarkersList[(MarkersList.length - 1)].setMap(null)
        marker.setMap(KakaoMap); // 지도 위에 마커를 표출합니다
        MarkersList[MarkersList.length] = marker;
  
        setMarkers([...MarkersList]);
      }
    }
  }, [curruntPosition]);


  useEffect(() => {
    if(overlay) {
      let OLL = overlayList

      if(OLL.length > 0) {

        let LastOverlay =  OLL[(OLL.length - 1)];

        LastOverlay.setMap(null);
      }

      OLL[(OLL.length)] = overlay;

      setOverlayList([...OLL]);
    }
  }, [overlay]);

  const CloseOverlay = () => {
    let OLL = overlayList

    if(OLL.length > 0) {

      let LastOverlay =  OLL[(OLL.length - 1)];

      LastOverlay.setMap(null);
    }
  }
  
  const SearchAgianNow = () => {

      const addMarker = (position, idx, title, map, kakao) => {
        var imageSrc =
          "https://ai.semos.kr/img/RNAPP/PlaceMarker.png", // 마커 이미지 url, 스프라이트 이미지를 씁니다
          imageSize = new kakao.maps.Size(32, 32), // 마커 이미지의 크기
          imgOptions = {
            offset: new kakao.maps.Point(11, 15), // 마커 좌표에 일치시킬 이미지 내에서의 좌표
          },
          markerImage = new kakao.maps.MarkerImage(
            imageSrc,
            imageSize,
            imgOptions
          ),
          marker = new kakao.maps.Marker({
            position: position, // 마커의 위치
            image: markerImage,
          });

          marker.setMap(map); // 지도 위에 마커를 표출합니다

        return marker;
      }

      CloseOverlay()
      setMapModal(false)
      setSelectPlace()
      setOverlay()
      setLoading(true);

      // 지도 위에 표시되고 있는 마커를 모두 제거합니다
      function removeMarker() {
        for (var i = 0; i < Markers.length; i++) {
          Markers[i].setMap(null);
        }
        setMarkers([]);
      }

      // 지도에 표시되고 있는 마커를 제거합니다
      removeMarker();

      let lo = curruntPosition.lo;
      let la = curruntPosition.la;
          
      let kakao = Kakao;
      let map = KakaoMap;
      let infowindow = KakaoInfoWindow;

      let bounds = map.getBounds();
      let center = map.getCenter(); 

      setMapBound(bounds)
      setMapCenter(center)

      const SW = bounds.getSouthWest();
      const NE = bounds.getNorthEast();

      const SWA = SW.La;
      const SWO = SW.Ma;

      const NEA = NE.La;
      const NEO = NE.Ma;

      const frm = new FormData();
      frm.append("mode", "sports_place");
      frm.append("la", la);
      frm.append("lo", lo);
      frm.append("swa", SWA);
      frm.append("swo", SWO);
      frm.append("nea", NEA);
      frm.append("neo", NEO);
  
      axios
        .post("https://ai.semos.kr/semos_user/v4/activity/SportsPlace", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          let Value = data.data;
          let Status = Value.status;
          let List = Value.list;
          let MyPlace = Value.data;

          // 인포윈도우에 장소명을 표시합니다
          function displayInfowindow(marker, title, map, kakao) {

            var content = `<div style='
              font-size: 1.2rem;
              font-weight: 600;
              display: inline-block;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 130px ;
              color : ${colors.Gr05};
              border-radius: 5px;
              padding : 5px;
              position : relative;
              bottom : 15px;
              text-align : center;
              background-color : #ffffff;
              border : 2px solid ${colors.Bl07};
            '>${title}</div>`;

            var customOverlay = new kakao.maps.CustomOverlay({
              map: map,
              position: marker.getPosition(),
              content: content,
              yAnchor: 1 
            });

            setOverlay(customOverlay)
          }

          let MarkersList = []
  
          if (Status == "success") {
            for (let i = 0; i < List.length; i++) {

              // 마커를 생성하고 지도에 표시합니다
              var placePosition = new kakao.maps.LatLng(List[i].y, List[i].x),
                marker = addMarker(placePosition, i, List[i].title, map, kakao);

              MarkersList[MarkersList.length] = marker;

              (function (marker, title, map, kakao) {
                kakao.maps.event.addListener(marker, "click", function () {
                  setMapModal(true)
                  setSelectPlace(List[i])

                  displayInfowindow(marker, title, map, kakao);
                });
      
              })(marker, List[i].title, map, kakao);
            }

            var imageSrc =
              "https://ai.semos.kr/img/RNAPP/myPlace.png", // 마커 이미지 url, 스프라이트 이미지를 씁니다
            imageSize = new kakao.maps.Size(24, 24), // 마커 이미지의 크기
            imgOptions = {
              offset: new kakao.maps.Point(8, 12), // 마커 좌표에 일치시킬 이미지 내에서의 좌표
            },
            markerImage = new kakao.maps.MarkerImage(
              imageSrc,
              imageSize,
              imgOptions
            ),
            markerPosition = new kakao.maps.LatLng(la, lo),
            marker = new kakao.maps.Marker({
              position: markerPosition, // 마커의 위치
              image: markerImage,
            });

            marker.setMap(map); // 지도 위에 마커를 표출합니다

            MarkersList[MarkersList.length] = marker;

            setMarkers(MarkersList);

            setLocation(List)

            setKakaoInfoWindow(infowindow);
            setMyPlace(MyPlace)
          } else {

          }
        });

        setLoading(false);
  }

  return (
    <>
      <Container>
        <Body className="spoex">
          <SearchBox>
            <Header>
              <img onClick={() => setOpen(0)} src={SpoexBackX} />
              <span>세모스 플레이스</span>
            </Header>
            {/*<LocationSearch>
              <input
                placeholder="장소 이름이나 주소를 입력해주세요."
                onKeyUp={(e) => EnterKey(e.keyCode)}
                maxLength={30}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                  setMapOpen(0);
                }}
              />
              <img src={SpoexSearch} onClick={() => SearchLocation()} />
            </LocationSearch>*/}
          </SearchBox>
          {
            MapOpen == 0 &&
            <Absolute>
              {location.map((item, idx) => (
                <LocationCard
                  onClick={() => SelectLocation(item)}
                  key={`${idx}_LoCa`}
                >
                  <div className="Title">
                    {item.title}
                    {/*<span>{` ${item.sports}`}</span>*/}
                  </div>

                  <div className="Info">
                    {`${item.sports} ${item.distance ? ` | ${item.distance}km` : ""}`}
                  </div>

                  <div className="Address">
                    {item.road_address
                      ? item.road_address
                      : item.address}
                  </div>
                </LocationCard>
              ))}
            </Absolute>
          }

          <Map id={`map`} />

          <ChangeMode infoCard={mapModal} onClick={() => {
              setMapOpen(MapOpen == 1 ? 0 : 1)
              setMapModal(false)
              CloseOverlay()
            }}>
              {MapOpen == 1 ? `목록보기` : `지도보기`}
          </ChangeMode>

          <SearchAgain onClick={() => SearchAgianNow()}>
            현 지도에서 검색
          </SearchAgain>
          
          {
            mapModal ?
              <InfoCard>

                <InfoCardInner>

                  <InfoTitle>{selectPlace ? selectPlace.title : ""}</InfoTitle>
                  <InfoExtraInfo>
                    {selectPlace?.sports ? `${selectPlace?.sports}` : ""}
                    {selectPlace?.distance ? ` | ${selectPlace?.distance}km` : "" }  
                  </InfoExtraInfo>

                  <InfoSubTitle>{selectPlace?.road_address ? `주소 : ${selectPlace?.road_address}` : `주소 : ${selectPlace?.address}`}</InfoSubTitle>

                  {selectPlace?.phone ?
                    <InfoCall href={`tel:${selectPlace?.phone}`}>Call : { selectPlace?.phone }</InfoCall>
                  :
                    <InfoCall>{ `정보 수정 제안` }</InfoCall>
                  }

                  <InfoCardClose onClick={() => {
                    setMapModal(false)
                    CloseOverlay()
                  }} src={MapModalClose} />

                </InfoCardInner>

              </InfoCard>
            : ""
          }

        </Body>

      </Container>

      <LoadingSpinner loading={loading} />
    </>
  );
};

export default SportsPlace;

const Container = styled.div`
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  position: realtive;
`;

const Body = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: inline-block;
  position: relative;
  background-color: #ffffff;
`;

const Map = styled.div`
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 50px);
  height: calc((var(--vh, 1vh) * 100) - 50px);
  display: inline-block;
  position: relative;
  background-color: #ffffff;
`;

const ChangeMode = styled.div`
  position: absolute;
  bottom: ${(props) => (props.infoCard ? "190px" : "30px")};
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1100;
  background-color: #ffffff;
  border : ${colors.Bl07} 2px solid;
  color: ${colors.Bl07};
  cursor: pointer;
  padding: 5px 12px;
  border-radius: 12px;
  font-size: 1.4rem;
  font-weight: 700;
`;

const SearchAgain = styled.div`
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1098;
  background-color: #ffffff;
  border : ${colors.Bl07} 2px solid;
  color: ${colors.Bl07};
  cursor: pointer;
  padding: 5px 12px;
  border-radius: 12px;
  font-size: 1.4rem;
  font-weight: 700;
`;

const Absolute = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 50px);
  height: calc((var(--vh, 1vh) * 100) - 50px);
  background-color: #ffffff;
  display: inline-block;
  z-index: 1099;
  padding: 0 20px 70px 20px;
  overflow: hidden auto;
`;

const SearchBox = styled.div`
  width: 100%;
  max-width: 100%;
  height: 50px;
  position: relative;
  display: inline-block;
  z-index: 1005;
  background-color: #ffffff;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.3);
`;

const Header = styled.div`
  width: 100%;
  max-width: 100%;
  height: 45px;
  position: relative;
  display: inline-flex;
  z-index: 1002;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  padding: 0 45px;

  img {
    position: absolute;
    top: 50%;
    left: 24px;
    width: 24px;
    display: inline-block;
    cursor: pointer;
    transform: translate(0, -50%);
  }
`;

const LocationSearch = styled.div`
  width: 100%;
  max-width: 100%;
  height: 50px;
  position: relative;
  display: inline-flex;
  z-index: 1003;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 0 20px;

  input {
    width: 100%;
    height: 50px;
    display: inline-flex;
    padding: 0 45px 0 15px;
    border: 2px solid #cadffe;
    border-radius: 12px;
  }

  img {
    width: 26px;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translate(0, -50%);
    cursor: pointer;
  }
`;

const InfoCard = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  width : calc(100% - 20px);
  padding : 10px 15px; 
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.3);
  z-index: 1003;
  display: inline-flex;
`;

const InfoCardClose = styled.img`
  display : inline-block;
  position : absolute;
  top : 0;
  right : 0;
  width : 26px;
`;

const InfoCardInner = styled.div`
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  width : 100%;
`;

const InfoTitle = styled.span`
    font-size: 1.6rem;
    font-weight: 600;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin-bottom: 5px;
    max-width: calc(100% - 30px);
`;

const InfoSubTitle = styled.span`
    font-size: 1.4rem;
    font-weight: 600;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;
    color : ${colors.Gr04};
`;

const InfoExtraInfo = styled.span`
    font-size: 1.4rem;
    font-weight: 600;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;
    color : ${colors.Gr03};
`;

const InfoCall = styled.a`
    font-size: 1.6rem;
    font-weight: 600;
    display: inline-flex;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin-top: 10px;
    color : ${colors.White};
    border-radius: 8px;
    width : 100%;
    align-items : center;
    justify-content : center;
    height : 40px;
    background-color : ${colors.Bl07};
`;

const LocationCard = styled.div`
  width: 100%;
  display: inline-block;
  padding: 20px 0;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  .Title {
    font-size: 1.6rem;
    font-weight: 700;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin-bottom: 5px;

    span {
      font-size: 1rem;
      font-weight: 500;
      display: inline-block;
      color: #818792;
      margin-left: 5px;
    }
  }

  .Info {
    font-size: 1.2rem;
    font-weight: 600;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    color: ${colors.Gr03};
    margin-bottom: 5px;
  }

  .Address {
    font-size: 1.4rem;
    font-weight: 600;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    color: ${colors.Gr04};
  }
`;
