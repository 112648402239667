import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import {
  Text,
  Wrapper,
  Icon,
  WrapperRow,
  Input,
  AbsoluteWrapper,
  WrapperColumn,
  Radio,
} from "../../component/layout/layoutStorage";

import { ReactComponent as FilterArrow } from "../../icon/filterArrow.svg";
import { ReactComponent as Xbutton } from "../../icon/modalx.svg";
import { ReactComponent as Reset } from "../../icon/modalreset.svg";

const CategoryList = [
  {
    text: "전체보기",
    value: "all",
  },
  {
    text: "워터스포츠",
    value: "water",
  },
  {
    text: "윈터스포츠",
    value: "snow",
  },
  {
    text: "실내스포츠",
    value: "indoor",
  },
];

const SportList = {
  all: [
    {
      text: "전체보기",
      value: "스포츠",
    },
  ],

  water: [
    {
      text: "워터스포츠 전체",
      value: "water_all",
    },
    {
      text: "스쿠버다이빙",
      value: "스쿠버다이빙",
    },
    {
      text: "프리다이빙",
      value: "프리다이빙",
    },
    {
      text: "서핑",
      value: "서핑",
    },
    {
      text: "패들보드",
      value: "패들보드",
    },
    {
      text: "수영 / 스노클링",
      value: "수영/스노클링",
    },
  ],

  snow: [
    {
      text: "윈터스포츠 전체",
      value: "snow_all",
    },
    {
      text: "스노보드",
      value: "스노보드",
    },
    {
      text: "스키",
      value: "스키",
    },
  ],

  indoor: [
    {
      text: "실내스포츠 전체",
      value: "indoor_all",
    },
    {
      text: "사격",
      value: "사격",
    },
  ],
};

const SportFilter = ({ data, setData, filter }) => {
  const ref = useRef();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const SportPopupData = {
    water_all: "water",
    snow_all: "snow",
    스포츠: "all",
    indoor_all: "indoor",

    스노보드: "snow",
    스키: "snow",

    프리다이빙: "water",
    스쿠버다이빙: "water",
    서핑: "water",
    패들보드: "water",
    "수영/스노클링": "water",

    사격: "indoor",
  };

  const [category, setCategory] = useState(SportPopupData[data]);
  const [sport, setSport] = useState(data);

  const handleClose = (e) => {
    if (open && (!ref.current || !ref.current.contains(e.target))) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [open]);

  const CategoryHandler = (type) => {
    setCategory(type);

    if (type === "all") {
      setSport("스포츠");
    } else if (type === "water") {
      setSport("water_all");
    } else if (type === "snow") {
      setSport("snow_all");
    } else if (type === "indoor") {
      setSport("indoor_all");
    }
  };

  const CategoryReset = () => {
    setCategory("all");
    setSport("스포츠");
  };

  const setting = () => {
    navigate("/activity_list", {
      state: {
        sport: sport,
        where: filter.where ? filter.where : "",
        when: filter.when ? filter.when : "",
        type: filter.type
          ? filter.type == "유형"
            ? "액티비티"
            : filter.type
          : "",
        duration: filter.duration ? filter.duration : "",
        price: filter.price ? filter.price : "",
        facility: filter.facility ? filter.facility : "",
        search: filter.search ? filter.search : "",
        semos: filter.semos,
        near: filter.near,
        full: filter.full,
      },
    });
    setTimeout(() => {
      window.location.reload();
    }, 2);

    // setData((prev) => ({ ...prev, sport }));
    setOpen(false);
  };

  return (
    <Wrapper margin="0 8px 8px 0" ref={ref}>
      <Wrapper
        padding="5px 11px"
        border={data !== "스포츠" ? "1px solid #3383FF" : "1px solid #dcdcdc"}
        bgc={data !== "스포츠" ? "#F2F7FF" : "transparent"}
        cursor="pointer"
        radius="10px"
        onClick={() => setOpen((prev) => !prev)}
      >
        <Text
          size="13px"
          weight="500"
          color={data !== "스포츠" ? "#3383FF" : "#818792"}
        >
          {data == "water_all"
            ? "워터스포츠 전체"
            : data == "snow_all"
            ? "윈터스포츠 전체"
            : data == "indoor_all"
            ? "실내스포츠 전체"
            : data}
        </Text>

        <FilterArrow
          fill={data !== "스포츠" ? "#3383FF" : "#818792"}
          width="14px"
          height="18px"
          style={{
            marginLeft: "4px",
            transform: open ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "all 0.3s ease",
          }}
        />
      </Wrapper>

      {open ? (
        <AbsoluteWrapper
          padding="24px"
          top="40px"
          width="375px"
          height="676px"
          bgc="#ffffff"
          radius="8px"
          border="1px solid #dcdcdc"
          style={{ zIndex: 2 }}
          ai="flex-start"
        >
          <WrapperColumn width="100%" height="100%" jc="space-between">
            <WrapperColumn width="100%">
              <Wrapper jc="space-between">
                <Wrapper>
                  <Text size="18px" color="#1a1c1f" weight="600">
                    스포츠 선택
                  </Text>

                  <Wrapper width="12px" />

                  <Wrapper
                    ai="center"
                    padding="2px 0 0 0"
                    cursor="pointer"
                    onClick={() => CategoryReset()}
                  >
                    <Text size="14px" weight="600" color="#0e6dff">
                      선택 초기화
                    </Text>
                    <Wrapper width="2px" />
                    <Reset width="20px" height="20px" />
                  </Wrapper>
                </Wrapper>

                <Xbutton
                  width="28px"
                  height="28px"
                  onClick={() => setOpen(false)}
                  style={{ cursor: "pointer" }}
                />
              </Wrapper>

              <Wrapper height="21px" />

              <Wrapper width="100%" ai="flex-start">
                <WrapperColumn width="40%">
                  {CategoryList.map((item) => (
                    <Wrapper
                      padding="16px 18px"
                      bb={
                        item.value == category
                          ? "1px solid #0e6dff"
                          : "1px solid #dcdcdc"
                      }
                      onClick={() => CategoryHandler(item.value)}
                      cursor="pointer"
                    >
                      <Text
                        size="14px"
                        weight="600"
                        color={item.value == category ? "#0e6dff" : "#818792"}
                      >
                        {item.text}
                      </Text>
                    </Wrapper>
                  ))}
                </WrapperColumn>

                <WrapperColumn width="80%">
                  {SportList[category]
                    ? SportList[category].map((item) => (
                        <Wrapper
                          padding="16px 0 16px 18px"
                          bb={
                            item.value == sport
                              ? "1px solid #0e6dff"
                              : "1px solid #f5f5f5"
                          }
                          jc="space-between"
                          onClick={() => setSport(item.value)}
                          cursor="pointer"
                        >
                          <Text
                            size="14px"
                            weight="600"
                            color={item.value == sport ? "#0e6dff" : "#818792"}
                          >
                            {item.text}
                          </Text>

                          <Radio
                            width="16"
                            padding="4"
                            border={
                              item.value == sport
                                ? "1px solid #0e6dff"
                                : "1px solid #dcdcdc"
                            }
                            radius="50%"
                            margin="0 4px 0 0"
                            bgc={item.value == sport ? "#0e6dff" : "#ffffff"}
                          >
                            <div />
                          </Radio>
                        </Wrapper>
                      ))
                    : ""}
                </WrapperColumn>
              </Wrapper>
            </WrapperColumn>

            <Wrapper
              width="100%"
              bgc="#0e6dff"
              padding="16px 0"
              radius="8px"
              jc="center"
              margin="0 0 18px 0"
              cursor="pointer"
              onClick={() => setting()}
            >
              <Text size="16px" weight="600" color="#ffffff">
                선택한 스포츠 찾기
              </Text>
            </Wrapper>
          </WrapperColumn>
        </AbsoluteWrapper>
      ) : (
        ""
      )}
    </Wrapper>
  );
};

export default SportFilter;
