import React, { useState, useEffect, useRef } from "react";

import {
  Icon,
  Wrapper,
  Text,
  WrapperColumn,
  AbsoluteWrapper,
} from "../component/layout/layoutStorage";

import BlueArrow from "../icon/blueArrow.png";
import fullStar from "../icon/fullStar.svg";
import { ReactComponent as RightArrowIcon } from "../icon/carouselArrowRight.svg";
import { ReactComponent as LeftArrowIcon } from "../icon/carouselArrowLeft.svg";
import ReviewItem from "./reviewItem";

const Mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

const FloatingReview = ({
  id,
  type,
  review,
  setReview,
  filter,
  setFilter,
  page,
  setPage,
  values,
  setValues,
  max,
  setMax,
  totalPages,
  setTotalPages,
  setReviewImg,
  reviewImg,
  photo,
  setPhoto,
  setStartImg,
  getData,
}) => {
  const [open, setOpen] = useState(false);

  const ref = useRef();

  const FilterTitleHandler = () => {
    switch (filter) {
      case "high_rate":
        return "리뷰 별점 높은 순";
        break;

      case "low_rate":
        return "리뷰 별점 낮은 순";
        break;

      case "new":
        return "최신순";
        break;
    }
  };

  const SortList = [
    {
      text: "리뷰 별점 높은 순",
      value: "high_rate",
    },
    {
      text: "리뷰 별점 낮은 순",
      value: "low_rate",
    },
    {
      text: "최신순",
      value: "new",
    },
  ];

  const handleClose = (e) => {
    if (open && (!ref.current || !ref.current.contains(e.target))) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [open]);

  return (
    <WrapperColumn>
      {review.list?.length > 0 ? (
        <>
          <Wrapper jc="space-between" margin={Mobile ? "0px" : "0 24px"}>
            <Wrapper>
              <Text size="20px" weight="700" color="#1a1c1f">
                리뷰
              </Text>
              <Wrapper width="4px" />
              <Text size="16px" weight="500" color="#0e6dff">
                {review.count}
              </Text>
            </Wrapper>

            {/*<Wrapper
              cursor="pointer"
              onClick={() => setOpen((prev) => !prev)}
              ref={ref}
            >
              <Text size="13px" weight="500" color="#0e6dff">
                {FilterTitleHandler()}
              </Text>
              <Icon
                src={BlueArrow}
                width="16px"
                height="16px"
                style={{ marginLeft: "4px" }}
                transform={open ? "rotate(-180deg)" : "rotate(0deg)"}
                transition="all 0.3s ease"
              />
            </Wrapper>*/}

            {open ? (
              <AbsoluteWrapper
                top="30px"
                right="0"
                width="102px"
                bgc="#ffffff"
                radius="8px"
                border="1px solid #dcdcdc"
                style={{ zIndex: 2 }}
                ai="flex-start"
              >
                <WrapperColumn width="100%">
                  {SortList.map((item, index) => (
                    <Wrapper
                      key={index}
                      width="100%"
                      padding="10px"
                      bb={
                        index + 1 != SortList.length ? "1px solid #dcdcdc" : ""
                      }
                      cursor="pointer"
                      onClick={() => {
                        setFilter(item.value);
                        setOpen(false);
                      }}
                    >
                      <Text size="12px" weight="500" color="#818792">
                        {item.text}
                      </Text>
                    </Wrapper>
                  ))}
                </WrapperColumn>
              </AbsoluteWrapper>
            ) : (
              ""
            )}
          </Wrapper>

          <Wrapper
            margin={Mobile ? "24px 0px 18px 0px" : "24px 24px 18px 24px"}
            radius="15px"
            height="141px"
            border="1px solid #DCDCDC"
            jc="center"
            ai="center"
          >
            <WrapperColumn jc="center" ai="center">
              <Text
                size="12px"
                weight="500"
                ls="-0.02em"
                color="#3C3E41"
                style={{ marginBottom: "4px" }}
              >
                별점 평균
              </Text>
              <Icon
                src={fullStar}
                margin="19px 0 3px 0"
                width="48px"
                height="48px"
              />
              <Text size="14px" weight="600" ls="-0.02em" color="#3C3E41">
                {review.value}
              </Text>
            </WrapperColumn>

            <Wrapper margin="0 35px" width="1px" height="94px" bgc="#dcdcdc" />

            <WrapperColumn jc="center" ai="center">
              <Text
                size="12px"
                weight="500"
                ls="-0.02em"
                color="#3C3E41"
                style={{ marginBottom: "9px" }}
              >
                별점 비율
              </Text>
              <Wrapper gap="13px" style={{ flexDirection: "row-reverse" }}>
                {values
                  ? values.map((item, idx) => {
                      if (max == item) {
                        return (
                          <WrapperColumn
                            key={idx}
                            ai="center"
                            jc="center"
                            gap="4px"
                          >
                            <Text
                              size="10px"
                              weight="500"
                              ls="-0.02em"
                              color="#0E6DFF"
                            >
                              {item}
                            </Text>
                            <Wrapper
                              width="8px"
                              height="49px"
                              radius="8px"
                              bgc="#3383FF"
                            ></Wrapper>
                            <Text
                              size="12px"
                              weight="500"
                              ls="-0.02em"
                              color="#3C3E41"
                            >
                              {idx + 1}점
                            </Text>
                          </WrapperColumn>
                        );
                      } else {
                        return (
                          <WrapperColumn
                            key={idx}
                            ai="center"
                            jc="center"
                            gap="4px"
                          >
                            <Text
                              size="10px"
                              weight="500"
                              ls="-0.02em"
                              color="#DCDCDC"
                            >
                              {item}
                            </Text>
                            <Wrapper
                              position="relative"
                              width="8px"
                              height="49px"
                              radius="8px"
                              bgc="#F5F5F5"
                            >
                              <AbsoluteWrapper
                                bottom="0"
                                width="8px"
                                height={`${(item / max) * 100}%`}
                                radius={
                                  (item / max) * 100 === 100
                                    ? "8px 8px 8px 8px"
                                    : "0 0 8px 8px"
                                }
                                bgc="#DCDCDC"
                              />
                            </Wrapper>
                            <Text
                              size="12px"
                              weight="500"
                              ls="-0.02em"
                              color="#DCDCDC"
                            >
                              {idx + 1}점
                            </Text>
                          </WrapperColumn>
                        );
                      }
                    })
                  : ""}
              </Wrapper>
            </WrapperColumn>
          </Wrapper>

          <WrapperColumn margin={Mobile ? "0px" : "0 24px"}>
            {review.list?.map((item, idx) => (
              <ReviewItem
                photo={photo}
                setPhoto={setPhoto}
                setStartImg={setStartImg}
                reviewImg={reviewImg}
                setReviewImg={setReviewImg}
                key={`${item.review_id}_review_List`}
                idx={idx}
                reviewId={item.review_id}
                length={review.list?.length}
                reviewList={item}
                page={page}
                getData={getData}
                where="detail"
              />
            ))}
          </WrapperColumn>

          <Wrapper height="1px" bgc="#dcdcdc" />

          <Wrapper margin="24px 0 0 0" jc="center">
            <Wrapper ai="center">
              <Wrapper
                cursor="pointer"
                onClick={() => {
                  if (page == 1) return 0;
                  setPage((prev) => prev - 1);
                }}
              >
                <LeftArrowIcon fill={page === 1 ? "#dcdcdc" : "#1A1C1F"} />
              </Wrapper>

              <Text
                color="#acafb5"
                size="14px"
                weight="500"
              >{`${page}/${totalPages}`}</Text>

              <Wrapper
                cursor="pointer"
                onClick={() => {
                  if (page == totalPages) return 0;
                  setPage((prev) => prev + 1);
                }}
              >
                <RightArrowIcon
                  fill={page == totalPages ? "#dcdcdc" : "#1A1C1F"}
                />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </>
      ) : (
        <WrapperColumn
          margin="24px 24px 30px 24px"
          jc="center"
          ai="center"
          bgc="#E5F0FF"
          radius="15px"
          height="96px"
        >
          <Text size="14px" weight="500" ls="-0.02em" color="#3383FF">
            첫 리뷰어가 되어주세요!
          </Text>
          <Text size="14px" weight="500" ls="-0.02em" color="#3383FF">
            포토 리뷰 작성 시 2,000P를 드려요.
          </Text>
        </WrapperColumn>
      )}
    </WrapperColumn>
  );
};

export default FloatingReview;
