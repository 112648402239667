import { useEffect } from "react";


const PayFail = () => {


  useEffect(() => { 

    window.alert("결제 실패");
    
  }, [])


  return (
  
    <></>
  
  );

};


export default PayFail;