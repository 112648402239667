import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Icon, Wrapper, Text, WrapperColumn, WrapperRow, AbsoluteWrapper } from "../component/layout/layoutStorage";

import RightArrowB from "../icon/rightArrowB.png";
import RightArrowG from "../icon/rightArrowG.png";
import CS from "../icon/cs.png";
import Announcement from "../icon/announcement.png";
import WantHeart from "../icon/wantHeart.png";
import Review from "../icon/review.png";
import ReviewActive from "../icon/reviewActive.png";
import FAQ from "../icon/faq.png";
import Default from "../img/sehorang.png";
import BlueWarning from "../icon/reviewPlz.png";

const channel_link = "https://semos.kr/kakao_channel";
const partner_link = "https://www.notion.so/semos/efbf8795207341788e54c4ebe182586a";

const NewProfile = ({ open, setOpen, setModalStatus }) => {
  const [info, setInfo] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    var token = localStorage.getItem("token");

    if (token) {
      const frm = new FormData();
      frm.append("token", token);
      frm.append("mode", "my_info");

      axios
        .post("https://ai.semos.kr/semos_user/profile/profile_new", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;

          setInfo(Value.profile_data);
        });
    }
  }, [open]);

  if (info) {
    return (
      <>
        <WrapperColumn width="100%">
          <Wrapper width="100%" height="60px" padding="17px 0" jc="center" ai="center">
            <Text size="18px" color="#1A1C1F" weight="600">
              프로필
            </Text>
          </Wrapper>

          <Wrapper width="100%" height="1px" bgc="#F5F5F5" />

          <Wrapper
            width="100%"
            jc="space-between"
            padding="20px 24px"
            height="104px"
            cursor="pointer"
            onClick={() => {
              setModalStatus("revise");
              setTimeout(() => {
                setOpen(true);
              }, 0.5);
            }}
          >
            <WrapperRow>
              <Wrapper
                width="64px"
                height="64px"
                // radius="50%"
                margin="0 12px 0 0"
              >
                <Icon
                  //   src={Default}
                  src={info.profile_img && info.profile_img != "가입미완" ? info.profile_img : Default}
                  width="64px"
                  height="64px"
                  radius="50%"
                  of="cover"
                />
              </Wrapper>
              <WrapperColumn jc="center">
                <Text size="16px" color="#3C3E41" weight="600" margin="0 4px 0 0">
                  {info.name}
                </Text>
                <Text margin="4px 4px 0 0" size="10px" color="#ACAFB5" weight="600">
                  {info.login_type} 로그인
                </Text>
              </WrapperColumn>
            </WrapperRow>
            <Icon cursor="pointer" width="24px" height="24px" src={RightArrowB} />
          </Wrapper>

          <Wrapper width="100%" height="2px" bgc="#F5F5F5" />

          <Wrapper width="100%" jc="space-evenly" padding="20px 24px" height="103px">
            <WrapperColumn ai="center" jc="center" cursor="pointer" onClick={() => navigate("/wish_list")}>
              <Icon width="42px" height="42px" src={WantHeart} />
              <Text margin="4px 0 0 0" size="12px" color="#818792" weight="600">
                위시 리스트
              </Text>
            </WrapperColumn>

            <Wrapper width="2px" height="63px" bgc="#F5F5F5" />

            <WrapperColumn ai="center" jc="center" cursor="pointer" onClick={() => navigate("/review_list")}>
              <Icon width="42px" height="42px" src={info.can_review == "yes" ? ReviewActive : Review} />
              <Text margin="4px 0 0 0" size="12px" color="#818792" weight="600">
                리뷰
              </Text>
            </WrapperColumn>
          </Wrapper>

          {info.can_review == "yes" ? (
            <Wrapper padding="16px 24px 16px 31px" bgc="#e5f0ff" jc="space-between" margin="0 0 20px 0">
              <Icon src={BlueWarning} width="24px" height="24px" />

              <Text size="14px" weight="500" color="#3383ff">
                기간 안에 리뷰를 작성하지 않으면, 기회가 사라져요.
              </Text>

              <AbsoluteWrapper right="calc(25% + 3px)" top="-18px">
                <Triangle />
              </AbsoluteWrapper>
            </Wrapper>
          ) : (
            ""
          )}

          <Wrapper width="100%" padding="8px 24px 34px 24px">
            <Wrapper
              cursor="pointer"
              ai="center"
              radius="8px"
              border="1px solid #DCDCDC"
              width="100%"
              padding="18px"
              jc="space-between"
              onClick={() => {
                setModalStatus("point");
                setTimeout(() => {
                  setOpen(true);
                }, 0.5);
              }}
            >
              <Text size="14px" color="#818792" weight="600">
                포인트 / 쿠폰
              </Text>
              <Wrapper ai="center" jc="center">
                <WrapperRow gap="6.64px">
                  <Text size="16px" color="#0E6DFF" weight="600">
                    {info.point}P
                  </Text>
                  <Text size="16px" color="#818792" weight="600">
                    {` / `}
                  </Text>
                  <Text size="16px" color="#0E6DFF" weight="600">
                    0개
                  </Text>
                </WrapperRow>
                <Icon margin="0 0 0 22px" width="20px" height="22px" src={RightArrowG} />
              </Wrapper>
            </Wrapper>
          </Wrapper>

          <Wrapper width="100%" height="2px" bgc="#F5F5F5" />

          <WrapperColumn width="100%">
            <Wrapper padding="24px">
              <Text size="14px" color="#818792" weight="600">
                고객센터
              </Text>
            </Wrapper>

            <WrapperColumn padding="0 24px 34px 24px" gap="24px">
              <Wrapper
                cursor="pointer"
                jc="space-between"
                ai="center"
                width="100%"
                onClick={() => {
                  setModalStatus("announce");
                  setTimeout(() => {
                    setOpen(true);
                  }, 0.5);
                }}
              >
                <WrapperRow gap="12px" ai="center">
                  <Icon width="20px" height="20px" src={Announcement} />
                  <Text size="16px" color="#3C3E41" weight="600">
                    공지사항
                  </Text>
                </WrapperRow>
                <Icon width="20px" height="22px" src={RightArrowG} />
              </Wrapper>

              <Wrapper
                cursor="pointer"
                jc="space-between"
                ai="center"
                width="100%"
                onClick={() => {
                  setModalStatus("faq");
                  setTimeout(() => {
                    setOpen(true);
                  }, 0.5);
                }}
              >
                <WrapperRow gap="12px" ai="center">
                  <Icon width="20px" height="20px" src={FAQ} />
                  <Text size="16px" color="#3C3E41" weight="600">
                    자주하는 질문
                  </Text>
                </WrapperRow>
                <Icon width="20px" height="22px" src={RightArrowG} />
              </Wrapper>

              <a style={{ textDecoration: "none" }} href={channel_link} target="_blank">
                <Wrapper cursor="pointer" jc="space-between" ai="center" width="100%">
                  <WrapperRow gap="12px" ai="center">
                    <Icon width="20px" height="20px" src={CS} />
                    <Text size="16px" color="#3C3E41" weight="600">
                      고객센터 문의
                    </Text>
                    <Text size="14px" color="#ACAFB5" weight="600">
                      10:00 ~19:00
                    </Text>
                  </WrapperRow>
                  <Icon width="20px" height="22px" src={RightArrowG} />
                </Wrapper>
              </a>
            </WrapperColumn>

            <WrapperColumn padding="12px 24px 22px 24px" gap="24px">
              <Wrapper
                cursor="pointer"
                jc="space-between"
                ai="center"
                width="100%"
                onClick={() => {
                  setModalStatus("personal_info");
                  setTimeout(() => {
                    setOpen(true);
                  }, 0.5);
                }}
              >
                <Text size="16px" color="#3C3E41" weight="600">
                  개인정보처리방침
                </Text>
                <Icon width="20px" height="22px" src={RightArrowG} />
              </Wrapper>
              <Wrapper
                cursor="pointer"
                jc="space-between"
                ai="center"
                width="100%"
                onClick={() => {
                  setModalStatus("use_info");
                  setTimeout(() => {
                    setOpen(true);
                  }, 0.5);
                }}
              >
                <Text size="16px" color="#3C3E41" weight="600">
                  이용약관
                </Text>
                <Icon width="20px" height="22px" src={RightArrowG} />
              </Wrapper>
            </WrapperColumn>

            <WrapperColumn></WrapperColumn>

            <Wrapper width="100%" height="2px" bgc="#F5F5F5" />

            <Wrapper padding="39px 24px 24px 24px">
              <Linker href={partner_link} target="_blank" style={{ textDecoration: "none" }}>
                <Wrapper
                  cursor="pointer"
                  position="relative"
                  jc="space-between"
                  width="100%"
                  border="1px solid #DCDCDC"
                  padding="27px 20px 26px 20px"
                  radius="8px"
                >
                  <AbsoluteWrapper top="-15px" left="0" bgc="#0E6DFF" padding="6px 14px" radius="20px">
                    <GmarketSansSub>스키/스노보드의 파트너를 모집 중이에요</GmarketSansSub>
                  </AbsoluteWrapper>
                  <WrapperColumn>
                    <GmarketSans>파트너 (강사/센터) 신청하기</GmarketSans>

                    <Text size="12px" color="#818792" weight="500">
                      세모스와 함께, 세상의 모든 스포츠를 함께 이뤄 나가요.
                    </Text>
                  </WrapperColumn>

                  <Icon cursor="pointer" width="24px" height="24px" src={RightArrowB} />
                </Wrapper>
              </Linker>
            </Wrapper>
          </WrapperColumn>
        </WrapperColumn>
      </>
    );
  } else {
    return <></>;
  }
};

export default NewProfile;

const GmarketSans = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #3c3e41;

  font-family: "Gmarket Sans" !important;
`;

const GmarketSansSub = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #ffffff;

  font-family: "Gmarket Sans" !important;
`;

const Linker = styled.a`
  text-decoration: none;
  outline: none;

  &:hover {
    text-decoration: none;
    outline: none;
  }
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #e5f0ff;
  border-left: 9px solid transparent;
`;
