import React, { useState } from "react";
import axios from "axios";

import {
  Text,
  Wrapper,
  Icon,
  WrapperRow,
  Input,
  AbsoluteWrapper,
  WrapperColumn,
} from "../component/layout/layoutStorage";

import P from "../icon/pointP.png";

const ProfilePoint = ({ point, list }) => {
  const [pointCode, setPointCode] = useState("");

  const PointRegister = () => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("token", token);
    frm.append("mode", "point_register");
    frm.append("code", pointCode);

    axios
      .post("https://ai.semos.kr/semos_user/profile/profile_new", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;

        if (Status === "success") {
          alert(StatusMessage);
          window.location.reload();
          // navigation.navigate("Profile");
        } else if (Status === "fail") {
          alert(StatusMessage);
        } else if (Status === "waring") {
          alert(StatusMessage);
          //   navigation.navigate("Profile");
        }
      });
  };

  return (
    <WrapperColumn>
      <WrapperColumn>
        <Wrapper padding="16px 24px">
          <Text size="13px" color="#818792" weight="500">
            포인트 등록하기
          </Text>
        </Wrapper>

        <Wrapper jc="space-between" padding="0 24px 33px 24px">
          <Wrapper border="1px solid #dcdcdc" radius="4px" width="224px">
            <Input
              placeholder="포인트 코드를 입력해주세요."
              size="14px"
              color="#3c3e41"
              style={{
                width: "100%",
                padding: "6px 0 6px 10px",
                borderRadius: "4px",
              }}
              onChange={(e) => setPointCode(e.target.value)}
            />
          </Wrapper>

          <Wrapper
            bgc="#0e6dff"
            padding="7px 32px"
            radius="8px"
            cursor="pointer"
            onClick={() => PointRegister()}
          >
            <Text size="14px" weight="700" color="#ffffff">
              등록
            </Text>
          </Wrapper>
        </Wrapper>

        <Wrapper padding="24px" bgc="#F2F7FF" jc="space-between" ai="center">
          <Text size="16px" weight="500" color="#3c3e41">
            사용 가능 포인트
          </Text>

          <Wrapper ai="center">
            <Text size="16px" color="#0e6dff" weight="600">
              {point}
            </Text>
            <Wrapper
              width="24px"
              height="24px"
              jc="center"
              ai="center"
              margin="0 0 0 6px"
            >
              <Icon width="20px" height="20px" src={P} />
              {/* <Text
                size="12px"
                color="#ffffff"
                weight="800"
                style={{ lineHeight: "100%" }}
              >
                P
              </Text> */}
            </Wrapper>
          </Wrapper>
        </Wrapper>

        {list.map((el, i) => (
          <Wrapper
            key={i}
            padding="20px 24px"
            jc="space-between"
            ai="center"
            bb="3px solid #f5f5f5"
          >
            <WrapperColumn>
              <Text size="14px" weight="500" color="#3c3e41">
                {el.name}
              </Text>
              <Wrapper height="6px" />
              <Text size="14px" weight="500" color="#3c3e41">
                {el.date}
              </Text>
            </WrapperColumn>

            <Wrapper>
              <Text
                size="16px"
                weight="500"
                color={el.type == "plus" ? "#0e6dff" : "#FF9187"}
              >
                {`${el.type == "plus" ? "+" : "-"} ${Number(
                  el.value
                ).toLocaleString()} P`}
              </Text>
            </Wrapper>
          </Wrapper>
        ))}
      </WrapperColumn>
    </WrapperColumn>
  );
};

export default ProfilePoint;
