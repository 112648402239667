import React, { Component } from "react";
import { BrowserRouter as Router, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

//component
import Header from "../component/header";
import DeskTopHeader from "../component/layout/header";

// css
const CrossContainer = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #ffffff;

  position: relative;
  display: inline-block;
  overflow: hidden;
  overflow-y: auto;
  z-index: 15;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
`;

const HeaderContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: none;
  }
  @media screen and (min-width: 860px) {
    display: block;
    margin: 100px 0 0 0;
  }
`;

const CrossContent = styled.div`
  width: 80rem;
  margin: 0 calc((100% - 80rem) / 2);
  min-height: calc(100vh - 13rem);
  min-height: calc((var(--vh, 1vh) * 100) - 13rem);
  display: inline-block;
  padding: 1rem 0;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
`;

const withParams = (Child) => {
  return (props) => {
    const params = useParams();
    const navigate = useNavigate();
    return <Child {...props} params={params} naviGate={navigate} />;
  };
};

class Semos_using_process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileCheck: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
      thisPage: window.location.pathname.split("/")[1],
    };
  }

  componentDidMount() {}

  render() {
    return (
      <CrossContainer>
        {this.state.mobileCheck ? <Header /> : ""}

        <CrossContent style={{ padding: this.state.mobileCheck ? "5.2rem 1.8rem 8rem 1.8rem" : "1.8rem 1.8rem 8rem 1.8rem" }}>
          {this.state.mobileCheck ? (
            ""
          ) : (
            <>
              <HeaderContainer>
                <DeskTopHeader />
              </HeaderContainer>
              <Header />
            </>
          )}

          <section
            style={{
              fontSize: "13px",
              fontWeight: "500",
              lineHeight: "142%",
            }}
          >
            <b>※ 본 약관 내에는 영리목적 광고성 정보 수신동의에 관한 사항이 포함되어 있습니다.</b>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <b>제1장 총 칙</b>
            <br></br>
            <br></br>
            <br></br>
            <b>제1조 (목적)</b>
            <br></br>
            <br></br>이 약관은 세모스(이하 당사)이 제공하는 서비스를 이용함에 있어 당사와 이용자의 권리, 의무 및 책임사항을 규정합니다. 이를 통하여 당사와
            이용자는 알아야 할 사항을 숙지, 상호 신뢰의 증진을 목적으로 합니다.<br></br>
            <br></br>
            <br></br>
            <b>제2조 (약관의 효력 및 변경)</b>
            <br></br>
            <br></br>
            가. 이 약관은 세모스 사이트 및 어플리케이션에 회원으로 등록, 조회 등 서비스 이용 순간부터 효력이 발생됩니다.<br></br>
            <br></br>
            나. 당사에서는 서비스 이용 수수료 등을 포함하여 각종 약관을 어느 시기에나 변경할 권리를 갖고 있으며 사전 고지 없이 이 약관들의 내용을 변경할 수
            있습니다. 변경된 약관은 홈페이지 공지, 회원가입 시 공지 또는 전자우편 등의 및 기타 방법으로 공지함으로써 효력이 발생됩니다. 당사는 또한 “마지막
            수정일”의 날짜를 약관의 가장 상단에 나타낼 것입니다. 이는 서비스 이용약관뿐만 아니라, 결제서비스약관, 환불규정, 개인정보처리방침 약관 모두가
            해당됩니다.<br></br>
            <br></br>
            다. 기존 회원들은 당사가 변경된 약관의 수정사항을 홈페이지, 어플리케이션 또는 이메일 등의 방법을 통해 공지한 경우, 웹사이트, 어플리케이션 또는
            서비스를 지속적으로 이용할 경우에 변경 사항에 대해 동의한 것으로 간주합니다. 만일 수정 약관에 대해 동의하지 않는 경우에는 사이트와 서비스 이용을
            즉시 중단하거나 탈퇴함으로써 약관의 내용을 거부할 수 있습니다.<br></br>
            <br></br>
            <br></br>
            <b>제3조 (약관 외 사항의 처리)</b>
            <br></br>
            <br></br>본 약관과 개인정보취급방침에 명시되지 않은 사항에 대해서는 관련 법령 및 회사가 정한 서비스 이용 안내에 따릅니다.<br></br>
            <br></br>
            <br></br>
            <b>제4조 (용어의 정리)</b>
            <br></br>
            <br></br>이 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다.<br></br>
            <br></br>
            가. 유저 : 액티비티 판매자를 구하기 위해 당사의 약관에 동의하고 유저 양식에 따라 등록한 자. "유저" 와 "크루"는 동의어로 간주합니다.<br></br>
            <br></br>
            나. 파트너 : 유저를 구하기 위해 당사의 약관에 동의하고 파트너 양식에 따라 등록한 자. "파트너" 와 "액티비티 판매자"는 동의어로 간주합니다.<br></br>
            <br></br>
            다. 서비스 : 세모스 사이트와 모바일 세모스에서 판매 가능한 상품으로 유형의 제품 혹은 무형의 서비스를 말합니다.<br></br>
            <br></br>
            라. 회원 : 이 약관을 승인하고 회사와 서비스 이용계약을 체결한 자를 말하며 회원은 가입 형태에 따라 개인회원과 기업회원으로, 서비스 이용목적에 따라
            유저와 파트너로 각 구분되며 상호 간 회사가 정하는 바에 따라 전환할 수 있습니다. 유저와 파트너 모두에게 이 약관이 적용됩니다.<br></br>
            <br></br>
            바. 액티비티: 파트너와 유저가 진행하는 오프라인/온라인 원데이 레슨, 다회차 레슨, 이용권, 입장권, 투어를 뜻합니다.<br></br>
            <br></br>
            <br></br>
            <b>제5조 (중개서비스의 성질과 목적)</b>
            <br></br>
            <br></br>
            중개서비스는 회사가 회원 각자의 자기결정에 의하여 회원 상호간에 액티비티의 거래가 이루어질 수 있도록 사이버 거래장소(marketplace)를 온라인으로
            제공하는 것이며, 세모스 안전결제서비스는 회원 상호간에 액티비티의 매매에 있어서 안전하고 편리하게 결제가 이루어질 수 있는 수단을 제공하는 것입니다.
            회사는 단지 회원간 거래의 안전성 및 신뢰성을 증진시키는 도구만을 제공합니다. 회원간에 성립된 거래와 관련된 책임은 거래당사자인 회원들 스스로가
            부담하여야 합니다.<br></br>
            <br></br>
            <br></br>
            <br></br>
            <b>제2장 회원정보의 보호</b>
            <br></br>
            <br></br>
            <br></br>
            <b>제6조 (회원정보의 수집과 보호)</b>
            <br></br>
            <br></br>
            가. 회사는 이용계약을 위하여 회원이 제공한 정보 외에도 수집목적 또는 이용목적 등을 밝혀 회원으로부터 필요한 정보를 수집·이용할 수 있습니다. 이 경우,
            회사는 회원으로부터 정보수집·이용에 대한 동의를 받습니다. 회사는 개인정보의 수집·이용·제공에 관한 회원의 동의 거절 시 제한되는 서비스를 구체적으로
            명시합니다.<br></br>
            <br></br>
            나. 회사가 정보수집·이용을 위하여 회원의 동의를 받는 경우, 회사는 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받는 자, 제공목적,
            제공 정보의 내용)을 미리 명시하거나 고지합니다. 회원은 정보제공에 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.<br></br>
            <br></br>
            다. 회원은 회사에게 정보를 사실대로 제공하여야 합니다. 회사는 회원이 이 약관 제8조 가항에 따라 제공한 정보의 정확성을 확인하기 위하여 관련법령이
            허용하는 범위 내에서 증빙 자료의 제공을 요청할 수 있고, 회원이 정당한 사유 없이 증빙자료를 제공하지 않는 경우 회사는 서비스의 이용을 제한하거나
            이용계약을 해지할 수 있습니다.<br></br>
            <br></br>
            라. 회사는 계정, 성명 등 서비스화면을 통하여 공개되는 정보를 제외하고는 회사가 수집한 회원정보를 정보수집 시에 밝힌 수집목적, 이용목적, 원활한
            서비스의 제공 및 제공서비스의 확대 외의 용도로 사용하거나 제3자에게 제공하지 않습니다. 회사는 정보수집 시에 밝힌 이용목적 이외에 새로운 이용목적이
            발생한 경우 또는 제3자에게 제공하는 경우에는 이용∙제공 단계에서 당해 회원에게 그 목적을 고지하고 동의를 받습니다, 다만, 관련 법령에 달리 정함이 있는
            경우에는 예외로 합니다.<br></br>
            <br></br>
            마. 회사의 개인정보 관리책임자 및 연락처는 세모스 사이트에서 별도로 공개하는 개인정보취급방침에서 공지합니다.<br></br>
            <br></br>
            바. 회원은 언제든지 자신에 관한 정보를 열람할 수 있고, 회사 또는 정보관리책임자에게 잘못된 정보에 대한 정정을 요청할 수 있습니다. 회사는
            회원으로부터 정보정정의 요청이 있는 경우 그 오류를 정정하거나 정정하지 못하는 사유를 회원에게 통지하는 등 필요한 조치를 취할 때까지 당해 정보를
            이용하지 않습니다. 다만, 다른 법률에 따라 개인정보의 제공을 요청 받은 경우에는 그러하지 아니합니다.<br></br>
            <br></br>
            사. 회사는 회원정보의 보호를 위하여 관리자를 최소한으로 한정하며, 회사의 고의 또는 과실로 인하여 회원정보가 분실, 도난, 유출, 변조되거나 위법하게
            제3자에게 제공된 경우에는 그로 인한 회원의 손해에 대하여 모든 책임을 부담합니다.<br></br>
            <br></br>
            아. 이용계약이 종료된 경우, 회사는 당해 회원의 정보를 파기하는 것을 원칙으로 합니다. 다만, 아래의 경우에는 회원정보를 보관합니다. 이 경우 회사는
            보관하고 있는 회원정보를 그 보관의 목적으로만 이용합니다.<br></br>
            <br></br>
            1) 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안
            회원정보를 보관합니다.<br></br>
            <br></br>
            2) 비방이나 허위사실 유포 등으로 타인에게 피해를 입힌 경우, 지적재산권 침해상품 판매 또는 기타 인터넷 사기행위 등으로부터 회원과 회사를 보호하고
            법적 절차에 따른 수사 협조를 위한 목적 등으로 회사는 이용계약 종료 후 2개월간 물품거래내역이 존재하는 회원의 계정, 성명 또는 상호, 연락처, 그 외
            제공받은 개인정보, 해지 및 회원자격정지 관련정보 등 필요한 정보를 보관합니다.<br></br>
            <br></br>
            3) 기타 정보수집에 관한 동의를 받을 때 보유기간을 명시한 경우에는 그 보유기간까지 회원정보를 보관합니다.<br></br>
            <br></br>
            <br></br>
            자. 회사가 제3자에게 회원의 개인정보를 제공할 필요가 있는 경우 회사는 실제 구매신청 시(유저) 또는 액티비티등록 시(파트너), 제공되는 개인정보 항목,
            제공받는 자, 제공받는 자의 개인정보 이용목적 및 보유·이용기간 등을 명시하여 회원의 동의를 받고, 개인정보의 수집·취급·관리 등을 위탁하는 경우에는
            위탁 업무의 내용 및 수탁사의 명칭 등에 관한 사항을 서면, 전자우편, 모사전송, 전화 또는 홈페이지를 통해 미리 회원에게 고지하여 동의를 받거나
            개인정보취급방침에 포함하여 세모스 사이트(https://semos.kr)에 공개합니다.<br></br>
            <br></br>
            차. 회사는 회원정보의 보호와 관리에 관한 개인정보취급방침을 회원과 회사의 서비스를 이용하고자 하는 자가 알 수 있도록 세모스
            사이트(https://www.semos.kr)에 공개합니다.<br></br>
            <br></br>
            <br></br>
            <br></br>
            <b>제3장 중개서비스의 이용</b>
            <br></br>
            <br></br>
            <br></br>
            <b>제7조 (중개서비스의 종류 및 이용)</b>
            <br></br>
            <br></br>
            가. 회사가 제공하는 중개서비스는 일반거래, 합의거래 등이 있습니다. 중개서비스의 종류와 내용은 회사의 정책에 따라 추가, 변경될 수 있습니다.<br></br>
            <br></br>
            나. 회사는 중개서비스의 종류에 따라 각 매매의 특성, 절차 및 결제방법에 대한 사항을 서비스화면을 통하여 공지합니다. 회원은 회사가 공지한 각 매매에
            관한 사항을 사전에 충분히 이해하고 중개서비스를 이용하여야 합니다.<br></br>
            <br></br>
            다. 회원은 SNS 계정, 이메일(e-mail)과 비밀번호를 통하여 회사가 제공하는 중개서비스에 접속할 수 있고, 동 서비스를 이용하여 액티비티를 판매하거나
            구매할 수 있습니다. 다만, 각 카테고리 별로 회사가 지정하는 중개서비스에 대해서는 파트너가 될 수 있는 자격요건이 설정될 수 있습니다. 회사는 이러한
            이용제한사항을 해당 서비스화면에서 공지합니다.<br></br>
            <br></br>
            라. 회원이 중개서비스를 통하여 판매 또는 구매하는 경우, 반드시 회사가 마련한 세모스 안전결제서비스를 통하여 거래를 완료하여야 합니다. 회사는 회원
            간의 직거래로 인하여 발생한 문제에 대하여 책임지지 않습니다. 또한 직거래 신고가 접수 될 경우 사이트 이용자는 서비스 이용에 제한을 받을 수 있습니다.
            <br></br>
            <br></br>
            <br></br>
            <b>제8조 (중개서비스의 활용 방식과 제한 정책)</b>
            <br></br>
            <br></br>
            가. 회사는 중개서비스를 통한 거래의 안전성과 신뢰성을 위하여 이 약관에서 정한 바에 따라 회원자격을 정지하거나 서비스의 이용 제한, 기타 필요한 조치를
            취할 수 있습니다. 회사가 회원자격을 정지하거나 판매/구매서비스의 이용제한, 기타 필요한 조치를 취할 경우 회사는 이 약관에 특별한 규정이 없는 한
            사전에 회원에게 유선 또는 메일(e-mail), 기타의 방법을 통하여 통보하며, 회원의 연락이 두절되거나 긴급을 요하는 것과 같이 부득이한 경우 선 조치 후
            사후 통보할 수 있습니다. 정지된 회원은 중개서비스를 이용할 수 없으며, 중개서비스의 이용이 제한된 회원은 판매/구매관리 서비스를 포함한 모든 서비스를
            이용할 수 없습니다.<br></br>
            <br></br>
            나. 회원자격의 정지와 중개서비스의 이용제한<br></br>
            <br></br>
            1) 불법홍보 또는 회사 서버에 무리를 주는 행위로 인한 회원자격정지<br></br>
            <br></br>
            회사는 타 사이트 등에서 세모스 서비스를 이용한 불법적 홍보행위, 회사 서버에 무리를 주는 행위의 적발 시 이용 제한을 진행할 수 있습니다. 아래 기준을
            따르되, 위반 정도에 따라 회사는 기준보다 높은 제한을 할 수 있습니다.<br></br>
            <br></br>
            적발 횟수에 따른 제한의 내용<br></br>
            <br></br>
            1회 경고<br></br>
            <br></br>
            2회 30일 이용정지<br></br>
            <br></br>
            3회 회원자격의 영구정지<br></br>
            <br></br>
            2) 직거래 유도 행위<br></br>
            <br></br>
            회사는 상호간의 신뢰도 증진 및 서비스의 품질 보장을 위해 직거래유도행위에 대하여 그 적발횟수에 따라 아래와 같은 제한을 부과합니다. 아래 기준을
            따르되, 위반 정도에 따라 회사는 기준보다 높은 제한을 할 수 있습니다. 이용계약해지 후 재가입하는 경우에 회원에게 이용계약해지 전 부과된 제한은 그대로
            유지되며 해지 시로부터 재가입까지의 기간은 적발횟수 차감 기간에서 제외될 수 있습니다.<br></br>
            <br></br>
            적발 횟수에 따른 제한의 내용<br></br>
            <br></br>
            1회 경고<br></br>
            <br></br>
            2회 30일 이용정지<br></br>
            <br></br>
            3회 회원자격의 영구정지<br></br>
            <br></br>
            3) 부당한 구매행위, 불법카드거래 또는 매매부적합액티비티의 등록행위<br></br>
            <br></br>
            회사는 허위거래, 매매조작행위 등 매매진행을 방해하는 부당한 구매행위, 불법카드거래행위, 매매부적합액티비티의 등록행위, 불법통신과금서비스 이용행위에
            대하여 회원자격을 정지하거나 중개서비스 이용을 제한할 수 있습니다. 다만, 파트너의 경과실에 의해 회원자격의 정지 또는 중개서비스의 이용제한 사유가
            발생하는 경우에는 회사는 1회 사전 경고를 하여 파트너가 자진 시정을 할 수 있는 기회를 제공할 수 있습니다.<br></br>
            <br></br>
            4) 장기휴면회원의 경우<br></br>
            <br></br>
            회사는 파트너 회원이 60일 이상 로그인 하지 않은 경우 등록한 액티비티를 중단처리 할 수 있고 회원이 1년 이상 로그인을 하지 않은 경우에는 ‘정보통신망
            이용촉진 및 정보보호 등에 관한 법률’ 제 29조 2항에 따라 자동 탈퇴 및 개인정보를 파기합니다. 단, 판매/구매이력이 있는 회원의 자동탈퇴 및 개인정보
            보관, 파기는 개인정보취급방침에 따른 별도 규정에 의하여 관리합니다.<br></br>
            <br></br>
            5) 금지행위<br></br>
            <br></br>① 허위 정보 입력 금지<br></br>
            허위 정보 입력 금지란 액티비티에 대한 허위 정보를 입력하는 행위를 말합니다. 이로 인해 유저의 피해나 클레임이 발생할 경우, 회사는 판매제한,
            액티비티등록 가능개수 제한 등의 페널티를 부여할 수 있습니다.<br></br>
            <br></br>② 이용정책 위반 시<br></br>
            파트너와 유저가 취소, 환불 절차 등 이용정책 위반 및 액티비티 후 무응답 등 액티비티에 상호에게 해가 되는 행동을 끼쳤거나 이로 인한 클레임이
            발생하였을 경우, 회사는 아래의 규정에 따라 조치를 할 수 있습니다.<br></br>
            <br></br>
            (1) 부당 액티비티 거부<br></br>
            <br></br>
            부당 액티비티 거부란 파트너가 세모스 약관이 정하는 운영정책 기준 상의 합당한 이유없이 특정 회원의 액티비티 신청을 일방적으로 거부하는 것을 뜻합니다.
            단, 파트너의 거부 사유를 회사에게 통보하여 합리적 판단에 기하여 액티비티 서비스 제공을 거부할 필요가 있다고 양 측이 인정하는 경우는 예외로 합니다.
            <br></br>
            <br></br>
            1차: 부당 액티비티 거부에 대한 경고 및 개선요청<br></br>
            <br></br>
            2차: 해당 액티비티 등록 중지<br></br>
            <br></br>
            3차: 전 액티비티 등록 중지 및 신규 액티비티 등록제한<br></br>
            <br></br>
            (2) 임의 취소/임의 환불<br></br>
            <br></br>
            임의 취소/임의 환불은 파트너가 세모스 약관이 정하는 운영정책 기준 상의 합당한 이유없이 특정 회원과의 액티비티 약속을 일방적으로 취소하고 환불 시키는
            것을 뜻합니다. 단, 액티비티의 취소 사유를 회사에게 통보하여 합리적 판단에 의하여 액티비티 서비스 제공을 취소하고 환불 할 필요가 있다고 양 측이
            인정하는 경우는 예외로 합니다.<br></br>
            <br></br>
            1차: 1차 경고 조치 및 해당 액티비티 선택사항 조정이나 일시 등록 중단 조치(경중에 따라 액티비티 등록 제한)<br></br>
            <br></br>
            2차: 2차 경고 조치 및 신규 액티비티 등록제한<br></br>
            <br></br>
            3차: 3차 경고 조치 및 정산중지<br></br>
            <br></br>③ 연락두절<br></br>
            <br></br>
            연락두절이란 파트너가 회원가입 시 기재한 유선 또는 메일(e-mail)등 기타의 방법을 통하여 회사가 연락을 시도하였음에도 불구하고 48 시간 동안 일체의
            응대가 진행되지 않는 상태를 말합니다. 파트너는 정상적인 액티비티 활동이 불가한 상태 또는 이와 같은 상태가 예상되는 경우 이를 즉시 회사에 통보하여야
            하며 정상적인 액티비티가 불가한 상태 또는 이와 같은 상태가 예상되는 경우 전자상거래 등에서의 소비자보호에 관한 법률 등 관련법에 따른 취소, 환불, A/S
            등의 신속한 처리를 위하여 최선의 노력을 다하여야 합니다. 파트너의 연락두절로 인한 클레임이 발생하였을 경우, 회사는 아래의 규정에 따라 조치할 수
            있습니다. 단, 천재지변이나 불가항력적인 사유가 있는 경우는 예외로 합니다.<br></br>
            <br></br>
            연락두절 1차 : 1차 경고 및 개선요청<br></br>
            <br></br>
            연락두절 2차 : 1차 경고 후 24시간 이상 일체의 응대가 진행되지 않는 상태를 연락두절 2차라 칭함. 연락두절 2차의 경우 취할 수 있는 조치는 2차 경고,
            직권으로 환불처리 및 해당 파트너의 전 액티비티 등록 중지, 신규액티비티 등록제한<br></br>
            <br></br>
            연락두절 3차 : 2차 경고 후 24시간 이상 일체의 응대가 진행되지 않는 상태를 연락두절 3차라 칭함. 연락두절 3차의 경우 취할 수 있는 조치는 정산 중지 및
            이용계약 해지<br></br>
            <br></br>※ 1차 경고 후 3개월 내에 연락두절 상태가 재발될 경우, 경고 없이 바로 연락두절 2차 조치를 취할 수 있습니다. 2차 경고 후 3개월 내에 연락두절
            상태가 재발될 경우, 경고 없이 바로 연락두절 3차 조치를 취할 수 있습니다.<br></br>
            <br></br>④ 유저의 문의 처리 지연(미처리) 및 불만족<br></br>
            <br></br>
            파트너는 유저의 문의(거래 메세지, 연락가능번호 등을 통한)를 신속하고 성실히 처리해야 합니다. 회사는 일정기한 내 파트너의 답변의 처리 유무에 따라
            처리율을 판단하고, 파트너 답변의 유저 만족도를 조사하여 유저 답변 만족도를 판단할 수 있습니다. 유저 문의에 대한 파트너의 처리율 및 만족도가 저조한
            경우 회사는 아래의 규정에 따라 조치할 수 있습니다.<br></br>
            <br></br>
            1차 유저 문의 처리 지연 및 불만족 : 경고 및 개선요청<br></br>
            <br></br>
            2차 유저 문의 처리 지연 및 불만족 : 해당 액티비티 등록 중지 및 개별 CS 가이드<br></br>
            <br></br>
            3차 유저 문의 처리 지연 및 불만족 : 전 액티비티 등록 중지 및 신규 액티비티 등록제한<br></br>
            <br></br>
            <br></br>
            6) 추가 계정을 통한 액티비티 등록<br></br>
            <br></br>
            회원이 추가 (개인)계정을 사용하여 최초 가입 계정과 동일한 대분류 카테고리 내에 액티비티 등록을 하는 경우, 회사는 회원에게 회원자격 정지, 액티비티
            등록 제한, 등록 액티비티 노출 순위(이하 “인기도”) 감점, 액티비티 등록 가능개수 제한 등의 페널티를 부과할 수 있습니다.<br></br>
            <br></br>
            7) 기타 위법·부당행위<br></br>
            <br></br>
            회사는 회원이 다음 각 목의 하나에 해당하는 경우 회원자격을 정지하거나 서비스 제공을 거부 또는 제한할 수 있으며, 그 밖에 법령준수, 타인의 권리보호,
            사이트의 안전 확보 등을 위하여 필요한 법적 조치를 취할 수 있습니다.<br></br>
            <br></br>① 회사의 세모스 사이트, 모바일세모스, 시스템, 서비스를 스팸정보 전송 또는 악성코드 프로그램 유포, 타인계정 도용 등 범죄 또는 불법행위에
            이용한 경우<br></br>
            <br></br>② 액티비티 이행 또는 액티비티료를 송금해야 하는 경우에 무단으로 잠적/ 회피 하는 경우<br></br>
            <br></br>③ 거래이행을 위한 유저 또는 회사로부터의 연락 회피나 제재 회피 등 부정한 목적으로 회원정보의 수정을 거부하거나 해태한 경우<br></br>
            <br></br>④ 게시판(예시: 블로그) 등을 통하여 불법정보를 유통하거나 유통을 시도한 경우<br></br>
            <br></br>⑤ 세모스 사이트, 모바일 세모스 정보, 데이터를 정당한 권한 없이 스스로 또는 제3자를 통하여 복사, 퍼가기, 스크래핑 하거나 기타의 방법으로
            상업적으로 이용한 경우<br></br>
            <br></br>⑥ 회원이 제공한 정보 또는 그에 관한 증빙자료가 허위이거나 회사가 요청하는 증빙자료를 제공하지 않는 경우<br></br>
            <br></br>⑦ 수출 관리 법령과 게시된 규칙, 제한을 위반하여 세모스의 웹/앱 서비스 및 관련 툴을 수출 또는 재수출하는 경우<br></br>
            <br></br>⑧ 세모스 서비스에 관련된 정보나 소프트웨어를 상업화하는 경우<br></br>
            <br></br>⑨ 회원이 다른 회원의 개인정보를 무단수집 하거나 마케팅 등 수집 목적 외로 이용하는 경우<br></br>
            <br></br>⑩ 회원이 다른 회원의 저작권, 초상권 등의 권리를 침해하거나 개인정보를 무단으로 제3자에게 제공하거나 관련법령 또는 회사의 개인정보취급방침을
            위배하여 다른 회원의 개인정보가 침해된 경우<br></br>
            <br></br>⑪ 세모스메세지 또는 거래메세지를 이용한 대화, 구매평가의 내용 중 욕설, 비방 혹은 명예를 훼손하는 내용을 작성하는 경우<br></br>
            <br></br>⑫ 그 외 약관 또는 법령에 위반되거나 타인의 권리를 침해하는 행위가 있거나 그러한 위법·부당한 행위가 있는 것으로 의심될 만한 상당한 이유가
            있는 경우<br></br>
            <br></br>
            <br></br>
            8) 리뷰 및 커뮤니티 사용 정책<br></br>
            <br></br>
            회사는 서비스와 액티비티에 관한 후기를 작성할 수 있는 리뷰와 커뮤니티 장소를 제공하고 있습니다. 회원이 작성한 내용들이 다음 각 목의 하나에 해당하는
            경우 회원자격을 정지하거나 서비스 제공을 거부 또는 제한할 수 있으며, 그 밖에 법령준수, 타인의 권리보호, 사이트의 안전 확보 등을 위하여 필요한
            민/형사상의 법적 조치를 취할 수 있습니다.<br></br>
            <br></br>① 작성 내용은 파트너/액티비티과 관련하여 주제에서 벗어나지 않아야 합니다.<br></br>
            <br></br>② 입력란에 상품 URL, 제휴 코드, 이메일 주소 또는 링크를 포함할 수 없습니다.<br></br>
            <br></br>③ 평점을 높이거나 낮출 의도로 허위 리뷰를 출시하거나 강요할 수 없습니다. 만일 허위 리뷰를 작성, 권유한 정황이 포착될 경우 회원자격이 박탈될
            수 있으며, 파트너의 경우 액티비티 삭제까지 진행 할 수 있습니다.<br></br>
            <br></br>④ 파트너는 액티비티에 관한 자료나 기타 조건 등을 통해 리뷰 작성을 요구하거나 강제적으로 높은 평점을 요구하는 리뷰를 할 수 없습니다.
            <br></br>
            <br></br>⑤ 노골적인 성적 표현이나 욕설이 포함된 내용을 등록해서는 안됩니다.<br></br>
            <br></br>⑥ 파트너와 회원을 모욕, 증오, 위협, 또는 희롱하는 내용을 등록해서는 안됩니다.<br></br>
            <br></br>⑦ 다른 사람으로 가장하거나 다른 사람 또는 법인과의 제휴 관계를 허위로 기술 또는 표시해서는 안됩니다. 타인에 대한 예의를 갖추며 공격하지
            않아야 합니다.<br></br>
            <br></br>⑧ 리뷰는 액티비티 당 1번만 작성할 수 있습니다. 최초 작성 이후에는 수정만이 가능하며, 삭제 후 다시 작성할 수 없습니다. 또한, 작성 30일
            이후에는 수정할 수 없습니다.<br></br>
            <br></br>
            <br></br>
            9) 세모스 내 제공하는 1:1대화 기능을 악용하는 행위<br></br>
            <br></br>
            회원은 파트너와 직접적으로 1:1대화를 시도할 수 있습니다. 회원이 작성한 내용들이 다음 각 목의 하나에 해당하는 경우 회원자격을 정지하거나 서비스
            제공을 거부 또는 제한할 수 있으며, 그 밖에 법령준수, 타인의 권리보호, 사이트의 안전 확보 등을 위하여 필요한 민/형사상의 법적 조치를 취할 수
            있습니다.<br></br>
            <br></br>① 회원은 세모스 내 제공하는 문의 기능을 활용하여 특정 서비스 및 상품의 홍보 행위를 할 수 없으며, 구인, 구직, 섭외, 영업 등의 제안 행위를
            진행할 수 없습니다.<br></br>
            <br></br>② 회원은 공공질서 및 미풍양속에 위반 되는 내용과 첨부파일을 전달해서는 안됩니다.<br></br>
            <br></br>③ 회원이 상대방의 업무를 방해할 목적으로 스팸성 메시지를 발송해서는 안됩니다.<br></br>
            <br></br>④ 회원 간 세모스 서비스를 활용한 특정 상품 추천, 서비스 추천 및 매매 유도, 강매 등 일체 행위를 금합니다. (보험 상품, 주식 종목 추천, 부동산
            매매, 화장품, 건강보조제, 정수기, 서적, 각종 회원권 및 이용권, 타 서비스의 회원 가입 유도, 부업 유도, 자격증, 수수료 발급 등의 일체 행위)<br></br>
            <br></br>
            <br></br>
            10) 세모스 서비스 상담 시 문제 행위<br></br>
            <br></br>
            회원은 세모스의 임직원, 상담원, 피용자, 업무수탁자와 서비스에 관해 문의할 시 모욕감을 줄 수 있는 행위를 금합니다. 외설, 폭력적인 메시지, 기타
            공공질서 및 미풍양속에 반하는 표현 모두를 금하며 이를 어길 시 영업방해에 의한 민사, 형사적 법적 절차를 진행할 수 있습니다.<br></br>
            <br></br>
            <br></br>
            <br></br>
            <b>제9조 (대리 및 보증의 부인)</b>
            <br></br>
            <br></br>
            가. 회사는 통신판매중개자로서 회원 상호간의 거래를 위한 온라인 거래장소(marketplace)를 제공할 뿐이므로 액티비티를 제공하거나 구매하고자 하는 회원을
            대리하지 않습니다. 또한, 회사의 어떠한 행위도 파트너 또는 유저를 대리하는 행위로 간주되지 않습니다.<br></br>
            <br></br>
            나. 회사는 중개서비스를 통하여 이루어지는 회원간의 판매 및 구매와 관련하여 판매의사 또는 구매의사의 존부 및 진정성, 등록물품의 품질, 완전성, 안전성,
            적법성 및 타인의 권리에 대한 비 침해성, 회원이 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의 진실성 등 일체에 대하여 보증하지
            아니하며, 이와 관련한 일체의 위험과 책임은 해당 회원이 부담해야 합니다.<br></br>
            <br></br>
            다. 회사는 회원이 게재하는 액티비티설명 등의 제반 정보를 통제하거나 제한하지 않습니다. 다만, 회사는 회원이 게재한 정보의 내용이 타인의 명예, 권리를
            침해하거나 법규정을 위반한다고 판단하는 경우에는 이를 삭제할 수 있고, 액티비티취소, 액티비티중지, 기타 필요한 조치를 취할 수 있습니다.<br></br>
            <br></br>
            <br></br>
            <b>제10조 (세모스 안전결제서비스)</b>
            <br></br>
            <br></br>
            가. 회사는 중개서비스를 제공하는 과정에서 첫 회 1시간 분의 시범 강의비 또는 전체금액 결제에 대한 대금 수취, 보관 및 환불로 이루어지는 에스크로
            방식의 세모스 안전결제서비스를 제공합니다. 이러한 세모스 안전결제서비스는 중개서비스를 통하여 이루어지는 회원 상호간의 거래의 안전성과 신뢰성을
            도모하고 유저를 보호하기 위한 목적에서 제공하는 장치이므로 회사가 세모스 안전결제서비스를 통하여 파트너 또는 유저를 대리, 대행하거나 그 이행을
            보조하는 것은 아닙니다.<br></br>
            <br></br>
            나. 회사가 제공하는 세모스 안전결제서비스는 기본적인 중개서비스에 포함됩니다. 회원이 세모스 내 유저와 파트너 간 결제에서 세모스 안전결제서비스를
            통하지 않는 직거래를 유도하는 경우, 회사는 주문취소, 판매중지 등 기타 필요한 조치를 취할 수 있습니다.<br></br>
            <br></br>
            다. 세모스 안전결제서비스의 일환으로 이루어지는 대금보관으로 인하여 회사가 취득하는 이자 등은 서비스 제공의 대가이므로 회원은 회사에 대하여 이자
            등의 반환을 청구할 수 없고, 대금 송금(실시간계좌이체, 가상계좌 등)으로 인하여 발생하는 수수료는 대금을 송금하는 회원이 부담하여야 합니다.<br></br>
            <br></br>
            라. 회사가 제공하는 세모스 안전결제서비스를 이용하지 않은 거래 또는 세모스 안전결제서비스가 포괄하는 액티비티의 내용들이 종결된 거래의 경우 해당
            거래와 관련하여 발생한 모든 사항은 파트너와 유저가 상호협의를 통해 해결하여야 합니다.<br></br>
            <br></br>
            마. 파트너는 회사가 제공하는 서비스를 이용함에 있어서 세모스 안전결제서비스의 이용과 그 규칙에 동의하여야 합니다.<br></br>
            <br></br>
            바. 회사가 제공하는 세모스 안전결제서비스를 오용, 악용함으로써 사회질서, 미풍양속을 침해하거나 침해할 우려가 있다고 판단되는 경우 또는 회사가 세모스
            안전결제서비스를 제공하지 못할 상황 또는 사유가 발생하는 경우 회사는 액티비티 판매를 제한할 수 있습니다.<br></br>
            <br></br>
            <br></br>
            <b>제11조 (파트너의 의무)</b>
            <br></br>
            <br></br>
            가. 파트너는 이 약관과 회사가 서비스화면을 통하여 미리 공지하는 개별약관이 정하는 절차에 따라 신원정보의 제공, 액티비티의 등록, 거래진행 과정의
            관리, 유저에 대한 액티비티가행, 또는 환불 등의 사후처리를 수행하여야 합니다.<br></br>
            <br></br>
            나. 파트너는 회원의 질문에 성실히 응해야 합니다.<br></br>
            <br></br>
            다. 회사 및 파트너는 유저의 행위가 허위 또는 불법 결제에 해당하는 경우 및 신속한 거래관계의 확정 또는 거래안전을 위하여 필요한 경우, 그 밖의 정당한
            사유가 있는 경우 거래를 취소할 수 있습니다. 다만, 파트너가 예상치 못한 액티비티불능 등으로 정상적 거래이행이 곤란하여 거래를 취소하는 경우에는 지체
            없이 유저에 대한 통지, 정산(에 필요한 조치) 등 관계법령이 정한 조치를 취하여야 합니다.<br></br>
            <br></br>
            라. 파트너는 유저의 승인요청 및 구매행위를 거절하는 경우, 24시간 이내 거절의사와 함께 불가사유를 유저에게 전달하여야 합니다.<br></br>
            <br></br>
            마. 파트너의 등록은 파트너 등록 서비스 화면상의 등록 양식에 따라야 하며, 액티비티 등록 양식에 어긋난 등록이나 허위등록의 경우에는 해당 액티비티를
            삭제, 취소하거나 중개를 하지 않을 수 있으며, 이에 따른 모든 책임은 해당 파트너가 부담하여야 합니다.<br></br>
            <br></br>
            바. 파트너는 액티비티등록 시 불법적인 거래를 유도하는 문구 및 유저의 정당한 권리(액티비티 시작 24시간 전 환불, 명시한 커리큘럼 및 포함/불포함사항
            준수 포함)를 제한하거나, 허위 또는 기타 법령이나 약관에 위배되는 내용(문구, 사진 또는 설명을 포함)을 등록해서는 아니 되며, 스스로 또는 다른 회원을
            통하여 고의로 구매평가, 액티비티 횟수 등을 조작하거나 기타 판매가장등록 등 중개서비스의 안전성과 신뢰성을 해하는 부정한 행위를 하여서도 안됩니다.
            이를 위반한 경우 회사는 관련 회원이나 해당 거래에 대하여 거래취소, 액티비티중지 및 기타 필요한 조치를 취할 수 있습니다.<br></br>
            <br></br>
            사. 파트너는 회사의 명칭, 로고 및 회원의 회원등급 표시를 회사의 서면 동의 없이 회사가 정한 목적 이외의 목적으로 임의로 사용하거나 지정된 곳 이외의
            장소에 표시하여서는 안되며, 이러한 행위로 인하여 회사 또는 타 회원에게 피해가 발생한 경우 이에 대하여 일체의 법적 책임을 부담하여야 합니다.<br></br>
            <br></br>
            아. 파트너는 회사의 세모스 사이트, 모바일 세모스에서의 거래와 관련하여 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령에서 명시한 자료를
            보관하여야 합니다.<br></br>
            <br></br>
            자. 파트너는 자신의 파트너 정보란에 회사가 정하는 절차에 따라 인증 받은 휴대폰 번호를 대표번호로서 설정하고 항상 최신 정보로서 유지하여야 합니다.
            또한, 대표번호 변경 시 새로이 인증을 받아야 합니다. 파트너가 본 항의 의무를 이행하지 않을 경우 회사는 이행 완료 시까지 해당 파트너의 액티비티 등록과
            편집을 제한할 수 있습니다.<br></br>
            <br></br>
            차. 파트너는 통신판매중개 의뢰 및 그와 관련한 계약이행 과정에서 알게 된 파트너 등 다른 회원의 개인정보를 법률, 이 약관 또는 회사의
            개인정보취급방침에서 정한 목적 외의 용도로 사용할 수 없으며, 이를 위반할 경우, 모든 법적 책임을 지고 자신의 노력과 비용으로 회사를 면책시켜야 하고
            회원자격이 정지 또는 박탈될 수 있습니다.<br></br>
            <br></br>
            카. 회사의 고의 또는 과실과 무관하게, 특정 파트너가 관계하고 있는 다른 회원 또는 제3자의 개인정보가 침해된 경우, 회사는 그에 대하여 책임을 지지
            않습니다.<br></br>
            <br></br>
            타. 신청한 유저에 대한 의무를 다합니다.<br></br>
            <br></br>
            1) 파트너는 모든 액티비티에 있어 성심과 성의를 다하여 유저를 지도해야 합니다.<br></br>
            <br></br>
            2) 파트너는 유저의 사전 동의 없이 액티비티에 관한 조건(액티비티횟수, 액티비티료, 시간 등)을 일방적으로 변경할 수 없으며, 액티비티 시작
            24시간전까지는 변경 사항을 알려 동의를 받아야 합니다. 만일 유저가 동의하지 않는 경우 수령한 액티비티료를 모두 환불해야 합니다.<br></br>
            <br></br>
            3) 파트너는 자신의 신상정보가 변경되었을 경우, 즉시 자신의 회원정보를 수정하여야 합니다.<br></br>
            <br></br>
            4) 파트너는 서비스와 관련하여 다음 사항을 해서는 안됩니다.<br></br>
            <br></br>① 다른 이용자의 계정을 부정 사용하는 행위<br></br>
            <br></br>② 다른 이용자의 개인정보를 수집, 저장하는 행위<br></br>
            <br></br>③ 회사 직원, 운영자 등을 포함한 타인을 사칭하는 행위<br></br>
            <br></br>④ 서비스의 운영에 지장을 주거나 줄 우려가 있는 일체의 행위<br></br>
            <br></br>⑤ 본 약관을 포함하여 기타 당사가 정한 규정 또는 이용조건을 위반하는 행위<br></br>
            <br></br>⑥ 객관적으로 범죄와 결부된다고 판단되는 행위<br></br>
            <br></br>⑦ 당사와 유저와의 협의 없이 제 3자를 액티비티에 대리 참석시키는 행위<br></br>
            <br></br>⑧ 기타 관계법령에 위배되는 행위<br></br>
            <br></br>
            5) 파트너는 액티비티 진행 시, 명시한 최소 인원이 모집 되지 않을 경우 해당 액티비티에 폐강을 진행할 수 있습니다. 이 경우에 액티비티 진행이 예정되어
            있던 유저에게는 액티비티 진행 시각 24시간전까지 유저에게 액티비티 진행여부를 유저에게 고지하여야 하며 사유를 필수적으로 알려야 합니다.<br></br>
            <br></br>
            6) 파트너는 액티비티 진행이 완료 된 시점에 세모스 app서비스의 액티비티료 받기 기능을 이용하여 정산 신청을 해야 합니다. 액티비티가 정상적으로
            완료되기 이전에 정산을 요청할 시에 해당 정산 요청은 반려될 수 있습니다. 만일, 정산 진행 이후 액티비티가 정상적으로 진행되지 않은 사실이 확인 될 경우
            정산 받은 비용은 전액 세모스 회사로 입금하고 세모스 약관에 따라 정상적인 절차를 진행해야 합니다. 이에 따라 파트너는 액티비티의 노출도 감소, 액티비티
            중단 등 불이익을 받을 수 있습니다.<br></br>
            <br></br>
            파. 파트너가 성실히 액티비티를 이행하지 않거나 일방적으로 계약을 파기할 경우, 당사는 관계법령에 의해 파트너를 고소, 고발 조치 및 회원 자격박탈 등을
            취할 수 있으며, 해당 파트너는 이에 대한 모든 법적 책임을 감수합니다. 또한, 유저의 액티비티 전문성에 대한 의문제기, 액티비티에 대한 불충분한 준비,
            지각 및 무단 연락두절에 대하여 신고 건수에 따라 다음과 같은 삼진아웃 조항을 적용합니다.<br></br>
            <br></br>① 2건 이상의 유저 신고 접수 시: 경고조치 및 2주간 액티비티 추천 노출도 0으로 표기<br></br>
            <br></br>② 3건 신고 접수 시: 잠정적 영구 액티비티 중단 조치<br></br>
            <br></br>
            <br></br>
            하. 파트너의 계정 공유 및 계정 재판매, 유/무상 양도 등을 금지합니다. 금지된 행위를 한 회원은 당사의 개인정보 불법유출, 영업방해 및 이미지 훼손에
            대한 손해배상을 해야 하며, 계정은 사전통보 없이 강제로 자격 박탈 처리됩니다.<br></br>
            <br></br>
            <br></br>
            <b>제12조 (파트너의 등록 및 수정)</b>
            <br></br>
            <br></br>
            가. 등록자격<br></br>
            <br></br>
            1) 파트너는 약관에 따라 액티비티를 등록할 수 있습니다. 다만, 회사는 소비자보호 및 서비스 수준 유지를 위해 일부 카테고리 액티비티의 경우 액티비티를
            등록할 수 있는 파트너의 자격을 제한할 수 있습니다.<br></br>
            <br></br>
            2) 파트너는 만 18세 이상만 액티비티 등록을 할 수 있으며 진행이 가능합니다. 단, 18세 미만의 경우 고용노동부 장관이 발급한 취직인허증이 있는 경우,
            추가적으로 파트너 활동에 대한 보호자의 동의서 제출을 통한 자료 검증 이후 액티비티 진행이 가능합니다.<br></br>
            <br></br>
            3) 파트너는 액티비티의 등록 시 유저가 액티비티에 대한 정보를 확인할 수 있도록 다음과 같은 사항을 정확하게 기재하여야 하며, 회사가 승인하지 아니한
            시스템을 통하여 회사 정보통신망에 무단 접속하여 자동으로 등록하는 방법으로 액티비티를 등록할 수 없습니다. 회사는 액티비티 검색의 효율성, 시스템에
            걸리는 부하 등을 고려하여 파트너에 대한 사전 통지로써 파트너 1인당 액티비티 등록 건수를 제한할 수 있습니다. 파트너가 회사의 액티비티 등록건수
            제한조치에 반하여 액티비티등록을 한 경우 회사는 등록된 액티비티를 중지하거나 삭제 할 수 있고 위반 횟수 및 수준에 따라 해당 액티비티를 등록한 의
            회원자격을 정지하거나 서비스 이용을 제한할 수 있습니다.<br></br>
            <br></br>
            4) 외국인은 현재 대한민국에 거주 및 체류하고 있는 외국인 중, 한국에서 취업 가능 비자를 소유해야 하며, 비자 인증을 통해서 파트너로 활동 가능합니다.
            E-2회화 지도 비자의 경우에는 외국어 전문 관련시설, 어학연구소, 연수원 등에서 가능하며 세모스에서 파트너로는 활동이 불가 합니다.<br></br>
            <br></br>① 액티비티료<br></br>
            <br></br>
            파트너는 등록하고자 하는 액티비티료를 명확히 기재하여야 합니다. 액티비티료는 시간 당/회 및 전체 커리큘럼의 총 횟 수를 입력하여 설정할 수 있습니다.
            <br></br>
            <br></br>② 액티비티의 상세정보<br></br>
            <br></br>
            액티비티에 대한 상세정보는 사실대로 명확하게 기재하여야 합니다. 허위 또는 과장 정보를 기재한 경우, 모순되는 내용의 정보가 기재되어 있는 경우, 이
            약관에 반하는 거래조건이 기재된 경우 회사는 해당 액티비티를 취소하거나 중지 시킬 수 있습니다. 이 경우, 취소 또는 중지된 액티비티에 관한 판매서비스
            수수료는 환불되지 않으며, 중개의 취소 또는 중지로 인한 모든 위험과 책임은 액티비티를 등록한 해당 회원이 부담해야 합니다. 액티비티가 취소되거나
            중지되면, 회사는 전화 또는 메일(e-mail) 등의 방법을 통하여 해당 파트너와 유저에게 그러한 사실을 통지합니다.<br></br>
            <br></br>③ 추가 정보<br></br>
            <br></br>
            (1) 파트너는 액티비티 등록 시 파트너가 액티비티 정보제공고시에 따른 정보를 입력하지 않거나, 액티비티 등록 후 변경된 액티비티 정보제공고시에 따라
            정보를 수정, 보완하지 않는 경우 회사는 제15조 나. 7에 따라 회원자격의 정지, 서비스 제공 거부•제한 등 필요한 조치를 취할 수 있습니다.<br></br>
            <br></br>
            (2) 파트너는 등록 액티비티에 특별한 거래조건이 있거나, 추가 액티비티, 장소 및 장비 대여비용 등 추가되는 비용이 있는 경우, 이를 유저가 알 수 있도록
            명확하게 기재하여야 합니다. 다만, 이러한 특별한 거래조건은 유저의 정당한 권리를 제한하거나 이 약관을 위반하거나 기타 회사가 미리 정한 거래조건에
            위배 되어서는 안됩니다.<br></br>
            <br></br>
            (3) 액티비티는 등록 이후에도 수정이 가능합니다. 일신의 문제로 인하여 액티비티가 불가능한 사유가 있는 경우에는 세모스 앱에서 운영중지를 하거나
            고객센터에게 이 사실을 알림으로써 일시적으로 액티비티를 중지할 수 있습니다. 수정되거나 추가된 내용이 유저에게 불리한 경우 수정 이전에 액티비티를
            신청한 유저에게는 추가 내용 등록 전의 내용이 적용됩니다.<br></br>
            <br></br>
            (4) 파트너는 액티비티 등록을 함에 있어서 저작권, 초상권 등 제3자의 권리를 침해해서는 안 됩니다. 파트너가 등록한 액티비티 정보 및 이미지는 판매
            활성화를 위하여 회사 소유의 자산(모바일, PC 등)과 회사가 제휴한 사이트와 다른 회원의 블로그 등에 노출될 수 있습니다. 다만, 회사 소유가 아닌 플랫폼에
            사용하는 경우나 다른 회원의 블로그 등에의 노출은 다른 회원이 회사가 정한 이용규칙에 동의하고 회사가 허용한 방식으로 공유하는 경우에만 가능합니다.
            <br></br>
            <br></br>
            (5) 파트너가 파트너 본인의 계정 또는 다른 회원의 계정을 이용하여 액티비티 및 거래조건이 실질적으로 동일한 액티비티 ("동일 액티비티")을 둘 이상
            중복하여 등록 할 수 없습니다. 이에 위반한 액티비티 등록에 대해 회사는 액티비티검색의 효율성 제고 등을 위해 일정한 절차를 거쳐 중복 등록된 액티비티를
            등록중단 또는 삭제 처리 할 수 있고 위반 횟수 및 수준에 따라 해당 액티비티를 등록한 파트너의 회원자격을 정지 또는 서비스 이용을 제한하거나 추천도를
            감점할 수 있습니다.<br></br>
            <br></br>
            (6) 파트너는 파트너 등록 및 액티비티 등록, 수정 시에 파트너의 개인정보 보호, 타인의 파트너정보 악용사례 방지 및 유저의 안전한 거래를 보장하기 위한
            세모스 시스템이 허용하지 않는 범위의 연락처를 기입할 수 없습니다. 파트너와 유저들의 개인정보 권리를 보호하고 세모스 시스템의 권리 보장을 통하지 않은
            개인간 거래를 방지하기 위하여 전화번호(유선/무선), 이메일 연락처, 메신저, SNS ID등 연락처의 기재를 금합니다. 파트너 액티비티 상세 페이지뿐만 아니라
            리뷰, 이미지/영상, 문의란 등 다른 게시판 또한 연락처를 기재 할 수 없으며 이를 위반할 시에 회사에서는 해당 문구 및 내용을 숨김 또는 삭제 처리 할 수
            있고 위반 횟수 및 수준에 따라 해당 액티비티를 등록한 파트너의 회원자격을 정지 또는 서비스 이용을 제한 할 수 있습니다.<br></br>
            <br></br>
            (7) 파트너는 액티비티의 인원과 가격 설정 시, 시간당 총 수익을 근로기준법이 정한 최저임금 이상으로 고려하여 설정 되어야 합니다. (19년 12월 20일 기준
            8,250원)<br></br>
            <br></br>
            (8) 파트너는 회원 탈퇴 시, 언제든지 회사에게 언제든지 회사에게 의사를 전달함으로써 이용 계약을 해지할 수 있습니다. 이에 따라 회사는 온라인으로
            탈퇴할 수 있는 절차를 제공합니다. 단, 탈퇴 요청 이전, 진행해야 할 액티비티가 있는 경우 유저와 함께 액티비티 이행 혹은 취소 사항을 합의 해야 합니다.
            유저와의 모든 액티비티 절차를 마무리하고, 회사와 서비스 이용에 따른 상호간의 채무 또한 청산하여야 합니다. 이 경우 거래 건의 철회 또는 취소로 인한
            불이익은 파트너 본인이 부담하여야 합니다.<br></br>
            <br></br>
            (9) 파트너는 세모스 서비스를 활용한 특정 상품 추천, 서비스 추천 및 매매 유도, 강매 등 일체 행위를 할 수 없습니다. 해당 사항이 확인 될 경우 회사는
            파트너에게 관련 자료를 요청할 수 있으며, 근거 자료를 통해 액티비티 승인 및 파트너 활동 여부에 대한 조치를 취할 수 있습니다. (보험 상품, 주식 종목
            추천, 부동산, 화장품, 식품, 건강보조제, 정수기, 서적, 각종 회원권, 서비스 이용권, 회원 가입 유도, 부업 유도, 자격증, 수료증 발급 등의 일체 행위)
            <br></br>
            <br></br>
            (10) 파트너가 액티비티를 삭제할 경우 액티비티는 복구되지 않습니다.<br></br>
            <br></br>
            <br></br>
            <b>제13조 (유저의 권리)</b>
            <br></br>
            <br></br>
            가. 유저는 자신의 개인 신상정보를 보호 받을 권리가 있습니다.<br></br>
            <br></br>
            나. 유저는 자신의 조건에 적합한 파트너를 직접 검색하고 액티비티를 신청할 수 있습니다.<br></br>
            <br></br>
            다. 유저는 파트너의 개인적인 사유로 액티비티가 중단된 경우, 지급한 액티비티료 전부에 대한 금액을 파트너에게 환불 받을 수 있습니다.<br></br>
            <br></br>
            <br></br>
            <b>제14조 (유저의 의무)</b>
            <br></br>
            <br></br>
            가. 유저는 관계법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한 주의사항, 당사가 통지한 사항을 준수해야 하며, 기타 회사의 업무에 방해되는 행위를
            해서는 안됩니다.<br></br>
            <br></br>
            나. 유저는 파트너의 사전 동의 없이 액티비티에 관한 조건(액티비티횟수, 액티비티료, 시간 등)을 일방적으로 변경할 수 없습니다.<br></br>
            <br></br>
            다. 유저는 가입시 기재한 정보에 변동이 생길 경우 회원정보 수정 및 관련 문의 접수를 위해 정보를 최신으로 갱신을 해야 합니다. 이를 진행하지 않아
            발생하는 불이익에 대한 모든 책임은 유저에게 있습니다.<br></br>
            <br></br>
            라. 파트너에게 유저가 불쾌한 언어 사용 및 행동, 액티비티과 관련없는 요청/ 이상 행동을 할 경우 파트너는 유저를 클래스에서 내보내거나 제외할 수
            있습니다. 또한 이에 대한 신고가 접수되는 경우 당사는 유저에게 다음과 같은 조치를 취합니다. 단, 중대한 범죄와 관련될 수 있는 사항으로 판단되는 경우,
            민/형사상 소송을 제기할 수 있으며 당사는 즉시 회원자격의 영구 탈퇴 조치를 진행할 수 있습니다.<br></br>
            <br></br>
            마. 유저는 파트너의 강의 내용 및 강의자료, 판매자료 일체를 수취하여 재판매를 목적으로 하는 행위를 금합니다. 또한 지적재산권에 대한 침해, 회사 및
            기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위를 금지합니다.<br></br>
            <br></br>
            바. 자신을 제외한 세모스의 모든 회원이 서비스 내 게시한 정보를 회사와 유저 모두에게 사전 승낙 없이 영리 또는 비영리의 목적으로 다른 회사에 제공할 수
            없습니다.<br></br>
            <br></br>
            사. 유저는 거래 의사 없이 구매, 취소를 반복해서는 안됩니다. 거래 의사 없이 의도적으로 반복적으로 구매와 취소를 반복한다고 판단할 시, 세모스는 전체
            파트너에게 해당 사실을 공지하고 회원 자격의 잠정 중지 및 영구적 박탈 등 의 조치를 취할 수 있습니다.<br></br>
            <br></br>
            1) 1건 신고 접수 시: 당사에서 상황에 대해 파악한 후, 경고조치<br></br>
            <br></br>
            2건 신고 접수 시: 파트너가 확인할 수 있도록 유저가 수강 신청한 액티비티신청서에 게시 및 표기<br></br>
            <br></br>
            3건 신고 접수 시: 회원 자격의 영구 정지<br></br>
            <br></br>
            <br></br>
            <b>제15조 (당사의 권리)</b>
            <br></br>
            <br></br>
            가. 당사는 액티비티 연결의 신뢰도를 제고하기 위해, 파트너로부터 학생증, 재학/졸업 증명서, 주민등록증에 준하는 신분증 제출을 요구할 수 있습니다.
            <br></br>
            <br></br>
            나. 제 1항과 같은 목적으로 당사의 사이트에서 파트너에게 온라인상 본인인증 절차를 밟도록 할 수 있으며, 파트너는 이에 응해야 합니다.<br></br>
            <br></br>
            다. 당사는 다음과 같은 회원의 자격을 예고 없이 박탈할 수 있습니다.<br></br>
            <br></br>
            (1) 회원의 개인신상정보가 사실과 다를 경우<br></br>
            <br></br>
            (2) 가입된 정보와 실제 이용자가 다를 경우<br></br>
            <br></br>
            (3) 사이트에서 취득한 회원정보를 양도하거나 중개에 이용할 경우<br></br>
            <br></br>
            (4) 본 약관을 준수하지 않아 3회의 경고조치 이후에도 시정되지 않을 경우<br></br>
            <br></br>
            ①1회 약관 위반에 대한 통보 및 시정조치 알림<br></br>
            <br></br>
            ②2회 경고 조치 알림<br></br>
            <br></br>③ 3회 30일 회원 자격 정지<br></br>
            <br></br>
            (5) 외부거래 및 결제 부정행위 (직거래 행위)<br></br>
            <br></br>
            ①회원이 상호간 세모스 서비스 내 제공하는 결제 서비스를 이용하지 않고, 다른 방식의 결제를 유도, 실제 거래하는 행위 일체 (연락처, 소셜계정, 이메일,
            메신저, 블로그, 유튜브채널, 각종 URL활용 등)<br></br>
            <br></br>
            ②회원 상호간 기타 혜택 제안 등을 통해 외부 거래를 유도하는 행위<br></br>
            <br></br>
            ③유저가 본인이 아닌 타인 명의를 도용하여 결제하는 행위<br></br>
            <br></br>
            ④위의 각 호의 행위에 준하는 일체의 결제 부정행위<br></br>
            <br></br>
            (6) 환불 기간이 끝난 이후 파트너/유저의 개인적인 사유로 생기는 환불 분쟁의 경우, 세모스 측에서는 별도의 개입과 판단 대신 약관에 명시된 환불 규정
            원칙을 적용<br></br>
            <br></br>
            라. 세모스에서 자체 제작한 모든 컨텐츠 (사진, 영상, 웹, 뉴스 등)의 본 저작권은 제작자인 세모스에게 있으며, 제작한 컨텐츠들은 세모스의 서면동의 없이
            무단배포 및 수정을 금지합니다. 허가 되지 않은 행위는 저작권 침해로 간주되며 세모스에서 엄격한 법적 조치를 취할 수 있습니다.<br></br>
            <br></br>
            마. 세모스 서비스 이용 시 개인정보처리방침에 명시된 항목이 당사에 기록되고 보관되며, 서비스 개선 및 운영을 목적으로 열람이 가능합니다.<br></br>
            <br></br>
            바. 사용자가 세모스에 등록한 콘텐츠는 홍보 혹은 기타 필요에 의해 정보가 편집 또는 훼손되지 않는 범위에서 이용할 수 있습니다. (단, 회원이 자신의
            콘텐츠에 대한 정보 제공에 거부 의사를 명확히 표현한 경우 회사는 해당 콘텐츠를 이용하지 않음)<br></br>
            <br></br>
            <br></br>
            <b>제16조 (유저의 권리)</b>
            <br></br>
            <br></br>
            가. 당사는 본 약관 및 관계법령에 따라 본 서비스를 실시하여야 하며, 다음 각 호의 사유가 발생한 경우를 제외하고 계속적, 안정적으로 서비스를 제공하기
            위해 최선의 노력을 다합니다.<br></br>
            <br></br>① 서비스용 설비의 보수, 정기점검 또는 공사로 인한 부득이한 경우<br></br>
            <br></br>② 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한 경우<br></br>
            <br></br>③ 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우<br></br>
            <br></br>④ 설비장애 또는 이용 폭주 등으로 인하여 서비스 이용에 지장이 있는 경우<br></br>
            <br></br>
            나. 당사는 회원의 정보를 철저히 보안 유지하며, 서비스를 운영하거나 개선하는 용도로만 사용하고, 이외의 목적으로 타 기관 및 개인에게 제공하지
            않습니다. 단. 다음 각 호에 해당하는 경우에는 그렇지 않습니다.<br></br>
            <br></br>① 관계법령에 의해 수사상의 목적으로 관계기관으로부터 요청이 있는 경우<br></br>
            <br></br>② 정보통신윤리위원회의 요청이 있는 경우<br></br>
            <br></br>③ 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우<br></br>
            <br></br>
            <br></br>
            <b>제17조 (액티비티료 결제)</b>
            <br></br>
            <br></br>
            가. 유저는 세모스의 안전결제서비스에 따라 결제하여야 하고, 회사는 입금정보와 환불정보를 회사에서 제공하는 양식에 따라 이용자들에게 내역을
            제공합니다. 실시간 계좌이체 및 가상계좌 입금방법으로 대금을 입금하는 경우 결제금액의 오입금으로 인한 모든 위험과 책임은 유저가 부담하여야 하고,
            회사는 이에 대하여 책임을 지지 않습니다.<br></br>
            <br></br>
            나. 유저는 신용카드로 대금을 결제할 수 있습니다. 단, 해외카드를 이용한 결제는 불가능합니다. 회사는 유저가 제공한 회원정보가 신용카드의 명의인과 다른
            경우 신용카드 결제를 거부하거나 취소할 수 있습니다. 다만, 법인카드의 경우 법인인증 확인절차를 거쳐 사용할 수 있습니다.<br></br>
            <br></br>
            다. 액티비티 내 다양한 커리큘럼을 기입해두고 하나의 액티비티료로만 결제하여 추가 결제를 받는 경우 맞춤결제 기능을 활용하여야 합니다. 즉, 기본
            액티비티료로 설정한 금액 대신 추가 금액이 발생하는 경우에, 파트너는 액티비티 소개서와 실시간 톡으로 결제 전 관련 내용을 유저에게 정확히
            고지해야합니다.<br></br>
            <br></br>
            라. 유저는 결제금액의 전부 또는 일부를 보유하고 있는 세모스 포인트를 사용하여 결제할 수 있습니다. 이 경우, 서비스화면의 주문정보 선택란에 사용할
            포인트 결제액을 기입하고, 차액만큼만 허용된 결제방법으로 결제하면 됩니다.<br></br>
            <br></br>
            <br></br>
            <b>제18조 (구매확정 및 수익금)</b>
            <br></br>
            <br></br>
            가. (2회차 이상의 클래스) 유저는 1회차 액티비티를 가져본 후, 수정/ 취소 요청을 하지않고 액티비티를 지속하는 경우, 첫 결제 대금은 세모스 측으로 귀속
            됩니다. 단, 첫 액티비티를 시작하기 24시간 이전에 취소요청을 하는 경우에는 결제대금이 100% 환불됩니다. 만일 첫 액티비티시작까지 24시간 이내에 취소
            의사를 밝히는경우 액티비티료 중 1시간의 금액은 환불 받을 수 없으며 시범강의비 명목으로 파트너에게 귀속됩니다.<br></br>
            <br></br>
            나. (원데이클래스) 유저가 액티비티를 완전히 들은 경우 또는 취소 의사를 밝히지 않고 예정된 액티비티 일정이 지난 경우 결제 금액은 세모스로 귀속됩니다.
            단, 액티비티를 시작하기 24시간 이전에 취소요청을 하는 경우에는 결제대금이 100% 환불됩니다. 만일 24시간 이내에 취소 의사를 밝히는 경우에는 등록된
            커리큘럼의 1시간 액티비티료가 차감된 금액이 환불 됩니다.<br></br>
            <br></br>
            다. 유저가 액티비티를 신청하는 경우, 요청한 시각으로부터 향후 12시간 이내에 안내된 세모스의 계좌로 액티비티료를 카드 또는 무통장 결제하여야 합니다.
            2회차 액티비티를 진행 이후에는 환불 요청이 있을 시에도, 세모스에 결제된 1회차 액티비티료는 환불되지 않습니다. 회사는 거래 내역, 수수료 세금계산서
            발행내역, 수익금 출금내역 요청 시에 요청자에게 공개합니다. 다만, 회사는 이 약관 [제11조 마. 항의 액티비티등록양식에 어긋난 등록, 허위등록,
            판매가장등록, 신용카드결제시스템 또는 통신 과금 시스템만을 이용하기 위한 액티비티등록 여부]를 확인하기 위하여 최고 60일까지 출금을 보류할 수
            있습니다. 이 경우, 파트너가 액티비티에 관한 거래사실 증빙서류를 회사에 제공하는 때에는 확인 후 송금처리를 합니다.<br></br>
            <br></br>
            라. 파트너는 액티비티등록 시 회사 및 유저로부터 수익금을 수취할 계좌를 지정하여야 하며, 회사가 정하는 기준에 내에서 계좌를 변경할 수 있습니다.
            파트너가 지정한 입금계좌의 예금주는 파트너와 동일인(개인회원의 경우 가입자 본인 명의)임을 원칙으로 합니다. 파트너가 지정한 입금계좌의 예금주가
            파트너 가입정보와 상이한 경우 송금에 불이익을 받을 수 있으며, 해당 조건에 맞는 계좌 관련 서류증빙이 완료될 때까지 대금의 송금을 보류할 수 있습니다.
            <br></br>
            <br></br>
            마. 첫 액티비티 진행 이전 취소 가능 기간은 60일 입니다. 유저가 입금한 날짜로부터 60일 이내에 환불, 액티비티교환 등의 의사를 표시하지 않고 액티비티의
            진행여부를 최종 확정을 지연하는 경우 61일째 되는 날 자동 최종확정 처리가 되어 파트너의 계좌로 수익금이 예치됩니다. 다만 파트너와 유저의 요청
            시(긴급한 개인적 용무 등) 최종확정 기간은 연장될 수 있습니다.<br></br>
            <br></br>
            바. 파트너는 회사에 대한 액티비티대금채권이 있을 경우 타인에게 양도할 수 없습니다.<br></br>
            <br></br>
            사. (VOD클래스의 경우) VOD클래스는 결제 하는 순간 구매 확정이 완료됩니다.<br></br>
            <br></br>
            <br></br>
            <b>제19조 (환불)</b>
            <br></br>
            <br></br>
            1. 2회차 이상의 클래스<br></br>
            <br></br>
            가. 첫 액티비티는 시작하기 24시간 이전에 취소요청을 하는 경우에는 결제대금이 100%환불됩니다. 단, 파트너에게 먼저 취소,환불 의사를 유선, SMS 등의
            수단으로 통보한 후에 환불 접수가 가능합니다.<br></br>
            <br></br>
            나. 만일 첫 액티비티시작까지 24시간 이내에 취소 의사를 밝히는 경우 전체 액티비티료 중 첫 1시간의 금액은 환불 받을 수 없으며, 시범강의 및 장소대여,
            참여인원 미확보에 대한 명목으로 파트너에게 귀속됩니다.<br></br>
            <br></br>
            다. 파트너와 유저 양측은 1회 액티비티 후 액티비티를 지속하기로 결정한 이후임에도 유저의 사정으로 액티비티를 중단해야 하는 경우에 한해 다음과 같은
            환불 규정을 적용 합니다.<br></br>
            <br></br>① 파트너는 전체 등록 커리큘럼의 50%에 해당하는 시간을 진행하기 이전에는, 진행된 과외 기간을 제외한 나머지 기간에 대한 금액을 유저에게
            환불하여야 합니다.<br></br>
            <br></br>② 파트너는 전체 등록 커리큘럼의 50%에 해당하는 시간을 진행한 경우에는, 진행되지 않은 잔여 기간과 상관없이 환불의 의무를 지지 않습니다.
            <br></br>
            <br></br>③ 단, 파트너의 판단 하에 유저에게 금액을 개인적으로 환불 조치 하는 것은 가능하며 이에 대해서는 세모스 측에서 관여하지 않습니다.<br></br>
            <br></br>④ 유저가 파트너에게 문제가 있다고 판단하여 액티비티 진행 및 환불을 요청하는 경우, 세모스 측에서는 개입할 수 있으며 이에 대한 사유 확인과
            액티비티 과정상에 문제는 없었는지 여부를 확인 합니다. 즉, 액티비티 진행상의 불쾌감 조성, 준비 소홀, 협박, 폭행, 추행, 불법적인 회유 등의 파트너의
            의무에 맞지 않는 행위를 확인하여, 해당 사실이 발견되는 형사 고발 및 법적인 조치를 가할 수 있습니다. 또한, 파트너의 회원자격을 정지 또는 서비스
            이용을 제한하는 조치를 취할 수 있습니다.<br></br>
            <br></br>⑤ 약관에 규정된 회사의 세모스안전결제서비스가 종료된 후 액티비티의 환불 등과 관련하여 파트너와 유저 사이에 분쟁 등이 발생한 경우 원칙상
            관련 당사자간에 해결을 우선시하며, 상호 합의 이후 회사는 이에 대한 책임을 보증하지 않습니다.<br></br>
            <br></br>
            <br></br>
            라. 파트너 개인의 사정으로 인해 액티비티를 중단해야 하는 경우는 다음과 같습니다.<br></br>
            <br></br>① 커리큘럼이 남아 있음에도 불구하고, 액티비티를 중단하는 경우 그 시기와 상관없이 파트너는 액티비티료로 받은 모든 금액을 유저에게 환불
            하여야 합니다.<br></br>
            <br></br>② 결제 수수료는 환불되지 않습니다.<br></br>
            <br></br>③ 파트너의 개인 사정은 건강상의 이유, 취직, 외국으로의 유학 등 모든 사항에 대해 예외를 두지 않습니다.<br></br>
            <br></br>④ 단, 파트너가 유저에게 문제가 있다고 판단하여 액티비티 진행 및 환불을 거부하는 경우, 세모스 측에서는 개입할 수 있으며 이에 대한 사유
            확인과 액티비티 과정상에 문제는 없었는지 여부를 확인 합니다. 즉, 액티비티 진행상의 불쾌감 조성, 협박, 폭행, 추행, 불법적인 회유 등의 범죄에 준하는
            행위를 확인하여, 해당 사실이 발견되는 즉시 형사 고발 및 법적인 조치를 가할 수 있습니다. 또한, 유저의 회원자격을 정지 또는 서비스 이용을 제한하는
            조치를 취할 수 있습니다.<br></br>
            <br></br>⑤ 파트너와 유저간의 협의 하에 유저가 남은 액티비티 분의 액티비티료만 파트너분에게 환불 조치 해드리는 것은 가능하며, 이에 대해서는 세모스
            측에서 관여하지 않습니다 다. 파트너의 귀책 사유로 다수의 유저의 취소 및 환불요청이 발생하는 경우, 또는 동일 중분류 카테고리 내 타 액티비티보다
            환불요청이 현저히 높은 경우 회사는 파트너의 등록 제한, 인기도 감소 등의 페널티를 부과할 수 있습니다.<br></br>
            <br></br>
            마. 파트너와 유저는 서비스를 이용하여 약속 된 첫 액티비티 일정이 진행 된 이후, 사전 약속되지 않은 파트너와 유저의 상호 추가적 협의에 대한 금전적
            환불에 대해서는 세모스에서는 관여 하지 않습니다. 단, 파트너와 유저 사이에 분쟁이 발생한 경우 세모스측에서는 개입할 수 있으며, 이를 중재하고 협의 및
            권장조치를 취할 수 있습니다.<br></br>
            <br></br>
            <br></br>
            2. 원데이 클래스의 경우<br></br>
            <br></br>
            가. 액티비티를 시작하기 24시간 이전에 취소 요청을 하는 경우에는 결제대금이 100% 환불됩니다. 만일 액티비티 시작 24시간 이내에 취소 의사를 밝히는
            경우에는 등록된 커리큘럼의 1시간 액티비티료가 차감된 금액이 환불 됩니다. 해당 금액은 시범강의 및 장소대여, 참여인원 미확보에 대한 명목으로
            파트너에게 귀속됩니다.<br></br>
            <br></br>
            나. 액티비티를 들었거나 예정된 액티비티 일정이 지난 경우, 결제 금액은 전액 환불되지 않습니다.<br></br>
            <br></br>
            다. 액티비티 등록 페이지 및 상호 합의/조정한 커리큘럼 전체의 액티비티를 파트너가 이행한 경우, 다음과 같은 환불 규정을 적용 합니다.<br></br>
            <br></br>① 파트너는 커리큘럼 상의 액티비티를 이행 했으므로 환불이 불가능 합니다.<br></br>
            <br></br>② 단, 액티비티 상 문제가 있었다고 판단될 시, 상호 합의 하에 유저에게 금액을 개인적으로 환불 조치 하는 것은 가능하며 이에 대해서는 세모스
            측에서 관여하지 않습니다.<br></br>
            <br></br>③ 만일, 유저가 파트너에게 문제가 있다고 판단하여 액티비티 진행 및 환불을 요청하는 경우, 세모스 측에서는 개입할 수 있으며 이에 대한 사유
            확인과 액티비티 과정상에 문제는 없었는지 여부를 확인 합니다. 즉, 액티비티 진행상의 불쾌감 조성, 준비 소홀, 협박, 폭행, 추행, 불법적인 회유 등의
            파트너의 의무에 맞지 않는 행위를 확인하여, 해당 사실이 발견되는 경우 형사 고발 및 법적인 조치를 가할 수 있습니다. 또한, 파트너의 회원자격을 정지
            또는 서비스 이용을 제한하는 조치를 취할 수 있습니다.<br></br>
            <br></br>④ 약관에 규정된 회사의 세모스안전결제서비스가 종료된 후 액티비티의 환불 등과 관련하여 파트너와 유저 사이에 분쟁 등이 발생한 경우 원칙상
            관련 당사자간에 해결을 우선시하며, 상호 합의 이후 회사는 이에 대한 책임을 보증하지 않습니다.<br></br>
            <br></br>
            라. 파트너와 유저는 서비스를 이용하여 약속 된 첫 액티비티 일정이 진행 된 이후, 사전 약속되지 않은 파트너와 유저의 상호 추가적 협의에 대한 금전적
            환불에 대해서는 세모스에서는 관여 하지 않습니다. 단, 파트너와 유저 사이에 분쟁이 발생한 경우 세모스측에서는 개입할 수 있으며, 이를 중재하고 협의 및
            권장조치를 취할 수 있습니다.<br></br>
            <br></br>
            <br></br>
            3. VOD 클래스의 경우<br></br>
            <br></br>
            가. VOD클래스를 시청 하는 도중 컨텐츠 액티비티 내용의 불만족에 의한 사유로는 환불이 불가합니다.<br></br>
            <br></br>
            나. 교재 or 구성품이 없는 VOD는 결제 후, 7일 이내 유료 영상을 재생한 이력이 없을 시 전액 환불이 가능합니다. 교재 or 구성품이 있는 VOD는 결제 이후
            실물 배송 완료 후, 7일 이내 유료 영상을 재생한 이력이 없을 시 전액 환불이 가능합니다. (재생 시간은 고려하지 않습니다) 단, 무료로 제공되는 샘플 영상
            시청은 해당되지 않습니다. 이에 따라 환불이 불가능한 상황에 최초 결제 이후, 개월 수 변경과 같은 결제금액 옵션 변경 또한 불가능합니다. 또한 실물의
            훼손이 있는 경우에는 환불이 불가능합니다.<br></br>
            <br></br>
            다. in-app결제를 진행하는 경우, 어플리케이션 마켓플레이스가 자사의 환불 정책을 1순위로 따르도록 하고 있다면, 환불은 해당 플랫폼으로 별도 문의하셔야
            합니다.<br></br>
            <br></br>
            라. 액티비티 결제 후, 영상이 정상적으로 출력되지 않는 경우, 세모스의 기술팀이 동시에 해당 문제점을 확인합니다. 세모스 플랫폼, 또는 재생 플레이어에
            중대한 문제가 있다고 판단되는 경우 전액 환불 됩니다. 세모스와 재생 플레이어의 기술팀이 직접 유저분과 원격 기술지원, 오프라인 기술지원 중 1개 이상의
            방법을 활용하여 문제점을 확인합니다.<br></br>
            <br></br>
            마. 시스템 오류에 대해 거짓된 정보를 통한 환불 사유 요구는 민사처벌의 대상이 될 수 있습니다.<br></br>
            <br></br>
            교재 or 구성품이 없는 경우 수강 전 수강 후<br></br>
            신청 후 7일 이내 전액 환불 환불 불가<br></br>
            신청 후 7일 초과 환불 불가 환불 불가<br></br>
            <br></br>
            교재 or 구성품이 있는 경우 수강 전 수강 후<br></br>
            실물 배송 완료 후, 7일 이내 전액 환불 환불 불가<br></br>
            실물 배송 완료 후, 7일 이내 환불 불가 환불 불가<br></br>
            <br></br>
            <br></br>
            4. 전자책 클래스의 경우<br></br>
            <br></br>
            가. PDF 형태로 제공되는 서비스이며, 별도의 레슨은 포함되어 있지 않습니다.<br></br>
            <br></br>
            나. 콘텐츠 내용의 불만족에 의한 사유로는 환불이 불가합니다. 단, 클래스 소개 페이지에 기재된 내용과 전자책에 포함된 내용이 다른 경우 세모스에서
            확인하여 환불을 진행할 수 있습니다.<br></br>
            <br></br>
            다. 다운로드 가능한 경우와, 다운로드 불가한 경우에 따라 다른 환불 규정을 적용합니다.<br></br>
            <br></br>
            (1) 다운로드 가능한 경우<br></br>① 구매 이후 세모스 사이트를 통해 다운로드가 가능합니다.<br></br>
            <br></br>② 콘텐츠를 다운로드한 경우 환불이 불가합니다.<br></br>
            <br></br>③ 콘텐츠를 다운로드하지 않은 경우 결제 후 7일 이내에는 전액 환불, 7일 경과 시에는 전체 결제 금액의 90% 환불이 가능합니다.<br></br>
            <br></br>④ 결제 완료 시점부터 90일간 무제한 다운로드가 가능하며, 90일 이후에는 다운로드가 불가합니다.<br></br>
            <br></br>
            (2) 다운로드 불가한 경우<br></br>① 구매 이후 파트너가 실물을 유저에게 직접 발송합니다.<br></br>
            <br></br>② 파트너가 실물을 유저에게 발송한 이후에는 환불이 불가합니다<br></br>
            <br></br>
            <br></br>
            <b>제20조 (면책조항)</b>
            <br></br>
            <br></br>
            가. 당사는 유저와 파트너의 독립적이고 자발적인 의사에 따라 액티비티를 신청, 협의, 결정할 수 있도록 사전에 동의한 정보와 방식으로 중개업무를 하고
            있습니다. 세모스는 회원이 등록한 정보에 대한 사실의 정확성과 신뢰성을 확보하기 위해 학력 인증, 신분증 인증, 경력 인증에 대해 정확히 인증 자료를
            확보하고 이를 서비스 내에 표시합니다. 이에 대한 허위 정보 보증은 세모스에게 책임이 있으며 세모스의 허위 신분증과 자격증을 보증한 경우 세모스의
            귀책으로 간주합니다. 다만 국가공인 자격증과 같이 명확히 시기와 보증번호 등을 확인할 수 없는, 인성, 업무 경험 등과 같은 정성적인 부분에 대한 정보에
            대한 보증을 하지 않습니다. 따라서 이로 인해 발생하는 정신적인 피해 및 손해 등에 대해서는 책임을 지지 않습니다.<br></br>
            <br></br>
            나. 당사는 유저와 파트너와의 액티비티방식, 결제방식, 환불 규정들은 세모스의 각 약관 규정들을 따르나, 상호간의 협의 사항이 있는 경우에는 규정이 면칙
            될 수 있도록 우선순위를 두고 있습니다. 또한 약관과 다르게 액티비티를 진행한다는 상호 협의가 있는 경우 이로 인해 발생하는 분쟁과 관련한 책임은
            당사자들이 지며, 당사는 당사의 고의 또는 중과실이 없는 한 책임이 없습니다.<br></br>
            <br></br>
            다. 당사는 천재지변 또는 이에 준하는 불가항력으로 인해 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. 또한 서비스 제공의
            지연이나 서비스를 제공하지 못하거나 또는 고객정보가 유출된 경우 당사의 고의 또는 과실이 없는 한 책임을 지지 않습니다.<br></br>
            <br></br>
            라. 당사는 이용자의 귀책사유로 인해 서비스 이용의 장애가 발생한 경우에는 당사의 고의 또는 중과실이 없는 한 당사의 책임은 면제됩니다.<br></br>
            <br></br>
            마. 본 약관의 규정을 위반함으로 인해 회사에 손해가 발생하는 경우, 이 약관을 위반한 이용자는 회사에 발생되는 손해를 배상할 책임이 있습니다. 또한
            회사가 약관을 위반한 이용자로 인해 제3자에게 손해를 배상한 경우 배상 청구 할 수 있습니다.<br></br>
            <br></br>
            <br></br>
            <b>제21조 (회원자격의 박탈)</b>
            <br></br>
            <br></br>
            가. 다음 각 호에 해당하는 경우 사전 통보 없이 강제탈퇴 하거나, 이용을 중지할 수 있습니다.<br></br>
            <br></br>① 공공질서 및 미풍양속에 반하는 경우<br></br>
            <br></br>② 범죄적 행위에 관련되는 경우<br></br>
            <br></br>③ 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우<br></br>
            <br></br>④ 타인의 계정 및 비밀번호를 도용한 경우<br></br>
            <br></br>⑤ 타인의 명예를 손상시키거나 불이익을 주는 경우<br></br>
            <br></br>⑥ 같은 사용자가 다른 계정으로 이중 등록을 한 경우<br></br>
            <br></br>⑦ 회사 직원 및 운영자 등을 포함한 타인을 사칭하기 위해 등록을 한 경우<br></br>
            <br></br>⑧ 서비스에 위해를 가하는 등 건전한 이용을 저해하는 경우<br></br>
            <br></br>⑨ 기타 관련 법령이나 회사가 정한 이용조건에 위배되는 경우<br></br>
            <br></br>
            나. 파트너가 액티비티를 이행하지 않고 일방적으로 계약을 파기하고 유저에게 액티비티료를 환불하지 않을 경우, 당사는 해당 파트너의 회원자격을 박탈함과
            동시에 관계법령에 의해 파트너를 고소, 고발하는 등의 조치를 취할 수 있으며, 피해자가 고소, 고발 조치를 취한 경우 당사는 적극 협조합니다.<br></br>
            <br></br>
            다. 세모스 사이트는 유저와 파트너 간의 액티비티 연결 및 세모스의 서비스를 제공받기 위한 목적으로만 이용이 가능하며, 사교육 기관이 유저를 모집하거나
            광고 등에 상업적으로 이용할 경우 예고 없이 회원자격을 박탈합니다.<br></br>
            <br></br>
            라. 타인에게 계정 공유, 기타 상업 및 비상업적으로 서비스 이용 시 회원등록 취소 및 강제탈퇴 처리되며, 당사의 운영상의 영업방해 및 이미지 훼손에 대한
            손해배상과 개인정보 무단수집에 대한 모든 민/형사적 책임을 집니다.<br></br>
            <br></br>
            <br></br>
            <b>제22조 (분쟁의 해결)</b>
            <br></br>
            <br></br>
            가. 당사와 이용자는 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위해 서로 필요한 노력을 해야 합니다. 나. 제 1항의 규정에도 불구하고, 동
            분쟁으로 인해 소송이 제기될 경우 소송은 상호 합의 하에 정한 소재 법원으로 합니다. 상호 합의점이 도출되지 않는 경우, 민사상에서 정하는 관할 법원으로
            합니다.<br></br>
            <br></br>
            <br></br>
            <b>부칙</b>
            <br></br>
            <br></br>
            이용약관 내용 추가, 삭제 및 수정이 있을 시에는 개정일로부터 7일전, 회원의 권리 의무에 중대한 영향을 미치는 중요한 사항의 변경인 경우에는 30일 전에
            세모스사이트 공지사항, 이메일 등을 통해 고지합니다.<br></br>
            <br></br>
            <b>공고일자 : 2021년 12월 02일</b>
            <br></br>
            <b>시행일자 : 2021년 12월 09일</b>
            <br></br>
            <br></br>
          </section>
        </CrossContent>
      </CrossContainer>
    );
  }
}

export default withParams(Semos_using_process);
