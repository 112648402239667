import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const ModalBox = ({ setStatus, status, children, confirm }) => {
  const ref = useRef(null);

  const CalenderClose = (e) => {
    if (status && (!ref.current || !ref.current.contains(e.target))) {
      setStatus(false);
    }
  };

  useEffect(() => {
    if (!confirm) {
      if (status) {
        window.addEventListener("click", CalenderClose);

        return () => {
          window.removeEventListener("click", CalenderClose);
        };
      }
    }
  }, [status]);

  return (
    <Container>
      <div ref={ref} style={{ boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.25)" }}>
        {children}
      </div>
    </Container>
  );
};

export default ModalBox;

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2100000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
