const save = (t, v) => {
  if(v) {
    window.localStorage.setItem(t, v);
  }
}

const get = (t) => {

  var item = window.localStorage.getItem(t);

  return item;
}

export function SaveOne(type, value) {

  save(type, value);

  return true;
};

export function GetOne(type) {

  var one = get(type);

  return one;
};

export function SaveMany(list) {

  for(let i=0; i < list.length; i++) {
    let type = list[i].type;
    let value = list[i].value;

    if(value) {
      save(type, value);
    }
  }

  return true;
};

export const Clear = () => {
  window.localStorage.clear();
};