import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./resource/css/App.css";
import "./resource/css/Font.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// general
import AppOpen from "./resource/pages/app_open";
import PartnerAppOpen from "./resource/pages/partner_app_open";
import Semos_user_info_process from "./resource/pages/세모스_개인정보_처리방침";
import Semos_using_process from "./resource/pages/semos_using_term";
import Semos_payment_process from "./resource/pages/세모스_결제약관";
import Semos_ad_process from "./resource/pages/세모스_광고_약관";
import KakaoChannel from "./resource/tool/kakao_channel";
import KakaoShare from "./resource/tool/kakao_share";
import KakaoPartnerShare from "./resource/tool/kakao_partner_share";
import PaySuccess from "./resource/pages/paySuccess";
import PaySuccessNew from "./resource/pages/paySuccessNew";
import PaySuccessV3 from "./resource/pages/paySuccessV3";
import PaySuccessFacilityV3 from "./resource/pages/PaySuccessFacilityV3";
import PayFail from "./resource/pages/payFail";
import PayComplete from "./resource/pages/payComplete";
import FacilityPaySuccess from "./resource/pages/facilityPaySuccess";
import Activity from "./resource/pages/activity";
import ActivityListing from "./resource/pages/activity_listing";
import KakaoMap from "./resource/component/basic/KakaoMap";
import SportsPlace from "./resource/pages/SportsPlace";
import GoogleMap from "./resource/pages/googleMap";

// user
import Pay_redi from "./resource/pages/pay_redi";
import TossPayRedi from "./resource/pages/toss_pay_redi";
import Pay_check_success from "./resource/pages/pay_check_success";
import AppPage from "./resource/pages/app_page";
import TossPay from "./resource/pages/tossPayments";
import TossPayNew from "./resource/pages/tossPaymentsNew";
import TossPayV3 from "./resource/pages/tossPaymentsV3";
import TossPayFacilityV3 from "./resource/pages/TossPaymentsFacilityV3";

// partner
import KakaoMapForRN from "./resource/pages/kakaoMapForRN";
import NewHome from "./resource/pages/newHome";
import NotFound from "./resource/pages/notFound";
import RNMap from "./resource/pages/map";
import MobileLogin from "./resource/pages/mobileLogin";
import FacInfo from "./resource/pages/fac_info";
import AppFailUrl from "./resource/pages/app_fail_url";
import FailUrl from "./resource/pages/failurl";
import Naver from "./resource/pages/naver";
import PartnerDetail from "./resource/pages/partnerDetail";


const { Kakao } = window;

const { TossPayments } = window;

var clientKey = "live_ck_ODnyRpQWGrNP41pBjKg3Kwv1M9EN";
var tossPayments = TossPayments(clientKey); // 클라이언트 키로 초기화하기
// SDK를 초기화 합니다. 사용할 앱의 JavaScript 키를 설정해 주세요.
Kakao.init("4233c0cfe93da40e79ffcd370034aa14");

const App = (props) => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  const google_client_id = "731129506416-n5o9e0moq0qce4s4i2qt5p70pvd3u5bt.apps.googleusercontent.com";

  return (
    <>
      <ScrollToTop />
      <GoogleOAuthProvider clientId={google_client_id}>
        <Routes>
        
            <Route path="/" element={<NewHome />} />
            <Route path="/activity/:activityId" element={<Activity />} />
            <Route path="/login/:payId" element={<MobileLogin />} />
            <Route path="/map/:address/:extra/:type" element={<RNMap />} />
            <Route path="/naver" element={<Naver />} />
            <Route path="/fac_info/:payId" element={<FacInfo />} />
            <Route path="/activity_list" element={<ActivityListing />} />
            <Route path="/partner_detail/:id" element={<PartnerDetail />} />

            <Route path="/toss_pay/:activity_number/:schedule_id/:date_number/:amount/:people/:device/:point/:pay_type" element={<TossPay />} />
            <Route path="/toss_pay_new/:activity_number/:schedule_id/:date_number/:amount/:people/:device/:point/:pay_type" element={<TossPayNew />} />
            <Route path="/toss_pay_v3/:activity_number/:schedule_id/:date_number/:amount/:people/:device/:point/:coupon/:discount/:pay_type" element={<TossPayV3 />} />
            <Route path="/toss_pay_facility_v3/:activity_number/:schedule_id/:date_number/:end_number/:amount/:people/:device/:point/:coupon/:discount/:pay_type" element={<TossPayFacilityV3 />} />
            <Route path="/facility_pay_success/:payment_id/:type" element={<FacilityPaySuccess />} />
            <Route path="/Toss_pay_redi" element={<TossPayRedi />} />
            <Route path="/pay_redi/:key1" element={<Pay_redi />} />
            <Route path="/pay_check_success/:key1/:key2/:key3/:key4" element={<Pay_check_success />} />
            <Route path="/pay_success/:payment_id/:userspec_token" element={<PaySuccess />} />
            <Route path="/pay_success_new/:payment_id/:userspec_token" element={<PaySuccessNew />} />
            <Route path="/pay_success_v3/:payment_id/:userspec_token" element={<PaySuccessV3 />} />
            <Route path="/pay_success_facility_v3/:payment_id/:userspec_token" element={<PaySuccessFacilityV3 />} />
            <Route path="/payment_fail" element={<AppFailUrl />} />
            <Route path="/pay_fail" element={<PayFail />} />
            <Route path="/pay_complete/:payment_id" element={<PayComplete />} />
            <Route path="/pay_complete_facility/:payment_id" element={<PayComplete />} />
            <Route path="/fail_url" element={<FailUrl />} />
            
            <Route path="/semos_user_info_process" element={<Semos_user_info_process />} />
            <Route path="/semos_using_info" element={<Semos_using_process />} />
            <Route path="/semos_payment_info" element={<Semos_payment_process />} />
            <Route path="/semos_ad_info" element={<Semos_ad_process />} />

            <Route path="/partner_app_open/:key1" element={<PartnerAppOpen />} />       
            <Route path="/app_open/:key1" element={<AppOpen />} />
            <Route path="/kakao_channel" element={<KakaoChannel />} />
            <Route path="/kakao_share/:AN" element={<KakaoShare />} />
            <Route path="/kakao_share/:PN" element={<KakaoPartnerShare />} />
            <Route path="/kakao_map/:number/:id/:type" element={<KakaoMapForRN />} />
            <Route path="/app_page" element={<AppPage />} />
            <Route path="/kakao_map_search" element={<KakaoMap />} />
            <Route path="/sports_place" element={<SportsPlace />} />
            <Route path="/google_map" element={<GoogleMap />} />

            <Route path="/*" element={<NotFound />} />
        </Routes>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <></>;
};