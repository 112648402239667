import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

// components
import KakaoMap from "../component/kakao_map";

function KakaoMapForRN() {
  const { number, id, type } = useParams();
  const [kakaoMapProps, setKakaoMapProps] = useState({
    mapStatus: "show",
    map_location: number,
    map_location_etc: id,
  });

  useEffect(() => {
    var userToken = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "product_info");
    frm.append("pm", number);
    frm.append("sm", id);
    frm.append("st", type);
    frm.append("token", userToken);

    axios
      .post("https://ai.semos.kr/semos_user/service/product", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        const Value = data.data;
        const Status = Value.status;
        const location = Value.partner_info_list.partner_service_list[0].service_location[0];

        if (Status === "success") {
          setKakaoMapProps({
            ...kakaoMapProps,
            map_location: location.location_address,
            map_location_etc: location.location_extra_address,
          });
        }
      });
  }, []);

  return (
    <MapContainer>
      <KakaoMap map_address={kakaoMapProps.map_location} map_address_etc={kakaoMapProps.map_location_etc} update={true} />
    </MapContainer>
  );
}

const MapContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

export default KakaoMapForRN;
