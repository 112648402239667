import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import Slider from "react-slick";
import { Icon, Wrapper, Text, WrapperColumn, GmarketText, AbsoluteWrapper, WrapperRow } from "./layout/layoutStorage";

//icon
import fullStar from "../icon/fullStar.svg";
import arrow from "../icon/arrow.svg";
import { ReactComponent as FullHeart } from "../icon/fullHeart.svg";
import { ReactComponent as DetailNotice } from "../icon/detailNotice.svg";
import { ReactComponent as RightArrowIcon } from "../icon/carouselArrowRight.svg";
import { ReactComponent as Check } from "../icon/check.svg";
import dayCheck from "../icon/dayCheck.svg";
import answer from "../icon/answer.svg";
import question from "../icon/question.svg";
import activityLocation from "../icon/activityLocation.svg";
import exclude from "../icon/exclude.svg";
import included from "../icon/included.svg";
import bring from "../icon/bring.svg";
import checkPoint from "../icon/checkPoint.svg";
import inDoor from "../icon/inDoor.svg";
import clock from "../icon/clock.svg";
import maxPeople from "../icon/maxPeople.svg";
import semosTypeLogo from "../icon/semosTypeLogo.svg";
import ArrowLeft from "../icon/carouselArrowLeftW.png";
import ArrowRight from "../icon/carouselArrowRightW.png";
import calenderCheck from "../icon/calenderCheck.png";
import { ReactComponent as SimpleLocation } from "../icon/simpleLocation.svg";

//components
import ActivityBottomCard from "./activityBottomCard";
import KakaoMap from "../component/kakao_map";
import ReviewItem from "./reviewItem";
import DatePicker from "./datePicker";
import Reconfirm from "./reconfirm";
import { LoadingSpinner } from "../tool/loading";
import SignInModal from "./signInModal";
import colors from "../styles/colors";
import AppModal from "../component/home/appModal";
import MataTagChange from "../tool/MetaTag";

const CurriculumList = (length, list) => {
  const res = [];
  const arr = Object.values(list);

  for (let i = 0; i < length; i++) {
    const number = i + 1;
    let lesson_time = "";

    // 한 회차당 총 소요시간
    const time = arr[i].map((val) => val.time).reduce((acc, cur) => Number(acc) + Number(cur), 0);

    if (time / 60 - parseInt(time / 60)) {
      lesson_time = `${time}분`;
    } else {
      lesson_time = `${time / 60}시간`;
    }

    if (arr[i].length) {
      res.push(
        <CurriculumContainer>
          {/* 원데이 / 다회차 title */}
          <CurriculumSubTitle>
            <span>{length === 1 ? "원데이" : `${number}회차`}</span>
            {/*lesson_time ? <CurriculumTime>{lesson_time}</CurriculumTime> : ""*/}
          </CurriculumSubTitle>
          {arr[i].map((val, idx) => {
            const { title, content, time, number } = val;
            return (
              <Wrapper key={`${title}-${idx}`} position="relative" padding="0 0 28px 0">
                <AbsoluteWrapper width="10px" height="10px" bgc="#0E6DFF" radius="5px" left="5px" top="5px" />
                {idx !== arr[i].length - 1 && <AbsoluteWrapper bgc="#0E6DFF" width="2px" height="100%" left="9px" top="12px" />}
                <WrapperColumn margin="0 0 0 32px">
                  <Text size="15px" weight="600" ls="-0.02em" color="#0E6DFF" margin="0 0 8px 0">
                    {title}
                  </Text>
                  <Text wrap={'wrap'} margin="0 0 0 15px" size="14px" weight="500" ls="-0.02em" color="#3C3E41">
                    {content?.split('\n').map((item, idx2) => (
                      <span style={{width : '100%'}} key={`${i}_${idx}_${idx2}_curri`}>{item}</span>
                    ))}
                  </Text>
                </WrapperColumn>
              </Wrapper>
            );
          })}
        </CurriculumContainer>
      );
    }
  }
  return res;
};

const Mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

const ActivityDetail = () => {
  const [confirmData, setConfirmData] = useState({
    partnerId: "",
    activityId: "",
    sport: "",
    duration: "",
    type: "",
    name: "",
    day: "",
    time: "",
    date: "",
    people: "",
    scheduleId: "",
    address: "",
  });

  const [ALD, setALD] = useState(false);

  //자격증 유도
  // const [tooltopOpen, setTooltipOpen] = useState(false);

  const [scroll, setScroll] = useState(false);
  const [menu, setMenu] = useState(1);
  // 카카오 맵
  const [kakaoMapProps, setKakaoMapProps] = useState({
    mapStatus: "show",
    map_location: "",
    map_location_etc: "",
    x: "",
    y: "",
  });
  const [signUpModal, setSignUpModal] = useState(false);
  const [purChaseModal, setPurChaseModal] = useState(false);

  // 커리큘럼 확인
  const [curriculumLength, setCurriculumLength] = useState(0);

  const [App, setApp] = useState(false);

  const navigate = useNavigate();

  const handleScroll = () => {
    if (window.scrollY > 79) {
      setScroll(true);
    } else {
      setScroll(false);
    }

    const isScrolledIntoView = (el) => {
      const rect = el.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;
      const isVisible = elemTop < window.innerHeight && elemBottom >= 155;
      return isVisible;
    };
    const activityInfo = document.getElementById("activityInfo");
    const activityLocation = document.getElementById("activityLocation");
    const activityReview = document.getElementById("activityReview");
    if (isScrolledIntoView(activityInfo)) {
      setMenu(1);
    } else if (isScrolledIntoView(activityLocation)) {
      setMenu(2);
    } else if (isScrolledIntoView(activityReview)) {
      setMenu(3);
    }
  };

  /*const handleVisibilityChange = () => { 
    if(window.document.hidden) { 
        window.open('','_self').close(); 
        
        let opener = window.self;

        setTimeout(() => { 
          window.self.close()
        },1000);

        window.self.close();
    } else { 
        // the page is visible 
    } 
  }*/

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    //window.document.addEventListener("visibilitychange", handleVisibilityChange, false);

    return () => {
      window.removeEventListener("scroll", handleScroll); //clean up
      //window.document.removeEventListener("visibilitychange", handleVisibilityChange, false);
    };
  }, []);


  const scrollToTop = (id) => {
    const ele = document.getElementById(id);
    const elePosition = window.scrollY + ele.getBoundingClientRect().top - 155;
    window.scrollTo(0, elePosition);
  };

  const { activityId } = useParams();

  const [focus, setFocus] = useState('focus');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [partnerId, setPartnerId] = useState("");
  const [heart, setHeart] = useState({
    status: false,
    count: 0,
  });

  //일정 확인 모달
  const [reConfirmModal, setReConfirmModal] = useState(false);

  //날짜 선택
  const Today = new Date();

  //인원 선택
  const [people, setPeople] = useState({
    min: 0,
    max: 0,
  });
  let selectPeopleList = [];
  for (let i = 0; i < +people.max - +people.min + 1; i++) {
    selectPeopleList.push({ text: +people.min + i, value: +people.min + i });
  }

  //리뷰 별점 바
  const [reviewLange, setReviewRange] = useState({});

  //자주묻는 질문
  const [faq, setFAQ] = useState(true);
  const [faqList, setFAQList] = useState([]);
  let realFAQList = faq ? faqList.slice(0, 2) : faqList;

  //환불 취소 규정
  const [rule, setRule] = useState(false);
  const [review, setReview] = useState(true);
  const [reviewList, setReviewList] = useState([]);
  let realReviewList = review ? reviewList.slice(0, 2) : reviewList;

  const getData = () => {
    setLoading(true);
    var token = localStorage.getItem("token");
    const frm = new FormData();
    frm.append("mode", "main_web");
    frm.append("activity_id", activityId);

    // test
    frm.append("test", "test");

    if (token) {
      frm.append("token", token);
    }

    axios
      .post("https://ai.semos.kr/semos_user/v4/activity/ActivityDetail", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        if (Status == "success") {
          let ActivityInfo = Value.activity_info;

          setData(ActivityInfo);

          MataTagChange({
            title : `세모스 - [${ActivityInfo.name}] 액티비티`,
            description : `${ActivityInfo.information ? ActivityInfo.information : ActivityInfo.name} | 세모스 : 1등 스포츠 예약 플랫폼`,
            imageUrl : `${ActivityInfo.image ? ActivityInfo.image[0] : ""}`
          });

          setCurriculumLength(Object.keys(ActivityInfo.curriculum).length); // 커리큘럼 회차 정보
          setPartnerId(ActivityInfo.partner_info.id);
          setReviewRange(ActivityInfo.review_range);
          setFAQList(ActivityInfo.faq);
          setReviewList(ActivityInfo.review);
          setPeople({
            min: +ActivityInfo.min_people,
            max: +ActivityInfo.max_people,
          });
          var JiimCount = Number(ActivityInfo.jjim_count);
          var JiimStatus = ActivityInfo.jjim;
          var ConfirmRate = Value.confirm_rate;
          if (Value.activity_info.location.length > 0) {
            setKakaoMapProps({
              ...kakaoMapProps,
              map_location: Value.activity_info.location[0].address,
              map_location_etc: Value.activity_info.location[0].area,
              x: Value.activity_info.location[0].x,
              y: Value.activity_info.location[0].y,
            });
          }

          setConfirmData({
            partner: ActivityInfo.partner_info.id,
            activity: activityId,
            sport: ActivityInfo.sport,
            duration: ActivityInfo.duration,
            type: ActivityInfo.type,
            name: ActivityInfo.name,
            day: "",
            time: "",
            tileId: "",
            people: "",
            // address: loc,
          });
          setHeart({
            status: JiimStatus,
            count: JiimCount,
          });

          //window.location.href = `semos://activity_detail/${activityId}/activityList`;

          setTimeout(() => {
            setLoading(false);
          }, 500);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  useEffect(() => {
    if(Mobile) {
      // setApp(true)
    }


    getData();

    const frm = new FormData();
    frm.append('token', "");
    frm.append('mode', 'view_history');
    frm.append('activity_id', activityId);
    frm.append('path', 'ActivityDetail');
    frm.append('platform', 'WEB');
    
    axios
    .post('https://ai.semos.kr/semos_user/v3/wish_list/wish', frm, {
        headers: {'Content-Type': 'multipart/form-data'},
      })
    .then(data => {
        var status = data.data.status;
        if (status === 'success') {
          console.log('RECENT CHECK SUCCESS');
        } else if (status === 'fail') {
          console.log('HISTORY CHECK FAIL');
        } else if (status === 'waring') {
          console.log('HISTORY CHECK WARNING');
        }
    });
    // getPay();
  }, []);

  //편의시설
  const FacilityTextList = {
    parking: "주차장",
    shower_room: "샤워실",
    fitting_room: "탈의실",
    cafeteria: "음식점",
  };

  const settings = {
    draggable: false,
    className: "slider variable-width",
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
  };

  let values = Object.values(reviewLange); // 0,0,0,0,1
  let max = Math.max(...values);

  const JJimStatus = () => {
    var token = localStorage.getItem("token");

    if (token) {
      const frm = new FormData();

      frm.append("token", token);
      frm.append("mode", "change");
      frm.append("activity_id", activityId);

      axios
        .post("https://ai.semos.kr/semos_user/v3/wish_list/wish", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((res) => {
          var status = res.data.status;
          var jjim_check = res.data.jjim_check;

          if (status === "success") {
            setHeart({
              count: jjim_check ? heart.count + 1 : heart.count - 1,
              status: jjim_check,
            });
          } else {
            window.alert(res.data.status_message);
          }
        });
    } else {
      window.alert("로그인 후 사용이 가능합니다.");
    }
  };

  const realInfo = data.information?.replace(/ /g, "");

  const AppContinue = () => {
    setLoading(true)
    window.location.href = `semos://activity_detail/${activityId}/activityList`;

    setTimeout(() => {
      window.alert('세모스 앱 설치가 필요합니다.')
      window.open('https://semos.kr/app_open/desktop', "_blank");
      setApp(true);
      setLoading(false);
    }, 2000);
  }

  return (
    <>
      {scroll && (
        <Header>
          <WrapperRow jc="flex-start" ai="flex-end" height={Mobile ? "60px" : "79px"} width="100%">
            <Wrapper
              cursor="pointer"
              onClick={() => scrollToTop("activityInfo")}
              jc="center"
              ai="center"
              width="110px"
              height="60px"
              style={{
                boxSizing: "border-box",
                borderBottom: menu == 1 && "2px solid #0E6DFF",
              }}
            >
              <Text size="16px" weight="600" color={menu == 1 ? "#0E6DFF" : "#818792"}>
                액티비티 정보
              </Text>
            </Wrapper>
            <Wrapper
              onClick={() => scrollToTop("activityLocation")}
              cursor="pointer"
              jc="center"
              ai="center"
              width="121px"
              height="60px"
              style={{ borderBottom: menu == 2 && "2px solid #0E6DFF" }}
            >
              <Text size="16px" weight="600" color={menu == 2 ? "#0E6DFF" : "#818792"}>
                진행 장소
              </Text>
            </Wrapper>
            <Wrapper
              cursor="pointer"
              onClick={() => scrollToTop("activityReview")}
              jc="center"
              ai="center"
              width="110px"
              height="60px"
              style={{ borderBottom: menu == 3 && "2px solid #0E6DFF" }}
            >
              <Text size="16px" weight="600" color={menu == 3 ? "#0E6DFF" : "#818792"}>
                리뷰
              </Text>
              <Text margin="0 0 0 6px" size="12px" weight="600" color={menu == 3 ? "#0E6DFF" : "#ACAFB5"}>
                {data.review_list?.length}
              </Text>
            </Wrapper>
          </WrapperRow>
        </Header>
      )}

      <WrapperColumn width="100%" id="test">
        <WrapperRow position="relative" width="100%">
          <Wrapper>
            <Container>
              <Slider {...settings}>
                {data.image?.map((item, idx) => (
                  <div key={idx}>
                    <div style={{ position: "relative" }}>
                      <Icon of="cover" src={item} width={Mobile ? `100vw` : "461px"} height={Mobile ? `calc(100vw * (366/461))` : "366px"}></Icon>
                      <AbsoluteWrapper top="16px" right="16px" bgc="rgba(0,0,0,0.4)" radius="16px" padding="2px 12px">
                        <Text size="12px" color="#ffffff" weight="600">
                          {idx + 1}&nbsp;
                        </Text>
                        <Text size="12px" color="#818792" weight="600">
                          {`/ ${data.image.length}`}
                        </Text>
                      </AbsoluteWrapper>
                    </div>
                  </div>
                ))}
              </Slider>
            </Container>
          </Wrapper>

          {
            Mobile ?
              ""
            :
            <WrapperColumn margin="20px 0 21px 24px" width="100%" jc="space-between">
              <WrapperColumn>
                <WrapperRow gap="6px" ai="center">
                  <Text size="14px" weight="600" color="#0E6DFF">
                    {data?.sport}
                  </Text>

                  {data?.duration && (
                    <>
                      <Wrapper width="1px" height="14px" bgc="#ACAFB5" />
                      <Text size="14px" weight="600" color="#ACAFB5">
                        {data?.duration}
                      </Text>
                    </>
                  )}

                  {data?.type && (
                    <>
                      <Wrapper width="1px" height="14px" bgc="#ACAFB5" />
                      <Text size="14px" weight="600" color="#ACAFB5">
                        {data?.type}
                      </Text>
                    </>
                  )}
                </WrapperRow>

                <Text margin="12px 0 8px 0" size="20px" weight="600" ls="-0.02em" color="#3C3E41">
                  {data?.name}
                </Text>

                <WrapperColumn ai="flex-start">
                  <Text margin="0 0 20px 0" size="22px" weight="700" ls="-0.02em" color="#1A1C1F">
                    {data.price}원
                  </Text>
                </WrapperColumn>

                {/*<WrapperRow ai="center" gap="9px" margin="0 0 20px 0">
                  {
                    (data?.partner_info?.subscribe === true) 
                    ?
                      <Wrapper  padding="4px 8px" radius="5px" border="1px solid #3383FF">
                        <Text size="12px" weight="600" ls="-0.02em" color="#3383FF">
                          #{`상담 가능`}
                        </Text>
                      </Wrapper>
                    : 
                      <></>
                  }
                  {data.keyword?.map((item, idx) => (
                    <Wrapper key={idx} padding="4px 8px" radius="5px" border="1px solid #3383FF">
                      <Text size="12px" weight="600" ls="-0.02em" color="#3383FF">
                        #{item}
                      </Text>
                    </Wrapper>
                  ))}
                </WrapperRow>*/}

                {(
                  <WrapperRow margin="0 0 22px 0" ai="center">
                    {
                      data.rating_info?.review_count > 0 && 
                      <>
                        <Icon src={fullStar} width="16px" height="16px" />
                        <Text margin="0 8px 0 5px" size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                          {data.rating_info?.review_value}
                        </Text>
                        <Text style={{ margin : "0 5px 0 0"}} size="12px" weight="500" ls="-0.02em" color="#ACAFB5">
                          ({data.rating_info?.review_count}개의 리뷰)
                        </Text>
                      </>
                    }

                    <Wrapper margin="0 4px 0 0">
                      <FullHeart fill="#FF9187" width="16px" height="16px" />
                    </Wrapper>
                    <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                      {data.rating_info?.jjim_count}
                    </Text>
                  </WrapperRow>
                )}
              </WrapperColumn>


                <WrapperRow
                  position="relative"
                  cursor="pointer"
                  ai="center"
                  width="100%"
                  padding="8px"
                  height="38px"
                  bgc={colors.Gr01}
                  radius="4px"
                  gap="4px"
                  jc="space-between"
                  onClick={() => scrollToTop("activityLocation")}
                >
                  <Wrapper>
                    <SimpleLocation style={{ margin: "0 8px 0 0" }} fill={colors.Gr04} width="16px" height="16px" />
                    {data.location?.length && (
                      <>
                        {data.location.slice(0, 2).map((item, idx) => (
                          <Text ws="pre" weight="500" size="14px" ls="-0.02em" color={colors.Gr04} key={idx}>
                            {`${item.city} ${item.district}`}
                            {data.location.length > 1 && idx === 0 ? `, ` : ""}
                          </Text>
                        ))}
                        <Text ws="pre" weight="500" size="14px" ls="-0.02em" color={colors.Gr04}>
                          {data.location.length > 2 && ` +${data.location.length - 2}`}
                        </Text>
                      </>
                    )}
                  </Wrapper>
                </WrapperRow>
            </WrapperColumn>
          }

        </WrapperRow>

        {
          Mobile 
          ?
            <></>
          :
            <WrapperRow
              jc="center"
              ai="center"
              gap={Mobile ? "" : "116px"}
              padding={Mobile ? "12px 24px" : "24px 0"}
              style={{
                borderBottom: "2px solid #f3f3f3",
                borderTop: "2px solid #f3f3f3",
              }}
              width={Mobile ? `100vw` : ""}
            >
              {/* 다회차 or 원데이 */}
              {data.duration && (
                <WrapperColumn width={`calc(100% / 4)`} ai="center" gap="4px">
                  <Icon src={dayCheck} width="24px" height="24px" />
                  <Text size="14px" weight="500" color="#3C3E41">
                    {data.duration}
                  </Text>
                </WrapperColumn>
              )}

              {/* 자격증 or  */}
              {data?.type && (
                <WrapperColumn width={`calc(100% / 4)`} ai="center" gap="4px">
                  <Icon src={semosTypeLogo} width="24px" height="24px" />
                  <Text size="14px" weight="500" color="#3C3E41">
                    {data.type}
                  </Text>
                </WrapperColumn>
              )}

              {/* 실내 or 실외 */}
              {data?.area && (
                <WrapperColumn width={`calc(100% / 4)`} ai="center" gap="4px">
                  <Icon src={inDoor} width="24px" height="24px" />
                  <Text size="14px" weight="500" color="#3C3E41">
                    {data.area}
                  </Text>
                </WrapperColumn>
              )}

              {/* 진행 시간 */}
              {data?.time && (
                <WrapperColumn width={`calc(100% / 4)`} ai="center" gap="4px">
                  <Icon src={clock} width="24px" height="24px" />
                  <Text size="14px" weight="500" color="#3C3E41">
                    {(data.duration === "다회차") ? `${data.time}회차` : `${Math.ceil(data.time / 60)}시간`}
                  </Text>
                </WrapperColumn>
              )}

              {/* 최대 인원 */}
              {/*data?.max_people && (
                <WrapperColumn width={`calc(100% / 5)`} ai="center" gap="4px">
                  <Icon src={maxPeople} width="24px" height="24px" />
                  <Text size="14px" weight="500" color="#3C3E41">
                    최대 {data.max_people}인
                  </Text>
                </WrapperColumn>
              )*/}
            </WrapperRow>
        }
      </WrapperColumn>

      {/* 상세페이지 */}
      <WrapperRow width="100%" height="100%" jc="space-between">
        <WrapperColumn width={Mobile ? "100%" : "461px"} padding={Mobile ? "0 24px" : ""}>
          <WrapperColumn id="activityInfo" margin="0px 0 20px 0">

            {Mobile ? 
              <WrapperColumn           
                margin="20px 0 0 0" 
                width="100%" 
                jc="space-between"
              >
                <WrapperColumn>
                  <WrapperRow gap="6px" ai="center">
                    <Text size="14px" weight="600" color="#0E6DFF">
                      {data?.sport}
                    </Text>

                    {data?.duration && (
                      <>
                        <Wrapper width="1px" height="14px" bgc="#ACAFB5" />
                        <Text size="14px" weight="600" color="#ACAFB5">
                          {data?.duration}
                        </Text>
                      </>
                    )}

                    {data?.type && (
                      <>
                        <Wrapper width="1px" height="14px" bgc="#ACAFB5" />
                        <Text size="14px" weight="600" color="#ACAFB5">
                          {data?.type}
                        </Text>
                      </>
                    )}
                  </WrapperRow>

                  <Text margin="12px 0 8px 0" size="20px" weight="600" ls="-0.02em" color="#3C3E41">
                    {data?.name}
                  </Text>

                  <WrapperColumn ai="flex-start">
                    <Text margin="0 0 20px 0" size="22px" weight="700" ls="-0.02em" color="#1A1C1F">
                      {data.price}원
                    </Text>
                  </WrapperColumn>

                  {(
                    <WrapperRow margin="0 0 20px 0" ai="center">
                      {
                        (data.rating_info?.review_count > 0) &&
                        <>
                          <Icon src={fullStar} width="16px" height="16px" />
                          <Text margin="0 8px 0 5px" size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                            {data.rating_info?.review_value}
                          </Text>
                          <Text style={{ margin : "0 5px 0 0"}} size="12px" weight="500" ls="-0.02em" color="#ACAFB5">
                            ({data.rating_info?.review_count}개의 리뷰)
                          </Text>
                        </>
                      }
                      <Wrapper margin="0 4px 0 0">
                        <FullHeart fill="#FF9187" width="16px" height="16px" />
                      </Wrapper>
                      <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                        {data.rating_info?.jjim_count}
                      </Text>
                    </WrapperRow>
                  )}

                  {/*<WrapperRow width={`100%`} ai="center" jc={`flex-end`} gap="9px" margin="0 0 20px 0">
                    {data.keyword?.map((item, idx) => (
                      <Wrapper key={idx} padding="4px 8px" radius="5px" border="1px solid #3383FF">
                        <Text size="12px" weight="600" ls="-0.02em" color="#3383FF">
                          #{item}
                        </Text>
                      </Wrapper>
                    ))}
                  </WrapperRow>*/}

                </WrapperColumn>
              </WrapperColumn>
            : ""}

            {
              Mobile 
              ?
                <WrapperRow
                  jc="center"
                  ai="center"
                  gap={Mobile ? "" : "116px"}
                  padding={Mobile ? "12px 0" : "24px 0"}
                  margin="0 0 20px 0"
                  style={{
                    borderBottom: "2px solid #f3f3f3",
                    borderTop: "2px solid #f3f3f3",
                  }}
                  width={Mobile ? `100%` : ""}
                >
                  {/* 다회차 or 원데이 */}
                  {data.duration && (
                    <WrapperColumn width={`calc(100% / 4)`} ai="center" gap="4px">
                      <Icon src={dayCheck} width="24px" height="24px" />
                      <Text size="14px" weight="500" color="#3C3E41">
                        {data.duration}
                      </Text>
                    </WrapperColumn>
                  )}

                  {/* 자격증 or  */}
                  {data?.type && (
                    <WrapperColumn width={`calc(100% / 4)`} ai="center" gap="4px">
                      <Icon src={semosTypeLogo} width="24px" height="24px" />
                      <Text size="14px" weight="500" color="#3C3E41">
                        {data.type}
                      </Text>
                    </WrapperColumn>
                  )}

                  {/* 실내 or 실외 */}
                  {data?.area && (
                    <WrapperColumn width={`calc(100% / 4)`} ai="center" gap="4px">
                      <Icon src={inDoor} width="24px" height="24px" />
                      <Text size="14px" weight="500" color="#3C3E41">
                        {data.area}
                      </Text>
                    </WrapperColumn>
                  )}

                  {/* 진행 시간 */}
                  {data?.time && (
                    <WrapperColumn width={`calc(100% / 4)`} ai="center" gap="4px">
                      <Icon src={clock} width="24px" height="24px" />
                      <Text size="14px" weight="500" color="#3C3E41">
                        {(data.duration === "다회차") ? `${data.time}회차` : `${Math.ceil(data.time / 60)}시간`}
                      </Text>
                    </WrapperColumn>
                  )}

                  {/* 최대 인원 */}
                  {/*data?.max_people && (
                    <WrapperColumn width={`calc(100% / 5)`} ai="center" gap="4px">
                      <Icon src={maxPeople} width="24px" height="24px" />
                      <Text size="14px" weight="500" color="#3C3E41">
                        최대 {data.max_people}인
                      </Text>
                    </WrapperColumn>
                  )*/}
                </WrapperRow>
              : 
                <></>
            }

            <Text margin={Mobile ? "0 0 24px 0" : "40px 0 24px 0"} size="20px" weight="700" ls="-0.02em" color="#000000">
              액티비티 정보
            </Text>

            {/* 특별한 정보 */}
            {data.unique_list?.length > 0 && (
              <WrapperColumn margin="0 0 40px 0">
                <Wrapper margin="0 0 12px 0" ai="center">
                  <Icon src={checkPoint} margin="0 8px 0 0" width="24px" height="24px" />
                  <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                    이 액티비티는 이런 점이 특별해요!
                  </Text>
                </Wrapper>
                <WrapperColumn padding="14px 12px" gap="8px">
                  {data.unique_list?.map((item, idx) => (
                    <Wrapper key={idx} ai="center">
                      <Check fill="#0E6DFF" style={{ margin: "0 8px 0 0" }} width="24px" height="24px" />
                      <Text size="14px" weight="500" ls="-0.02em" color="#3C3E41">
                        {item}
                      </Text>
                    </Wrapper>
                  ))}
                </WrapperColumn>
              </WrapperColumn>
            )}

            {/* 액티비티 소개 */}
            {realInfo ? (
              <WrapperColumn margin="0 0 40px 0">
                <Wrapper margin="0 0 12px 0" ai="center">
                  <Icon src={checkPoint} margin="0 8px 0 0" width="24px" height="24px" />
                  <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                    액티비티 소개
                  </Text>
                </Wrapper>
                <Wrapper padding="14px 18px" bgc="#F9F9F9" border="1px solid #DCDCDC" radius="14px">
                  <Text wrap={`wrap`} ws="pre-line" size="14px" weight="500" ls="-0.02em" color="#3C3E41">
                    {data?.information?.split('\n').map((item, idx) => (
                      <span style={{
                        width : '100%',
                        display : "inline-block"
                      }} key={`${idx}_activity_info`}>{item}</span>
                    ))}
                  </Text>
                </Wrapper>
              </WrapperColumn>
            ) : (
              data.information_image  && (
                <WrapperColumn margin="0 0 40px 0">
                  <Wrapper margin="0 0 12px 0" ai="center">
                    <Icon src={checkPoint} margin="0 8px 0 0" width="24px" height="24px" />
                    <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                      액티비티 소개
                    </Text>
                  </Wrapper>
                  <Icon src={data.information_image} width="100%" height="100%" />
                </WrapperColumn>
              )
            )}

            {/* 추천대상 */}
            {data.recommend?.length > 0 && (
              <WrapperColumn margin="0 0 40px 0">
                <Wrapper margin="0 0 12px 0" ai="center">
                  <Icon src={checkPoint} margin="0 8px 0 0" width="24px" height="24px" />
                  <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                    추천대상
                  </Text>
                </Wrapper>
                <WrapperColumn gap="8px" margin="0 0 0 5px">
                  {data.recommend?.map((item, idx) => (
                    <Wrapper key={idx}>
                      <Wrapper width="6px" height="6px" radius="3px" bgc="#3C3E41" margin="0 13px 0 0" />
                      <Text size="14px" weight="500" ls="-0.02em" color="#3C3E41">
                        {item}
                      </Text>
                    </Wrapper>
                  ))}
                </WrapperColumn>
              </WrapperColumn>
            )}

            {/* 자격요건 */}
            {data.requirement?.length > 0 && (
              <WrapperColumn margin="0 0 40px 0">
                <Wrapper margin="0 0 14px 0" ai="center">
                  <Icon src={checkPoint} margin="0 8px 0 0" width="24px" height="24px" />
                  <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                    자격요건
                  </Text>
                </Wrapper>
                <WrapperColumn gap="8px" padding="14px 18px" bgc="#F9F9F9" border="1px solid #DCDCDC" radius="14px">
                  {data.requirement?.map((item, idx) => (
                    <Wrapper key={idx}>
                      <Check fill="#3C3E41" style={{ margin: "0 8px 0 0" }} width="16px" height="16px" />
                      <Text size="14px" weight="500" ls="-0.02em" color="#3C3E41">
                        {item}
                      </Text>
                    </Wrapper>
                  ))}
                </WrapperColumn>
              </WrapperColumn>
            )}

            {/* 커리큘럼 */}
            {curriculumLength > 0 && (
              <WrapperColumn margin="0 0 50px 0">
                <WrapperRow jc="space-between">
                  {/* <Wrapper ai="center"> */}
                  <Wrapper margin="0 0 12px 0" ai="center">
                    <Icon src={checkPoint} margin="0 8px 0 0" width="24px" height="24px" />
                    <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                      커리큘럼
                    </Text>
                  </Wrapper>

                </WrapperRow>

                {curriculumLength && CurriculumList(curriculumLength, data.curriculum)}

              </WrapperColumn>
            )}

            {/* 준비물 */}
            <WrapperColumn margin="0 0 32px 0">
              <Wrapper margin="0 0 12px 0" ai="center">
                <Icon src={bring} margin="0 8px 0 0" width="24px" height="24px" />
                <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                  준비물
                </Text>
              </Wrapper>
              <Wrapper wrap="wrap" gap="14px">
                {data.preparation?.length ? (
                  <>
                    {data.preparation?.map((item, idx) => (
                      <Wrapper key={idx} padding="6px 14px" border="1px solid #ACAFB5" bgc="#F9F9F9" radius="24px">
                        <Text size="14px" weight="500" ls="-0.02em" color="#3C3E41">
                          {item}
                        </Text>
                      </Wrapper>
                    ))}
                  </>
                ) : (
                  <Wrapper padding="6px 14px" border="1px solid #ACAFB5" bgc="#F9F9F9" radius="24px">
                    <Text size="14px" weight="500" ls="-0.02em" color="#3C3E41">
                      없음
                    </Text>
                  </Wrapper>
                )}
              </Wrapper>
            </WrapperColumn>

            {/* 포함 사항 */}
            {data.include?.length > 0 && (
              <WrapperColumn margin="0 0 32px 0">
                <Wrapper margin="0 0 12px 0" ai="center">
                  <Icon src={included} margin="0 8px 0 0" width="24px" height="24px" />
                  <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                    포함 사항
                  </Text>
                </Wrapper>

                <Wrapper wrap="wrap" gap="14px">
                  {data.include?.map((item, idx) => (
                    <Wrapper key={idx} padding="6px 14px" border="1px solid #66A2FF" bgc="#f9f9f9" radius="24px">
                      <Text size="14px" weight="500" ls="-0.02em" color="#3383FF">
                        {item}
                      </Text>
                    </Wrapper>
                  ))}
                </Wrapper>
              </WrapperColumn>
            )}

            {/* 불포함 사항 */}
            {data.exclude?.length > 0 && (
              <WrapperColumn margin="0 0 32px 0">
                <Wrapper margin="0 0 12px 0" ai="center">
                  <Icon src={exclude} margin="0 8px 0 0" width="24px" height="24px" />
                  <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                    불포함 사항
                  </Text>
                </Wrapper>
                <Wrapper wrap="wrap" gap="14px">
                  {data.exclude?.map((item, idx) => (
                    <Wrapper key={idx} padding="6px 14px" border="1px solid #FFA199" bgc="#FFF3F2" radius="24px">
                      <Text size="14px" weight="500" ls="-0.02em" color="#FF9187">
                        {item}
                      </Text>
                    </Wrapper>
                  ))}
                </Wrapper>
              </WrapperColumn>
            )}

            {/* 부정행위 */}
            {data.cheating_text?.title && (
              <WrapperColumn>
                <WrapperRow radius="8px" border="1px solid #DCDCDC" padding="16px 24px">
                  <Wrapper ai="flex-start" margin="0 12px 0 0">
                    <DetailNotice fill="#FF9187" width="24px" height="24px" />
                  </Wrapper>
                  <WrapperColumn>
                    <Text margin="0 0 4.5px 0" size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                      {data.cheating_text?.title}
                    </Text>
                    <Text ws="pre-line" size="12px" weight="500" ls="-0.02em" color="#818792">
                      {data.cheating_text?.decsription}
                    </Text>
                  </WrapperColumn>
                </WrapperRow>
              </WrapperColumn>
            )}

          </WrapperColumn>

          <Wrapper width="100%" height="2px" bgc="#f3f3f3" />

          {/* 진행장소 */}
          <WrapperColumn id="activityLocation" margin="20px 0 52px 0">
            <Text size="20px" weight="700" ls="-0.02em" color="#000000">
              진행장소
            </Text>

            {/* 진행장소 유의사항*/}
            {data.cheating_text?.title_lo && (
              <WrapperRow radius="8px" border="1px solid #DCDCDC" padding="16px 24px" margin="24px 0 20px 0">
                <Wrapper ai="flex-start" margin="0 12px 0 0">
                  <DetailNotice fill="#FF9187" width="24px" height="24px" />
                </Wrapper>
                <WrapperColumn>
                  <Text margin="0 0 4.5px 0" size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                    {data.cheating_text?.title_lo}
                  </Text>
                  <Text ws="pre-line" size="12px" weight="500" ls="-0.02em" color="#818792">
                    {data.cheating_text?.decsription_lo}
                  </Text>
                </WrapperColumn>
              </WrapperRow>
            )}
            <Wrapper padding="20px 0 14px 0">
              <Icon src={activityLocation} margin="0 8px 0 0" width="24px" height="24px" />
              <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                액티비티 장소
              </Text>
            </Wrapper>

            <KakaoMapWrap>
              <AbsoluteWrapper />
              <KakaoMap
                setKakaoMapProps={setKakaoMapProps}
                KakaoMapProps={kakaoMapProps}
                status={kakaoMapProps.mapStatus}
                map_address={kakaoMapProps.map_location}
                map_address_etc={kakaoMapProps.map_location_etc}
                locationX={kakaoMapProps.x}
                locationY={kakaoMapProps.y}
                update={true}
                locationList={data.location}
              />
            </KakaoMapWrap>

            {/* 편의시설 */}
            {data.facility?.length > 0 && (
              <WrapperColumn margin="40px 0 0 0">
                <Wrapper padding="0 0 14px 0">
                  <Icon src={activityLocation} margin="0 8px 0 0" width="24px" height="24px" />
                  <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                    편의시설
                  </Text>
                </Wrapper>

                <WrapperRow gap="37px">
                  {data.facility?.map((item, idx) => (
                    <WrapperColumn key={idx} jc="center" ai="center">
                      <Icon margin="0 0 6px 0" src={`https://ai.semos.kr/img/RNAPP/${item}.png`} width="24px" height="24px" />
                      <Text size="12px" weight="500" ls="-0.02em" color="#3C3E41">
                        {FacilityTextList[item]}
                      </Text>
                    </WrapperColumn>
                  ))}
                </WrapperRow>
              </WrapperColumn>
            )}
          </WrapperColumn>

          {/* 리뷰 */}
          <WrapperColumn id="activityReview">
            <Wrapper ai="center">
              <Text margin="0 8px 0 0" size="20px" weight="700" ls="-0.02em" color="#000000">
                리뷰
              </Text>
              <Text size="16px" weight="500" ls="-0.02em" color="#0E6DFF">
                {data.review?.length}
              </Text>
            </Wrapper>
            {/* 리뷰 탑 박스 */}
            {data.review?.length > 0 ? (
              <Wrapper margin="24px 0 20px 0" radius="15px" height="141px" border="1px solid #DCDCDC" jc="center" ai="center">
                <WrapperColumn jc="center" ai="center">
                  <Text size="12px" weight="500" ls="-0.02em" color="#3C3E41" style={{ marginBottom: "4px" }}>
                    별점 평균
                  </Text>
                  <Icon src={fullStar} margin="19px 0 3px 0" width="48px" height="48px" />
                  <Text size="14px" weight="600" ls="-0.02em" color="#3C3E41">
                    {data.rating_info?.review_value}
                  </Text>
                </WrapperColumn>

                <Wrapper margin="0 35px" width="1px" height="94px" bgc="#dcdcdc" />

                <WrapperColumn jc="center" ai="center">
                  <Text size="12px" weight="500" ls="-0.02em" color="#3C3E41" style={{ marginBottom: "9px" }}>
                    별점 비율
                  </Text>
                  <Wrapper gap="13px" style={{ flexDirection: "row-reverse" }}>
                    {values.map((item, idx) => {
                      if (max == item) {
                        return (
                          <WrapperColumn key={idx} ai="center" jc="center" gap="4px">
                            <Text size="10px" weight="500" ls="-0.02em" color="#0E6DFF">
                              {item}
                            </Text>
                            <Wrapper width="8px" height="49px" radius="8px" bgc="#3383FF"></Wrapper>
                            <Text size="12px" weight="500" ls="-0.02em" color="#3C3E41">
                              {idx + 1}점
                            </Text>
                          </WrapperColumn>
                        );
                      } else {
                        return (
                          <WrapperColumn key={idx} ai="center" jc="center" gap="4px">
                            <Text size="10px" weight="500" ls="-0.02em" color="#DCDCDC">
                              {item}
                            </Text>
                            <Wrapper position="relative" width="8px" height="49px" radius="8px" bgc="#F5F5F5">
                              <AbsoluteWrapper
                                bottom="0"
                                width="8px"
                                height={`${(item / max) * 100}%`}
                                radius={(item / max) * 100 === 100 ? "8px 8px 8px 8px" : "0 0 8px 8px"}
                                bgc="#DCDCDC"
                              />
                            </Wrapper>
                            <Text size="12px" weight="500" ls="-0.02em" color="#DCDCDC">
                              {idx + 1}점
                            </Text>
                          </WrapperColumn>
                        );
                      }
                    })}
                  </Wrapper>
                </WrapperColumn>
              </Wrapper>
            ) : (
              <WrapperColumn margin="24px 0 30px 0" jc="center" ai="center" bgc="#E5F0FF" radius="15px" height="96px">
                <Text size="14px" weight="500" ls="-0.02em" color="#3383FF">
                  첫 리뷰어가 되어주세요!
                </Text>
                <Text size="14px" weight="500" ls="-0.02em" color="#3383FF">
                  포토 리뷰 작성 시 2,000P를 드려요.
                </Text>
              </WrapperColumn>
            )}

            {/* 리뷰 리스트*/}
            {realReviewList
              ? realReviewList.map((item, idx) => <ReviewItem key={idx} idx={idx} length={realReviewList.length} reviewList={item} type="activity" />)
              : ""}

            {data.review?.length > 2 && (
              <Wrapper
                cursor="pointer"
                onClick={() => setReview(!review)}
                margin="24px 0 40px"
                height="40px"
                jc="center"
                ai="cetner"
                border="1px solid #DCDCDC"
                radius="14px"
                bgc="#f9f9f9"
              >
                <Text size="14px" weight="500" ls="-0.02em" color="#3C3E41">
                  {review ? "더보기" : "간략히 보기"}
                </Text>
              </Wrapper>
            )}
            {/* 파트너 정보 */}
            <Wrapper
              jc="space-between"
              padding="16px 31px 24px 16px"
              style={{
                borderBottom: "2px solid #f3f3f3",
                borderTop: "2px solid #f3f3f3",
              }}
              cursor="pointer"
              onClick={() => {
                navigate(`/partner_detail/${partnerId}`);
                /*if (data.pc === "Partner") {
                  navigate(`/partner_detail/${partnerId}`);
                } else {
                  navigate(`/center_detail/${partnerId}`);
                }*/
                // if (partnerId == 176 || partnerId == 177) {
                //   navigate(`/partner_detail/${partnerId}`);
                // } else {
                //   navigate(`/center_detail/${partnerId}`);
                // }
              }}
            >
              <Wrapper>
                <Icon src={data.partner_info?.profile} radius="30px" margin="0 22px 0 0" width="54px" height="54px" />
                <WrapperColumn>
                  <Text size="18px" weight="600" ls="-0.02em" color="#3C3E41">
                    {data.partner_info?.name}
                  </Text>
                  <Wrapper>
                    <Text ws="pre-line" size="12px" weight="500" ls="-0.02em" color="#818792">
                      {`액티비티 `}
                      {data.partner_info?.activity_count}
                    </Text>
                    <Wrapper margin="0 10px" width="1px" height="12px" bgc="#ACAFB5" />
                    <Wrapper>
                      <Text ws="pre-line" size="12px" weight="500" ls="-0.02em" color="#818792">
                        {`리뷰 `}
                        {data.partner_info?.review_count}
                      </Text>
                    </Wrapper>
                  </Wrapper>
                </WrapperColumn>
              </Wrapper>
              <RightArrowIcon fill="#1A1C1F" width="24px" height="24px" />
            </Wrapper>

            {/* 자주 묻는 질문 */}
            {data.faq?.length > 0 && (
              <WrapperColumn margin="24px 0 0 0">
                <Wrapper ai="center" margin="0 0 12px 0">
                  <Icon src={checkPoint} margin="0 8px 0 0" width="24px" height="24px" />
                  <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                    자주 묻는 질문
                  </Text>
                </Wrapper>
                {realFAQList.map((item, idx) => (
                  <WrapperColumn key={idx} gap="14px">
                    <Wrapper ai="flex-start" padding="12px 25px" radius="15px 15px 15px 0" border="1px solid #DCDCDC" bgc="#F9F9F9">
                      <Icon src={question} margin="0 8px 0 0" width="16px" height="18px" />
                      <Text ws="pre-line" size="14px" weight="500" ls="-0.02em" color="#3C3E41">
                        {item.title}
                      </Text>
                    </Wrapper>

                    <Wrapper margin="0 0 14px 0" ai="flex-start" padding="12px 25px" radius="15px 15px 0 15px" bgc="#E5F0FF" border="1px solid #66A2FF">
                      <Icon src={answer} margin="0 8px 0 0" width="16px" height="18px" />
                      <Text ws="pre-line" size="14px" weight="500" ls="-0.02em" color="#3383FF">
                        {item.content}
                      </Text>
                    </Wrapper>
                  </WrapperColumn>
                ))}
                {data.faq?.length > 2 && (
                  <Wrapper
                    cursor="pointer"
                    onClick={() => setFAQ(!faq)}
                    margin="24px 0 0 0"
                    radius="14px"
                    jc="center"
                    ai="center"
                    bgc="#f9f9f9"
                    border="1px solid #DCDCDC"
                    height="40px"
                  >
                    <Text size="14px" weight="500" ls="-0.02em" color="#3C3E41">
                      {faq ? "더보기" : "간략히 보기"}
                    </Text>
                  </Wrapper>
                )}
              </WrapperColumn>
            )}

            {/* 유의사항 */}
            {data.caution?.length > 0 && (
              <WrapperColumn margin="24px 0 0 0">
                <WrapperRow ai="center">
                  <Wrapper margin="0 8px 0 0">
                    <DetailNotice fill="#0E6DFF" width="24px" height="24px" />
                  </Wrapper>
                  <Text size="16px" weight="600" ls="-0.02em" color="#3C3E41">
                    유의사항
                  </Text>
                </WrapperRow>

                <Wrapper margin="14px 0 0 0" border="1px solid #0E6DFF" radius="14px" bgc="#ffffff">
                  <WrapperColumn padding="16px 24px" gap="12px">
                    {data.caution?.map((item, idx) => (
                      <WrapperRow key={idx}>
                        <Wrapper ai="flex-start" margin="0 10px 0 0">
                          <DetailNotice fill="#0E6DFF" width="18px" height="18px" />
                        </Wrapper>
                        <Text ws="pre-line" size="14px" weight="500" ls="-0.02em" color="#3383FF">
                          {item}
                        </Text>
                      </WrapperRow>
                    ))}
                  </WrapperColumn>
                </Wrapper>
              </WrapperColumn>
            )}
          </WrapperColumn>
        </WrapperColumn>

        {/* 플로팅 박스 */}
        {
          Mobile ?
            ""
          : 
            <div>
              <FloatingBox>
                <DatePicker
                  scrollToTop={scrollToTop}
                  partner={partnerId}
                  activity={activityId}
                  subscribe={data?.partner_info?.subscribe}
                  // loc={loc}
                  heart={heart.status}
                  heartCount={heart.count}
                  setReConfirmModal={setReConfirmModal}
                  setConfirmData={setConfirmData}
                  confirmData={confirmData}
                  jjim={JJimStatus}
                  purChaseModal={purChaseModal}
                  setPurChaseModal={setPurChaseModal}
                  AppOpen={App} 
                  setAppOpen={setApp}
                />
              </FloatingBox>
            </div>
        }
      </WrapperRow>

      <WrapperColumn width="100%" margin="40px 0 0 0">
        {/*  취소/환불 규정 */}
        <WrapperRow
          padding="16px 0 17px 0"
          jc="space-between"
          style={{
            borderBottom: "2px solid #f3f3f3",
            borderTop: "2px solid #f3f3f3",
          }}
        >
          <WrapperColumn padding={Mobile ? "0 24px" : ""} width="100%">
            <WrapperRow jc="space-between">
              <Text size="18px" weight="600" ls="-0.02em" color="#3C3E41">
                취소/환불 규정
              </Text>
              <Icon
                cursor="pointer"
                onClick={() => setRule(!rule)}
                src={arrow}
                width="27px"
                height="27px"
                style={{
                  transition: "all 0.4s",
                  transform: rule ? "rotate(0.5turn)" : "",
                }}
              />
            </WrapperRow>
            {rule && (
              <WrapperColumn margin="18px 0 0 0">
                {/* 액티비티 예약 안내 */}
                <WrapperColumn border="1px solid #E1E1E1" padding="16px 24px" radius="15px">
                  <Text size="14px" weight="600" ls="-0.02em" color="#0E6DFF">
                    {data.rule_text?.resevation_title}
                  </Text>

                  <WrapperColumn margin="16px 0 0 0" gap="6px">
                    {data.rule_text.resevation?.map((item, idx) => (
                      <Wrapper ai="center" key={idx}>
                        <Wrapper style={{ flexShrink: "0" }} width="6px" height="6px" radius="3px" border="1.3px solid #3C3E41" margin="0 14px 0 0" />
                        <Text size="12px" weight="500" ls="-0.02em" color="#3C3E41">
                          {item}
                        </Text>
                      </Wrapper>
                    ))}
                  </WrapperColumn>
                </WrapperColumn>

                {/* 액티비티 취소 / 환불 규정 */}
                <WrapperColumn margin="18px 0 0 0" border="1px solid #E1E1E1" padding="16px 24px" radius="15px">
                  <Text size="14px" weight="600" ls="-0.02em" color="#0E6DFF">
                    {data.rule_text?.cancel_title}
                  </Text>
                  <WrapperColumn margin="16px 0 0 0" gap="6px">
                    {data.rule_text.cancel?.map((item, idx) => (
                      <Wrapper ai="center" key={idx}>
                        <Wrapper width="6px" height="6px" radius="3px" border="1.3px solid #3C3E41" margin="0 14px 0 0" />
                        <Text size="12px" weight="500" ls="-0.02em" color="#3C3E41">
                          {item}
                        </Text>
                      </Wrapper>
                    ))}
                  </WrapperColumn>
                </WrapperColumn>
              </WrapperColumn>
            )}
          </WrapperColumn>
        </WrapperRow>

        <div id="card">
          {data.similar_activity?.length > 0 ? <ActivityBottomCard text="" itemList={data.similar_activity} setSignUpModal={setSignUpModal} /> : ""}
        </div>

        <WrapperColumn width={Mobile ? "100%" : ""} margin={Mobile ? "0" : "0 0 39px 0"} padding={Mobile ? "24px" : "21.5px 10px"}>
          <Text size="10px" weight="500" ls="-0.02em" color="#ACAFB5">
            리포츠(주)는 통신판매 중개자이며, 통신판매 당사자가 아닙니다. 따라서 리포츠(주)는 파트너가 등록한 모든 액티비티 정보 및 거래에 관한 의무와 책임을
            지지 않습니다.
          </Text>
          <Text size="10px" weight="500" ls="-0.02em" color="#ACAFB5">
            단, 리포츠(주)가 파트너로 등록 및 판매한 액티비티는 그 의무와 책임을 부담합니다.
          </Text>
        </WrapperColumn>
      </WrapperColumn>


      {
        Mobile ?
          <WrapperRow onClick={() => AppContinue()} style={{zIndex : 100}} jc={'space-between'} padding={'10px 24px'} bgc={'#ffffff'} width={'100%'} position={"fixed"} bottom={'0'} left={'0'}>
            <BottomTap>
              {(data?.partner_info?.subscribe == true) ? '세모스 앱에서 상담/예약하기' : '세모스 앱에서 예약하기'}
            </BottomTap>
          </WrapperRow>
        :
        ""
      }

      {reConfirmModal && <Reconfirm data={confirmData} status={reConfirmModal} setStatus={setReConfirmModal} />}
      {purChaseModal ? <SignInModal status={purChaseModal} setStatus={setPurChaseModal} dup={ALD} setDup={setALD} page="activity" /> : ""}
      {signUpModal ? <SignInModal status={signUpModal} setStatus={setSignUpModal} dup={ALD} setDup={setALD} /> : ""}
      <LoadingSpinner loading={loading} />
      <AppModal open={App} setOpen={setApp} Mobile={Mobile} page={'activity_detail'} />
    </>
  );
};

export default ActivityDetail;

const Header = styled.div`
  width: 860px;
  position: fixed;
  background-color: #ffffff;
  top: 76px;
  align-items: flex-start;
  z-index: 30;

  @media screen and (max-width: 859px) {
    width : 100vw;
  }
`;

const RainbowBox = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  border-radius: 20px;
  padding: 3px 10px 3px 10px;
  background: radial-gradient(100% 843.76% at 0% 52.38%, #ff6759 0%, #8d4ef2 44.79%, #0e6dff 100%);
`;
const Container = styled.div`
  .slick-slider {
    width: 461px;
    height: 366px;

    @media screen and (max-width: 859px) {
      width : 100vw;
      height : calc(100vw * (366/461));
    }
  }

  .slick-next::before {
    background-image: url(${ArrowRight});
    background-size: 32px;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    content: "";
  }
  .slick-prev::before {
    background-image: url(${ArrowLeft});
    background-size: 32px;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    content: "";
  }
`;

const KakaoMapWrap = styled.div`
  #map {
    width: 461px !important;
    height: 192px !important;

    @media screen and (max-width: 859px) {
      width : calc(100vw - 48px) !important;
      height : calc((100vw - 48px) * (192/461)) !important;
    }
  }
`;

const FloatingBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 36px 0px 39px 0px;
  position: sticky;
  top: 170px;
  width: 375px;
  height: auto;
  border-radius: 8px;
  margin: 40px 0 40px 0;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  overflow: auto;
`;

const CurriculumContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CurriculumSubTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
  span {
    color: #818792;
    line-height: 22px;
    letter-spacing: -0.02em;
    font-size: 14px;
    font-weight: 500;
  }
`;

const CurriculumTime = styled.div`
  padding: 4px 12px;
  background: #e5f0ff;
  border-radius: 8px;
  color: #3383ff;
  text-align: center;
  letter-spacing: -0.02em;
  line-height: 142%;
  font-size: 12px;
  font-weight: 600;
`;

const BottomTap = styled.div`
  width : 100%;
  border-radius: 8px;
  background-color: #0E6DFF;
  display : inline-flex;
  align-items: center;
  justify-content: center;
  height : 52px;
  color: #ffffff;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 16px;
  font-weight: 600;
  cursor : pointer;
`;