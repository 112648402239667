import React, { Component } from 'react';
import { BrowserRouter as Router, useParams, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import axios from "axios";

// 이미지 

// component


// css

const FooterContent = styled.section`
    width : 80rem;
    height : 85px;
    background-color : #ffffff;
    display : inline-flex;
    justify-content: space-between;
    align-items: center;
    position : fixed;
    bottom : 0;
    left : 50%;
    right : 50%;
    transform : translate(-50%, 0);
    border-radius : 8px 8px 0 0 ;
    padding : 0 18px;
    z-index : 16;

    @media screen and (max-width: 800px) {
      width : 100%;
      margin : 0;
      height : ${(props) => 
        props.app ? '60px' : '70px' };
    }
  `

    
const withParams = (Child) => {
  return (props) => {
      const params = useParams();
      const navigate = useNavigate();
      return <Child {...props} params={params} naviGate={navigate} />;
  };
};

class PartnerAppOpen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appCheck : window.navigator.userAgent.indexOf("SemosAndroid") > -1 || window.navigator.userAgent.indexOf("SemosIosWebviewApp") > -1,
            mobileCheck : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
            IOSCheck : /iPhone|iPad|iPod/i.test(navigator.userAgent),
        }
    }

    componentDidMount() { 

        if(this.state.appCheck) {
            window.location.replace('https://semos.kr')
        } else {

            var history_back_uri = window.document.referrer;
            var userDevice = "";
            var device = "win16|win32|win64|mac|macintel";
      
            if (history_back_uri) {
              if (history_back_uri.indexOf("naver") !== -1) {
                var site_type = "naver";
              } else if (history_back_uri.indexOf("instagram") !== -1) {
                var site_type = "instagram";
              } else if (history_back_uri.indexOf("facebook") !== -1) {
                var site_type = "facebook";
              } else if (history_back_uri.indexOf("semos") !== -1) {
                var site_type = "semos";
              } else if (history_back_uri.indexOf("google") !== -1) {
                var site_type = "google";
              } else {
                var site_type = "another";
              }
            } else {
              var site_type = "typing";
            }
      
            if (window.navigator.platform) {
              if (device.indexOf(window.navigator.platform.toLowerCase()) < 0) {
                if( this.state.appCheck ) {
                  userDevice = "APP";
                } else {
                  userDevice = "MOBILE";
                }
              } else {
                userDevice = "PC";
              }
            }

            const frm = new FormData()
            frm.append( 'mode', 'app_down_page' )
            frm.append( 'type', this.props.params.key1 )
            frm.append("device", userDevice);
            frm.append("site_type", site_type);
            frm.append("site", history_back_uri);
            frm.append("platform", "partner");

            axios.post("https://ai.semos.kr/semos_user/profile/user_profile", frm, {
                headers: {'Content-Type': 'multipart/form-data'}
            }).then( data => {

                if(!this.state.mobileCheck) {
                  
                    /*window.close();
                    window.self.close();
                    window.opener = window.location.href; window.self.close();
                    window.open('about:blank', '_self').close();*/
                    window.location.replace('https://apps.apple.com/kr/app/%EC%84%B8%EB%AA%A8%EC%8A%A4-%ED%8C%8C%ED%8A%B8%EB%84%88/id1610055663')
                    window.open('https://apps.apple.com/kr/app/%EC%84%B8%EB%AA%A8%EC%8A%A4-%ED%8C%8C%ED%8A%B8%EB%84%88/id1610055663', '_blank')
                } else {

                    if(this.state.IOSCheck) {
                      
                        /*window.close();
                        window.self.close();
                        window.opener = window.location.href; window.self.close();
                        window.open('about:blank', '_self').close();*/
                        window.location.replace('https://apps.apple.com/kr/app/%EC%84%B8%EB%AA%A8%EC%8A%A4-%ED%8C%8C%ED%8A%B8%EB%84%88/id1610055663')
                        window.open('https://apps.apple.com/kr/app/%EC%84%B8%EB%AA%A8%EC%8A%A4-%ED%8C%8C%ED%8A%B8%EB%84%88/id1610055663', '_blank')
                    } else {
            
                        /*window.close();
                        window.self.close();
                        window.opener = window.location.href; window.self.close();
                        window.open('about:blank', '_self').close();*/
                        window.location.replace('https://play.google.com/store/apps/details?id=kr.semos.partners')
                        window.open('https://play.google.com/store/apps/details?id=kr.semos.partners', '_blank')
                    }

                }

            })

        }
    }


    render() {

        return (
            <>
            </>
        )
    }

}


export default withParams(PartnerAppOpen);
