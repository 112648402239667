const colors = {
  // Primary color
  Bl08: "#003C99",
  Bl07: "#0E6DFF",
  Bl06: "#3383FF",
  Bl05: "#66A2FF",
  Bl04: "#80B2FF",
  Bl03: "#CCE0FF",
  Bl02: "#E5F0FF",
  Bl01: "#F2F7FF",

  // Greyscale color
  Black: "#000000",
  Gr06: "#1A1C1F",
  Gr05: "#3C3E41",
  Gr04: "#818792",
  Gr03: "#ACAFB5",
  Gr02: "#DCDCDC",
  Gr01: "#F9F9F9",
  White: "#ffffff",

  // white Transparent color
  W90: "rgba(255, 255, 255, 0.9)",
  W80: "rgba(255, 255, 255, 0.8)",
  W70: "rgba(255, 255, 255, 0.7)",
  W60: "rgba(255, 255, 255, 0.6)",
  W50: "rgba(255, 255, 255, 0.5)",
  W40: "rgba(255, 255, 255, 0.4)",
  W30: "rgba(255, 255, 255, 0.3)",
  W20: "rgba(255, 255, 255, 0.2)",
  W10: "rgba(255, 255, 255, 0.1)",

  // Black Transparent color
  B90: "rgba(0, 0, 0, 0.9)",
  B80: "rgba(0, 0, 0, 0.8)",
  B70: "rgba(0, 0, 0, 0.7)",
  B60: "rgba(0, 0, 0, 0.6)",
  B50: "rgba(0, 0, 0, 0.5)",
  B40: "rgba(0, 0, 0, 0.4)",
  B30: "rgba(0, 0, 0, 0.3)",
  B20: "rgba(0, 0, 0, 0.2)",
  B10: "rgba(0, 0, 0, 0.1)",

  //functional color
  Red03: "#FF6052",
  Red02: "#FFB0A8",
  Red01: "#FFE7E5",
  Green03: "#96F24E",
  Green02: "#C9EEAC",
  Green01: "#E4F7D5",
  Yellow03: "#FFD966",
  Yellow02: "#FFE8A3",
  Yellow01: "#FFF4D1",

  //Brand color
  Kakao: "#FEE500",
  Naver: "#03C75A",
  Apple_Black: "#000000",
  Apple_White: "#FFFFFF",
  black: "#000000",
  white: "#ffffff",

  //Gradient color
  Gradient: `radial-gradient(100% 843.76% at 0% 52.38%, #FF6759 0%, #8D4EF2 44.79%, #0E6DFF 100%)`,

  //old
  lightBlue: "rgba(233,242,254,1)",
  lightGrey: "rgba(132, 140, 148, .3)",
  grey: "rgba(132, 140, 148, 1)",

  blue: "#0E6DFF",
  red: "#FF6052",
  green: "#86D946",
  yellow: "#FFD966",
  gradient: `radial-gradient(100% 843.76% at 0% 52.38%, #FF6759 0%, #8D4EF2 44.79%, #0E6DFF 100%)`,
};

export default colors;
