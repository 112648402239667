import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Icon,
  Wrapper,
  Text,
  WrapperColumn,
  GmarketText,
  AbsoluteWrapper,
  WrapperRow,
} from "./layout/layoutStorage";

import ModalBox from "./modalBox";

import Exclam from "../icon/exclam.png";

const ProfileReviseConfirm = ({ status, setStatus, setModalStatus }) => {
  const goBack = () => {
    setModalStatus("profile");
    setStatus(false);
  };

  return (
    <ModalBox status={status} setStatus={setStatus} confirm={true}>
      <Wrapper padding="24px" bgc="#ffffff" radius="16px" width="375px">
        <WrapperColumn width="100%">
          <Text size="20px" weight="700" color="#1a1c1f">
            변경사항이 저장되지 않았어요.
          </Text>

          <Wrapper height="8px" />

          <Text size="14px" weight="500" color="#818792">
            뒤로 가면 변경사항이 저장되지 않아요.
          </Text>

          <Text size="14px" weight="500" color="#818792">
            다시 한 번 살펴봐주실래요?
          </Text>

          <Wrapper height="36px" />

          <Wrapper jc="center">
            <Icon src={Exclam} width="88px" height="88px" />
          </Wrapper>

          <Wrapper height="44px" />

          <Wrapper jc="space-between">
            <Wrapper
              padding="14.5px"
              jc="center"
              bgc="#E5F0FF"
              width="157px"
              radius="8px"
              cursor="pointer"
              onClick={() => goBack()}
            >
              <Text size="16px" weight="600" color="#0e6dff">
                뒤로가기
              </Text>
            </Wrapper>

            <Wrapper
              padding="14.5px"
              jc="center"
              bgc="#0e6dff"
              width="157px"
              radius="8px"
              cursor="pointer"
              onClick={() => setStatus(false)}
            >
              <Text size="16px" weight="600" color="#ffffff">
                다시 작성하기
              </Text>
            </Wrapper>
          </Wrapper>
        </WrapperColumn>
      </Wrapper>
    </ModalBox>
  );
};

export default ProfileReviseConfirm;
