import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import Header from "../component/layout/header";
import Layout from "../component/layout/layout";
import {
  Text,
  Wrapper,
  Icon,
  WrapperRow,
  Input,
  AbsoluteWrapper,
  WrapperColumn,
} from "../component/layout/layoutStorage";
// import Item from "../component/listingItem";
import SportFilter from "../component/activityListing/sportFilter";
import WhenFilter from "../component/activityListing/whenFilter";
import WhereFilter from "../component/activityListing/whereFilter";
import TypeFilter from "../component/activityListing/typeFilter";
import DurationFilter from "../component/activityListing/durationFilter";
import PriceFilter from "../component/activityListing/priceFilter";
import FacilityFilter from "../component/activityListing/facilityFilter";
import { LoadingSpinner } from "../tool/loading";
import NoListUI from "../component/noListUI";
import AppModal from "../component/home/appModal";
import MataTagChange from "../tool/MetaTag";

//img
import BlueArrow from "../icon/blueArrow.png";
import colors from "../styles/colors";
import Item from "../component/item";
import { SkeletonElement } from "../component/skeletonElement";

const TypePopupData = {
  lesson: "레슨",
  license: "자격증",
  ticket: "이용권",
  tour: "투어",
};

const DurationPopupData = {
  oneDay: "원데이",
  moreDay: "다회차",
};

const PricePopupData = {
  tenLower: "10만원 이하",
  betweenTenFifty: "10 ~ 50만원",
  overFifty: "50만원 초과",
};

const SortSelector = {
  jjim_all: "찜 많은 순",
  high_all: "예약가 높은 순",
  low_all: "예약가 낮은 순",
  review_all: "리뷰 별점 순",
  count_all: "리뷰 많은 순",
};

const SortList = [
  {
    text: "리뷰 별점 순",
    value: "review_all",
  },
  {
    text: "리뷰 많은 순",
    value: "count_all",
  },
  {
    text: "예약가 높은 순",
    value: "high_all",
  },
  {
    text: "예약가 낮은 순",
    value: "low_all",
  },
  {
    text: "찜 많은 순",
    value: "jjim_all",
  },
];

const ActivityListing = () => {
  const ref = useRef();
  const navigate = useNavigate();

  const [App, setApp] = useState(false);

  const { state } = useLocation();

  const [page, setPage] = useState(1);

  const [list, setList] = useState([]);

  const [count, setCount] = useState({
    max: 1,
    current: 0,
  });

  const [loading, setLoading] = useState(false);

  const Mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

  useEffect(() => {
    const handleScroll = () => {
      var Height = document.getElementById("infinite").clientHeight;
      if (window.scrollY + window.innerHeight === Height) {
        setPage((n) => n + 1);
      }
    };
    if (!loading) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  // useEffect(() => {
  //   if (loading) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  // }, [loading]);

  const [filter, setFilter] = useState({
    sort: state?.sort ? state.sort : "review_all",
    sport: state?.sport ? state.sport : "스포츠",
    when: state?.when ? state.when : "언제",
    where: state?.where ? state.where : "어디서",
    type: state?.type ? state.type : "액티비티",
    duration: state?.duration ? state.duration : "기간",
    price: state?.price ? state.price : "가격",
    facility: state?.facility ? state.facility : "시설",
    search: state?.search ? state.search : "",
    semos: state?.semos ? true : false,
    near: state?.near ? true : false,
    full: state?.full ? true : false,
  });
  const [open, setOpen] = useState(false);

  const [locationData, setLocationData] = useState({
    location_name: [],
    location_value: [],
    location_text: [],
  });

  const TypeValueTextF = (value) => {
    var TypeValueList = value ? value : filter.type;
    var TypeValueText = "액티비티";

    if (TypeValueList !== "액티비티") {
      var TypeValueListSplit = TypeValueList.split(", ");
      var TypeValueListLength = TypeValueListSplit.length;

      if (TypeValueListLength > 1) {
        for (var i = 0; i < TypeValueListLength; i++) {
          if (i === 0) {
            TypeValueText = `${TypePopupData[TypeValueListSplit[i]]}`;
          } else {
            TypeValueText = `${TypeValueText}, ${
              TypePopupData[TypeValueListSplit[i]]
            }`;
          }
        }
      } else {
        TypeValueText = TypePopupData[TypeValueList];
      }
    }

    return TypeValueText;
  };

  const DurationValueTextF = (value) => {
    var TypeValueList = value ? value : filter.duration;
    var TypeValueText = "기간";

    if (TypeValueList !== "기간") {
      var TypeValueListSplit = TypeValueList.split(", ");
      var TypeValueListLength = TypeValueListSplit.length;

      if (TypeValueListLength > 1) {
        for (var i = 0; i < TypeValueListLength; i++) {
          if (i === 0) {
            TypeValueText = `${DurationPopupData[TypeValueListSplit[0]]}`;
          } else {
            TypeValueText = `${TypeValueText}, ${
              DurationPopupData[TypeValueListSplit[i]]
            }`;
          }
        }
      } else {
        TypeValueText = DurationPopupData[TypeValueList];
      }
    }

    return TypeValueText;
  };

  const PriceValueTextF = (value) => {
    var TypeValueList = value ? value : filter.price;
    var TypeValueText = "가격";

    if (TypeValueList !== "가격") {
      var TypeValueListSplit = TypeValueList.split(", ");
      var TypeValueListLength = TypeValueListSplit.length;

      if (TypeValueListLength > 1) {
        for (var i = 0; i < TypeValueListLength; i++) {
          if (i === 0) {
            TypeValueText = `${PricePopupData[TypeValueListSplit[0]]}`;
          } else {
            TypeValueText = `${TypeValueText}, ${
              PricePopupData[TypeValueListSplit[i]]
            }`;
          }
        }
      } else {
        TypeValueText = PricePopupData[TypeValueList];
      }
    }

    return TypeValueText;
  };

  const getData = () => {
    var token = localStorage.getItem("token");
    if (count.max > count.current) {
      setLoading(true);

      const frm = new FormData();

      frm.append("mode", "list");
      if (token) frm.append("token", token);
      frm.append("sport", filter.sport);
      frm.append("when", filter.when);
      frm.append("where", filter.where);
      frm.append("type", filter.type != "액티비티" ? TypeValueTextF() : "유형");
      frm.append("duration", DurationValueTextF());
      frm.append("price", filter.price);
      frm.append("package", filter.full);
      frm.append("semosOnly", filter.semos);
      frm.append("myLocation", filter.near);
      filter.near && frm.append("MLS", sessionStorage.getItem("lo_si"));
      filter.near && frm.append("MLG", sessionStorage.getItem("lo_gu"));
      filter.near && frm.append("MLD", sessionStorage.getItem("lo_dong"));
      filter.near && frm.append("MLST", sessionStorage.getItem("lo_si_text"));
      filter.near && frm.append("MLGT", sessionStorage.getItem("lo_gu_text"));
      filter.near && frm.append("MLDT", sessionStorage.getItem("lo_dong_text"));
      frm.append("location", filter.facility);
      frm.append("filter", filter.sort);
      frm.append("page", page);
      frm.append("text", filter.search);
      frm.append("platform", "WEB");

      axios
        .post("https://ai.semos.kr/semos_user/v3/activity/list", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var Value = data.data;

          setList(Value.activity_list);

          setCount({
            max: Value.max,
            current: Value.current,
          });

          setLocationData({
            location_name: Value.location_name_list,
            location_value: Value.location_list,
            location_text: Value.location_text_list,
          });

          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [filter, state, page]);

  useEffect(() => {
    if(Mobile) {
      setApp(true)
    }

    MataTagChange({
      title : `세모스 : 세상의 모든 스포츠 - 액티비티 검색 리스트`,
      description : ``,
      imageUrl : ``
    });
  }, []);

  const handleClose = (e) => {
    if (open && (!ref.current || !ref.current.contains(e.target))) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [open]);

  return (
    <>
      <AppModal open={App} setOpen={setApp} Mobile={Mobile} />

      <Layout>
        <WrapperColumn width="100%">
          <Wrapper
            width="100%"
            padding="4px 0 8px 0"
            bb="1px solid #f5f5f5"
            margin="0 0 12px 0"
            wrap="wrap"
          >
            {filter.semos ? (
              // <SemosOnly onClick={() => setSemosOnly(false)}>
              <SemosOnly
                onClick={() => {
                  navigate("/activity_list", {
                    state: {
                      sport: filter.sport ? filter.sport : "",
                      where: filter.where ? filter.where : "",
                      when: filter.when ? filter.when : "",
                      type: filter.type
                        ? filter.type == "유형"
                          ? "액티비티"
                          : filter.type
                        : "",
                      duration: filter.duration ? filter.duration : "",
                      price: filter.price ? filter.price : "",
                      facility: filter.facility ? filter.facility : "",
                      search: filter.search ? filter.search : "",
                      semos: false,
                      near: filter.near,
                      full: filter.full,
                    },
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 2);
                }}
              >
                <RainbowText>세모스ONLY</RainbowText>
              </SemosOnly>
            ) : (
              <Wrapper
                padding="4px 11px"
                border="1px solid #dcdcdc"
                onClick={() => {
                  navigate("/activity_list", {
                    state: {
                      sport: filter.sport ? filter.sport : "",
                      where: filter.where ? filter.where : "",
                      when: filter.when ? filter.when : "",
                      type: filter.type
                        ? filter.type == "유형"
                          ? "액티비티"
                          : filter.type
                        : "",
                      duration: filter.duration ? filter.duration : "",
                      price: filter.price ? filter.price : "",
                      facility: filter.facility ? filter.facility : "",
                      search: filter.search ? filter.search : "",
                      semos: true,
                      near: filter.near,
                      full: filter.full,
                    },
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 2);
                }}
                cursor="pointer"
                radius="10px"
                margin="0 8px 8px 0"
              >
                <Text size="13px" weight="500" color="#818792">
                  세모스ONLY
                </Text>
              </Wrapper>
            )}

            <Wrapper
              id="nearFilter"
              padding="5px 11px"
              border={filter.near ? "1px solid #3383FF" : "1px solid #dcdcdc"}
              bgc={filter.near ? "#F2F7FF" : "transparent"}
              cursor="pointer"
              radius="10px"
              margin="0 8px 8px 0"
              onClick={() => {
                navigate("/activity_list", {
                  state: {
                    sport: filter.sport ? filter.sport : "",
                    where: filter.where ? filter.where : "",
                    when: filter.when ? filter.when : "",
                    type: filter.type
                      ? filter.type == "유형"
                        ? "액티비티"
                        : filter.type
                      : "",
                    duration: filter.duration ? filter.duration : "",
                    price: filter.price ? filter.price : "",
                    facility: filter.facility ? filter.facility : "",
                    search: filter.search ? filter.search : "",
                    semos: filter.semos,
                    near: !filter.near,
                    full: filter.full,
                  },
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2);
              }}
            >
              <Text
                size="13px"
                weight="500"
                color={filter.near ? "#3383FF" : "#818792"}
              >
                내 주변
              </Text>
            </Wrapper>

            <Wrapper
              padding="5px 11px"
              border={filter.full ? "1px solid #3383FF" : "1px solid #dcdcdc"}
              bgc={filter.full ? "#F2F7FF" : "transparent"}
              cursor="pointer"
              radius="10px"
              margin="0 8px 8px 0"
              onClick={() => {
                navigate("/activity_list", {
                  state: {
                    sport: filter.sport ? filter.sport : "",
                    where: filter.where ? filter.where : "",
                    when: filter.when ? filter.when : "",
                    type: filter.type
                      ? filter.type == "유형"
                        ? "액티비티"
                        : filter.type
                      : "",
                    duration: filter.duration ? filter.duration : "",
                    price: filter.price ? filter.price : "",
                    facility: filter.facility ? filter.facility : "",
                    search: filter.search ? filter.search : "",
                    semos: filter.semos,
                    near: filter.near,
                    full: !filter.full,
                  },
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2);
              }}
            >
              <Text
                size="13px"
                weight="500"
                color={filter.full ? "#3383FF" : "#818792"}
              >
                풀패키지
              </Text>
            </Wrapper>

            <SportFilter
              data={filter.sport}
              setData={setFilter}
              filter={filter}
            />

            <WhenFilter data={filter.when} setData={setFilter} filter={filter} />

            <WhereFilter
              data={filter.where}
              setData={setFilter}
              name={locationData.location_name}
              value={locationData.location_value}
              filter={filter}
            />

            <TypeFilter
              data={filter.type}
              text={TypeValueTextF()}
              setData={setFilter}
              filter={filter}
            />

            <DurationFilter
              data={filter.duration}
              text={DurationValueTextF()}
              setData={setFilter}
              filter={filter}
            />

            <PriceFilter
              data={filter.price}
              text={PriceValueTextF()}
              setData={setFilter}
              filter={filter}
            />

            <FacilityFilter
              data={filter.facility}
              setData={setFilter}
              filter={filter}
            />
          </Wrapper>

          <Wrapper
            style={{ alignSelf: "end" }}
            margin="0 0 28px 0"
            ai="flex-start"
            cursor="pointer"
            onClick={() => setOpen((prev) => !prev)}
            height="20px"
            ref={ref}
          >
            <Text size="13px" weight="500" color="#0e6dff">
              {SortSelector[filter.sort]}
            </Text>
            <Icon
              src={BlueArrow}
              width="16px"
              height="16px"
              style={{ marginLeft: "4px" }}
              transform={open ? "rotate(-180deg)" : "rotate(0deg)"}
              transition="all 0.3s ease"
            />

            {open ? (
              <AbsoluteWrapper
                top="30px"
                right="0"
                width="102px"
                bgc="#ffffff"
                radius="8px"
                border="1px solid #dcdcdc"
                style={{ zIndex: 2 }}
                ai="flex-start"
              >
                <WrapperColumn width="100%">
                  {SortList.map((item, index) => (
                    <Wrapper
                      key={index}
                      width="100%"
                      padding="10px"
                      bb={index + 1 != SortList.length ? "1px solid #dcdcdc" : ""}
                      cursor="pointer"
                      onClick={() => {
                        navigate("/activity_list", {
                          state: {
                            sport: filter.sport ? filter.sport : "",
                            where: filter.where ? filter.where : "",
                            when: filter.when ? filter.when : "",
                            type: filter.type
                              ? filter.type == "유형"
                                ? "액티비티"
                                : filter.type
                              : "",
                            duration: filter.duration ? filter.duration : "",
                            price: filter.price ? filter.price : "",
                            facility: filter.facility ? filter.facility : "",
                            search: filter.search ? filter.search : "",
                            semos: filter.semos,
                            near: filter.near,
                            full: filter.full,
                            sort: item.value,
                          },
                        });
                        setTimeout(() => {
                          window.location.reload();
                        }, 2);
                        //   setFilter((prev) => ({ ...prev, sort: item.value }));
                      }}
                    >
                      <Text size="12px" weight="500" color="#818792">
                        {item.text}
                      </Text>
                    </Wrapper>
                  ))}
                </WrapperColumn>
              </AbsoluteWrapper>
            ) : (
              ""
            )}
          </Wrapper>
          {list.length ? (
            <Grid>
              {list.map((el, i) => (
                <Item length={list?.length} item={el} index={i} page="list" />
              ))}
            </Grid>
          ) : !loading ? (
            <NoListUI
              big="검색된 액티비티가 없어요"
              small="세모스가 얼른 채우도록 노력할게요"
              margin="102px"
            />
          ) : (
            ""
          )}
        </WrapperColumn>
        {/*  <NoListUI big="로딩중입니다" small="잠시만 기다려주세요" margin="102px" />*/}

        {loading ? (
          <Grid>
            {Array(12).fill(
              <WrapperColumn>
                <SkeletonElement width="200px" height="125px" />
                <SkeletonElement
                  width="111px"
                  height="13px"
                  margin="10px 0 6px 0"
                />
                <SkeletonElement width="200px" height="36px" />
                <SkeletonElement
                  width="200px"
                  height="18px"
                  margin="6px 0 8px 0"
                />
                <SkeletonElement width="103px" height="21px" />
                <SkeletonElement width="200px" height="38px" margin="8px 0 0 0" />
              </WrapperColumn>
            )}
          </Grid>
        ) : (
          ""
        )}
        {/* <Grid>
          {Array(12).fill(
            <WrapperColumn>
              <SkeletonElement width="200px" height="125px" />
              <SkeletonElement width="111px" height="13px" margin="10px 0 6px 0" />
              <SkeletonElement width="200px" height="36px" />
              <SkeletonElement width="200px" height="18px" margin="6px 0 8px 0" />
              <SkeletonElement width="103px" height="21px" />
              <SkeletonElement width="200px" height="38px" margin="8px 0 0 0" />
            </WrapperColumn>
          )}
        </Grid> */}
      </Layout>
    </>
  );
};

export default ActivityListing;

const SemosOnly = styled.div`
  border: 1px solid transparent;
  padding: 0px;
  border-radius: 10px;
  background-image: linear-gradient(#fff, #ffffff),
    linear-gradient(to right, #ff6759 0%, #9262df 56.77%, #0e6dff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
`;

const RainbowText = styled.div`
  border: 11px solid white;
  border-bottom: 5px solid white;
  border-top: 5px solid white;
  border-radius: 10px;
  background: radial-gradient(
    100% 843.76% at 0% 52.38%,
    #ff6759 0%,
    #9262df 56.77%,
    #0e6dff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 13px;
  font-weight: 500;
`;

const Blackout = styled.div`
  /* height: 100%; */
  background-color: #0e6dff;
  opacity: 0.1;
`;

const Grid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 32px 20px;
  margin-bottom: 32px;
`;
