import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Footer from "./footer";
import Header from "./header";

const Layout = ({ children, page }) => {
  const outerContainerRef = useRef(null);
  const [right, setRight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const outerContainer = outerContainerRef.current;
      if (outerContainer) {
        const rect = outerContainer.getBoundingClientRect();
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        setRight(rect.right - 375 + 10);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // 초기 로드 시에도 한 번 호출하여 값 초기화

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
      <LayoutContainer>
        <Container ref={outerContainerRef} id="infinite">
          <TopBar></TopBar>

          <div style={{ position: "relative" }}>{children}</div>

          <Footer />
          <Header right={right} page={page} />
        </Container>

        {/* <AbsoluteWrapper z={open ? 2 : 22} bottom="480px" left="50%" style={{ transform: "translate(-50%,-50%)" }} width="100%" height="2px" bgc="#f5f5f5" /> */}
      </LayoutContainer>
  );
};

export default Layout;

const LayoutContainer = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* overflow-y: scroll; */
`;

const Container = styled.div`
  position: relative;
  width: 860px;
  margin: 0 auto;

  @media screen and (max-width: 859px) {
    width : 100vw;
  }
`;
const TopBar = styled.div`
  width: 860px;
  height: 76px;

  @media screen and (max-width: 859px) {
    width : 100vw;
  }
`;
