import React from "react";
import styled from "styled-components";

function DivideLine() {
  return <Line />;
}

export default DivideLine;

const Line = styled.div`
  width: 100%;
  height: 0;
  border: 1px solid rgba(132, 140, 148, 0.5);
  opacity: 30%;
  margin: 20px 0;
`;
