import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  Text,
  Wrapper,
  Icon,
  WrapperRow,
  Input,
  AbsoluteWrapper,
  WrapperColumn,
  Radio,
  PaddingText,
} from "../../component/layout/layoutStorage";

import DatePicker from "../datePicker";

import { ReactComponent as FilterArrow } from "../../icon/filterArrow.svg";
import { ReactComponent as Xbutton } from "../../icon/modalx.svg";
import { ReactComponent as Reset } from "../../icon/modalreset.svg";
import arrow from "../../icon/arrow.svg";
import xDay from "../../icon/xDay.png";

const WhenFilter = ({ data, setData, filter }) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [show, setShow] = useState({
    year: data.includes("언제") ? new Date().getFullYear() : data.split(".")[0],
    month: data.includes("언제")
      ? new Date().getMonth() + 1 < 10
        ? `0${new Date().getMonth() + 1}`
        : new Date().getMonth() + 1
      : data.split(".")[1],
    date: data.includes("언제") ? new Date().getDate() : data.split(".")[2],
  });

  const [date, setDate] = useState(
    data.includes("언제") ? 0 : data.split(".")[2]
  );

  const [holiday, setHoliday] = useState("");

  const HolidayCheck = () => {
    const frm = new FormData();
    frm.append("mode", "holiday_year");
    frm.append("year", show.year);

    axios
      .post("https://ai.semos.kr/semos_user/tool/holiday_check_new", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var HolidayList = Value.holiday;
        var newList = [];

        if (Status === "success") {
          // setHoliday(HolidayList);
          for (let i = 0; i < HolidayList.length; i++) {
            var convert = "";
            var arr = HolidayList[i].split("");
            for (let j = 4; j < arr.length; j++) {
              convert = convert + arr[j];
            }

            newList.push(convert);
          }
          setHoliday(newList);
        }
      });
  };

  useEffect(() => {
    HolidayCheck();
  }, []);

  const handleClose = (e) => {
    if (open && (!ref.current || !ref.current.contains(e.target))) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [open]);

  const CategoryReset = () => {
    setDate("");
  };

  const setting = () => {
    var dateText = `${show.year}.${show.month}.${
      date < 10 ? `0${date}` : date
    }`;

    if (date) {
      navigate("/activity_list", {
        state: {
          sort: filter.sort,
          sport: filter.sport ? filter.sport : "",
          where: filter.where ? filter.where : "",
          when: dateText,

          type: filter.type
            ? filter.type == "유형"
              ? "액티비티"
              : filter.type
            : "",
          duration: filter.duration ? filter.duration : "",
          price: filter.price ? filter.price : "",
          facility: filter.facility ? filter.facility : "",
          search: filter.search ? filter.search : "",
          semos: filter.semos,
          near: filter.near,
          full: filter.full,
        },
      });

      setTimeout(() => {
        window.location.reload();
      }, 2);
    } else {
      navigate("/activity_list", {
        state: {
          sort: filter.sort,
          sport: filter.sport ? filter.sport : "",
          where: filter.where ? filter.where : "",
          when: "언제",
          type: filter.type
            ? filter.type == "유형"
              ? "액티비티"
              : filter.type
            : "",
          duration: filter.duration ? filter.duration : "",
          price: filter.price ? filter.price : "",
          facility: filter.facility ? filter.facility : "",
          search: filter.search ? filter.search : "",
          semos: filter.semos,
          near: filter.near,
          full: filter.full,
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 2);
    }

    setOpen(false);
  };

  const DateCheck = () => {
    const DateWidth = 43;
    var Calendar = [];

    let Year = show.year;
    let Month = Number(show.month);

    let thisMonth =
      new Date().getMonth() + 1 != 13 ? new Date().getMonth() + 1 : 12;
    let thisDay = new Date().getDate();
    let thisYear = new Date().getFullYear();

    let MonthText = Month < 10 ? `0${Month}` : Month;

    var LastDate = new Date(Year, Month, 0);
    let LastDay = LastDate.getDate();
    let LastYoil = 6 - LastDate.getDay();

    var FisrtDate = new Date(Year, Month - 1, 1);
    let FisrtYoil = FisrtDate.getDay();

    var BeforeDate = new Date(Year, Month - 1, 0);
    let BeforeDay = BeforeDate.getDate();

    for (let i = 0; i < LastDay; i++) {
      let ThisDay = i + 1;
      let Today =
        ThisDay == thisDay && MonthText == thisMonth && show.year == thisYear;
      let ThisDate = new Date(Year, Month - 1, ThisDay);
      let ThisYoil = ThisDate.getDay();
      let DateColor = `#818792`;
      var holidaychecker = `${MonthText}${
        ThisDay < 10 ? `0${ThisDay}` : ThisDay
      }`;

      if (ThisYoil == 0 || holiday.includes(holidaychecker)) {
        DateColor = `#FF9187`;
      } else if (ThisYoil == 6) {
        DateColor = `#0E6DFF`;
      }

      var XDATE =
        Number(ThisDay) < thisDay &&
        Number(Month) == thisMonth &&
        Year == thisYear;

      if (ThisDay == 1) {
        for (let fi = FisrtYoil - 1; fi > -1; fi--) {
          Calendar.push(
            <Wrapper
              position="relative"
              key={"date_" + i + "_" + fi}
              bgc={`#ffffff`}
              radius={`50%`}
              jc={`center`}
              ai="center"
              width={`${DateWidth}px`}
              height={`${DateWidth}px`}
            >
              <AbsoluteWrapper
                width={`${DateWidth - 4}px`}
                height={`${DateWidth - 4}px`}
                bgc="#f5f5f5"
              >
                <Wrapper width="100%" height="100%">
                  <svg style={{ width: "100%", height: "100%" }}>
                    <line
                      x1="0"
                      y1="0"
                      x2={`${DateWidth - 4}`}
                      y2={`${DateWidth - 4}`}
                      stroke="#dcdcdc"
                    />
                    <line
                      x1="0"
                      y1={`${DateWidth - 4}`}
                      x2={`${DateWidth - 4}`}
                      y2="0"
                      stroke="#dcdcdc"
                    />
                  </svg>
                </Wrapper>
              </AbsoluteWrapper>
              <PaddingText
                color={`#DCDCDC`}
                className="f12"
                style={{ zIndex: "2" }}
              >
                {BeforeDay - fi}
              </PaddingText>
            </Wrapper>
          );
        }

        Calendar.push(
          <Wrapper
            position="relative"
            onClick={() => {
              if (
                Number(ThisDay) < thisDay &&
                Number(Month) == thisMonth &&
                Year == thisYear
              ) {
                return 0;
              } else {
                setDate(ThisDay);
              }
            }}
            key={"date_" + i}
            cursor={`pointer`}
            bgc={`${ThisDay == date ? `#0E6DFF` : `#ffffff`}`}
            radius={`50%`}
            jc={`center`}
            ai="center"
            width={`${DateWidth}px`}
            height={`${DateWidth}px`}
          >
            {XDATE ? (
              <AbsoluteWrapper
                width={`${DateWidth - 4}px`}
                height={`${DateWidth - 4}px`}
                bgc="#f5f5f5"
              >
                <Wrapper width="100%" height="100%">
                  <svg style={{ width: "100%", height: "100%" }}>
                    <line
                      x1="0"
                      y1="0"
                      x2={`${DateWidth - 4}`}
                      y2={`${DateWidth - 4}`}
                      stroke="#dcdcdc"
                    />
                    <line
                      x1="0"
                      y1={`${DateWidth - 4}`}
                      x2={`${DateWidth - 4}`}
                      y2="0"
                      stroke="#dcdcdc"
                    />
                  </svg>
                </Wrapper>
              </AbsoluteWrapper>
            ) : (
              ""
            )}
            <PaddingText
              color={
                XDATE ? "#dcdcdc" : ThisDay == date ? `#ffffff` : DateColor
              }
              className="f12"
              style={{ zIndex: "2" }}
            >
              {ThisDay}
            </PaddingText>
            {Today && (
              <AbsoluteWrapper
                width="40px"
                height="40px"
                border="1px dashed #0E6DFF"
                radius="50%"
              ></AbsoluteWrapper>
            )}
          </Wrapper>
        );
      } else if (ThisDay == LastDay) {
        Calendar.push(
          <Wrapper
            position="relative"
            key={"date_" + i}
            onClick={() => {
              if (
                Number(ThisDay) < thisDay &&
                Number(Month) == thisMonth &&
                Year == thisYear
              ) {
                return 0;
              } else {
                setDate(ThisDay);
              }
            }}
            cursor={`pointer`}
            // bgc={`${Today ? `#0E6DFF` : `#ffffff`}`}
            bgc={`${ThisDay == date ? `#0E6DFF` : `#ffffff`}`}
            radius={`50%`}
            jc={`center`}
            ai="center"
            width={`${DateWidth}px`}
            height={`${DateWidth}px`}
          >
            <PaddingText
              color={ThisDay == date ? `#ffffff` : DateColor}
              className="f12"
            >
              {ThisDay}
            </PaddingText>
            {Today && (
              <AbsoluteWrapper
                width="40px"
                height="40px"
                border="1px dashed #0E6DFF"
                radius="50%"
              ></AbsoluteWrapper>
            )}
          </Wrapper>
        );
        for (var li = 0; LastYoil > li; li++) {
          Calendar.push(
            <Wrapper
              position="relative"
              key={"date_" + i + "_" + li}
              bgc={`#ffffff`}
              radius={`50%`}
              jc={`center`}
              width={`${DateWidth}px`}
              height={`${DateWidth}px`}
            >
              <PaddingText color={`#DCDCDC`} className="f12">
                {li + 1}
              </PaddingText>
            </Wrapper>
          );
        }
      } else {
        Calendar.push(
          <Wrapper
            position="relative"
            key={"date_" + i}
            onClick={() => {
              if (
                Number(ThisDay) < thisDay &&
                Number(Month) == thisMonth &&
                Year == thisYear
              ) {
                return 0;
              } else {
                setDate(ThisDay);
              }
            }}
            cursor={`pointer`}
            bgc={`${ThisDay == date ? `#0E6DFF` : `#ffffff`}`}
            radius={`50%`}
            jc={`center`}
            ai="center"
            width={`${DateWidth}px`}
            height={`${DateWidth}px`}
          >
            {XDATE ? (
              <AbsoluteWrapper
                width={`${DateWidth - 4}px`}
                height={`${DateWidth - 4}px`}
                bgc="#f5f5f5"
              >
                <Wrapper width="100%" height="100%">
                  <svg style={{ width: "100%", height: "100%" }}>
                    <line
                      x1="0"
                      y1="0"
                      x2={`${DateWidth - 4}`}
                      y2={`${DateWidth - 4}`}
                      stroke="#dcdcdc"
                    />
                    <line
                      x1="0"
                      y1={`${DateWidth - 4}`}
                      x2={`${DateWidth - 4}`}
                      y2="0"
                      stroke="#dcdcdc"
                    />
                  </svg>
                </Wrapper>
              </AbsoluteWrapper>
            ) : (
              ""
            )}
            <PaddingText
              color={
                XDATE ? "#dcdcdc" : ThisDay == date ? `#ffffff` : DateColor
              }
              className="f12"
              style={{ zIndex: "2" }}
            >
              {ThisDay}
            </PaddingText>
            {Today ? (
              <AbsoluteWrapper
                width="40px"
                height="40px"
                border="1px dashed #0E6DFF"
                radius="50%"
              />
            ) : (
              ""
            )}
          </Wrapper>
        );
      }
    }

    return Calendar;
  };

  const BeforeMonth = () => {
    var thisYear = new Date().getFullYear();
    var thisMonth = new Date().getMonth() + 1;
    if (thisYear == show.year && thisMonth == show.month) {
    } else {
      if (show.month == "01") {
        setShow({
          year: show.year - 1,
          month: 12,
          date: "01",
        });
      } else {
        setShow({
          year: show.year,
          month:
            Number(show.month) - 1 < 10
              ? `0${Number(show.month) - 1}`
              : Number(show.month) - 1,
          date: "01",
        });
      }
    }
  };

  const AfterMonth = () => {
    if (show.month == 12) {
      setShow({
        year: show.year + 1,
        month: "01",
        date: "01",
      });
    } else {
      setShow({
        year: show.year,
        month:
          Number(show.month) + 1 < 10
            ? `0${Number(show.month) + 1}`
            : Number(show.month) + 1,
        date: "01",
      });
    }
  };

  return (
    <Wrapper margin="0 8px 8px 0" ref={ref}>
      <Wrapper
        padding="5px 11px"
        border={data !== "언제" ? "1px solid #3383FF" : "1px solid #dcdcdc"}
        bgc={data !== "언제" ? "#F2F7FF" : "transparent"}
        cursor="pointer"
        radius="10px"
        onClick={() => setOpen((prev) => !prev)}
      >
        <Text
          size="13px"
          weight="500"
          color={data !== "언제" ? "#3383FF" : "#818792"}
        >
          {data}
        </Text>

        <FilterArrow
          fill={data !== "언제" ? "#3383FF" : "#818792"}
          width="14px"
          height="18px"
          style={{
            marginLeft: "4px",
            transform: open ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "all 0.3s ease",
          }}
        />
      </Wrapper>

      {open ? (
        <AbsoluteWrapper
          padding="24px"
          top="40px"
          width="375px"
          height="676px"
          bgc="#ffffff"
          radius="8px"
          border="1px solid #dcdcdc"
          style={{ zIndex: 2 }}
          ai="flex-start"
        >
          <WrapperColumn width="100%" height="100%" jc="space-between">
            <WrapperColumn width="100%">
              <Wrapper jc="space-between">
                <Wrapper>
                  <Text size="18px" color="#1a1c1f" weight="600">
                    일정 선택
                  </Text>

                  <Wrapper width="12px" />

                  <Wrapper
                    ai="center"
                    padding="2px 0 0 0"
                    cursor="pointer"
                    onClick={() => CategoryReset()}
                  >
                    <Text size="14px" weight="600" color="#0e6dff">
                      선택 초기화
                    </Text>
                    <Wrapper width="2px" />
                    <Reset width="20px" height="20px" />
                  </Wrapper>
                </Wrapper>

                <Xbutton
                  width="28px"
                  height="28px"
                  onClick={() => setOpen(false)}
                  style={{ cursor: "pointer" }}
                />
              </Wrapper>

              <Wrapper height="21px" />

              {/* 캘린더 시작점 */}

              <Wrapper
                display="inline-flex"
                width={`327px`}
                padding={`20px 10px 20px 10px`}
                bgc={`#ffffff`}
                border="1px solid #f5f5f5"
                radius="10px"
              >
                {/* <Wrapper display={status ? `inline-flex` : `none`} width={`100%`} bgc={`#ffffff`}> */}
                <Wrapper wrap={`wrap`} width={`100%`} position={`relative`}>
                  <Wrapper
                    padding={`0 12px`}
                    jc={`space-between`}
                    margin={`0 0 13px 0`}
                    wrap={`wrap`}
                    width={`100%`}
                  >
                    <Icon
                      onClick={() => BeforeMonth()}
                      height={`24px`}
                      cursor={`pointer`}
                      src={arrow}
                      style={{ transform: "rotate(90deg)" }}
                    />

                    <Text
                      size="14px"
                      weight={`500`}
                    >{`${show.year}년 ${show.month}월`}</Text>

                    <Icon
                      onClick={() => AfterMonth()}
                      height={`24px`}
                      transform={`rotate(180deg)`}
                      cursor={`pointer`}
                      src={arrow}
                      style={{ transform: "rotate(-90deg)" }}
                    />
                  </Wrapper>

                  <Wrapper
                    margin={`0 0 13px 0`}
                    jc={`space-between`}
                    width={`100%`}
                  >
                    <Text
                      width={`43px`}
                      //   width={`calc(100% / 7)`}
                      jc={`center`}
                      size="14px"
                      color={`#FF9187`}
                      weight={`500`}
                    >{`일`}</Text>
                    <Text
                      width={`43px`}
                      //   width={`calc(100% / 7)`}
                      jc={`center`}
                      size="14px"
                      color={`#ACAFB5`}
                      weight={`500`}
                    >{`월`}</Text>
                    <Text
                      width={`43px`}
                      //   width={`calc(100% / 7)`}
                      jc={`center`}
                      size="14px"
                      color={`#ACAFB5`}
                      weight={`500`}
                    >{`화`}</Text>
                    <Text
                      width={`43px`}
                      //   width={`calc(100% / 7)`}
                      jc={`center`}
                      size="14px"
                      color={`#ACAFB5`}
                      weight={`500`}
                    >{`수`}</Text>
                    <Text
                      width={`43px`}
                      //   width={`calc(100% / 7)`}
                      jc={`center`}
                      size="14px"
                      color={`#ACAFB5`}
                      weight={`500`}
                    >{`목`}</Text>
                    <Text
                      width={`43px`}
                      //   width={`calc(100% / 7)`}
                      jc={`center`}
                      size="14px"
                      color={`#ACAFB5`}
                      weight={`500`}
                    >{`금`}</Text>
                    <Text
                      width={`43px`}
                      //   width={`calc(100% / 7)`}
                      jc={`center`}
                      size="14px"
                      color={`#0E6DFF`}
                      weight={`500`}
                    >{`토`}</Text>
                  </Wrapper>

                  <Wrapper jc={`space-between`} wrap={`wrap`} width={`100%`}>
                    {show.year && show.month && show.date && DateCheck()}
                  </Wrapper>
                </Wrapper>
              </Wrapper>
            </WrapperColumn>

            <Wrapper
              width="100%"
              bgc="#0e6dff"
              padding="16px 0"
              radius="8px"
              jc="center"
              margin="0 0 18px 0"
              cursor="pointer"
              onClick={() => setting()}
            >
              <Text size="16px" weight="600" color="#ffffff">
                선택한 스포츠 찾기
              </Text>
            </Wrapper>
          </WrapperColumn>
        </AbsoluteWrapper>
      ) : (
        ""
      )}
    </Wrapper>
  );
};

export default WhenFilter;

const Xbox = styled.div`
  /* margin-bottom: 10px; */
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ src }) => `url(${src})`};
  background-size: ${({ width }) => width};
`;
