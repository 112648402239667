import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const PayComplete = () => {
  const { payment_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(`/pay_info/${payment_id}`);
    }, 500);
  }, []);

  return <></>;
};

export default PayComplete;
