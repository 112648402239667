import React from "react";

import {
  Icon,
  Wrapper,
  Text,
  WrapperColumn,
  GmarketText,
  AbsoluteWrapper,
  WrapperRow,
} from "../component/layout/layoutStorage";

const NoListUI = ({ big, small, margin }) => {
  return (
    <Wrapper jc="center" ai="center" margin={`${margin} 0`}>
      <WrapperColumn padding="88px 73px" jc="center" ai="center">
        <Text size="18px" weight="600" color="#1a1c1f">
          {big}
        </Text>

        <Wrapper height="4px" />

        <Wrapper ai="center" jc="center">
          {small ? (
            <Text
              size="14px"
              weight="500"
              color="#818792"
              ws="pre-wrap"
              ta="center"
            >
              {small}
            </Text>
          ) : (
            ""
          )}
        </Wrapper>
      </WrapperColumn>
    </Wrapper>
  );
};

export default NoListUI;
