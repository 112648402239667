import React, { useEffect } from "react";

function FailUrl() {
  useEffect(() => {
    window.location.replace("/payment_fail");
  }, []);
  return <div></div>;
}

export default FailUrl;
