import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Text, Wrapper, Icon, WrapperRow, Input, AbsoluteWrapper, WrapperColumn, Radio } from "../../component/layout/layoutStorage";

import { ReactComponent as FilterArrow } from "../../icon/filterArrow.svg";
import { ReactComponent as Xbutton } from "../../icon/modalx.svg";
import { ReactComponent as Reset } from "../../icon/modalreset.svg";
import BlueCheck from "../../icon/bluecheck.png";

const DurationTypeList = [
  {
    text: "10만원 이하",
    value: "tenLower",
  },
  {
    text: "10 ~ 50만원",
    value: "betweenTenFifty",
  },
  {
    text: "50만원 초과",
    value: "overFifty",
  },
];

const PriceFilter = ({ data, text, setData, filter }) => {
  const [left, setLeft] = useState(false);
  useEffect(() => {
    let nearEl = document.getElementById("nearFilter");
    let priceEL = document.getElementById("priceFilter");
    if (nearEl && priceEL) {
      if (nearEl.getBoundingClientRect().top === priceEL.getBoundingClientRect().top) {
        setLeft(false);
      } else {
        setLeft(true);
      }
    }
  }, [filter]);

  const TypeTextArray = () => {
    var TypeCheck = data;
    var TypeCheckSplit = data.split(", ");

    if (TypeCheck == "가격") {
      var TypePopupData = {
        tenLower: false,
        betweenTenFifty: false,
        overFifty: false,
      };
    } else {
      if (TypeCheckSplit.length > 1) {
        var TypePopupData = {
          tenLower: TypeCheckSplit.indexOf("tenLower") > -1,
          betweenTenFifty: TypeCheckSplit.indexOf("betweenTenFifty") > -1,
          overFifty: TypeCheckSplit.indexOf("overFifty") > -1,
        };
      } else {
        var TypePopupData = {
          tenLower: TypeCheck === "tenLower",
          betweenTenFifty: TypeCheck === "betweenTenFifty",
          overFifty: TypeCheck === "overFifty",
        };
      }
      return TypePopupData;
    }
  };

  const first = TypeTextArray()
    ? TypeTextArray()
    : {
        tenLower: false,
        betweenTenFifty: false,
        overFifty: false,
      };

  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(first);
  const navigate = useNavigate();

  const handleClose = (e) => {
    if (open && (!ref.current || !ref.current.contains(e.target))) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [open]);

  const CategoryReset = () => {
    setType({
      tenLower: false,
      betweenTenFifty: false,
      overFifty: false,
    });
  };

  const setTypes = (types) => {
    setType((prev) => ({
      ...prev,
      [types]: !type[types],
    }));
  };

  const SelectAllTypes = () => {
    if (type.tenLower && type.betweenTenFifty && type.overFifty) {
      setType({
        tenLower: false,
        betweenTenFifty: false,
        overFifty: false,
      });
    } else {
      setType({
        tenLower: true,
        betweenTenFifty: true,
        overFifty: true,
      });
    }
  };

  const setting = () => {
    var typeList = Object.entries(type);

    var typeData = "";

    var typeListCount = 0;

    for (var i = 0; i < typeList.length; i++) {
      if (typeList[i][1]) {
        if (typeListCount === 0) {
          typeData = `${typeList[i][0]}`;
        } else {
          typeData = `${typeData}, ${typeList[i][0]}`;
        }

        typeListCount = typeListCount + 1;
      }
    }

    if (typeListCount === 0) {
      typeData = "가격";
    }

    navigate("/activity_list", {
      state: {
        sort: filter.sort,
        sport: filter.sport ? filter.sport : "",
        where: filter.where ? filter.where : "",
        when: filter.when ? filter.when : "",
        type: filter.type ? filter.type : "",
        duration: filter.duration ? filter.duration : "",
        price: typeData,
        facility: filter.facility ? filter.facility : "",
        search: filter.search ? filter.search : "",
        semos: filter.semos,
        near: false,
        full: filter.full,
      },
    });
    setTimeout(() => {
      window.location.reload();
    }, 2);

    setData((prev) => ({
      ...prev,
      price: typeData,
    }));

    setOpen(false);
  };

  return (
    <Wrapper margin="0 8px 8px 0" ref={ref}>
      <Wrapper
        id="priceFilter"
        padding="5px 11px"
        border={data != "가격" ? "1px solid #3383FF" : "1px solid #dcdcdc"}
        bgc={data != "가격" ? "#F2F7FF" : "transparent"}
        cursor="pointer"
        radius="10px"
        onClick={() => setOpen((prev) => !prev)}
      >
        <Text size="13px" weight="500" color={data != "가격" ? "#3383FF" : "#818792"}>
          {text}
        </Text>

        <FilterArrow
          fill={data != "가격" ? "#3383FF" : "#818792"}
          width="14px"
          height="18px"
          style={{
            marginLeft: "4px",
            transform: open ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "all 0.3s ease",
          }}
        />
      </Wrapper>

      {open ? (
        <AbsoluteWrapper
          padding="24px"
          top="40px"
          right={!left && "0"}
          left={left && "0"}
          width="375px"
          height="676px"
          bgc="#ffffff"
          radius="8px"
          border="1px solid #dcdcdc"
          style={{ zIndex: 2 }}
          ai="flex-start"
        >
          <WrapperColumn width="100%" height="100%" jc="space-between">
            <WrapperColumn width="100%">
              <Wrapper jc="space-between">
                <Wrapper>
                  <Text size="18px" color="#1a1c1f" weight="600">
                    1인당 가격
                  </Text>

                  <Wrapper width="12px" />

                  <Wrapper ai="center" padding="2px 0 0 0" cursor="pointer" onClick={() => CategoryReset()}>
                    <Text size="14px" weight="600" color="#0e6dff">
                      선택 초기화
                    </Text>
                    <Wrapper width="2px" />
                    <Reset width="20px" height="20px" />
                  </Wrapper>
                </Wrapper>

                <Xbutton width="28px" height="28px" onClick={() => setOpen(false)} style={{ cursor: "pointer" }} />
              </Wrapper>

              <Wrapper height="21px" />

              <Wrapper jc="flex-end" ai="center" cursor="pointer" onClick={() => SelectAllTypes()}>
                <Text size="12px" weight="600" color={type.tenLower && type.betweenTenFifty && type.overFifty ? "#3c3e41" : "#acafb5"}>
                  전체보기
                </Text>
                <Wrapper
                  width="16px"
                  height="16px"
                  border={type.tenLower && type.betweenTenFifty && type.overFifty ? "1px solid #0e6dff" : "1px solid #dcdcdc"}
                  radius="4px"
                  margin="0 0 0 6px"
                  jc="center"
                  ai="center"
                >
                  {type.tenLower && type.betweenTenFifty && type.overFifty ? <Icon src={BlueCheck} width="90%" height="90%" /> : ""}
                </Wrapper>
              </Wrapper>

              <Wrapper height="20px" />

              <WrapperColumn>
                {DurationTypeList.map((item, index) => (
                  <Wrapper
                    padding="13px 0"
                    jc="space-between"
                    bb={type[item.value] ? "1px solid #0e6dff" : "1px solid #f5f5f5"}
                    cursor="pointer"
                    onClick={() => setTypes(item.value)}
                    margin="0 0 12px 0"
                  >
                    <Text size="14px" weight="600" color={type[item.value] ? "#3c3e41" : "#acafb5"}>
                      {item.text}
                    </Text>

                    <Wrapper
                      width="16px"
                      height="16px"
                      border={type[item.value] ? "1px solid #0e6dff" : "1px solid #dcdcdc"}
                      radius="4px"
                      margin="0 0 0 6px"
                      jc="center"
                      ai="center"
                    >
                      {type[item.value] ? <Icon src={BlueCheck} width="90%" height="90%" /> : ""}
                    </Wrapper>
                  </Wrapper>
                ))}
              </WrapperColumn>
            </WrapperColumn>

            <Wrapper width="100%" bgc="#0e6dff" padding="16px 0" radius="8px" jc="center" margin="0 0 18px 0" cursor="pointer" onClick={() => setting()}>
              <Text size="16px" weight="600" color="#ffffff">
                선택한 가격대의 액티비티 찾기
              </Text>
            </Wrapper>
          </WrapperColumn>
        </AbsoluteWrapper>
      ) : (
        ""
      )}
    </Wrapper>
  );
};

export default PriceFilter;
