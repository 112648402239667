import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

// components
import { Wrapper, Text, WrapperColumn, WrapperRow } from "./layout/layoutStorage";

// icon
import { ReactComponent as RightArrowIcon } from "../icon/carouselArrowRight.svg";
import { ReactComponent as LeftArrowIcon } from "../icon/carouselArrowLeft.svg";
import Ulsan from "../img/ulsan.png";
import Seoul from "../img/seoul.png";
import Incheon from "../img/incheon.png";
import Gangwon from "../img/gangwon.png";
import Gyeonggi from "../img/gyeonggi.png";
import Daegu from "../img/daegu.png";
import Jeju from "../img/jeju.png";
import Busan from "../img/busanaiga.png";

const MapCard = () => {
  const [nowSlide, setNowSlide] = useState(0);
  const slickRef = useRef();
  const settings = {
    className: "slider variable-width",
    dots: false,
    arrows: false,
    draggable: false,
    infinite: false,
    // centerMode: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    // variableWidth: true,
  };

  const navigate = useNavigate();

  const cardText = "아래 모든 지역에서 즐길 수 있어요!";
  const cardItem = [
    {
      location: "경기",
      photo: Gyeonggi,
    },
    {
      location: "서울",
      photo: Seoul,
    },
    {
      location: "인천",
      photo: Incheon,
    },
    {
      location: "부산",
      photo: Busan,
    },
    {
      location: "대구",
      photo: Daegu,
    },
    {
      location: "강원",
      photo: Gangwon,
    },
    {
      location: "제주",
      photo: Jeju,
    },
    // {
    //   location: "충북",
    //   photo: Chungbuk,
    // },
    {
      location: "울산",
      photo: Ulsan,
    },
  ];

  const goActivityList = (location) => {
    navigate("/activity_list", {
      state: {
        where: `${location}_전체`,
      },
    });
  };

  const reviewCount = cardItem.length;
  return (
    <Container>
      <WrapperRow margin="24px 0 26px 0" width="100%" jc="space-between" ai="center">
        <Wrapper>
          <Text size="18px" weight="600" color="#1A1C1F">
            {cardText}
          </Text>
        </Wrapper>
        <Wrapper>
          <Wrapper cursor="pointer" onClick={() => slickRef.current.slickPrev()} margin="0 18px 0 0">
            <LeftArrowIcon fill={nowSlide === 0 ? "#dcdcdc" : "#1A1C1F"} />
          </Wrapper>
          <Wrapper
            cursor="pointer"
            onClick={() => {
              if (nowSlide === reviewCount - 6) {
                return;
              } else {
                slickRef.current.slickNext();
              }
            }}
          >
            <RightArrowIcon fill={nowSlide === reviewCount - 6 ? "#dcdcdc" : "#1A1C1F"} />
          </Wrapper>
        </Wrapper>
      </WrapperRow>
      <Slider
        beforeChange={(index, next) => {
          setNowSlide(next);
        }}
        ref={slickRef}
        {...settings}
      >
        {cardItem.map((item, idx) => (
          <Wrapper key={idx} padding="0 8px 0 0">
            <WrapperColumn
              style={{
                background: `url(${item.photo})`,
                backgroundPosition: "center",
                backgroundSize: "136px 87px",
              }}
              bgc="#dcdcdc"
              width="136px"
              height="87px"
              radius="12px"
              jc="center"
              ai="center"
              cursor="pointer"
              onClick={() => goActivityList(item.location)}
            >
              <Text size="16px" weight="600" color="#ffffff" lc="-0.02em">
                {item.location}
              </Text>
            </WrapperColumn>
          </Wrapper>
        ))}
      </Slider>
    </Container>
  );
};

export default MapCard;
const Container = styled.div`
  margin-bottom: 36px;
  .slick-next::before {
    display: none;
  }
  .slick-prev::before {
    display: none;
  }
`;

const ReviewCard = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-size: 212px 125px;
  background-position: center;
  position: relative;
  width: 212px;
  height: 125px;
  border-radius: 8px 8px 0 0;
`;

const RainbowBox = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  border-radius: 20px;
  padding: 3px 7px;
  background: radial-gradient(100% 843.76% at 0% 52.38%, #ff6759 0%, #8d4ef2 44.79%, #0e6dff 100%);
`;
