import React, { useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";

import { Wrapper, Text, WrapperRow } from "./layout/layoutStorage";

import { ReactComponent as RightArrowIcon } from "../icon/carouselArrowRight.svg";
import { ReactComponent as LeftArrowIcon } from "../icon/carouselArrowLeft.svg";
// import Item from "./otherItem";
import Item from "./item";

const ActivityBottomCard = ({ itemList, text, setSignUpModal }) => {
  const [nowSlide, setNowSlide] = useState(0);

  const slickRef = useRef();

  const settings = {
    draggable: false,
    className: "slider variable-width",
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const reviewCount = itemList?.length > 4 ? itemList?.length - 4 : 0;

  return (
    <Container>
      <WrapperRow margin="23px 0 25.8px 0" width="100%" jc="space-between" ai="center">
        <Wrapper>
          <Text size="18px" weight="600" color="#0E6DFF">
            {text ? text : "이런 액티비티는 어때요?"}
          </Text>
        </Wrapper>
        <Wrapper>
          <Wrapper cursor="pointer" onClick={() => slickRef.current.slickPrev()} margin="0 18px 0 0">
            <LeftArrowIcon fill={nowSlide === 0 ? "#dcdcdc" : "#1A1C1F"} />
          </Wrapper>
          <Wrapper
            cursor="pointer"
            onClick={() => {
              if (nowSlide == reviewCount) return 0;
              slickRef.current.slickNext();
            }}
          >
            <RightArrowIcon fill={nowSlide === reviewCount ? "#dcdcdc" : "#1A1C1F"} />
          </Wrapper>
        </Wrapper>
      </WrapperRow>
      <Slider
        beforeChange={(index, next) => {
          setNowSlide(next);
        }}
        ref={slickRef}
        {...settings}
      >
        {itemList?.map((item, idx) => (
          <Item key={idx} item={item} setModal={setSignUpModal} />
        ))}
      </Slider>
    </Container>
  );
};

export default ActivityBottomCard;

const Container = styled.div`
  .slick-next::before {
    display: none;
  }
  .slick-prev::before {
    display: none;
  }
`;
