import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Icon,
  Wrapper,
  Text,
  WrapperColumn,
  GmarketText,
  AbsoluteWrapper,
  WrapperRow,
} from "./layout/layoutStorage";

import ModalBox from "./modalBox";

import CheckCircle from "../icon/bluecheckcircle.png";

const ProfileReviseComplete = ({ status, setStatus, setModalStatus }) => {
  const navigate = useNavigate();

  const check = () => {
    setModalStatus("profile");
    setStatus(false);
  };

  return (
    <ModalBox status={status} setStatus={setStatus} confirm={true}>
      <Wrapper padding="24px" bgc="#ffffff" radius="16px" width="375px">
        <WrapperColumn width="100%">
          <Text size="20px" weight="700" color="#1a1c1f">
            프로필 수정이 완료되었어요
          </Text>

          <Wrapper height="8px" />

          <Text size="14px" weight="500" color="#818792">
            변경사항을 완벽하게 저장했어요
          </Text>

          <Wrapper height="36px" />

          <Wrapper jc="center">
            <Icon src={CheckCircle} width="88px" height="88px" />
          </Wrapper>

          <Wrapper height="44px" />

          <Wrapper
            padding="14.5px"
            jc="center"
            bgc="#0e6dff"
            width="100%"
            radius="8px"
            cursor="pointer"
            onClick={() => check()}
          >
            <Text size="16px" weight="600" color="#ffffff">
              확인
            </Text>
          </Wrapper>
        </WrapperColumn>
      </Wrapper>
    </ModalBox>
  );
};

export default ProfileReviseComplete;
