import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import { Loading } from 'react-loading-dot'
import Sehorang from "../icon/sehorangDown.png";

const PaySuccess = () => {

  const { payment_id, userspec_token } = useParams();
  const user_token = window.localStorage.getItem("token");

  useEffect(() => { 

    if(userspec_token == 'kakao') {

      const frm = new FormData();
      const current = decodeURI(window.location.href);
      const search = current.split("?")[1];
      const params = new URLSearchParams(search);
      const paymentKey = params.get('pg_token');

      frm.append('mode', 'kakao_payment')
      frm.append('token', user_token)
      frm.append('payment_key', paymentKey)
      frm.append('payment_id', payment_id)
  
      axios.post("https://ai.semos.kr/semos_user/service/app_payment", frm, {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(data => {
        const Value = data.data;
        const Status = Value.status;
        const StatusMessage = Value.status_message;
        const PaymentId = Value.payment_number;

        if(Status == 'success') { 
          window.location.replace(`/pay_complete/${PaymentId}`);
        } else if(Status == 'fail') {
          window.alert(StatusMessage);
        } else if(Status == 'waring') {
          window.alert(StatusMessage);
        }
  
      });

    } else {

      const current = decodeURI(window.location.href);
      const search = current.split("?")[1];
      const params = new URLSearchParams(search);
      const orderId = params.get('orderId');
      const paymentKey = params.get('paymentKey');
      const amount = params.get('amount');

      const frm = new FormData();
      frm.append('mode', 'toss_payment')
      frm.append('token', user_token)
      frm.append('order_id', orderId)
      frm.append('payment_key', paymentKey)
      frm.append('amount', amount)
      frm.append('payment_id', payment_id)
      frm.append('userspec_token', userspec_token)
  
      axios.post("https://ai.semos.kr/semos_user/service/app_payment", frm, {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(data => {
        const Value = data.data;
        const Status = Value.status;
        const StatusMessage = Value.status_message;
        const PaymentId = Value.payment_number;
  
        if(Status == 'success') { 
          window.location.replace(`/pay_complete/${PaymentId}`);
        } else if(Status == 'fail') {
          window.alert(StatusMessage);
        } else if(Status == 'waring') {
          window.alert(StatusMessage);
        }
  
      });

    }
 
  }, []);

  return (
  
    <LoadingPayment>
      <div className="content" >
          <img className="sehorang" src={Sehorang} />

          <div className={`dot_container`}>
            <Loading background={`#80B2FF`}/>
          </div>
        

          <div className="text_view" >결제페이지로 이동하고 있습니다.</div>
          <div className="text_view" >잠시만 기다려 주세요!</div>
      </div>
    </LoadingPayment>
  
  );

};


export default PaySuccess;

const LoadingPayment = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position : fixed;
  top : 0;
  right : 0;
  display : inline-flex;
  align-items : center;
  justify-content : center; 
  background-color :rgba(0, 0, 0, 0.6); 
  z-index : 100;

  .bhynco  {
    position : unset !important; 
    transform : unset !important;

    div {
      width : 8px !important; 
      height : 8px !important; 
    }
  }

  .dot_container {
    width : 100%;
    margin : 10px 0;
  }

  .content {
    width: 100%;
    display : inline-flex;
    flex-wrap : wrap;
    justify-content : center;
    z-index : 101;
  }

  .sehorang {
    display : inline-block;
    width : 123px;
  }

  .text_view {
    display : inline-flex;
    flex-wrap : wrap;
    width: 100%;
    font-weight : 400;
    line-height : 22.72px;
    font-size : 16px;
    color : #CCE0FF;
    justify-content : center;
  }

  
`;