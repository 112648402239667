import React, { Component } from "react";
import { BrowserRouter as Router, withRouter, Link as domLink, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

//component
import Header from "../component/header";
import DeskTopHeader from "../component/layout/header";

// css
const CrossContainer = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #ffffff;

  position: relative;
  display: inline-block;
  overflow: hidden;
  overflow-y: auto;
  z-index: 15;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
`;

const CrossContent = styled.div`
  width: 80rem;
  margin: 0 calc((100% - 80rem) / 2);
  min-height: calc(100vh - 13rem);
  min-height: calc((var(--vh, 1vh) * 100) - 13rem);
  display: inline-block;
  padding: 1rem 0;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
`;

const Content = styled.div`
  margin-top: 2px;
  margin-left: 12px;
`;

const HeaderContainer = styled.div`
  @media screen and (max-width: 860px) {
    display: none;
  }
  @media screen and (min-width: 860px) {
    display: block;
    margin: 100px 0 0 0;
  }
`;

const withParams = (Child) => {
  return (props) => {
    const params = useParams();
    const navigate = useNavigate();
    return <Child {...props} params={params} naviGate={navigate} />;
  };
};

class Semos_ad_process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileCheck: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
      thisPage: window.location.pathname.split("/")[1],
    };
  }
  componentDidMount() {
    console.log(this.state.mobileCheck);
  }

  render() {
    return (
      <CrossContainer>
        {this.state.mobileCheck ? <Header /> : ""}

        <CrossContent style={{ padding: this.state.mobileCheck ? "5.2rem 1.8rem 8rem 1.8rem" : "1.8rem 1.8rem 8rem 1.8rem" }}>
          {this.state.mobileCheck ? (
            ""
          ) : (
            <>
              <HeaderContainer>
                <DeskTopHeader />
              </HeaderContainer>
              <Header />
            </>
          )}

          <div className="home_container partner_page">
            <section
              style={{
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "142%",
              }}
            >
              리포츠 (주)는 회원님이 수집 및 이용에 동의한 개인정보를 이용하여 SMS, MMS, 이메일, 앱 푸시 알림 등 다양한 전자 전송 매체를 통해 오전 8시부터 오후
              9시까지 개인 맞춤형 광고 및 정보를 전송할 수 있습니다.
              <br />
              <br />
              1. 개인정보 수집 • 이용 항목
              <br></br>
              <Content>
                이메일, 휴대전화번호, 닉네임, 성별, 생일, 연령대, 지역, 개인식별정보 (Token), DI(중복가입확인정보), 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP
                주소, 광고 식별자
              </Content>
              <br></br>
              2. 개인정보 수집 • 이용 목적
              <br></br>
              <Content>(광고성) 맞춤형 마케팅, 프로모션 및 혜택 소식 전송</Content>
              <br></br>
              3. 개인정보 보유 • 이용 기간
              <br></br>
              <Content>동의 철회 또는 회원 탈퇴 시 까지</Content>
              <br></br>
              4. 동의 거부 권리 및 거부 시 불이익
              <br></br>
              <Content>
                광고성 정보 수신에 동의하지 않을 권리가 있으며, 본 동의는 거부하실 수 있습니다. 거부 시 회원가입 및 서비스 이용에는 제한이 없으나 이벤트 및
                프로모션 안내 등 혜택 안내를 받으실 수 없습니다.
              </Content>
              <br></br>
              5. 광고성 정보 수신의 변경
              <br></br>
              <Content>
                {`1) 세모스 이용자 어플리케이션, 웹 브라우저에서 로그인 > 프로필 > 이벤트 및 알림 에서 언제든지 변경할 수 있습니다.`}
                <br></br>
                {`2) 세모스 고객센터 (카카오톡 플러스 친구: 세모스 , 세상의 모든 스포츠)로 요청할 수 있습니다.`}
              </Content>
              <br></br>
              동의를 거부할 수 있으며, 동의를 거부해도 서비스를 이용할 수 있습니다.
            </section>
          </div>
        </CrossContent>
      </CrossContainer>
    );
  }
}

export default withParams(Semos_ad_process);
