import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

// icon
import DesktopContent from "../../icon/desktopContent.png";
import MobileContent from "../../icon/mobileContent.png";
import DownQR from "../../icon/downQR.png";
import WhiteArrow from "../../icon/whiteArrow.png";
import XButton from "../../icon/spoex/spoexClos.png";

const AppModal = ({open, setOpen, Mobile, page}) => {
    const [phone, setPhone] = useState("");

    const PhoneNumber = (Num) => {
        var PhoneNum = Num
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
        .replace(/(\-{1,2})$/g, "");

        setPhone(PhoneNum)
    }

    const MessageSend = () => {
        if(phone.length < 12) {
            window.alert("휴대폰 번호를 확인해주세요!")
        } else {
            const frm = new FormData();

            frm.append("mode", "send_appdown");
            frm.append("phone", phone);
    
            axios
              .post("https://ai.semos.kr/semos_user/profile/user_profile", frm, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((data) => {
                var Value = data.data;
                var Status = Value.status;
    
                if (Status == "success") {
                    window.alert("전송된 메시지를 확인해주세요!")
                    window.location.reload();
                } else {
                    window.alert("휴대폰 번호를 확인해주세요!")
                }
            });
        }
    }

    const AppDown = () => {
        window.location.replace('https://semos.kr/app_open/desktop')
    }

    if(open) {
        if(Mobile) {
            return (
                <Background>
                    <DownModalM>

                        {((page == 'activity_detail') || (page == 'partner_detail')) ? 
                            <img style={{
                                    position : 'absolute', 
                                    top : 8, 
                                    right : 8, 
                                    width : 30, 
                                    cursor : 'pointer', 
                                    display : 'inline-block', 
                                    zIndex : 1000
                                }} 
                                src={XButton} 
                                onClick={() => setOpen(!open)} 
                            />
                            : ""
                        }

                        <DownContent src={MobileContent}/>
        
                        <ModalBottomM>
                            <span onClick={() => AppDown()} className={`SEND`} >
                                앱 다운로드하러 가기
                            </span>
                        </ModalBottomM>
        
                    </DownModalM>
                </Background>
            );
        } else {
            return (
                <Background>
                    <DownModal>

                        <img style={{
                            position : 'absolute', 
                            top : 8, 
                            right : 8, 
                            width : 30, 
                            cursor : 'pointer', 
                            display : 'inline-block', 
                            zIndex : 1000
                            }} 
                            src={XButton} 
                            onClick={() => setOpen(!open)} 
                        />

                        <DownContent src={DesktopContent}/>
        
                        <ModalBottom>
                            <span className="Title" >
                                QR코드로 앱 다운받기
                            </span>
        
                            <span className="QRDIV" >
                                <img src={DownQR} />
                            </span>
        
                            <span className="Title" >
                                문자로 앱 다운로드 안내받기
                            </span>
        
                            <span className="MESSAGE" >
        
                                <input value={phone} onChange={(e) => PhoneNumber(e.target.value)} type='text' placeholder='휴대폰 번호를 입력해주세요' maxLength='13' />
        
                                <span onClick={() => MessageSend()} className="SEND">
                                    <img src={WhiteArrow}/>
                                </span>
                            </span>
                        </ModalBottom>
        
                    </DownModal>
                </Background>
            );
        }
    } else {
        return <></>
    }

}


export default AppModal;

const Background = styled.div`
    position: fixed;
    top : 0;
    left : 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 999;
    display: inline-flex;
    align-items : center;
    justify-content : center;
    background-color : rgba(0, 0, 0, 0.6);
    overflow : hidden;
`;

const DownModal =  styled.div`
    width: 375px;
    display: inline-flex;
    flex-wrap : wrap;
    position: relative;
    border-radius : 18px;
    background-color : #ffffff;
`;

const DownModalM =  styled.div`
    width: 327px;
    display: inline-flex;
    flex-wrap : wrap;
    position: relative;
    border-radius : 18px;
    background-color : #ffffff;
`;


const DownContent =  styled.img`
    width: 100%;
    display: inline-block;
`;

const ModalBottomM =  styled.div`
    width: 100%;
    display: inline-flex;
    flex-wrap : wrap;
    padding : 12px 24px 32px 24px;

    .SEND {
        width : 100%;
        height : 56px;
        display: inline-flex;
        align-items : center;
        justify-content : center;
        border-radius : 8px;
        background-color : rgba(14, 109, 255, 1);
        cursor : pointer;
        color : #ffffff;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
`;

const ModalBottom =  styled.div`
    width: 100%;
    display: inline-flex;
    flex-wrap : wrap;
    padding : 16px 16px 36px 16px;

    .Title {
        width : 100%;
        display : inline-block;
        color : rgba(26, 28, 31, 1);
        line-height : 26px;
        font-weight : 700;
        font-size : 18px;
        margin-bottom : 16px;
    }

    .QRDIV {
        width : 100%;
        display: inline-flex;
        align-items : center;
        justify-content : center;
        margin-bottom : 40px;

        img {
            width : 100px;
        }
    }

    .MESSAGE {
        width : 100%;
        display: inline-flex;
        align-items : center;
        justify-content : space-between;

        input {
            width : 275px;
            height : 56px;
            border-radius : 8px;
            display: inline-flex;
            padding : 0 16px;
            align-items : center;
            border : 1px solid rgba(220, 220, 220, 1);
        }

        .SEND {
            width : 56px;
            height : 56px;
            display: inline-flex;
            align-items : center;
            justify-content : center;
            border-radius : 8px;
            background-color : rgba(14, 109, 255, 1);
            cursor : pointer;

            img {
                width : 24px;
                height : 24px;
                display: inline-block;
            }
        }
    }
`;