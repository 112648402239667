import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { BrowserRouter as Router, Link as domLink, useNavigate, useLocation } from "react-router-dom";

//component
import DrawerModal from "../drawerModal";
import NewProfile from "../../pages/newProfile";
import ProfileRevise from "../profile_revise";
import NewPoint from "../../pages/newPoint";
import { Text, Wrapper, Icon, WrapperRow, Input, AbsoluteWrapper } from "./layoutStorage";
import NewAnnounce from "../../pages/newAnnounce";
import NewAnnounceDetail from "../../pages/newAnnounceDetail";
import NewFaq from "../../pages/newFaq";
import SemosUserInfoProcess from "../../pages/personalInfoProcessing";
import UseInfo from "../../pages/useInfo";
import LogoutModal from "../logoutModal";
import LogoutConfirm from "../logoutConfirm";
import RetireModal from "../retireModal";
import RetireConfirm from "../retireConfirm";
import ProfileReviseConfirm from "../profileReviseConfirm";
import ProfileReviseComplete from "../profileReviseComplete";
import SignInModal from "../signInModal";
import ProfileUserSpec from "../../pages/profileUserSpec";
import AppModal from "../home/appModal";
import colors from "../../styles/colors";

//icon
import SemosLogo from "../../icon/semosLogo.png";
import X from "../../icon/x.png";
import { ReactComponent as SearchIcon } from "../../icon/searchIcon.svg";
import { ReactComponent as XIcon } from "../../icon/xButton.svg";
import { ReactComponent as Profile } from "../../icon/profile.svg";
import { ReactComponent as MySemos } from "../../icon/mySemos.svg";
import { ReactComponent as Chat } from "../../icon/chat.svg";
import { ReactComponent as Caution } from "../../icon/caution.svg";
import locationIcon from "../../icon/location_tag.svg";

const locationDATA = [
  {
    text: "경기",
    filter: "경기_전체",
  },
  {
    text: "경기 수원",
    filter: "경기_수원",
  },
  {
    text: "서울",
    filter: "서울_전체",
  },
  {
    text: "부산",
    filter: "부산_전체",
  },
  {
    text: "인천",
    filter: "인천_전체",
  },
  {
    text: "경남",
    filter: "경남_전체",
  },
  {
    text: "대구",
    filter: "대구_전체",
  },
  {
    text: "전북",
    filter: "전북_전체",
  },
  {
    text: "강원",
    filter: "강원_전체",
  },
  {
    text: "울산",
    filter: "울산_전체",
  },
  {
    text: "제주",
    filter: "제주_전체",
  },
];

const MobileCheck = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const Header = ({ right, page }) => {
  const [signInModal, setSignInModal] = useState(false);
  const [loginCheck, setLoginCheck] = useState("");
  const { state, pathname } = useLocation();
  const location = useLocation();
  // const [open, setOpen] = useState(false);
  const [boxWidth, setBoxWidth] = useState(0);
  const [searchBox, setSearchBox] = useState(false);
  const [recentList, setRecentList] = useState([]);
  const searchRef = useRef();
  const [modalStatus, setModalStatus] = useState("profile");
  const [announce, setAnnounce] = useState("");
  const navigate = useNavigate();
  const [search, setSearch] = useState(state?.search ? state?.search : "");

  const [userSpecChange, setUserSpecChange] = useState(false);

  const [logout, setLogout] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);

  const [retire, setRetire] = useState(false);
  const [retireConfirm, setRetireConfirm] = useState(false);

  const [reviseComplete, setReviseComplete] = useState(false);
  const [reviseQuit, setReviseQuit] = useState(false);

  const [open, setOpen] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      var searchbox = document.getElementById("searchBox").offsetWidth;
      setBoxWidth(searchbox);
    }, 500);
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem("naverToken") && window.localStorage.getItem("type")) {
      setSignInModal(true);
    }
  }, []);

  useEffect(() => {
    var token = localStorage.getItem("token");

    var logged = localStorage.getItem("lc") == "yes";
    setLoginCheck(logged);

    //최근 검색어
    if (token) {
      const frm = new FormData();
      frm.append("mode", "search_data");
      frm.append("token", token);

      axios
        .post("https://ai.semos.kr/semos_user/service/category_new", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var Value = data.data;
          setRecentList(Value.current_search);
        });
    }
  }, [searchBox]);

  const handleClickOutside = (event) => {
    if (searchBox && !searchRef.current.contains(event.target)) {
      setSearchBox(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [searchBox]);

  const MySemosRef = useRef();
  const [MSDropdown, setMSDropdown] = useState(false);

  const semosHandleClickOutside = (event) => {
    if (MSDropdown && !MySemosRef.current.contains(event.target)) {
      setMSDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", semosHandleClickOutside);
    return () => {
      window.removeEventListener("click", semosHandleClickOutside);
    };
  }, [MSDropdown]);

  const deleteSearch = (target, i) => {
    var token = localStorage.getItem("token");
    if (window.confirm(`검색 기록에서 '${target}'를 삭제하시겠습니까?`)) {
      const frm = new FormData();
      frm.append("mode", "search_delete");
      frm.append("token", token);
      frm.append("search_text", target);
      axios
        .post("https://ai.semos.kr/semos_user/profile/search", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var Value = data.data;
          if (Value.status === "success") {
            var copy = [...recentList];
            copy.splice(i, 1);
            setRecentList(copy);
            alert("성공적으로 삭제되었습니다.");
          }
        });
    }
  };

  const deleteAll = () => {
    var token = localStorage.getItem("token");
    if (window.confirm(`검색 기록을 모두 삭제하시겠습니까?`)) {
      const frm = new FormData();
      frm.append("mode", "search_delete_all");
      frm.append("token", token);
      axios
        .post("https://ai.semos.kr/semos_user/profile/search", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var Value = data.data;
          if (Value.status === "success") {
            setRecentList([]);
          }
        });
    }
  };

  const info = pathname == "/semos_user_info_process" || pathname == "/semos_ad_info" || pathname == "/semos_payment_info" || pathname == "/semos_using_info";

  const moveListing = (text, whereFilter) => {
    var token = localStorage.getItem("token");
    if (token) {
      const frm = new FormData();
      frm.append("mode", "list");
      frm.append("token", token);
      frm.append("text", text);
      axios
        .post("https://ai.semos.kr/semos_user/service/category_new", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          if (pathname == "/activity_list") {
            if (whereFilter) {
              navigate("/activity_list", {
                state: {
                  sport: state.sport ? state.sport : "",
                  where: whereFilter ? whereFilter : "",
                  when: state.when ? state.when : "",
                  type: state.type ? (state.type == "유형" ? "액티비티" : state.type) : "",
                  duration: state.duration ? state.duration : "",
                  price: state.price ? state.price : "",
                  facility: state.facility ? state.facility : "",
                  search: text,
                  semos: state.semos,
                  full: state.full ? state.full : false,
                  near: state.near ? state.near : false,
                },
              });
            } else {
              navigate("/activity_list", {
                state: {
                  sport: state.sport ? state.sport : "",
                  where: state.where ? state.where : "",
                  when: state.when ? state.when : "",
                  type: state.type ? (state.type == "유형" ? "액티비티" : state.type) : "",
                  duration: state.duration ? state.duration : "",
                  price: state.price ? state.price : "",
                  facility: state.facility ? state.facility : "",
                  search: text,
                  semos: state.semos,
                  full: state.full ? state.full : false,
                  near: state.near ? state.near : false,
                },
              });
            }

            setTimeout(() => {
              window.location.reload();
            }, 5);
          } else {
            navigate("/activity_list", {
              state: {
                sport: "",
                where: whereFilter ? whereFilter : "",
                when: "",
                type: "",
                duration: "",
                price: "",
                facility: "",
                search: text,
                semos: false,
                full: false,
                near: false,
              },
            });
          }
        });
    } else {
      if (pathname == "/activity_list") {
        if (whereFilter) {
          navigate("/activity_list", {
            state: {
              sport: state.sport ? state.sport : "",
              where: whereFilter ? whereFilter : "",
              when: state.when ? state.when : "",
              type: state.type ? (state.type == "유형" ? "액티비티" : state.type) : "",
              duration: state.duration ? state.duration : "",
              price: state.price ? state.price : "",
              facility: state.facility ? state.facility : "",
              search: text,
              semos: state.semos,
              full: state.full ? state.full : false,
              near: state.near ? state.near : false,
            },
          });
        } else {
          navigate("/activity_list", {
            state: {
              sport: state.sport ? state.sport : "",
              where: state.where ? state.where : "",
              when: state.when ? state.when : "",
              type: state.type ? (state.type == "유형" ? "액티비티" : state.type) : "",
              duration: state.duration ? state.duration : "",
              price: state.price ? state.price : "",
              facility: state.facility ? state.facility : "",
              search: text,
              semos: state.semos,
              full: state.full ? state.full : false,
              near: state.near ? state.near : false,
            },
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 5);
      } else {
        navigate("/activity_list", {
          state: {
            sport: "",
            where: "",
            when: "",
            type: "",
            duration: "",
            price: "",
            facility: "",
            search: text,
            semos: false,
            full: false,
            near: false,
          },
        });
      }
    }
  };

  const RecentSearch = (text) => {
    var token = localStorage.getItem("token");
    if (text) {
      if (token) {
        const frm = new FormData();
        frm.append("mode", "search_log");
        frm.append("token", token);
        frm.append("text", text);
        axios
          .post("https://ai.semos.kr/semos_user/service/category_new", frm, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          })
          .then((data) => {
            if (pathname == "/activity_list") {
              navigate("/activity_list", { state: { search: text } });
              setTimeout(() => {
                window.location.reload();
              }, 5);
            } else {
              navigate("/activity_list", { state: { search: text } });
            }
          });
      }
    }
  };
  const onlyAddRecentSearch = (text) => {
    var token = localStorage.getItem("token");
    if (text) {
      if (token) {
        const frm = new FormData();
        frm.append("mode", "search_log");
        frm.append("token", token);
        frm.append("text", text);
        axios
          .post("https://ai.semos.kr/semos_user/service/category_new", frm, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          })
          .then((data) => {});
      }
    }
  };

  const detectMySemos = (link) => {
    if (localStorage.getItem("token")) {
      navigate(link);
    } else {
      alert("로그인 후 사용이 가능합니다.");
    }
  };

  // 세모스 챗 알림
  const [newChat, setNewChat] = useState(0);
  const semosChat = () => {
    var token = localStorage.getItem("token");
    if (token) {
      const frm = new FormData();
      frm.append("mode", "chatCheck");
      frm.append("token", token);
      axios
        .post("https://ai.semos.kr/semos_user/service/chat", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var Value = data.data;
          var Status = Value.status;
          var StatusMessage = Value.status_message;

          if (Status === "success") {
            setNewChat(Value.new_chat);
          } else {
            window.alert(StatusMessage);
          }
        });
    }
  };

  useEffect(() => {
    if (pathname.includes("question_chat")) {
      setTimeout(() => {
        semosChat();
      }, 300);
    } else {
      semosChat();
    }
  }, [pathname]);

  const [searchResults, setSearchResults] = useState([]);
  const [selectedResultIndex, setSelectedResultIndex] = useState(-1);

  const handleArrowKeyDown = (event) => {
    // 경기_수원
    let index = searchResults.length ? searchResults.length : -1;
    if (event.keyCode === 40) {
      // 아래 방향키
      setSelectedResultIndex((prev) => (prev + 1) % index);
    } else if (event.keyCode === 38) {
      // 위 방향키
      setSelectedResultIndex((prev) => (prev - 1 + index) % index);
    } else if (event.keyCode === 13) {
      // 엔터 키
      if (selectedResultIndex !== -1) {
        let searchText = searchResults[selectedResultIndex];
        setSearch("");
        moveListing("", searchText.filter);
        onlyAddRecentSearch(searchText.text);

        setSearchResults([]);
        setSelectedResultIndex(-1);
      } else {
        moveListing(search);
      }
    }
  };

  useEffect(() => {
    let copy = [];
    locationDATA.map((item, index) => {
      if (item.text.includes(search) || search.includes(item.text)) {
        if (search.trim() !== "") {
          copy.push(item);
        }
      }
      return setSearchResults(copy);
    });
  }, [search]);

  const renderText = (item) => {
    const index = item.indexOf(search);
    if (index >= 0) {
      return (
        <>
          {item.substring(0, index)}
          <span style={{ color: "#3383FF" }}>{search}</span>
          {item.substring(index + search.length)}
        </>
      );
    } else {
      return (
        <>
          <span style={{ color: "#3383FF" }}>{item}</span>
        </>
      );
    }
  };

  return (
    <>
      <LayoutContainer open={open}>
        <Container>
          <WrapperRow jc={MobileCheck ? `space-between` : ``} width={MobileCheck ? `100%` : `860px`}>
            <Icon 
              width={MobileCheck ? `60px` : `60px`} 
              height={MobileCheck ? `60px` : `60px`} 
              src={SemosLogo} 
              cursor="pointer" 
              onClick={() => navigate("/")} 
            />

            {
              MobileCheck ? 
              ""
              :
              <Wrapper width="100%">
                <Wrapper
                  id="searchBox"
                  ref={searchRef}
                  width="100%"
                  height="39px"
                  padding="10.5px 18px"
                  radius="25px"
                  border={searchBox ? "1px solid #66A2FF" : "1px solid #dcdcdc"}
                  jc="space-between"
                  onFocus={() => setSearchBox(true)}
                >
                  <Input
                    style={{ width: "100%" }}
                    size="13px"
                    type="text"
                    onKeyDown={handleArrowKeyDown}
                    placeholder="이번 주 프리다이빙 어때요?"
                    value={search ? search : ""}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <WrapperRow>
                    {search.length > 0 && searchBox && (
                      <XIcon
                        width="24px"
                        height="24px"
                        fill={colors.Gr03}
                        style={{ margin: "0 8px 0 0", cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSearch("");
                        }}
                      />
                    )}
                    <SearchIcon
                      fill={searchBox ? colors.Bl05 : colors.Gr03}
                      width="24px"
                      height="24px"
                      style={{ cursor: "pointer" }}
                      onClick={() => moveListing(search)}
                    />
                  </WrapperRow>
                  {searchBox
                    ? search === ""
                      ? localStorage.getItem("token") && (
                          <AbsoluteWrapper
                            width={boxWidth ? `${boxWidth}px` : 0}
                            ai="flex-start"
                            top="39px"
                            z="99"
                            bgc="white"
                            left="0"
                            style={{ flexDirection: "column" }}
                          >
                            <Wrapper width={boxWidth ? `${boxWidth}px` : 0} jc="space-between" padding="0 24px" margin="21px 0 24px 0">
                              <Text ws="pre" size="16px" color="#3c3e41" weight="600">
                                최근검색어
                              </Text>
                              <Text ws="pre" size="12px" color="#ACAFB5" weight="600" style={{ cursor: "pointer" }} onClick={() => deleteAll()}>
                                전체삭제
                              </Text>
                            </Wrapper>
                            <Wrapper width="100%" jc="flex-start" padding="0 24px 24px 24px">
                              <Wrapper id="searchscroll" width={boxWidth ? `${boxWidth}px` : 0} of="scroll">
                                {recentList.map((el, i) => (
                                  <SearchItem key={el} onClick={() => RecentSearch(el)}>
                                    {el}
                                    <XButton
                                      src={X}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        deleteSearch(el, i);
                                      }}
                                    />
                                  </SearchItem>
                                ))}
                              </Wrapper>
                            </Wrapper>
                          </AbsoluteWrapper>
                        )
                      : search !== "" && (
                          <AbsoluteWrapper
                            width={boxWidth ? `${boxWidth}px` : 0}
                            ai="flex-start"
                            top="39px"
                            z="99"
                            bgc="white"
                            left="0"
                            style={{ flexDirection: "column" }}
                          >
                            {searchResults.map((item, index) => (
                              <Wrapper
                                key={index}
                                onClick={() => {
                                  setSearch("");
                                  onlyAddRecentSearch(item.text);
                                  moveListing("", item.filter);
                                  setSearchResults([]);
                                }}
                                hvBgc={"#F2F7FF"}
                                bgc={selectedResultIndex === index && "#F2F7FF"}
                                width="100%"
                                jc="flex-start"
                                padding="12px 16px"
                                cursor="pointer"
                              >
                                <Icon margin="0 12px 0 0" width="20px" height="20px" src={locationIcon} />
                                <Text ws="pre" size="12px" weight="600">
                                  {renderText(item.text)}
                                </Text>
                              </Wrapper>
                            ))}
                          </AbsoluteWrapper>
                        )
                    : ""}
                </Wrapper>
              </Wrapper>
            }


            {loginCheck ? (
              <WrapperRow position="relative" margin="0 0 0 35px" gap="18px" jc="center" ai="center">
                <Wrapper
                  onClick={() => {
                    navigate("/question_list");
                  }}
                  cursor="pointer"
                >
                  <Chat
                    fill={pathname === "/question_list" || pathname.includes("question_chat") ? "#0E6DFF" : "#818792"}
                    style={{ margin: "0 2px 0 0" }}
                    width="16px"
                    height="16px"
                  />
                  <Text ws="pre" size="14px" color={pathname === "/question_list" || pathname.includes("question_chat") ? "#0E6DFF" : "#818792"} weight="600">
                    세모스 챗
                  </Text>
                  {newChat > 0 && <AbsoluteWrapper radius="2px" bgc="#3383FF" width="4px" height="4px" top="1px" right="-6px"></AbsoluteWrapper>}
                </Wrapper>
                {/* 세모스 챗 알림 */}
                {newChat > 0 && (
                  <AbsoluteWrapper z={20} right="159px" bgc="#E5F0FF" radius="9px" top="57px" padding="12px 21px" ai="center">
                    <Caution width="20px" height="24px" fill="#0E6DFF" />
                    <Text margin="0 0 0 6px" ws="pre" size="12px" color="#3383FF" weight="500">
                      파트너님이 새로운 메시지를 보냈어요
                    </Text>
                    <Triangle />
                  </AbsoluteWrapper>
                )}

                <Wrapper cursor="pointer" onClick={() => setMSDropdown(!MSDropdown)}>
                  <MySemos
                    fill={pathname === "/my_semos" || pathname === "/wish_list" || pathname === "/activity_history" ? "#0E6DFF" : "#818792"}
                    style={{ margin: "0 2px 0 0" }}
                    width="16px"
                    height="16px"
                  />
                  <Text
                    ws="pre"
                    size="14px"
                    color={pathname === "/my_semos" || pathname === "/wish_list" || pathname === "/activity_history" ? "#0E6DFF" : "#818792"}
                    weight="600"
                  >
                    마이 세모스
                  </Text>
                  {MSDropdown && (
                    <AbsoluteWrapper ref={MySemosRef} left="-12px" top="28px">
                      <Wrapper bgc="#ffffff" border="1px solid #dcdcdc" radius="8px" fd="column">
                        <Wrapper
                          hvBgc="#F2F7FF"
                          atBgc="#E5F0FF"
                          radius="8px 8px 0 0"
                          // bgc={pathname === "/my_semos" && "#E5F0FF"}
                          onClick={() => detectMySemos("/my_semos")}
                          padding="0 0 0 12px"
                          width="120px"
                          height="44px"
                          style={{ borderBottom: "1px solid #dcdcdc" }}
                        >
                          <Text ws="pre" size="14px" color={"#818792"} weight="500">
                            예약 내역
                          </Text>
                        </Wrapper>
                        <Wrapper
                          hvBgc="#F2F7FF"
                          atBgc="#E5F0FF"
                          onClick={() => detectMySemos("/wish_list")}
                          padding="0 0 0 12px"
                          width="120px"
                          height="44px"
                          style={{ borderBottom: "1px solid #dcdcdc" }}
                        >
                          <Text ws="pre" size="14px" color={"#818792"} weight="500">
                            위시 리스트
                          </Text>
                        </Wrapper>
                        <Wrapper
                          hvBgc="#F2F7FF"
                          atBgc="#E5F0FF"
                          radius="0 0 8px 8px"
                          onClick={() => detectMySemos("/activity_history")}
                          padding="0 0 0 12px"
                          width="120px"
                          height="44px"
                        >
                          <Text ws="pre" size="14px" color={"#818792"} weight="500">
                            최근 본 항목
                          </Text>
                        </Wrapper>
                      </Wrapper>
                    </AbsoluteWrapper>
                  )}
                </Wrapper>

                <Wrapper cursor="pointer" onClick={() => setOpen(!open)}>
                  <Profile fill={open ? "#0E6DFF" : "#818792"} style={{ margin: "0 2px 0 0" }} width="16px" height="16px" />
                  <Text ws="pre" size="14px" color={open ? "#0E6DFF" : "#818792"} weight="600">
                    프로필
                  </Text>
                </Wrapper>
              </WrapperRow>
            ) : (
              <WrapperRow margin="0 0 0 110px" gap="18px" jc="center" ai="center">
                <Wrapper
                  cursor="pointer"
                  onClick={() => {
                    setSignInModal(!signInModal);
                  }}
                >
                  <Text ws="pre" size="14px" color="#818792" weight="600">
                    세모스 다운받기
                  </Text>
                </Wrapper>
              </WrapperRow>
            )}
          </WrapperRow>
        </Container>
        {!info && (
          <DrawerModal right={right} setOpen={setOpen} open={open}>
            {modalStatus == "profile" && <NewProfile open={open} setOpen={setOpen} setModalStatus={setModalStatus} />}
            {modalStatus == "revise" && (
              <ProfileRevise
                modalStatus={modalStatus}
                setModalStatus={setModalStatus}
                setOpen={setOpen}
                open={open}
                setLogout={setLogout}
                setRetire={setRetire}
                setComplete={setReviseComplete}
                setQuit={setReviseQuit}
                userSpecChange={userSpecChange}
                setUserSpecChange={setUserSpecChange}
              />
            )}
            {modalStatus == "userSpec" && <ProfileUserSpec setModalStatus={setModalStatus} setOpen={setOpen} />}

            {modalStatus == "point" && <NewPoint setModalStatus={setModalStatus} setOpen={setOpen} />}
            {modalStatus == "announce" && <NewAnnounce setModalStatus={setModalStatus} setOpen={setOpen} setAnnounce={setAnnounce} />}
            {modalStatus == "announce_detail" && <NewAnnounceDetail id={announce} setModalStatus={setModalStatus} setOpen={setOpen} />}
            {modalStatus == "faq" && <NewFaq setModalStatus={setModalStatus} setOpen={setOpen} />}
            {modalStatus == "personal_info" && <SemosUserInfoProcess setModalStatus={setModalStatus} setOpen={setOpen} />}
            {modalStatus == "use_info" && <UseInfo setModalStatus={setModalStatus} setOpen={setOpen} />}
          </DrawerModal>
        )}
      </LayoutContainer>

      {logout ? <LogoutModal status={logout} setStatus={setLogout} setConfirm={setLogoutConfirm} /> : ""}

      {logoutConfirm ? <LogoutConfirm status={logoutConfirm} setStatus={setLogoutConfirm} /> : ""}

      {retire ? <RetireModal status={retire} setStatus={setRetire} setConfirm={setRetireConfirm} /> : ""}

      {retireConfirm ? <RetireConfirm status={retireConfirm} setStatus={setRetireConfirm} /> : ""}

      {reviseQuit ? <ProfileReviseConfirm status={reviseQuit} setStatus={setReviseQuit} setModalStatus={setModalStatus} /> : ""}

      {reviseComplete ? <ProfileReviseComplete status={reviseComplete} setStatus={setReviseComplete} setModalStatus={setModalStatus} /> : ""}

      <AppModal open={signInModal} setOpen={setSignInModal} Mobile={MobileCheck} page={page} />

      {/*MobileCheck ? <MobileCheckModal status={logout} setStatus={setLogout} setConfirm={setLogoutConfirm} /> : ""*/}

      {/*signInModal ? <SignInModal status={signInModal} setStatus={setSignInModal} dup={ALD} setDup={setALD} search={location.search} /> : ""*/}

    </>
  );
};

export default Header;

const LayoutContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #dcdcdc;
  align-items: flex-start;
  
`;

const Container = styled.div`
  height: 76px;
  background-color: #ffffff;
  /* width: 860px; */
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 20;
  position: fixed;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* position: fixed;
  top: 0; */

  @media screen and (max-width: 859px) {
    padding : 0 24px;
  }
`;
const SubWrap = styled.div`
  width: 860px;
`;

const SearchItem = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 500;
  line-height: 100% !important;
  align-items: center;
  border-radius: 24px;
  padding: 6px 12px;
  border: 1.2px solid #dcdcdc;
  background-color: #f9f9f9;
  white-space: nowrap;
  margin-right: 12px;
  cursor: pointer;
`;

const XButton = styled.img`
  width: 12px;
  height: 18px;
  margin-left: 4px;
`;

const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-bottom: 15px solid #e5f0ff;
  border-left: 8.5px solid transparent;
  border-right: 8.5px solid transparent;
  position: absolute;
  top: -14px;
  right: 13px;
  /* transform: rotate(-180deg); */
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
`;
