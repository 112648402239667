import styled, { keyframes } from "styled-components";

export const SkeletonElement = ({ width, height, margin }) => {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Box width={width}>
          <PlaceHolder height={height} margin={margin} />
        </Box>
        {/* <Skeleton width={width} height={height} margin={margin} /> */}
      </div>
    </>
  );
};

const Box = styled.div`
  box-sizing: border-box;
  width: ${({ width }) => `${width}`};
`;

const animation = keyframes`
    0% {
      background-position: 0px;
    }
    40%,
    100% {
      background-position: 180px;
    }
`;

const PlaceHolder = styled.div`
  box-sizing: border-box;
  background: linear-gradient(90deg, #f9f9f9 0px, #ffffff 40px, #f9f9f9 80px);
  background-size: 250px;
  width: 100%;
  height: ${({ height }) => `${height}`};
  border-radius: 4px;
  margin: ${({ margin }) => `${margin}`};
  animation: ${animation} 2s infinite;
`;
