import React, { useState, useRef } from "react";
import styled from "styled-components";
import axios from "axios";

//icon
import Review_1 from "../img/review/review_1.png";
import Review_2 from "../img/review/review_2.png";
import Review_3 from "../img/review/review_3.png";
import Review_4 from "../img/review/review_4.png";
import { ReactComponent as RightArrowIcon } from "../icon/carouselArrowRight.svg";
import { ReactComponent as LeftArrowIcon } from "../icon/carouselArrowLeft.svg";
import { Link } from "react-router-dom";

import { Wrapper, Text, WrapperRow } from "./layout/layoutStorage";

import Slider from "react-slick";

const ReviewCard = ({ cardTitle, list }) => {
  const [nowSlide, setNowSlide] = useState(0);

  const settings = {
    className: "slider variable-width",
    dots: false,
    arrows: false,
    draggable: false,
    infinite: false,
    // centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };
  const slickRef = useRef();

  const history = (id) => {
    var token = localStorage.getItem("token");

    if (token) {
      const frm = new FormData();

      frm.append("token", token);
      frm.append("mode", "view_history");
      frm.append("activity_id", id);
      frm.append("page", window.location.pathname.includes("history") ? "history" : "");

      axios
        .post("https://ai.semos.kr/semos_user/v3/wish_list/wish", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((res) => {
          var status = res.data.status;
          console.log("???");
          if (status === "success") {
          } else {
            window.alert(res.data.status_message);
          }
        });
    }
  };

  return (
    <>
      <Container>
        <WrapperRow width="100%" jc="space-between" ai="center">
          <Text margin="24px 0 25.8px 0" size="18px" weight="600" color="#1A1C1F">
            {cardTitle}
          </Text>
          <Wrapper>
            <Wrapper cursor="pointer" onClick={() => slickRef.current.slickPrev()} margin="0 18px 0 0">
              <LeftArrowIcon fill={nowSlide === 0 ? "#dcdcdc" : "#1A1C1F"} />
            </Wrapper>
            <Wrapper
              cursor="pointer"
              onClick={() => {
                if (nowSlide === 2) {
                  return;
                } else {
                  slickRef.current.slickNext();
                }
              }}
            >
              <RightArrowIcon fill={nowSlide === 2 ? "#dcdcdc" : "#1A1C1F"} />
            </Wrapper>
          </Wrapper>
        </WrapperRow>
        <Slider
          beforeChange={(index, next) => {
            setNowSlide(next);
          }}
          ref={slickRef}
          {...settings}
        >
          <Wrapper margin="0 20px 0 0">
            <Wrapper onClick={() => history(863)}>
              <Link to={`/activity/863`} target="_blank">
                <ReviewImage src={Review_1} />
              </Link>
            </Wrapper>
          </Wrapper>

          <Wrapper margin="0 20px 0 0">
            <Wrapper onClick={() => history(863)}>
              <Link to={`/activity/863`} target="_blank">
                <ReviewImage src={Review_2} />
              </Link>
            </Wrapper>
          </Wrapper>

          <Wrapper margin="0 20px 0 0">
            <Wrapper onClick={() => history(863)}>
              <Link to={`/activity/863`} target="_blank">
                <ReviewImage src={Review_4} />
              </Link>
            </Wrapper>
          </Wrapper>
        </Slider>
      </Container>
    </>
  );
};

export default ReviewCard;

const Container = styled.div`
  .slick-next::before {
    display: none;
  }
  .slick-prev::before {
    display: none;
  }
  margin-bottom: 36px;
`;

const ReviewImage = styled.img`
  width: 420px;
  height: 263px;
  object-fit: cover;
`;

const ReviewImageOld = styled.img`
  width: 276px;
  height: 158px;
  border-radius: 8px 8px 0 0;
  position: absolute;
  object-fit: cover;
  top: 0;
  z-index: -1;
`;

const CardBox = styled.div`
  width: 276px;
  height: 251px;
  position: relative;
  z-index: 0;
  padding-top: 125px;
`;

const BottomBox = styled.div`
  z-index: 99;
  height: 125px;
  width: 276px;
  padding: 12px 8px 12px 25px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 40px 0 0 0;
  background-color: #f2f7ff;
`;

const Reviewer = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: ${({ color }) => (color ? color : "#ACAFB5")};
  line-height: 142%;
  letter-spacing: -0.02em;
`;
const Review = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #818792;
`;
const Item = styled.span`
  font-weight: 600;
  line-height: 132%;
  font-size: 10px;
  letter-spacing: -0.02em;
  color: #acafb5;
`;

const LinkToDetail = styled(Link)`
  text-decoration: none !important;
`;
