import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Text, Wrapper, Icon, WrapperRow, Input, AbsoluteWrapper, WrapperColumn, Radio } from "../../component/layout/layoutStorage";

import { ReactComponent as FilterArrow } from "../../icon/filterArrow.svg";
import { ReactComponent as Xbutton } from "../../icon/modalx.svg";
import { ReactComponent as Reset } from "../../icon/modalreset.svg";
import BlueCheck from "../../icon/bluecheck.png";

const ActivityTypeList = [
  {
    text: "레슨",
    value: "lesson",
  },
  {
    text: "자격증",
    value: "license",
  },
  {
    text: "이용권",
    value: "ticket",
  },
  {
    text: "투어",
    value: "tour",
  },
];

const TypeFilter = ({ data, text, setData, filter }) => {
  const TypeTextArray = () => {
    var TypeCheck = data;
    var TypeCheckSplit = data.split(", ");

    if (TypeCheck === "액티비티") {
      var TypePopupData = {
        lesson: false,
        license: false,
        ticket: false,
        tour: false,
      };
    } else {
      if (TypeCheckSplit.length > 1) {
        var TypePopupData = {
          lesson: TypeCheckSplit.indexOf("lesson") !== -1,
          license: TypeCheckSplit.indexOf("license") !== -1,
          ticket: TypeCheckSplit.indexOf("ticket") !== -1,
          tour: TypeCheckSplit.indexOf("tour") !== -1,
        };
      } else {
        var TypePopupData = {
          lesson: TypeCheck === "lesson",
          license: TypeCheck === "license",
          ticket: TypeCheck === "ticket",
          tour: TypeCheck === "tour",
        };
      }
    }

    return TypePopupData;
  };

  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(TypeTextArray());
  const navigate = useNavigate();

  const handleClose = (e) => {
    if (open && (!ref.current || !ref.current.contains(e.target))) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [open]);

  const CategoryReset = () => {
    setType({
      lesson: false,
      license: false,
      ticket: false,
      tour: false,
    });
  };

  const setTypes = (types) => {
    setType((prev) => ({
      ...prev,
      [types]: !type[types],
    }));
  };

  const SelectAllTypes = () => {
    if (type.lesson && type.ticket && type.license && type.tour) {
      setType({
        lesson: false,
        license: false,
        ticket: false,
        tour: false,
      });
    } else {
      setType({
        lesson: true,
        license: true,
        ticket: true,
        tour: true,
      });
    }
  };

  const setting = () => {
    var typeList = Object.entries(type);

    var typeData = "";

    var typeListCount = 0;

    for (var i = 0; i < typeList.length; i++) {
      if (typeList[i][1]) {
        if (typeListCount === 0) {
          typeData = `${typeList[i][0]}`;
        } else {
          typeData = `${typeData}, ${typeList[i][0]}`;
        }

        typeListCount = typeListCount + 1;
      }
    }

    if (typeListCount === 0) {
      navigate("/activity_list", {
        state: {
          sort: filter.sort,
          sport: filter.sport ? filter.sport : "",
          where: filter.where ? filter.where : "",
          when: filter.when ? filter.when : "",
          type: "액티비티",
          duration: filter.duration ? filter.duration : "",
          price: filter.price ? filter.price : "",
          facility: filter.facility ? filter.facility : "",
          search: filter.search ? filter.search : "",
          semos: filter.semos,
          near: false,
          full: filter.full,
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 2);
    } else {
      navigate("/activity_list", {
        state: {
          sort: filter.sort,
          sport: filter.sport ? filter.sport : "",
          where: filter.where ? filter.where : "",
          when: filter.when ? filter.when : "",
          type: typeData,
          duration: filter.duration ? filter.duration : "",
          price: filter.price ? filter.price : "",
          facility: filter.facility ? filter.facility : "",
          search: filter.search ? filter.search : "",
          semos: filter.semos,
          near: false,
          full: filter.full,
        },
      });
    }

    setData((prev) => ({
      ...prev,
      type: typeData,
    }));

    setOpen(false);
  };

  return (
    <Wrapper margin="0 8px 8px 0" ref={ref}>
      <Wrapper
        padding="5px 11px"
        border={data != "액티비티" ? "1px solid #3383FF" : "1px solid #dcdcdc"}
        bgc={data != "액티비티" ? "#F2F7FF" : "transparent"}
        cursor="pointer"
        radius="10px"
        onClick={() => setOpen((prev) => !prev)}
      >
        <Text size="13px" weight="500" color={data != "액티비티" ? "#3383FF" : "#818792"}>
          {text}
        </Text>

        <FilterArrow
          fill={data != "액티비티" ? "#3383FF" : "#818792"}
          width="14px"
          height="18px"
          style={{
            marginLeft: "4px",
            transform: open ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "all 0.3s ease",
          }}
        />
      </Wrapper>

      {open ? (
        <AbsoluteWrapper
          padding="24px"
          top="40px"
          right="0"
          width="375px"
          height="676px"
          bgc="#ffffff"
          radius="8px"
          border="1px solid #dcdcdc"
          style={{ zIndex: 2 }}
          ai="flex-start"
        >
          <WrapperColumn width="100%" height="100%" jc="space-between">
            <WrapperColumn width="100%">
              <Wrapper jc="space-between">
                <Wrapper>
                  <Text size="18px" color="#1a1c1f" weight="600">
                    액티비티 유형 선택
                  </Text>

                  <Wrapper width="12px" />

                  <Wrapper ai="center" padding="2px 0 0 0" cursor="pointer" onClick={() => CategoryReset()}>
                    <Text size="14px" weight="600" color="#0e6dff">
                      선택 초기화
                    </Text>
                    <Wrapper width="2px" />
                    <Reset width="20px" height="20px" />
                  </Wrapper>
                </Wrapper>

                <Xbutton width="28px" height="28px" onClick={() => setOpen(false)} style={{ cursor: "pointer" }} />
              </Wrapper>

              <Wrapper height="21px" />

              <Wrapper jc="flex-end" ai="center" cursor="pointer" onClick={() => SelectAllTypes()}>
                <Text size="12px" weight="600" color={type.lesson && type.license && type.ticket && type.tour ? "#3c3e41" : "#acafb5"}>
                  전체보기
                </Text>
                <Wrapper
                  width="16px"
                  height="16px"
                  border={type.lesson && type.license && type.ticket && type.tour ? "1px solid #0e6dff" : "1px solid #dcdcdc"}
                  radius="4px"
                  margin="0 0 0 6px"
                  jc="center"
                  ai="center"
                >
                  {type.lesson && type.license && type.ticket && type.tour ? <Icon src={BlueCheck} width="90%" height="90%" /> : ""}
                </Wrapper>
              </Wrapper>

              <Wrapper height="20px" />

              <WrapperColumn>
                {ActivityTypeList.map((item, index) => (
                  <Wrapper
                    padding="13px 0"
                    jc="space-between"
                    bb={type[item.value] ? "1px solid #0e6dff" : "1px solid #f5f5f5"}
                    cursor="pointer"
                    onClick={() => setTypes(item.value)}
                    margin="0 0 12px 0"
                  >
                    <Text size="14px" weight="600" color={type[item.value] ? "#3c3e41" : "#acafb5"}>
                      {item.text}
                    </Text>

                    <Wrapper
                      width="16px"
                      height="16px"
                      border={type[item.value] ? "1px solid #0e6dff" : "1px solid #dcdcdc"}
                      radius="4px"
                      margin="0 0 0 6px"
                      jc="center"
                      ai="center"
                    >
                      {type[item.value] ? <Icon src={BlueCheck} width="90%" height="90%" /> : ""}
                    </Wrapper>
                  </Wrapper>
                ))}
              </WrapperColumn>
            </WrapperColumn>

            <Wrapper width="100%" bgc="#0e6dff" padding="16px 0" radius="8px" jc="center" margin="0 0 18px 0" cursor="pointer" onClick={() => setting()}>
              <Text size="16px" weight="600" color="#ffffff">
                선택한 유형의 액티비티 찾기
              </Text>
            </Wrapper>
          </WrapperColumn>
        </AbsoluteWrapper>
      ) : (
        ""
      )}
    </Wrapper>
  );
};

export default TypeFilter;
