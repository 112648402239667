import React, { Component } from "react";
import { Link as domLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";

// img
import NavHomeBlack from "../icon/nav_home_black.png";
import CheckBlueIcon from "../icon/check_blue_icon.svg";
import locationIcon from "../icon/fi_heart.svg";

// component
import Kakao_map_pay from "../component/kakao_map_pay";
import DeskTopHeader from "../component/desktop_header";
import DivideLine from "../component/activity/divide_line";

const withParams = (Child) => {
  return (props) => {
    const params = useParams();
    const navigate = useNavigate();
    return <Child {...props} params={params} naviGate={navigate} />;
  };
};

// css
const CrossContainer = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  display: inline-block;
  z-index: 15;
  overflow: hidden;
  overflow-y: auto;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
`;

const CrossContent = styled.section`
  width: 80rem;
  margin: 0 calc((100% - 80rem) / 2);
  padding: 5.2rem 1.8rem 8.7rem 1.8rem;
  display: inline-block;
  overflow: hidden;
  overflow-y: hidden;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
`;

const BackgroundViewWhiteHeader = styled.div`
  width: 100%;
  max-width: 80rem;
  height: 5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 50%;
  transform: translate(50%, 0);
  padding: 0 4rem;
  z-index: 15;
`;

const BackgroundViewWhiteHeaderNameContainer = styled.span`
  width: 100%;
  display: inline-flex;
  position: relative;
`;

const BackgroundViewWhiteHeaderName = styled.span`
  width: 100%;
  display: block;
  position: relative;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BackgroundViewWhiteHeaderClose = styled(domLink)`
  width: 4rem;
  height: 5rem;
  display: inline-flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
`;

const BackgroundViewWhiteHeaderCloseIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
`;

const PaymentServiceContainer = styled.div`
  width: 100%;
  display: inline-block;
  margin-bottom: 16px;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceHeader = styled.span`
  width: 100%;
  display: inline-block;
  margin-bottom: 16px;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceContent = styled.span`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceImgContainer = styled.span`
  width: 100px;
  height: 100px;
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 800px) {
    width: calc((17rem / 5) * 8);
    height: 17rem;
  }
`;

const PaymentServiceImgContent = styled.img`
  height: 140px;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, 0);

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceTextContainer = styled.span`
  width: calc(100% - 110px);
  display: inline-block;
  height: 100px;

  @media screen and (min-width: 800px) {
    width: calc(100% - (((17rem / 5) * 8) + 20px));
    height: 17rem;
  }
`;

const PaymentServiceText = styled.span`
  width: 100%;
  display: inline-block;
  margin-bottom: 5px;
  position: relative;

  @media screen and (max-width: 800px) {
  }
`;
const PaymentServiceAddressIcon = styled.img`
  height: 18px;
  position: absolute;
  top: -1px;
  @media screen and (max-width: 800px) {
  }
`;

const PaymentRegionInfo = styled.span`
  margin-right: 3px;
  padding: 5px 0;
`;

const PaymentServiceType = styled.span``;
const PaymentServiceLevel = styled.span``;

const PaymentServiceInfoText = styled.span`
  display: inline-block;
  margin-bottom: 0;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceMap = styled.div`
  width: 100%;
  display: inline-block;
  height: 20rem;
  border-radius: 8px;
  background-color: #eeeeee;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceMapIcon = styled.img`
  width: 100%;
  display: inline-block;
  width: 1.5rem;
  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceMapCopy = styled.span`
  display: inline-block;
  cursor: pointer;
  width: 6rem;
  text-align: right;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceMapText = styled.span`
  display: inline-block;
  width: calc(100% - 10rem);
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceTextBetween = styled.span`
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceTextBlock = styled.span`
  display: inline-block;
  margin-bottom: 0.5rem;
  width: 100%;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentFooterContainer = styled.div`
  display: inline-block;
  padding: 5px 1.8rem 8px 1.8rem;
  width: 100%;
  max-width: 80rem;
  position: fixed;
  bottom: 0;
  right: 50%;
  transform: translate(50%, 0);
  background-color: #ffffff;
  z-index: 1000;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentFooterButton = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #2b84ef;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  height: 50px;
  color: #ffffff;

  @media screen and (max-width: 800px) {
  }
`;

const BackgroundViewWhiteNotice = styled.section`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #00000085;
  display: inline-flex;
  align-items: flex-end;
`;

const BackgroundViewWhiteContent = styled.div`
  width: 90%;
  max-width: 38rem;
  position: absolute;
  display: inline-flex;
  flex-wrap: wrap;
  top: 50%;
  left: 50%;
  z-index: 102;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
`;

const PayPopUpContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: inline-block;
`;

const PayPopUpClose = styled.span`
  width: 100%;
  padding: 2rem 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PayPopUpContent = styled.span`
  width: 100%;
  margin-bottom: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const PayPopUpCheckIcon = styled.img`
  width: 5rem;
  display: inline-block;
`;

const PayPopUpContentText = styled.span`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const GreyBar = styled.span`
  width: 100%;
  display: inline-block;
  background-color: #8c8c8c1a;
  height: 0.8rem;
  margin-bottom: 20px;
`;

const PaymentServiceMyTextContainer = styled.span`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
  }
`;

const ServiceInfoContainer = styled.div`
  display: inline-flex;
  width: 80rem;
  flex-wrap: wrap;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const ServiceInfoContent = styled.div`
  display: inline-flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ServiceInfoContentText = styled.span`
  display: inline-block;

  @media screen and (min-width: 800px) {
  }
`;

const ServiceInfoText = styled.span`
  display: inline-block;
  width: 100%;
  @media screen and (min-width: 800px) {
  }
`;

const ServiceInfoHeader = styled.span`
  display: inline-flex;
  width: 100%;
  margin-bottom: 16px;

  @media screen and (min-width: 800px) {
  }
`;
const UserSpecInfo = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  /* border: rgba(132, 140, 148, 0.3) 1px solid; */

  /* div {
    display: inline-flex;
    width: 100%;
    border-bottom: rgba(132, 140, 148, 0.3) 1px solid;

    span {
      width: 25%;
      display: inline-flex;
      border-right: rgba(132, 140, 148, 0.3) 1px solid;
      padding: 5px 3px;
      justify-content: center;
    }

    input {
      width: 25%;
      display: inline-flex;
      border: none;
      border-right: rgba(132, 140, 148, 0.3) 1px solid;
      padding: 4px;
      caret-color: #666d75 !important;
      text-align: center;
    }
    input:placeholder {
      color: #666d75 !important;
    }
    input:-webkit-input-placeholder {
      color: #666d75 !important;
    }
    input:-ms-input-placeholder {
      color: #666d75 !important;
    }
  } */
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.idx === props.length - 1 ? "0" : "30px")};
  padding: 0 10px;
`;

const Info = styled.div`
  display: flex;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: black;
`;

const SectionName = styled.div`
  width: 90%;
  margin-bottom: 10px;
`;

const Input = styled.div`
  width: 90%;
  height: 40px;
  border: 0.5px solid lightgrey;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// facebook
const { fbq } = window;

const firebaseConfig = {
  apiKey: "AIzaSyCn7j7R8FUZKmVKVniI6AGGPFXRz3ncPH4",
  authDomain: "report-semos.firebaseapp.com",
  projectId: "report-semos",
  storageBucket: "report-semos.appspot.com",
  messagingSenderId: "731129506416",
  appId: "1:731129506416:web:9d183c6605ab82849d22b5",
  measurementId: "G-97DY4WG77T"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class Pay_check_success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pay_info: "",
      mobileCheck:
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ),
      payPopup: "show",
      confirmPopup: "hide",
      confirmType: "",
      confirmFunction: "",
      confirmLink: "",
      confirmText: "",
      confirmFunctionNo: "",
      userSpec: [],
    };
  }

  componentDidMount() {
    const current = decodeURI(window.location.href);
    const search = current.split("?")[1];
    const params = new URLSearchParams(search);
    const pg_token = params.get("pg_token");

    if (this.props.params.key4 == "toss") {
      // var link_3 = 'https://ai.semos.kr/payment/toss_payment_api?oid=' + pg_token + '&ut=' + user_token_toss + '&mode=toss_success';

      var user_token_toss = window.localStorage.getItem("token");

      const frm = new FormData();

      frm.append("ut", user_token_toss);
      frm.append("mode", "toss_success");
      frm.append("oid", pg_token);

      axios
        .post("https://ai.semos.kr/semos_user/service/toss_payment", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((result) => {

          this.setState({
            pay_info: result.data.pay_result,
          })

          fbq("track", "Purchase", {
            content_category : result.data.pay_result.service_category,
            content_name : result.data.pay_result.service_name,
            content_ids : `${result.data.pay_result.partner_number}_${result.data.pay_result.service_number}`,
            currency : "KRW",
            value : result.data.pay_result.pay_amount_value
          });


          logEvent(analytics, 'purchase', {
            currency : "KRW",
            value : result.data.pay_result.pay_amount_value,
            transaction_id : result.data.pay_result.payment_id,
            items: [{ 
              item_id: `${result.data.pay_result.partner_number}_${result.data.pay_result.service_number}`,
              item_name: result.data.pay_result.service_name
            }]
          });


          //var status = result.data.pay_status ? result.data.pay_status : "waring";
          /*if (status == "success") { 
            this.setState({
              pay_info: result.data.pay_result,
            })

            fbq("track", "Purchase", {
              content_category : result.data.pay_result.service_category,
              content_name : result.data.pay_result.service_name,
              content_ids : `${result.data.pay_result.partner_number}_${result.data.pay_result.service_number}`,
              currency : "KRW",
              value : result.data.pay_result.pay_amount_value
            });
          } else {
            alert("결제가 실패했습니다. 결제를 다시 시도해주세요");
            window.location.replace("/home");
          }*/

        }

        );
    } else {
      // var link_3 = 'https://ai.semos.kr/payment/kakao_payment_api?pg_token=' + pg_token + '&pay_num=' + this.props.match.params.key4 + '&mode=kakao_success';
      const frm = new FormData();

      frm.append("ut", window.localStorage.getItem("token"));
      frm.append("mode", "kakao_success");
      frm.append("oid", this.props.params.key1);
      frm.append("pay_num", this.props.params.key4);

      axios
        .post("https://ai.semos.kr/semos_user/service/kakao_new", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var status = data.data.status;

          if (status == "success") {
            this.setState({
              pay_info: data.data.pay_result,
            });

            fbq("track", "Purchase", {
              content_category : data.data.pay_result.service_category,
              content_name : data.data.pay_result.service_name,
              content_ids : `${data.data.pay_result.partner_number}_${data.data.pay_result.service_number}`,
              currency : "KRW",
              value : data.data.pay_result.pay_amount_value
            });

            logEvent(analytics, 'purchase', {
              currency : "KRW",
              value : data.data.pay_result.pay_amount_value,
              transaction_id : data.data.pay_result.payment_id,
              items: [{ 
                item_id: `${data.data.pay_result.partner_number}_${data.data.pay_result.service_number}`,
                item_name: data.data.pay_result.service_name
              }]
            });
          } else if (status == "waring") {
            alert(data.data.status_message);
            window.location.replace("/home");
          }
        });
    }

    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", "세모스 | 결제완료");
    document.querySelector("title").innerText = "세모스 | 결제완료";
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute(
        "content",
        "세상의 모든 스포츠, 세모스에 있다! 익스트림, 하이엔드 스포츠 레슨 전문 큐레이터 세모스에서 스쿠버다이빙, 프리다이빙, 수영, 스키, 스노보드 등 다양한 스포츠 레슨과 체계적인 운동 기록 관리까지 만나보세요."
      );
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute("content", "");
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "세상의 모든 스포츠, 세모스에 있다! 익스트림, 하이엔드 스포츠 레슨 전문 큐레이터 세모스에서 스쿠버다이빙, 프리다이빙, 수영, 스키, 스노보드 등 다양한 스포츠 레슨과 체계적인 운동 기록 관리까지 만나보세요."
      );
  }

  moreService = () => {
    window.location.replace("/product/스포츠/언제/어디서/1/all/review_all");
  };

  confirmHide = () => {
    this.setState({
      confirmPopup: "hide",
      confirmText: "",
      confirmLink: "",
      confirmType: "",
    });
  };

  popupFunction = () => {
    if (this.state.confirmLink == "replace") {
      window.location.replace("/");
    } else if (this.state.confirmLink == "reload") {
      window.location.reload();
    } else if (this.state.confirmLink == "hide") {
      this.confirmHide();
    } else if (this.state.confirmLink == "back") {
      window.history.back();
    } else {
      this.props.naviGate(this.state.confirmLink, { replace: true });
    }
  };

  render() {
    var data = this.state.pay_info;

    var service_option_list = [];

    var service_option = data.service_option;

    if (service_option) {
      for (var i = 0; i < service_option.length; i++) {
        service_option_list.push(
          <PaymentServiceContent
            key={i}
            className="color_grey weight_500 font_20 "
          >
            <PaymentServiceTextBetween>
              옵션 : {service_option[i] ? service_option[i].option_name : ""} (
              {service_option[i] ? service_option[i].option_count : 0}개)
            </PaymentServiceTextBetween>
            <PaymentServiceTextBetween>
              + {service_option[i] ? service_option[i].option_amount_text : ""}
              원
            </PaymentServiceTextBetween>
          </PaymentServiceContent>
        );
      }
    }

    var mobileHeader = [
      <BackgroundViewWhiteHeader className="font_24 bg_white weight_500 color_black">
        <BackgroundViewWhiteHeaderNameContainer>
          <BackgroundViewWhiteHeaderClose to="/">
            <BackgroundViewWhiteHeaderCloseIcon src={NavHomeBlack} />
          </BackgroundViewWhiteHeaderClose>

          <BackgroundViewWhiteHeaderName>
            {data.service_name ? data.service_name : ""}
          </BackgroundViewWhiteHeaderName>
        </BackgroundViewWhiteHeaderNameContainer>
      </BackgroundViewWhiteHeader>,
    ];

    var PayPopup = [
      <BackgroundViewWhiteNotice>
        <BackgroundViewWhiteContent>
          <PayPopUpContainer>
            <PayPopUpContent className="color_black weight_700 font_24">
              결제 완료
            </PayPopUpContent>

            <PayPopUpContent>
              <PayPopUpCheckIcon src={CheckBlueIcon} />
            </PayPopUpContent>

            <PayPopUpContent
              style={{ margin: "0", lineHeight: "1.4" }}
              className="color_black weight_400 font_20"
            >
              <PayPopUpContentText>
                강사님 확인 후 예약이 확정됩니다.
              </PayPopUpContentText>
              <PayPopUpContentText>
                예약 안내는 알림 톡을 통해 발송됩니다.
              </PayPopUpContentText>
            </PayPopUpContent>
          </PayPopUpContainer>

          <PayPopUpClose
            onClick={() => this.setState({ payPopup: "hide" })}
            className="color_white bg_blue_100 font_20 weight_400"
          >
            확인
          </PayPopUpClose>
        </BackgroundViewWhiteContent>
      </BackgroundViewWhiteNotice>,
    ];
    const address = data && data.split_address;

    return (
      <CrossContainer>
        {this.state.mobileCheck ? mobileHeader : ""}

        <CrossContent
          style={{ paddingTop: this.state.mobileCheck ? "5.2rem" : "13.3rem" }}
          className="home_container"
        >
          {this.state.mobileCheck ? "" : <DeskTopHeader />}

          <PaymentServiceContainer>
            <PaymentServiceHeader className="weight_500 font_24 color_black ">
              액티비티 정보
            </PaymentServiceHeader>

            <PaymentServiceContent>
              <PaymentServiceImgContainer>
                <PaymentServiceImgContent src={data ? data.service_img : ""} />
              </PaymentServiceImgContainer>

              <PaymentServiceTextContainer>
                <PaymentServiceText className="font_17 weight_400 color_light_black">
                  <PaymentServiceAddressIcon src={locationIcon} />
                  <PaymentRegionInfo style={{ marginLeft: "18px" }}>
                    {address.si}
                  </PaymentRegionInfo>
                  <PaymentRegionInfo>{address.gu}</PaymentRegionInfo>
                  <PaymentRegionInfo>{address.dong}</PaymentRegionInfo>
                </PaymentServiceText>

                <PaymentServiceText className="font_17 weight_400 color_black">
                  <PaymentServiceType>
                    {data.service_category}
                  </PaymentServiceType>
                  &nbsp;<span className="font_20">|</span>&nbsp;
                  <PaymentServiceLevel>{data.service_type}</PaymentServiceLevel>
                </PaymentServiceText>

                <PaymentServiceText className="font_17 weight_500 color_black">
                  {data ? data.service_name : ""}
                </PaymentServiceText>
              </PaymentServiceTextContainer>

              {/* <div
                style={{ justifyContent: "end" }}
                className="flex_show my_lesson_specific_container"
              >
                <a href={"tel:" + String(data.phone_number)}>
                                        <img src={phone_call}/>
                                    </a>
                <div>
                    <img src={move_to_review} />
                  </div>
    </div>
     */}
            </PaymentServiceContent>
          </PaymentServiceContainer>

          <PaymentServiceContainer>
            <PaymentServiceText className="font_17 weight_400 color_black">
              <span className="color_black">일정</span> :&nbsp;
              {data ? data.pay_date.split("~")[0] : ""}
            </PaymentServiceText>

            <PaymentServiceMyTextContainer>
              <PaymentServiceInfoText
                className="font_17 weight_400 color_black"
                style={{ maxWidth: "65%" }}
              >
                <span className="color_black">인원</span> :&nbsp;
                {data ? data.coaching_people : ""}인
              </PaymentServiceInfoText>
            </PaymentServiceMyTextContainer>
          </PaymentServiceContainer>

          <DivideLine />

          {data
            ? data.user_spec.length && (
                <>
                  <PaymentServiceContainer>
                    <PaymentServiceHeader className="weight_500 font_24 color_black ">
                      액티비티 이용자 정보
                    </PaymentServiceHeader>

                    <PaymentServiceMyTextContainer>
                      <UserSpecInfo>
                        {data.user_spec.map((item, idx) => (
                          <InputContainer
                            key={idx}
                            idx={idx}
                            length={data.user_spec.length}
                          >
                            <div
                              className="font_17"
                              style={{
                                marginBottom: "20px",
                                fontWeight: "500",
                              }}
                            >
                              이용자 {idx + 1}
                            </div>
                            <Info>
                              <Section>
                                <SectionName>성함</SectionName>
                                <Input>{item.name}</Input>
                              </Section>
                              <Section>
                                <SectionName>생년월일</SectionName>
                                <Input>{item.birth}</Input>
                              </Section>
                              <Section>
                                <SectionName>성별</SectionName>
                                <Input>{item.gender}</Input>
                              </Section>
                            </Info>
                            <Info>
                              <Section>
                                <SectionName>키 (cm)</SectionName>
                                <Input>{item.height}</Input>
                              </Section>
                              <Section>
                                <SectionName>체중 (kg)</SectionName>
                                <Input>{item.weight}</Input>
                              </Section>
                              <Section>
                                <SectionName>발 사이즈 (mm)</SectionName>
                                <Input>{item.foot}</Input>
                              </Section>
                            </Info>
                          </InputContainer>
                        ))}
                      </UserSpecInfo>
                    </PaymentServiceMyTextContainer>
                  </PaymentServiceContainer>

                  <DivideLine />
                </>
              )
            : ""}

          <Kakao_map_pay
            map_address_etc={data ? data.extra_address : ""}
            map_address={data ? data.target_place : ""}
          />

          <DivideLine />
          <ServiceInfoContainer style={{ marginBottom: "30px" }}>
            <ServiceInfoHeader className="font_24 weight_500">
              금액 및 할인 정보
            </ServiceInfoHeader>

            <PaymentServiceContent>
              <PaymentServiceTextBetween className="font_17 color_black">
                결제 수단
              </PaymentServiceTextBetween>
              <PaymentServiceTextBetween className="font_17 color_black">
                {data.payment_type
                  ? data.payment_type == "toss"
                    ? "토스"
                    : "카카오페이"
                  : ""}
              </PaymentServiceTextBetween>
            </PaymentServiceContent>

            <PaymentServiceContent>
              <PaymentServiceTextBetween className="font_17 weight_400 color_black">
                포인트 사용
              </PaymentServiceTextBetween>
              <PaymentServiceTextBetween className="font_17 weight_400 color_black">
                - {data ? data.point : ""}P
              </PaymentServiceTextBetween>
            </PaymentServiceContent>

            <PaymentServiceContent>
              <PaymentServiceTextBetween className="font_17 weight_400 color_black">
                결제 금액
              </PaymentServiceTextBetween>
              <PaymentServiceTextBetween className="font_20 weight_500 color_black">
                {data.pay_amount} 원
              </PaymentServiceTextBetween>
            </PaymentServiceContent>
          </ServiceInfoContainer>

          <PaymentFooterContainer>
            <PaymentFooterButton
              onClick={this.moreService}
              className="font_20 weight_500"
            >
              다른 레슨 구경하기
            </PaymentFooterButton>
          </PaymentFooterContainer>
        </CrossContent>

        {this.state.payPopup == "show" ? PayPopup : ""}
      </CrossContainer>
    );
  }
}

export default withParams(Pay_check_success);
