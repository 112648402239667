import React from "react";
import styled from "styled-components";
import {
  Wrapper,
  Text
} from "./layout/layoutStorage";
import { useNavigate } from "react-router-dom";

import { ReactComponent as RightArrowIcon } from "../icon/carouselArrowRight.svg";

const MoreItemBox = () => {
  const navigate = useNavigate();
  return (
    <>
      <Wrapper
        jc="center"
        ai="center"
        height="104px"
        padding="0 0 12px 0"
        width="100%"
      >
        <Wrapper
          cursor="pointer"
          jc="center"
          ai="center"
          radius="8px"
          width="298px"
          height="56px"
          bgc="#E5F0FF"
          hvBgc="#CCE0FF"
          onClick={() => navigate("/activity_list", { state: {} })}
        >
          <Text size="14px" weight="600" color="#0E6DFF">
            전체 액티비티 보러가기
          </Text>
          <RightArrowIcon width="20px" height="20px" fill="#0E6DFF" />
        </Wrapper>
      </Wrapper>
    </>
  );
};

export default MoreItemBox;

const Container = styled.div``;
