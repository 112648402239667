import React, { useState, useEffect } from "react";
import axios from "axios";

// components
import Layout from "../component/layout/layout";
import Category from "../component/category";
import Card from "../component/Card";
import PartnerCard from "../component/PartnerCard";
import MapCard from "../component/mapCard";
import ReviewCard from "../component/reviewCard";
import MoreItemBox from "../component/moreItemBox";
import { LoadingSpinner } from "../tool/loading";
import AppModal from "../component/home/appModal";
import MataTagChange from "../tool/MetaTag";

import semosLogo2 from "../icon/semos_logo2.png";

const NewHome = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState("");
  const [App, setApp] = useState(false);

  const Mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

  useEffect(() => {
    setLoading(true);

    const frm = new FormData();

    frm.append('mode', 'home');
    frm.append('platform', 'WEB');

    axios.post("https://ai.semos.kr/semos_user/v4/profile/Home", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
    }).then((res) => {
        var Value = res.data;
        setList(Value);
        setLoading(false);

        window.document
        .querySelector('meta[property="og:title"]')
        .setAttribute("content", "세모스 : 1등 스포츠 예약 플랫폼");
        window.document.querySelector("title").innerText =
          "세모스 : 1등 스포츠 예약 플랫폼";
        window.document
          .querySelector('meta[property="og:description"]')
          .setAttribute(
            "content",
            "스포츠에 도전하기 망설여진다면, 세모스에서 함께할 파트너를 찾아보세요. 세모스에서 스포츠 레슨, 자격증, 이용권을 찾고 상담부터 예약까지 한번에! 1등 스포츠 예약 플랫폼 세모스!"
          );
        window.document
          .querySelector('meta[property="og:image"]')
          .setAttribute("content", semosLogo2);
        window.document
          .querySelector('meta[name="description"]')
          .setAttribute(
            "content",
            "스포츠에 도전하기 망설여진다면, 세모스에서 함께할 파트너를 찾아보세요. 세모스에서 스포츠 레슨, 자격증, 이용권을 찾고 상담부터 예약까지 한번에! 1등 스포츠 예약 플랫폼 세모스!"
          );
      });

    if(Mobile) {
      setApp(true)
    }

    MataTagChange({
      title : ``,
      description : ``,
      imageUrl : ``
    });

    var token = localStorage.getItem("token");
    var tokenReset = localStorage.getItem("tokenReset");

    if(token && (tokenReset != 'yes')) {
      localStorage.setItem("token", '');
      localStorage.setItem("tokenReset", 'yes');
    }
    
  }, []);

  return (
    <>
      <AppModal open={App} setOpen={setApp} Mobile={Mobile} />
      <Layout>
        {list ? (
          <>
            {/* 액티비티 카테고리 */}
            <Category />

            {list.pop_snow_data?.status ? (
              <Card
                cardTitle={list.pop_snow_data?.title}
                cardImage={list.pop_snow_data?.image}
                cardText={list.pop_snow_data?.text}
                params={list.pop_snow_data?.params}
                list={list.pop_snow_list}
                category={"pop_snow_data"}
              />
            ) : (
              ""
            )}

            {list.pop_partner_data?.status ? (
              <PartnerCard
                cardTitle={list.pop_partner_data?.title}
                cardLocation={list.pop_partner_data?.location}
                list={list.pop_partner_list}
                category={"pop_partner_data"}
              />
            ) : (
              ""
            )}

            {list.top_data?.status ? (
              <Card
                cardTitle={list.top_data?.title}
                cardImage={list.top_data?.image}
                cardText={list.top_data?.text}
                params={list.top_data?.params}
                list={list.top_list}
                category={"top_data"}
              />
            ) : (
              ""
            )}

            {/* 생생한 액티비티 리뷰 */}
            <ReviewCard
              cardTitle={list.reviews_list?.text}
              list={list.review_list}
            />
            
            {/* 지역 선택 card */}
            <MapCard />

            {list.pop_scuba_data?.status ? (
              <Card
                cardTitle={list.pop_scuba_data?.title}
                cardImage={list.pop_scuba_data?.image}
                cardText={list.pop_scuba_data?.text}
                params={list.pop_scuba_data?.params}
                list={list.pop_scuba_list}
                category={"pop_scuba_data"}
              />
            ) : (
              ""
            )}

            {list.pop_etc_data?.status ? (
              <Card
                cardTitle={list.pop_etc_data?.title}
                cardImage={list.pop_etc_data?.image}
                cardText={list.pop_etc_data?.text}
                params={list.pop_etc_data?.params}
                list={list.pop_etc_list}
                category={"pop_etc_data"}
              />
            ) : (
              ""
            )}

            {/* 전체 액티비티 보러가기 */}
            <MoreItemBox />
          </>
        ) : (
          ""
        )}

        <LoadingSpinner loading={loading} />
      </Layout>
    </>
  );
};

export default NewHome;
