import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import axios from "axios";

// componetns

// icon
import SpoexBackX from "../../icon/spoex/spoexBackX.png";
import SpoexSearch from "../../icon/spoex/spoexSearch.png";

const Sports = ['프리다이빙', '스쿠버다이빙', '스키', '스노보드', '패들보드', '서핑', '수상레저', '클라이밍', '수영', '테니스'];

const SiGunGu = {
    "서울" : [
        "강남구", 
        "강동구", 
        "강북구", 
        "강서구", 
        "관악구", 
        "광진구", 
        "구로구", 
        "금천구", 
        "노원구", 
        "도봉구", 
        "동대문구", 
        "동작구", 
        "마포구", 
        "서대문구", 
        "서초구", 
        "성동구", 
        "성북구", 
        "송파구", 
        "양천구", 
        "영등포구", 
        "용산구", 
        "은평구", 
        "종로구", 
        "중구", 
        "중랑구"
    ],
    "광주광역시" : [
        "광산구", 
        "남구", 
        "동구", 
        "북구", 
        "서구", 
    ],
    "대구광역시" : [
        "남구", 
        "달서구", 
        "동구", 
        "북구", 
        "서구", 
        "수성구", 
        "중구", 
        "군위군", 
        "달성군", 
    ],
    "대전광역시" : [
        "대덕구", 
        "동구", 
        "서구", 
        "유성구", 
        "중구"
    ],
    "부산광역시" : [
        "강서구", 
        "금정구", 
        "남구", 
        "동구", 
        "동래구", 
        "부산진구", 
        "북구", 
        "사상구", 
        "사하구", 
        "서구", 
        "수영구", 
        "연제구", 
        "영도구", 
        "중구", 
        "해운대구", 
        "기장군"
    ],
    "울산광역시" : [
        "남구", 
        "동구", 
        "북구", 
        "중구", 
        "울주군", 
    ],
    "인천광역시" : [
        "계양구", 
        "남동구", 
        "동구", 
        "미추홀구", 
        "부평구", 
        "서구", 
        "연수구", 
        "중구", 
        "강화군", 
        "옹진군", 
    ],
    "강원특별자치도" : [
        "강릉시",
        "동해시",
        "삼척시",
        "속초시",
        "원주시",
        "춘천시",
        "태백시",
        "고성군",
        "양구군",
        "양양군",
        "인제군",
        "정선군",
        "철원군",
        "평창군",
        "홍천군",
        "화천군",
        "횡성군",
    ],
    "경기도" : [
        "고양시",
        "과천시",
        "광명시",
        "광주시",
        "구리시",
        "군포시",
        "김포시",
        "남양주시",
        "동두천시",
        "부천시",
        "성남시",
        "수원시",
        "시흥시",
        "안산시",
        "안성시",
        "안양시",
        "양주시",
        "여주시",
        "오산시",
        "용인시",
        "의왕시",
        "의정부시",
        "이천시",
        "파주시",
        "평택시",
        "포천시",
        "하남시",
        "화성시",
        "가평군",
        "양평군",
        "연천군",
    ],
    "경상남도" : [
        "거제시",
        "김해시",
        "밀양시",
        "사천시",
        "양산시",
        "진주시",
        "창원시",
        "통영시",
        "거창군",
        "고성군",
        "남해군",
        "산청군",
        "의령군",
        "창녕군",
        "하동군",
        "함안군",
        "함양군",
        "합천군"
    ],
    "경상북도" : [
        "고령군",
        "봉화군",
        "성주군",
        "영덕군",
        "영양군",
        "예천군",
        "을릉군",
        "울진군",
        "의성군",
        "청도군",
        "청송군",
        "칠곡군",
        "경산시",
        "경주시",
        "구미시",
        "김천시",
        "문경시",
        "상주시",
        "안동시",
        "영주시",
        "영천시",
        "포항시"
    ],
    "전라남도" : [
        "강진군",
        "고흥군",
        "곡성군",
        "구례군",
        "담양군",
        "무안군",
        "보성군",
        "신안군",
        "영광군",
        "영암군",
        "완도군",
        "장성군",
        "진도군",
        "함평군",
        "해남군",
        "화순군",
        "광양시",
        "나주시",
        "목포시",
        "순천시",
        "여수시"
    ],
    "전라북도" : [
        "고창군",
        "무주군",
        "부안군",
        "순창군",
        "완주군",
        "임실군",
        "장수군",
        "진안군",
        "영광군",
        "군산시",
        "김제시",
        "남원시",
        "익산시",
        "전주시",
        "정읍시"
    ],
    "충청남도" : [
        "금산군",
        "부여군",
        "서천군",
        "예산군",
        "청양군",
        "태안군",
        "홍성군",
        "계룡시",
        "공주시",
        "논산시",
        "당진시",
        "보령시",
        "서산시",
        "아산시",
        "천안시"
    ],
    "충청북도" : [
        "괴산군",
        "단양군",
        "보은군",
        "영동군",
        "옥천군",
        "음성군",
        "증평군",
        "진천군",
        "제천시",
        "청주시",
        "충주시"
    ],
}

const KakaoMapSearch = ({ setOpen, setSelected }) => {
  // useState
  const [text, setText] = useState("");
  const [location, setLocation] = useState([]);
  const [KakaoMap, setKakaoMap] = useState();
  const [KakaoSearch, setKakaoSearch] = useState();
  const [KakaoInfoWindow, setKakaoInfoWindow] = useState();
  const [Markers, setMarkers] = useState([]);
  const [MapOpen, setMapOpen] = useState(1);

  const SearchLocation = (S, C, Sports) => {
    const { kakao } = window;
    // 키워드 검색을 요청하는 함수입니다

    // 지도 위에 표시되고 있는 마커를 모두 제거합니다
    function removeMarker() {
      for (var i = 0; i < Markers.length; i++) {
        Markers[i].setMap(null);
      }
      setMarkers([]);
    }

    // 인포윈도우에 장소명을 표시합니다
    function displayInfowindow(marker, title) {
      var content = '<div style="padding:5px;z-index:1;">' + title + "</div>";

      KakaoInfoWindow.setContent(content);
      KakaoInfoWindow.open(KakaoMap, marker);
    }

    // 마커를 생성하고 지도 위에 마커를 표시하는 함수입니다
    function addMarker(position, idx, title) {
      var imageSrc =
          "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_number_blue.png", // 마커 이미지 url, 스프라이트 이미지를 씁니다
        imageSize = new kakao.maps.Size(36, 37), // 마커 이미지의 크기
        imgOptions = {
          spriteSize: new kakao.maps.Size(36, 691), // 스프라이트 이미지의 크기
          spriteOrigin: new kakao.maps.Point(0, idx * 46 + 10), // 스프라이트 이미지 중 사용할 영역의 좌상단 좌표
          offset: new kakao.maps.Point(13, 37), // 마커 좌표에 일치시킬 이미지 내에서의 좌표
        },
        markerImage = new kakao.maps.MarkerImage(
          imageSrc,
          imageSize,
          imgOptions
        ),
        marker = new kakao.maps.Marker({
          position: position, // 마커의 위치
          image: markerImage,
        });

      marker.setMap(KakaoMap); // 지도 위에 마커를 표출합니다
      setMarkers([marker, ...Markers]);

      return marker;
    }

    // 검색 결과 목록과 마커를 표출하는 함수입니다
    const displayPlaces = (places) => {
      var bounds = new kakao.maps.LatLngBounds();

      // 지도에 표시되고 있는 마커를 제거합니다
      removeMarker();

      for (let i = 0; i < places.length; i++) {
        // 마커를 생성하고 지도에 표시합니다
        var placePosition = new kakao.maps.LatLng(places[i].y, places[i].x),
          marker = addMarker(placePosition, i);

        // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
        // LatLngBounds 객체에 좌표를 추가합니다
        bounds.extend(placePosition);

        // 마커와 검색결과 항목에 mouseover 했을때
        // 해당 장소에 인포윈도우에 장소명을 표시합니다
        // mouseout 했을 때는 인포윈도우를 닫습니다
        (function (marker, title) {
          kakao.maps.event.addListener(marker, "mouseover", function () {
            displayInfowindow(marker, title);
          });

          kakao.maps.event.addListener(marker, "mouseout", function () {
            KakaoInfoWindow.close();
          });
        })(marker, places[i].place_name);
      }

      // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다
      KakaoMap.setBounds(bounds);
    };

    // 장소검색이 완료됐을 때 호출되는 콜백함수 입니다
    const placesSearchCB = (data, status, pagination) => {
      if (status === kakao.maps.services.Status.OK) {
        displayPlaces(data);
        setLocation(data);
        SendAddredd(data, S, C, Sports);
      } else if (status === kakao.maps.services.Status.ZERO_RESULT) {
        console.log("검색한 장소와 일치하는 장소가 없어요.");
        return;
      } else if (status === kakao.maps.services.Status.ERROR) {
        console.log("인터넷 연결 상태가 불안정해요.\n검색 결과 중 오류가 발생했습니다.");
        return;
      }
    };

    var keyword = `${S} ${C} ${Sports}`;
    setText(keyword)

    if (!keyword.replace(/^\s+|\s+$/g, "")) {
      return false;
    }

    // 장소검색 객체를 통해 키워드로 장소검색을 요청합니다
    KakaoSearch.keywordSearch(keyword, placesSearchCB);
  };

  const LoadMap = async () => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://dapi.kakao.com/v2/maps/sdk.js?appkey=4233c0cfe93da40e79ffcd370034aa14&autoload=false&libraries=services";

    document.head.appendChild(script);

    script.onload = async () => {
      const { kakao } = window;

      kakao.maps.load(() => {
        var mapContainer = document.getElementById("map"), // 지도를 표시할 div
          mapOption = {
            center: new kakao.maps.LatLng(37.566826, 126.9786567), // 지도의 중심좌표
            level: 3, // 지도의 확대 레벨
          };

        // 지도를 생성합니다
        var map = new kakao.maps.Map(mapContainer, mapOption);
        // 장소 검색 객체를 생성합니다
        var ps = new kakao.maps.services.Places();
        // 검색 결과 목록이나 마커를 클릭했을 때 장소명을 표출할 인포윈도우를 생성합니다
        var infowindow = new kakao.maps.InfoWindow({ zIndex: 1 });

        setKakaoMap(map);
        setKakaoSearch(ps);
        setKakaoInfoWindow(infowindow);
      });
    };
  };

  const EnterKey = (KeyCode) => {
    if (KeyCode == 13) {
      SearchLocation();
    }
  };

  const SelectLocation = (item) => {
    const roadAddress = item.road_address_name;
    const buildingName = item.place_name;
    const x = item.x;
    const y = item.y;
    setSelected((prev) => ({
      ...prev,
      address: roadAddress,
      location: buildingName,
      x: x,
      y: y,
    }));

    setOpen(0);
  };

  useEffect(() => {
    LoadMap();
  }, []);

  const SearchAddress = async () => {

    let Keys = Object.keys(SiGunGu);
    let KeyCount = Keys.length;

    const sleep = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms))
    }

    for(let i=0; i < KeyCount; i++) {
      
      let ThisKeys = Keys[i];
      let ThisData = SiGunGu[ThisKeys];

      for(let i2=0; i2 < ThisData.length; i2++) {
        let ThisKeys2 = ThisData[i2];

        for(let i3=0; i3 < Sports.length; i3++) {
          let ThisKeys3 = Sports[i3];

          SearchLocation(ThisKeys, ThisKeys2, ThisKeys3);

          await sleep(1500);
        }
      }
    }
    console.log('End Thank You !!');
  }


  const SendAddredd = (list, state, city, sports) => {

    axios
      .post(
        "https://ai.semos.kr/semos_user/v4/activity/Partner",
        {
          mode : "sports_place",
          data : list,
          state : state,
          city : city,
          sports : sports,
        },
        {
          headers: { "Content-Type" : "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        const Value = data.data;
        const Status = Value.status;
        const SuccessCount = Value.success_count;
        const StatusMessage = Value.status_message;

        if (Status == "success") {

          console.log(`${state} ${city} ${sports}의 장소 : ${SuccessCount}개 추가`);

        } else {

        }

      }).catch(error => {
        if (error.response) {
          window.alert(error.response.status)
          // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
          console.log('Error', error.message);
        }
        console.log(error.config);
    });

  }

  return (
    <Container>
      <Body className="spoex">
        <SearchBox>
          <Header>
            <img onClick={() => setOpen(0)} src={SpoexBackX} />
            <span>WORKOUT 장소 검색</span>
          </Header>
          <LocationSearch>
            <input
              placeholder="장소 이름이나 주소를 입력해주세요."
              onKeyUp={(e) => EnterKey(e.keyCode)}
              maxLength={30}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
                setMapOpen(0);
              }}
            />
            <img src={SpoexSearch} onClick={() => SearchLocation()} />
          </LocationSearch>
        </SearchBox>
        {
          // MapOpen == 0 &&
          <Absolute>
            {location.map((item, idx) => (
              <LocationCard
                onClick={() => SelectLocation(item)}
                key={`${idx}_LoCa`}
              >
                <div className="Title">
                  {item.place_name}
                  <span>{` ${item.category_group_name}`}</span>
                </div>

                <div className="Address">
                  {item.road_address_name
                    ? item.road_address_name
                    : item.address_name}
                </div>
              </LocationCard>
            ))}
          </Absolute>
        }

        <Map id={`map`} />

        {/*<ChangeMode onClick={() => setMapOpen(MapOpen == 1 ? 0 : 1)}>
                    {MapOpen == 1 ? `목록보기` : `지도보기`}
                </ChangeMode>*/}
      </Body>
    </Container>
  );
};

export default KakaoMapSearch;

const Container = styled.div`
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  position: realtive;
`;

const Body = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: inline-block;
  position: relative;
  background-color: #ffffff;
`;

const Map = styled.div`
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 120px);
  height: calc((var(--vh, 1vh) * 100) - 120px);
  display: inline-block;
  position: relative;
  background-color: #ffffff;
`;

const ChangeMode = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1003;
  background-color: #0e6dff;
  color: #ffffff;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 8px;
  font-size: 1.4rem;
  font-weight: 700;
`;

const Absolute = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 120px);
  height: calc((var(--vh, 1vh) * 100) - 120px);
  background-color: #ffffff;
  display: inline-block;
  z-index: 1002;
  padding: 0 20px;
  overflow: hidden auto;
`;

const SearchBox = styled.div`
  width: 100%;
  max-width: 100%;
  height: 120px;
  position: relative;
  display: inline-block;
  z-index: 1005;
  background-color: #ffffff;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.3);
`;

const Header = styled.div`
  width: 100%;
  max-width: 100%;
  height: 50px;
  position: relative;
  display: inline-flex;
  z-index: 1002;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 700;
  padding: 0 45px;

  img {
    position: absolute;
    top: 50%;
    left: 24px;
    width: 24px;
    display: inline-block;
    cursor: pointer;
    transform: translate(0, -50%);
  }
`;

const LocationSearch = styled.div`
  width: 100%;
  max-width: 100%;
  height: 70px;
  position: relative;
  display: inline-flex;
  z-index: 1003;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 10px 20px;

  input {
    width: 100%;
    height: 50px;
    display: inline-flex;
    padding: 0 45px 0 15px;
    border: 1px solid #cadffe;
    border-radius: 8px;
  }

  img {
    width: 24px;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translate(0, -50%);
    cursor: pointer;
  }
`;

const LocationCard = styled.div`
  width: 100%;
  display: inline-block;
  padding: 20px 0;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  .Title {
    font-size: 1.4rem;
    font-weight: 600;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;

    span {
      font-size: 1rem;
      font-weight: 500;
      display: inline-block;
      color: #818792;
      margin-left: 5px;
    }
  }

  .Address {
    font-size: 1.2rem;
    font-weight: 500;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    color: #818792;
  }
`;
