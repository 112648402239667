import React, { Component } from "react";
import styled from "styled-components";

// img
import MapIcon from "../icon/map_icon.png";
import LocationIconBlack from "../icon/fi_heart.svg";
import CopyIcon from "../icon/activity/clipboard.svg";

// css

const PaymentServiceMap = styled.div`
  width: 100%;
  display: inline-block;
  height: 20rem;
  border-radius: 8px;
  background-color: #eeeeee;
  margin-bottom: 5px;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceMapIcon = styled.img`
  width: 100%;
  display: inline-block;
  width: 20px;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceMapCopy = styled.span`
  display: inline-block;
  cursor: pointer;
  width: 80px;
  text-align: right;
`;

const PaymentServiceMapText = styled.span`
  display: inline-block;
  width: calc(100% - 110px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceContainer = styled.div`
  width: 100%;
  display: inline-block;

  @media screen and (min-width: 800px) {
  }
`;

const PaymentServiceHeader = styled.span`
  width: 100%;
  display: inline-block;
  margin-bottom: 16px;

  @media screen and (max-width: 800px) {
  }
`;

const PaymentServiceContent = styled.span`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 800px) {
  }
`;

const BackgroundViewWhiteNotice = styled.section`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #00000085;
  display: inline-flex;
  align-items: flex-end;
`;

const BackgroundViewWhiteContent = styled.div`
  width: 90%;
  max-width: 38rem;
  position: absolute;
  display: inline-flex;
  flex-wrap: wrap;
  top: 50%;
  left: 50%;
  z-index: 102;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    padding: 1.5rem;
  }
`;

const PayPopUpContainer = styled.div`
  width: 100%;
  padding: 4rem;
  display: inline-block;
`;

const PayPopUpClose = styled.span`
  width: 100%;
  padding: 2rem 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0 0 8px 8px;

  @media screen and (max-width: 800px) {
    padding: 1.5rem 0;
  }
`;

const PayPopUpYes = styled.span`
  width: 50%;
  padding: 2rem 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0 0 0 8px;

  @media screen and (max-width: 800px) {
    padding: 1.5rem 0;
  }
`;

const PayPopUpNO = styled.span`
  width: 50%;
  padding: 2rem 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0 0 8px 0;

  @media screen and (max-width: 800px) {
    padding: 1.5rem 0;
  }
`;

const PayPopUpContent = styled.span`
  width: 100%;
  margin-bottom: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const PayPopUpCheckIcon = styled.img`
  width: 5rem;
  display: inline-block;
`;

const PayPopUpContentText = styled.span`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

class Kakao_map_pay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      id: "",
      isLoggedIn: false,
      partner_code: "",

      confirmPopup: "hide",
      confirmType: "",
      confirmFunction: "",
      confirmLink: "",
      confirmText: "",
      confirmFunctionNo: "",
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.map_address !== this.props.map_address) {
      const script = document.createElement("script");
      script.async = true;
      script.src =
        "https://dapi.kakao.com/v2/maps/sdk.js?appkey=4233c0cfe93da40e79ffcd370034aa14&autoload=false&libraries=services";
      document.head.appendChild(script);

      script.onload = () => {
        window.kakao.maps.load(() => {
          let el = document.getElementById("map");
          let map = new window.kakao.maps.Map(el, {
            center: new window.kakao.maps.LatLng(
              37.39531548247998,
              126.63124919609585
            ),
            level: 5,
          });

          var geocoder = new window.kakao.maps.services.Geocoder();

          geocoder.addressSearch(
            [this.props.map_address],
            function (result, status) {
              if (status === window.kakao.maps.services.Status.OK) {
                let markerPosition = new window.kakao.maps.LatLng(
                  result[0].y,
                  result[0].x
                );

                let imageSrc = MapIcon, // 마커이미지의 주소입니다
                  imageSize =
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      navigator.userAgent
                    )
                      ? new window.kakao.maps.Size(41.5, 50)
                      : new window.kakao.maps.Size(59.7, 72), // 마커이미지의 크기입니다
                  imageOption =
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      navigator.userAgent
                    )
                      ? { offset: new window.kakao.maps.Point(21, 49) }
                      : { offset: new window.kakao.maps.Point(30, 70) };

                var markerImage = new window.kakao.maps.MarkerImage(
                  imageSrc,
                  imageSize,
                  imageOption
                );

                let marker = new window.kakao.maps.Marker({
                  map: map,
                  position: markerPosition,
                  image: markerImage,
                });

                map.setCenter(markerPosition);

                document.querySelector("a[href='http://map.kakao.com/']").id =
                  "pagination_stop";
              }
            }
          );
        });
      };
    }
  }

  KakaoMapClose = () => {
    this.props.KakaoMapProps("hide");
  };

  confirmHide = () => {
    this.setState({
      confirmPopup: "hide",
      confirmText: "",
      confirmLink: "",
      confirmType: "",
    });
  };

  popupFunction = () => {
    if (this.state.confirmLink == "replace") {
      window.location.replace("/");
    } else if (this.state.confirmLink == "reload") {
      window.location.reload();
    } else if (this.state.confirmLink == "hide") {
      this.confirmHide();
    } else if (this.state.confirmLink == "back") {
      window.history.back();
    } else {
      this.props.naviGate(this.state.confirmLink, { replace: true });
    }
  };

  render() {
    if (this.props.map_address) {
      var kakao_map_container = [
        <PaymentServiceContainer>
          <PaymentServiceHeader className="weight_500 font_24 color_black ">
            액티비티 장소
          </PaymentServiceHeader>

          <PaymentServiceMap id="map"></PaymentServiceMap>

          <PaymentServiceContent>
            <PaymentServiceMapIcon src={LocationIconBlack} />

            <PaymentServiceMapText className="font_17 color_black weight_400">
              {this.props.map_address
                ? this.props.map_address + " " + this.props.map_address_etc
                : ""}
            </PaymentServiceMapText>

            <PaymentServiceMapCopy
              onClick={() => {
                navigator.clipboard.writeText(
                  this.props.map_address ? this.props.map_address : ""
                );
                this.setState({
                  confirmPopup: "show",
                  confirmText: "주소가 복사되었습니다.",
                  confirmLink: "hide",
                  confirmType: "",
                });
              }}
              className="color_blue font_15 weight_500"
            >
              <img src={CopyIcon} />
            </PaymentServiceMapCopy>
          </PaymentServiceContent>
        </PaymentServiceContainer>,
      ];
    }

    if (this.state.confirmType == "confirm") {
      var PayPopup = [
        <BackgroundViewWhiteNotice>
          <BackgroundViewWhiteContent>
            <PayPopUpContainer>
              <PayPopUpContent className="color_black weight_700 semos_big_content">
                알림
              </PayPopUpContent>

              {/*<PayPopUpContent>
                            <PayPopUpCheckIcon src={CheckBlueIcon}/>
                        </PayPopUpContent>*/}

              <PayPopUpContent
                style={{ margin: "0", lineHeight: "1.4" }}
                className="color_black weight_400 semos_small_content"
              >
                <PayPopUpContentText>
                  {this.state.confirmText}
                </PayPopUpContentText>
              </PayPopUpContent>
            </PayPopUpContainer>

            <PayPopUpYes
              onClick={this.confirmHide}
              style={{ borderRight: ".1rem solid #ffffff" }}
              className="semos_color_white semos_background_color_blue semos_small_content weight_400"
            >
              취소
            </PayPopUpYes>

            <PayPopUpNO
              onClick={this.reportUpdate}
              className="semos_color_white semos_background_color_blue semos_small_content weight_400"
            >
              확인
            </PayPopUpNO>
          </BackgroundViewWhiteContent>
        </BackgroundViewWhiteNotice>,
      ];
    } else {
      var PayPopup = [
        <BackgroundViewWhiteNotice>
          <BackgroundViewWhiteContent>
            <PayPopUpContainer>
              <PayPopUpContent className="color_black weight_500 semos_big_content">
                알림
              </PayPopUpContent>

              {/*<PayPopUpContent>
                            <PayPopUpCheckIcon src={CheckBlueIcon}/>
                        </PayPopUpContent>*/}

              <PayPopUpContent
                style={{ margin: "0", lineHeight: "1.4" }}
                className="color_black weight_400 semos_small_content"
              >
                <PayPopUpContentText>
                  {this.state.confirmText}
                </PayPopUpContentText>
              </PayPopUpContent>
            </PayPopUpContainer>

            <PayPopUpClose
              onClick={this.popupFunction}
              className="semos_color_white semos_background_color_blue semos_small_content weight_400"
            >
              확인
            </PayPopUpClose>
          </BackgroundViewWhiteContent>
        </BackgroundViewWhiteNotice>,
      ];
    }

    return (
      <>
        {this.state.confirmPopup == "show" ? PayPopup : ""}
        {kakao_map_container}
      </>
    );
  }
}

export default Kakao_map_pay;
