import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";

// components
import { Wrapper, Text, WrapperRow, AbsoluteWrapper, Icon } from "./layout/layoutStorage";
import Item from "./item";
import colors from "../styles/colors";
import SignInModal from "./signInModal";

// icon
import { ReactComponent as RightArrowIcon } from "../icon/carouselArrowRight.svg";
import { ReactComponent as LeftArrowIcon } from "../icon/carouselArrowLeft.svg";

const Card = ({ cardTitle, list, jjim, category }) => {
  const [modal, setModal] = useState(false);
  const [dup, setDup] = useState(false);

  const [selectIndex, setSelectIndex] = useState("");
  const [location, setLocation] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [selectItem, setSelectItem] = useState(0);
  const [nowSlide, setNowSlide] = useState(0);

  const slickRef = useRef();
  const cardRef = useRef();
  const locationRef = useRef();

  const handleClose = (e) => {
    if (location && ((!cardRef.current && !locationRef.current) || (!locationRef.current.contains(e.target) && !cardRef.current.contains(e.target)))) {
      setLocation(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [location]);

  const settings = {
    draggable: false,
    className: "slider variable-width",
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const reviewCount = list?.length;

  return (
    <>
      <Container>
          <WrapperRow width="100%" jc="space-between" ai="center">
            <Text margin="24px 0 25.8px 0" size="18px" weight="600" color="#1A1C1F">
              {cardTitle}
            </Text>
            
            <Wrapper>
              <Wrapper
                cursor="pointer"
                onClick={() => {
                  setLocation(false);
                  slickRef.current.slickPrev();
                }}
                margin="0 18px 0 0"
              >
                <LeftArrowIcon fill={nowSlide === 0 ? "#dcdcdc" : "#1A1C1F"} />
              </Wrapper>
              <Wrapper
                cursor="pointer"
                onClick={() => {
                  setLocation(false);
                  if (nowSlide === reviewCount - 4) {
                    return;
                  } else {
                    slickRef.current.slickNext();
                  }
                }}
              >
                <RightArrowIcon fill={nowSlide === reviewCount - 4 ? "#dcdcdc" : "#1A1C1F"} />
              </Wrapper>
            </Wrapper>
          </WrapperRow>

        <div ref={cardRef} style={{ position: "relative" }}>
          <Slider
            beforeChange={(index, next) => {
              setNowSlide(next);
            }}
            ref={slickRef}
            {...settings}
          >

            {list
              ? list.map((item, i) => (
                  <>
                    <Item
                      item={item}
                      key={`${category}_${i}`}
                      category={category}
                      jjim={jjim}
                      index={i}
                      selectItem={selectItem}
                      setSelectItem={setSelectItem}
                      nowSlide={nowSlide}
                      setNowSlide={setNowSlide}
                      location={location}
                      setLocation={setLocation}
                      setLocationList={setLocationList}
                      setSelectIndex={setSelectIndex}
                      selectIndex={selectIndex}
                      setModal={setModal}
                    />
                  </>
                ))
              : ""}
          </Slider>
          {location && (
            <AbsoluteWrapper
              ref={locationRef}
              left={`${selectItem * 220}px`}
              z={1}
              width="200px"
              radius="8px"
              border="1px solid #dcdcdc"
              bgc={colors.white}
              fd="column"
              top="298px"
            >
              {locationList.map((el, idx) => (
                <Wrapper
                  key={`${category}_${idx}`}
                  padding="0 10px"
                  height="37px"
                  jc="flex-start"
                  ai="center"
                  style={{ borderBottom: location.length === idx + 1 ? "none" : "1px solid #F5F5F5" }}
                  width="100%"
                >
                  <Text weight="500" size="12px" ls="-0.02em" color={colors.Gr04}>
                    {`${el.city} ${el.district}`}
                  </Text>
                </Wrapper>
              ))}
            </AbsoluteWrapper>
          )}
        </div>
      </Container>

      {modal && <SignInModal status={modal} setStatus={setModal} dup={dup} setDup={setDup} />}
    </>
  );
};

export default Card;
const Container = styled.div`
  position: relative;

  .slick-next::before {
    display: none;
  }
  .slick-prev::before {
    display: none;
  }
  .slick-list {
    position: relative;

    overflow-x: hidden;
    overflow-y: visible;
  }
  margin-bottom: 36px;
`;

