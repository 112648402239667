import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  Icon,
  Wrapper,
  Text,
  WrapperColumn,
  WrapperRow,
  AbsoluteWrapper,
  Input,
} from "../component/layout/layoutStorage";
import ProfilePoint from "../component/profile_point";
import ProfileCoupon from "../component/profile_coupon";

import Back from "../icon/backbutton.png";

const NewPoint = ({ setModalStatus, setOpen }) => {
  const [menu, setMenu] = useState("point");
  const [list, setList] = useState([]);
  const [point, setPoint] = useState();

  const getData = () => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("token", token);
    frm.append("mode", "my_point");

    axios
      .post("https://ai.semos.kr/semos_user/profile/profile_new", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var profile = data.data.profile_data;
        if (Value.status === "success") {
          setList(profile.point_list);
          setPoint(profile.point);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <WrapperColumn width="100%">
      <Wrapper height="125px" />
      <AbsoluteWrapper
        width="100%"
        height="60px"
        padding="17px 22px"
        jc="space-between"
        ai="center"
        top="0"
        bgc="#ffffff"
        z={2}
      >
        <Icon
          src={Back}
          width="24px"
          height="24px"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setModalStatus("profile");
            setTimeout(() => {
              setOpen(true);
            }, 0.5);
          }}
        />
        <Text size="18px" color="#1A1C1F" weight="600">
          포인트 / 쿠폰
        </Text>
        <Icon src={Back} width="24px" height="24px" style={{ opacity: 0 }} />
      </AbsoluteWrapper>

      <Wrapper width="100%" height="1px" bgc="#F5F5F5" />

      <AbsoluteWrapper
        width="100%"
        padding="0 24px"
        height="65px"
        style={{ boxShadow: `0px 3px 3px rgba(0, 0, 0, 0.25)` }}
        top="60px"
        bgc="#ffffff"
        z={2}
      >
        <Wrapper
          width="50%"
          jc="center"
          padding="20px"
          bb={menu == "point" ? "2px solid #0e6dff" : ""}
          onClick={() => setMenu("point")}
          cursor="pointer"
        >
          <Text
            size="16px"
            color={menu == "point" ? "#0e6dff" : "#818792"}
            weight="600"
          >
            포인트
          </Text>
        </Wrapper>

        <Wrapper
          width="50%"
          jc="center"
          padding="20px"
          bb={menu == "coupon" ? "2px solid #0e6dff" : ""}
          onClick={() => setMenu("coupon")}
          cursor="pointer"
        >
          <Text
            size="16px"
            color={menu == "coupon" ? "#0e6dff" : "#818792"}
            weight="600"
          >
            쿠폰
          </Text>
        </Wrapper>
      </AbsoluteWrapper>

      {menu == "point" && <ProfilePoint point={point} list={list} />}
      {menu == "coupon" && <ProfileCoupon />}
    </WrapperColumn>
  );
};

export default NewPoint;
