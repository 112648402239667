import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import axios from "axios";

// components
import DropDown from "./dropDown";
import {
  Wrapper,
  AbsoluteWrapper,
  Icon,
  Text,
  PaddingText,
  WrapperRow,
  WrapperColumn,
} from "./layout/layoutStorage";

// icon

import arrow from "../icon/arrow.svg";
import { ReactComponent as FullHeart } from "../icon/fullHeart.svg";
import { ReactComponent as EmptyHeart } from "../icon/emptyHeart.svg";
import choiceCalendar from "../icon/choiceCalendar.svg";
import xBox from "../icon/xBox.svg";
import xDay from "../icon/xDay.png";
import colors from "../styles/colors";

const DatePicker = ({
  partner,
  activity,
  subscribe,
  loc,
  heart,
  scrollToTop,
  heartCount,
  setReConfirmModal,
  confirmData,
  setConfirmData,
  jjim,
  purChaseModal,
  setPurChaseModal,
  AppOpen,
  setAppOpen
}) => {
  let weekday = ["일", "월", "화", "수", "목", "금", "토"];
  //진행장소 선택
  const [locationText, setLocationText] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [selectLocation, setSelectLocation] = useState({
    id: "",
    text: "",
  });
  const [locationList, setLocationList] = useState([]);

  //인원 선택
  const [peopleModal, setPeopleModal] = useState(false);
  const [selectPeople, setSelectPeople] = useState("");
  const [people, setPeople] = useState({
    min: 0,
    max: 0,
  });
  let selectPeopleList = [];

  for (let i = 0; i < +people.max - +people.min + 1; i++) {
    if (people.min === 0 || people.max === 0) {
      selectPeopleList.push({ text: "", value: "" });
    } else {
      selectPeopleList.push({
        text: `${+people.min + i}`,
        value: +people.min + i,
      });
    }
  }

  //요금 확인하기 데이터
  const [priceList, setPriceList] = useState({});
  const [price, setPrice] = useState("");
  const [maxPeopleList, setMaxPeopleList] = useState({});
  const [minPeople, setMinPeople] = useState(0);
  const [schedule, setSchedule] = useState([]);
  const [holiday, setHoliday] = useState("");

  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const [yoil, setYoil] = useState("");

  const [off, setOff] = useState(false); // 예약일정 없음

  const HolidayCheck = () => {
    const frm = new FormData();
    frm.append("mode", "holiday_year");
    frm.append("year", show.year);

    axios
      .post("https://ai.semos.kr/semos_user/tool/holiday_check_new", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var HolidayList = Value.holiday;
        var newList = [];

        if (Status === "success") {
          for (let i = 0; i < HolidayList.length; i++) {
            var convert = "";
            var arr = HolidayList[i].split("");
            for (let j = 4; j < arr.length; j++) {
              convert = convert + arr[j];
            }

            newList.push(convert);
          }
          setHoliday(newList);
        }
      });
  };

  useEffect(() => {
    HolidayCheck();
  }, []);

  const getPay = (locationId) => {
    var token = localStorage.getItem("token");
    setLoading(true);
    if (token) {
      const frm = new FormData();

      frm.append("mode", "activity_schedule_new");
      frm.append("token", token);
      frm.append("schedule_id", locationId);
      frm.append("activity_id", activity);
      frm.append("year", show.year);
      frm.append("month", show.month);

      axios
        .post("https://ai.semos.kr/semos_user/service/activity_new", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var Value = data.data;
          var Status = Value.status;
          var StatusMessage = Value.status_message;
          var Schedule = Value.schedule;
          var ActivityPrice = Value.activity_price;

          if (Status === "success") {
            setMaxPeopleList(Value.schedule_count);
            setSchedule(Schedule);
            setMinPeople(Value.activity_info.min_capacity);
            setPriceList(ActivityPrice);
          } else if (Status === "fail") {
            window.alert(StatusMessage);
          } else if (Status === "waring") {
            setOff(true);
          }
          setLoading(false);
        });
    }
  };

  const getLocation = () => {
    var token = localStorage.getItem("token");
    setLoading(true);
    if (token) {
      const frm = new FormData();

      frm.append("mode", "activity_reservation_location");
      frm.append("token", token);
      frm.append("id", activity);

      axios
        .post("https://ai.semos.kr/semos_user/service/activity_new", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var Value = data.data;
          var Status = Value.status;
          var StatusMessage = Value.status_message;
          var location = Value.location;
          var array = [];

          if (Status === "success") {
            for (let i = 0; i < location.length; i++) {
              array.push({
                value: location[i].id,
                text: location[i].location,
              });
            }
            setLocationList(array);
          } else if (Status === "fail") {
            window.alert(StatusMessage);
          } else if (Status === "waring") {
            window.alert(StatusMessage);
          }
          setLoading(false);
        });
    }
  };

  const [open, setOpen] = useState(false);
  const DateWidth = (320 - 46) / 7;
  const [show, setShow] = useState({
    year: new Date().getFullYear(),
    month:
      new Date().getMonth() + 1 < 10
        ? `0${new Date().getMonth() + 1}`
        : new Date().getMonth() + 1,
    day: "",
  });
  const [time, setTime] = useState("");

  const calRef = useRef();

  const CalenderClose = (e) => {
    if (open && (!calRef.current || !calRef.current.contains(e.target))) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      window.addEventListener("click", CalenderClose);

      return () => {
        window.removeEventListener("click", CalenderClose);
      };
    }
  }, [open]);

  const DateCheck = () => {
    var Calendar = [];

    let Year = show.year;
    let Month = Number(show.month);

    var LastDate = new Date(Year, Month, 0);
    let LastDay = LastDate.getDate();
    let LastYoil = 6 - LastDate.getDay();

    var FisrtDate = new Date(Year, Month - 1, 1);
    let FisrtYoil = FisrtDate.getDay();

    var BeforeDate = new Date(Year, Month - 1, 0);
    let BeforeDay = BeforeDate.getDate();

    let MonthText = Month < 10 ? `0${Month}` : Month;

    for (let i = 0; i < LastDay; i++) {
      let ThisDay = i + 1;
      let Day = i + 1 > 9 ? i + 1 : `0${i + 1}`;
      let ThisDate = new Date(Year, Month - 1, ThisDay);
      let ThisYoil = ThisDate.getDay();
      let DateColor = `#818792`;
      let TodayConfirm =
        show.year === new Date().getFullYear() &&
        Number(show.month) == new Date().getMonth() + 1 &&
        ThisDay == new Date().getDate();

      var holidaychecker = `${MonthText}${
        ThisDay < 10 ? `0${ThisDay}` : ThisDay
      }`;

      if (ThisYoil == 0 || holiday.includes(holidaychecker)) {
        DateColor = `#FF9187`;
      } else if (ThisYoil == 6) {
        DateColor = `#0E6DFF`;
      }

      if (ThisDay == 1) {
        for (let fi = FisrtYoil - 1; fi > -1; fi--) {
          Calendar.push(
            <Wrapper
              position="relative"
              key={"date_" + i + "_" + fi}
              bgc={`#ffffff`}
              radius={`50%`}
              jc={`center`}
              width={`${DateWidth}px`}
              height={`${DateWidth}px`}
              margin="0 0 10px 0"
            >
              <PaddingText color={`#DCDCDC`} size="14px">
                {BeforeDay - fi}
              </PaddingText>
            </Wrapper>
          );
        }
        Calendar.push(
          <>
            {schedule[`${show.year}.${show.month}.${Day}`] ? (
              <Wrapper
                position="relative"
                onClick={() => {
                  setShow({
                    year: show.year,
                    month: show.month,
                    day: ThisDay > 9 ? ThisDay : `0${ThisDay}`,
                  });
                  setTime("");
                  setSelectPeople("");
                  selectPeopleList = [];
                  setPeople({
                    min: 0,
                    max: 0,
                  });
                  setYoil(weekday[ThisYoil]);
                }}
                key={"date_" + i}
                cursor={`pointer`}
                bgc={`${ThisDay == show.day ? `#0E6DFF` : `#ffffff`}`}
                radius={`50%`}
                jc={`center`}
                width={`${DateWidth}px`}
                height={`${DateWidth}px`}
                margin="0 0 10px 0"
              >
                <PaddingText
                  color={ThisDay == show.day ? `#ffffff` : DateColor}
                  size="14px"
                >
                  {ThisDay}
                </PaddingText>
                {TodayConfirm && (
                  <AbsoluteWrapper
                    width="24px"
                    height="24px"
                    border="1px dashed #0E6DFF"
                    radius="12px"
                  ></AbsoluteWrapper>
                )}
              </Wrapper>
            ) : (
              <>
                <Xbox
                  key={"date_" + i}
                  src={!loading ? xDay : ""}
                  width={`${DateWidth}px`}
                  height={`${DateWidth}px`}
                >
                  <PaddingText color="#dcdcdc" size="14px">
                    {ThisDay}
                  </PaddingText>
                </Xbox>
              </>
            )}
          </>
        );
      } else if (ThisDay == LastDay) {
        Calendar.push(
          <>
            {schedule[`${show.year}.${show.month}.${Day}`] ? (
              <Wrapper
                position="relative"
                key={"date_" + i}
                onClick={() => {
                  setShow({
                    year: show.year,
                    month: show.month,
                    day: ThisDay > 9 ? ThisDay : `0${ThisDay}`,
                  });
                  setTime("");
                  setSelectPeople("");
                  selectPeopleList = [];
                  setPeople({
                    min: 0,
                    max: 0,
                  });
                  setYoil(weekday[ThisYoil]);
                }}
                cursor={`pointer`}
                // bgc={`${Today ? `#0E6DFF` : `#ffffff`}`}
                bgc={`${ThisDay == show.day ? `#0E6DFF` : `#ffffff`}`}
                radius={`50%`}
                jc={`center`}
                width={`${DateWidth}px`}
                height={`${DateWidth}px`}
                margin="0 0 10px 0"
              >
                <PaddingText
                  color={ThisDay == show.day ? `#ffffff` : DateColor}
                  size="14px"
                >
                  {ThisDay}
                </PaddingText>
                {TodayConfirm && (
                  <AbsoluteWrapper
                    width="24px"
                    height="24px"
                    border="1px dashed #0E6DFF"
                    radius="12px"
                  ></AbsoluteWrapper>
                )}
              </Wrapper>
            ) : (
              <Xbox
                key={"date_" + i}
                src={!loading ? xDay : ""}
                width={`${DateWidth}px`}
                height={`${DateWidth}px`}
              >
                <PaddingText color="#dcdcdc" size="14px">
                  {ThisDay}
                </PaddingText>
              </Xbox>
            )}
          </>
        );
        for (var li = 0; LastYoil > li; li++) {
          Calendar.push(
            <Wrapper
              position="relative"
              key={"date_" + i + "_" + li}
              bgc={`#ffffff`}
              radius={`50%`}
              jc={`center`}
              width={`${DateWidth}px`}
              height={`${DateWidth}px`}
              margin="0 0 10px 0"
            >
              <PaddingText color={`#DCDCDC`} size="14px">
                {li + 1}
              </PaddingText>{" "}
            </Wrapper>
          );
        }
      } else {
        Calendar.push(
          <>
            {schedule[`${show.year}.${show.month}.${Day}`] ? (
              <Wrapper
                position="relative"
                key={"date_" + i}
                onClick={() => {
                  setShow({
                    year: show.year,
                    month: show.month,
                    day: ThisDay > 9 ? ThisDay : `0${ThisDay}`,
                  });
                  setTime("");
                  setSelectPeople("");
                  selectPeopleList = [];
                  setPeople({
                    min: 0,
                    max: 0,
                  });
                  setYoil(weekday[ThisYoil]);
                }}
                cursor={`pointer`}
                // bgc={`${Today ? `#0E6DFF` : `#ffffff`}`}

                bgc={`${ThisDay == show.day ? `#0E6DFF` : `#ffffff`}`}
                radius={`50%`}
                jc={`center`}
                width={`${DateWidth}px`}
                height={`${DateWidth}px`}
                margin="0 0 10px 0"
              >
                <PaddingText
                  color={ThisDay == show.day ? `#ffffff` : DateColor}
                  size="14px"
                >
                  {ThisDay}
                </PaddingText>
                {TodayConfirm && (
                  <AbsoluteWrapper
                    width="24px"
                    height="24px"
                    border="1px dashed #0E6DFF"
                    radius="12px"
                  ></AbsoluteWrapper>
                )}
              </Wrapper>
            ) : (
              <>
                <Xbox
                  key={"date_" + i}
                  src={!loading ? xDay : ""}
                  width={`${DateWidth}px`}
                  height={`${DateWidth}px`}
                >
                  <PaddingText color="#dcdcdc" size="14px">
                    {ThisDay}
                  </PaddingText>
                </Xbox>
              </>
            )}
          </>
        );
      }
    }

    return Calendar;
  };

  const BeforeMonth = () => {
    var thisYear = new Date().getFullYear();
    var thisMonth = new Date().getMonth() + 1;
    if (thisYear == show.year && thisMonth == show.month) {
      return;
    } else {
      if (show.month == "01") {
        setShow({
          year: show.year - 1,
          month: 12,
          date: "01",
        });
      } else {
        setShow({
          year: show.year,
          month:
            Number(show.month) - 1 < 10
              ? `0${Number(show.month) - 1}`
              : Number(show.month) - 1,
          date: "01",
        });
      }
    }
  };

  const AfterMonth = () => {
    if (show.month == 12) {
      setShow({
        year: show.year + 1,
        month: "01",
        date: "01",
      });
    } else {
      setShow({
        year: show.year,
        month:
          Number(show.month) + 1 < 10
            ? `0${Number(show.month) + 1}`
            : Number(show.month) + 1,
        date: "01",
      });
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    getPay(selectLocation.id);
  }, [show.month, off]);

  return (
    <>
      <Text
        margin="0px 24px 0px 24px"
        size="20px"
        weight="700"
        ls="-0.02em"
        color="#1A1C1F"
      >
        {/*가격 확인하기*/subscribe ? `상담/예약하기` : `예약하기`}
      </Text>

      <Container
        off={off}
        token={/*window.localStorage.getItem("lc") == "yes"*/ true}
        onClick={(e) => {
          if (off || /*window.localStorage.getItem("lc") == "yes"*/ true) {
            e.stopPropagation();
          }
        }}
      >
        {/* 진행장소 선택 
        <WrapperRow margin="30px 24px 8px 24px" jc="space-between">
          <Text size="14px" weight="500" ls="-0.02em" color="#3C3E41">
            진행장소
          </Text>
          <Text
            cursor="pointer"
            onClick={() => scrollToTop("activityLocation")}
            style={{ textDecorationLine: "underline" }}
            size="14px"
            weight="500"
            ls="-0.02em"
            color={colors.Gr03}
          >
            위치 보기
          </Text>
        </WrapperRow>
        <Wrapper fd="column" ai="flex-start" margin={"0 24px 8px 24px"}>
          <DropDown
            open={locationModal}
            setOpen={setLocationModal}
            data={selectLocation.text}
            changeDataFun={(value) => {
              setSelectLocation(value);
              setOpen(true);
              setLocationText(false);
              getPay(value.id);
            }}
            option={locationList}
            defaultText="원하는 장소를 선택해주세요"
            no={false}
            padding="18px 10px 18px 18px"
            iconHeight="22px"
            iconWidth="20px"
            color={selectLocation.text ? "#0E6DFF" : "#818792"}
          />
          {locationText && (
            <Text
              margin="12px 0 0 0"
              size="14px"
              weight="500"
              ls="-0.02em"
              color={colors.red}
            >
              장소 먼저 선택해주세요
            </Text>
          )}
        </Wrapper> */}

        {/* 날짜 선택
        <Text
          margin="24px 24px 8px 24px"
          size="14px"
          weight="500"
          ls="-0.02em"
          color="#3C3E41"
        >
          날짜
        </Text>
        <WrapperColumn
          margin="0px 24px 0px 24px"
          padding="18px 0px 18px 0px"
          border={
            show.year && show.month && show.day && time
              ? " 1px solid #0E6DFF "
              : "1px solid #DCDCDC"
          }
          radius="8px"
        >
          <Wrapper
            onClick={() => {
              if (selectLocation.id) {
                setOpen(!open);
              } else {
                setLocationText(true);
              }
            }}
            padding="0 16px 0 18px"
            cursor="pointer"
            jc="space-between"
          >
            <Text
              size="14px"
              weight="600"
              ls="-0.02em"
              color={
                show.year && show.month && show.day && time
                  ? "#0E6DFF"
                  : "#818792"
              }
            >
              {show.year && show.month && show.day && time
                ? `${show.year}.${show.month}.${show.day} ${time}`
                : "원하는 날짜와 시간을 선택해주세요"}
            </Text>
            <Icon src={choiceCalendar} width="20px" height="22px" />
          </Wrapper>
          <WrapperColumn ai="center" width="100%">
            <WrapperColumn
              display={open ? `inline-flex` : `none`}
              ref={calRef}
              width={`320px`}
              padding={`42px 6px 20px 6px`}
              bgc={`#ffffff`}
            >
              <Wrapper wrap={`wrap`} width={`100%`} position={`relative`}>
                <Wrapper
                  padding={`0 22px`}
                  jc={`space-between`}
                  margin={`0 0 19px 0`}
                  wrap={`wrap`}
                  width={`100%`}
                >
                  <Icon
                    onClick={() => BeforeMonth()}
                    width={`24px`}
                    cursor={`pointer`}
                    src={arrow}
                    style={{ transform: "rotate(90deg)" }}
                  />

                  <Text
                    size="14px"
                    weight={`600`}
                  >{`${show.year}년 ${show.month}월`}</Text>

                  <Icon
                    onClick={() => AfterMonth()}
                    width={`24px`}
                    cursor={`pointer`}
                    src={arrow}
                    style={{ transform: "rotate(-90deg)" }}
                  />
                </Wrapper>

                <Wrapper
                  margin={`0 0 13px 0`}
                  jc={`space-between`}
                  width={`100%`}
                >
                  <Text
                    width={`calc(100% / 7)`}
                    jc={`center`}
                    size="12px"
                    color={`#FF9187`}
                    weight={`500`}
                  >{`일`}</Text>
                  <Text
                    width={`calc(100% / 7)`}
                    jc={`center`}
                    size="12px"
                    color={`#ACAFB5`}
                    weight={`500`}
                  >{`월`}</Text>
                  <Text
                    width={`calc(100% / 7)`}
                    jc={`center`}
                    size="12px"
                    color={`#ACAFB5`}
                    weight={`500`}
                  >{`화`}</Text>
                  <Text
                    width={`calc(100% / 7)`}
                    jc={`center`}
                    size="12px"
                    color={`#ACAFB5`}
                    weight={`500`}
                  >{`수`}</Text>
                  <Text
                    width={`calc(100% / 7)`}
                    jc={`center`}
                    size="12px"
                    color={`#ACAFB5`}
                    weight={`500`}
                  >{`목`}</Text>
                  <Text
                    width={`calc(100% / 7)`}
                    jc={`center`}
                    size="12px"
                    color={`#ACAFB5`}
                    weight={`500`}
                  >{`금`}</Text>
                  <Text
                    width={`calc(100% / 7)`}
                    jc={`center`}
                    size="12px"
                    color={`#0E6DFF`}
                    weight={`500`}
                  >{`토`}</Text>
                </Wrapper>

                <Wrapper jc={`space-between`} wrap={`wrap`} width={`100%`}>
                  {show.year && show.month && DateCheck()}
                </Wrapper>
              </Wrapper>
              <WrapperRow jc="flex-end" padding="10px">
                <Icon
                  margin="0 4px 0 0"
                  src={xBox}
                  width="16.5px"
                  height="16.5px"
                />
                <Text size="12px" weight="500" ls="-0.02em" color="#ACAFB5">
                  예약 불가
                </Text>
              </WrapperRow>
              <Wrapper gap="13px" padding="0 10px" wrap="wrap">
                {schedule[`${show.year}.${show.month}.${show.day}`]?.map(
                  (item, idx) =>
                    maxPeopleList[
                      `${show.year}.${show.month}.${show.day} ${item}`
                    ] > 0 ? (
                      <Wrapper
                        onClick={() => {
                          setTime(item);
                          setOpen(false);
                          setPeopleModal(true);
                          setPeople({
                            min: minPeople,
                            max: maxPeopleList[
                              `${show.year}.${show.month}.${show.day} ${item}`
                            ],
                          });
                        }}
                        key={idx}
                        cursor="pointer"
                        radius="8px"
                        border={item !== time && "1px solid #DCDCDC"}
                        width="72px"
                        height="34px"
                        jc="center"
                        ai="center"
                        bgc={item === time && "#0E6DFF"}
                      >
                        <Text
                          size="14px"
                          weight="500"
                          ls="-0.02em"
                          color={item === time ? "#ffffff" : "#ACAFB5"}
                        >
                          {item}
                        </Text>
                      </Wrapper>
                    ) : (
                      <Wrapper
                        key={idx}
                        radius="8px"
                        border={item !== time && "1px solid #DCDCDC"}
                        width="72px"
                        height="34px"
                        jc="center"
                        ai="center"
                        bgc="#f5f5f5"
                      >
                        <Text
                          size="14px"
                          weight="500"
                          ls="-0.02em"
                          color={"#ACAFB5"}
                        >
                          {item}
                        </Text>
                      </Wrapper>
                    )
                )}
              </Wrapper>
            </WrapperColumn>
          </WrapperColumn>
        </WrapperColumn> */}

        {/* 인원 선택
        <Text
          margin="30px 24px 8px 24px"
          size="14px"
          weight="500"
          ls="-0.02em"
          color="#3C3E41"
        >
          인원
        </Text>
        <Wrapper
          onClick={() => {
            if (!selectLocation.id) setLocationText(true);
          }}
          margin="0 24px 40px 24px"
        >
          <DropDown
            dropdownType="people"
            open={peopleModal}
            setOpen={setPeopleModal}
            data={selectPeople}
            can={selectLocation.id}
            changeDataFun={(value) => {
              setSelectPeople(value);
            }}
            option={selectPeopleList}
            defaultText="인원을 선택해주세요"
            no={false}
            padding="18px 10px 18px 18px"
            iconHeight="22px"
            iconWidth="20px"
            color={selectPeople ? "#0E6DFF" : "#818792"}
          />
        </Wrapper> */}

        {/* 최종 금액
        {priceList[selectPeople]?.price_text && (
          <>
            <WrapperColumn padding="24px" gap="24px" bgc="#F9F9F9">
              <Wrapper jc="space-between">
                <Text size="14px" weight="500" ls="-0.02em" color="#ACAFB5">
                  액티비티 금액
                </Text>
                <Text size="14px" weight="500" ls="-0.02em" color="#3C3E41">
                  {`${priceList[selectPeople]?.price_text}원`}
                </Text>
              </Wrapper>
            </WrapperColumn>
            <Wrapper jc="space-between" padding="24px">
              <Text size="16px" weight="500" ls="-0.02em" color="#3C3E41">
                최종 결제금액
              </Text>
              <Text size="16px" weight="500" ls="-0.02em" color="#3383FF">
                {`${priceList[selectPeople]?.price_text}원`}
              </Text>
            </Wrapper>
          </>
        )} */}
      </Container>
      {/*off ? (
        <WrapperRow
          style={{ borderTop: "1px solid #F3F3F3" }}
          padding="11px 24px 0 24px"
          jc="space-between"
        >
          <Wrapper
            jc="center"
            width="100%"
            height="56px"
            radius="8px"
            bgc="#f9f9f9"
          >
            <Text size="16px" weight="600" color="#acafb5">
              예약 마감된 액티비티에요
            </Text>
          </Wrapper>
        </WrapperRow>
      ) : window.localStorage.getItem("lc") == "yes" ? (
        <WrapperRow
          style={{ borderTop: "1px solid #F3F3F3" }}
          padding="11px 24px 0 24px"
          jc="space-between"
        >
          <WrapperColumn ai="center">
            {heart ? (
              <>
                <Wrapper
                  cursor="pointer"
                  margin="0 0 2px 0"
                  onClick={() => jjim()}
                >
                  <FullHeart fill="#FF6759" width="36px" height="36px" />
                </Wrapper>
                <Text size="14px" weight="600" color="#FF6759">
                  {heartCount}
                </Text>
              </>
            ) : (
              <>
                <Wrapper
                  cursor="pointer"
                  margin="0 0 2px 0"
                  onClick={() => jjim()}
                >
                  <EmptyHeart stroke="#ACAFB5" width="36px" height="36px" />
                </Wrapper>
                <Text size="14px" weight="600" color="#ACAFB5">
                  {heartCount}
                </Text>
              </>
            )}
          </WrapperColumn>
          <Wrapper
            cursor="pointer"
            onClick={() => {
              if (
                show.year &&
                show.month &&
                show.day &&
                yoil &&
                time &&
                selectPeople
              ) {
                setReConfirmModal(true);
                setConfirmData({
                  partnerId: partner,
                  activityId: activity,
                  sport: confirmData.sport,
                  duration: confirmData.duration,
                  type: confirmData.type,
                  name: confirmData.name,
                  day: `${show.year}.${show.month}.${show.day} (${yoil})`,
                  date: `${show.year}.${show.month}.${show.day} ${time}`,
                  time: time,
                  people: selectPeople,
                  address: confirmData.address,
                  scheduleId: selectLocation.id,
                });
              }
            }}
            jc="center"
            width="271px"
            height="56px"
            radius="8px"
            bgc="#0E6DFF"
          >
            <Text size="16px" weight="600" color="#ffffff">
              액티비티 예약하기
            </Text>
          </Wrapper>
        </WrapperRow>
      ) : (
        <WrapperRow
          style={{ borderTop: "1px solid #F3F3F3" }}
          padding="11px 24px 0 24px"
          jc="space-between"
        >
          <Wrapper
            onClick={() => {
              setAppOpen(!AppOpen);
            }}
            cursor="pointer"
            jc="center"
            width="100%"
            height="56px"
            radius="8px"
            bgc="#0e6dff"
          >
            <Text size="16px" weight="600" color="#ffffff">
              세모스 앱에서 예약하기
            </Text>
          </Wrapper>
        </WrapperRow>
      )*/}
      <WrapperRow
          style={{ borderTop: "1px solid #F3F3F3" }}
          padding="24px 24px 0 24px"
          margin="24px 0 0 0"
          jc="space-between"
        >
          <Wrapper
            onClick={() => {
              setAppOpen(!AppOpen);
            }}
            cursor="pointer"
            jc="center"
            width="100%"
            height="56px"
            radius="8px"
            bgc="#0e6dff"
          >
            <Text size="16px" weight="600" color="#ffffff">
              {subscribe ? '세모스 앱에서 상담/예약하기' : '세모스 앱에서 예약하기'}
            </Text>
          </Wrapper>
        </WrapperRow>
    </>
  );
};

export default DatePicker;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ off, token }) =>
    off || !token
      ? css`
          filter: blur(5px);
          -webkit-filter: blur(5px);
          pointer-events: none;
        `
      : ""}
`;

const Xbox = styled.div`
  margin-bottom: 10px;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ src }) => `url(${src})`};
  background-size: ${({ width }) => width};
`;
