import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Icon, Wrapper, Text, WrapperColumn, AbsoluteWrapper, WrapperRow } from "./layout/layoutStorage";

// img
import Star from "../icon/star.png";
import Heart from "../icon/fullHeart.png";
import ReservationClosed from "../icon/reservationClosed.png";

const Item = ({ page, item }) => {

  const [locationModal, setLocationModal] = useState(false);
  const locationRef = useRef();

  const handleClose = (e) => {
    if (locationModal && (!locationRef.current || !locationRef.current.contains(e.target))) {
      setLocationModal(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [locationModal]);

  return (
    <>
      <WrapperColumn height="270px" margin={(page != "list") && "0 20px 0 0"} style={{ position: "relative" }}>
        <Link to={`/activity/${item.activity_id}`} target="_blank" style={{ textDecoration: "none" }}>
          <Wrapper height="250px">
            <WrapperColumn cursor="pointer">
              <Wrapper width="200px">
                <ReviewCard img={item?.activity_img}>

                  {/* 예약 마감 */}
                  {item?.status !== "1" && (
                    <AbsoluteWrapper radius="8px 8px 0px 0px" jc="center" ai="center" bgc="rgba(0, 0, 0, 0.4)" width="100%" height="100%">
                      <Icon width="90px" height="100px" src={ReservationClosed} />
                    </AbsoluteWrapper>
                  )}
                </ReviewCard>
              </Wrapper>

              <WrapperColumn padding="10px 0 0 0" height="125px" width="200px" bgc="#ffffff" jc="space-between">
                <WrapperColumn gap="5px">
                  <WrapperRow gap="6px" ai="center">
                    <Text weight="600" size="10px" ls="-0.02em" color="#0E6DFF">
                      {item?.category}
                    </Text>
                    <Wrapper width="1px" height="10px" bgc="#ACAFB5" />
                    <Text weight="600" size="10px" ls="-0.02em" color="#ACAFB5">
                      {item?.duration}
                    </Text>
                    <Wrapper width="1px" height="10px" bgc="#ACAFB5" />
                    <Text weight="600" size="10px" ls="-0.02em" color="#ACAFB5">
                      {item?.type}
                    </Text>
                  </WrapperRow>

                  <Text weight="600" size="13px" color="#3C3E41" ai="flex-start">
                    {item?.name}
                  </Text>
                </WrapperColumn>

                <WrapperColumn margin="0 0 3px 0" position="relative" gap="6px">
                  {/* 키워드 & 별점 */}
                  <WrapperRow width="100%" jc="space-between">
                    <Wrapper ai="center">
                      {(item?.subscribe == true)
                        ? 
                          <Wrapper padding="3.5px 4px">
                            <Text weight="600" size="10px" ls="-0.02em" color={`#0E6DFF`}>
                              {`#상담가능`}
                            </Text>
                          </Wrapper>
                        : ""}
                    </Wrapper>

                      <Wrapper ai="center" gap="1.8px">
                        {
                          (item?.jjim_count > 0)
                          ? 
                            <>
                                <Icon width="12px" height="12px" src={Heart} />
                                <Text style={{marginLeft : '3px'}} weight="600" size="10px" ls="-0.02em" color="#3C3E41">
                                  {item?.jjim_count}
                                </Text>
                            </>
                          :
                            <></>
                        }

                        {
                          item?.review_rating 
                          ? 
                            <>
                              <Icon style={{marginLeft : '5px'}} width="12px" height="12px" src={Star} />
                              <Text style={{marginLeft : '3px'}} weight="600" size="10px" ls="-0.02em" color="#3C3E41">
                                {item.review_rating == "5.0" ? item.review_rating.slice(0, 1) : item.review_rating}
                              </Text>
                            </>
                          : <></>
                        }
                      </Wrapper>
                  </WrapperRow>

                  {/* 가격 */}
                  <WrapperRow>
                    <Wrapper gap="4px" ai="center">
                      <Text weight="600" size="15px" ls="-0.02em" color="#1A1C1F">
                        {item?.price ? `${item.price} 원` : "120,000 원"}
                      </Text>

                      {/* // 할인 ui
                      <Text weight="600" size="15px" ls="-0.02em" color="#FF9187">
                        15%
                      </Text> 

                      <Text weight="500" size="10px" ls="-0.02em" color="#FF9187">
                        (1인 할인가)
                      </Text> */}
                    </Wrapper>
                  </WrapperRow>
                </WrapperColumn>

              </WrapperColumn>
            </WrapperColumn>
          </Wrapper>
        </Link>

        {/* // 좋아요 
        <AbsoluteWrapper top="93px" right="8px" onClick={() => JjimStatus(item.activity_id)} style={{ cursor: "pointer" }}>
          <Icon width="20px" height="20px" src={wish ? FullHeart : EmptyHeart} />
        </AbsoluteWrapper>*/}

      </WrapperColumn>
    </>
  );
};

export default Item;

const ReviewCard = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-size: 212px 125px;
  background-position: center;
  position: relative;
  width: 200px;
  height: 125px;
  border-radius: 8px;
`;

const RainbowBox = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  border-radius: 8px;
  padding: 3px 7px;
  background: radial-gradient(100% 843.76% at 0% 52.38%, #ff6759 0%, #8d4ef2 44.79%, #0e6dff 100%);
`;
