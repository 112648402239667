import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { Wrapper, Text, WrapperColumn } from "./layout/layoutStorage";
import colors from "../styles/colors";
import UserSpec from "./userSpec";

const FacInfoUserSpec = ({ PC, setSuccess }) => {
  // 정보 입력 데이터
  const [userSpec, setUserSpec] = useState({
    name: "",
    gender: "",
    birth: "",
    height: "",
    weight: "",
    foot: "",
  });

  // 숫자 입력
  const patternText = /^[가-힣a-zA-Z]+$/;

  // 버튼 활성화
  let valid =
    patternText.test(userSpec.name) &&
    userSpec.gender !== "" &&
    userSpec.birth?.length === 6 &&
    userSpec.height !== "" &&
    userSpec.weight !== "" &&
    userSpec.foot !== "";

  const navigate = useNavigate();
  const { payId } = useParams();

  const saveData = () => {
    var token = localStorage.getItem("token");
    const frm = new FormData();
    frm.append("mode", "share_input");
    frm.append("token", token);
    frm.append("payment", payId);
    frm.append("birth", userSpec.birth);
    frm.append("weight", userSpec.weight);
    frm.append("foot", userSpec.foot);
    frm.append("height", userSpec.height);
    frm.append("name", userSpec.name);
    frm.append("gender", userSpec.gender);
    frm.append("save", userSpec.save);

    axios
      .post("https://ai.semos.kr/semos_user/v3/profile/index", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        if (Status === "success") {
          if (PC) {
            navigate("/my_semos", {
              state: {
                id: payId,
                userSpec: true,
                successPageText: {
                  title: "정보입력 완료!",
                  content: ["이제 세모스 앱 - 마이 세모스에서", "친구와 함께 예약을 확인할 수 있어요!"],
                  btnText: "예약 확인하기",
                },
              },
            });
          } else {
            setSuccess(true);
          }
        } else {
          window.alert(StatusMessage);
        }
      });
  };

  return (
    <>
      <WrapperColumn ai="center" jc="space-between" bgc="#ffffff" width={"100%"} height="100%">
        <WrapperColumn width="100%" height="100%">
          <Wrapper
            position="relative"
            width="100%"
            style={{
              borderBottom: "1px solid #F5F5F5",
            }}
            height="58px"
            padding="19px 24px"
            ai="center"
            jc="center"
          >
            <Text ws="pre" size="18px" color="#1A1C1F" weight="600">
              정보 입력
            </Text>
          </Wrapper>

          <WrapperColumn padding={PC ? "40px 0 0 0" : "32px 24px"} gap="12px" width={PC && "432px"}>
            <WrapperColumn>
              <Text ws="pre" size="16px" weight="600" ls="-0.02em" color={colors.Gr06}>
                장비 대여에
              </Text>
              <Text ws="pre" size="16px" weight="600" ls="-0.02em" color={colors.Gr06}>
                필요한 정보를 입력해주세요
              </Text>
              <Text margin="12px 0" ws="pre" size="14px" weight="500" ls="-0.02em" color={colors.Gr04}>
                저장 후 수정은 불가능해요
              </Text>
            </WrapperColumn>

            <UserSpec data={userSpec} setData={setUserSpec} />
          </WrapperColumn>
        </WrapperColumn>

        <Wrapper
          style={PC && { borderTop: "1px solid #f9f9f9", marginTop: "338px" }}
          width="100%"
          gap="12px"
          padding={PC ? "8px 24px 36px 24px" : "12px 24px 44px 24px"}
        >
          <Wrapper
            onClick={(e) => {
              e.stopPropagation();
              if (valid) {
                saveData();
              }
            }}
            cursor={valid && "pointer"}
            jc="center"
            ai="center"
            height="56px"
            width="100%"
            bgc={valid ? colors.Bl07 : colors.Gr01}
            radius="8px"
          >
            <Text size="16px" color={valid ? colors.white : colors.Gr03} weight="600">
              입력 완료
            </Text>
          </Wrapper>
        </Wrapper>
      </WrapperColumn>
    </>
  );
};

export default FacInfoUserSpec;
