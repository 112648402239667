import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Icon, Wrapper, Text, WrapperColumn, WrapperRow } from "./layout/layoutStorage";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";

// icon
import FreeDiving from "../icon/activity/freeDiving.png";
import ScubaDiving from "../icon/activity/scubaDiving.png";
import Surfing from "../icon/activity/surfing.png";
import PaddleBoard from "../icon/activity/paddleBoard.png";
import Swimming from "../icon/activity/swimming.png";
import { ReactComponent as RightArrowIcon } from "../icon/carouselArrowRight.svg";
import { ReactComponent as LeftArrowIcon } from "../icon/carouselArrowLeft.svg";

// style
import colors from "../styles/colors";

const Category = () => {
    const navigate = useNavigate();

    const [sportList, setSportList] = useState([
      {name: '', amount: '0', img: '', selected: false},
    ]);

    const categoryData = [
      {
        name: "프리다이빙",
        icon: FreeDiving,
        value: "프리다이빙",
      },
      {
        name: "스쿠버다이빙",
        icon: ScubaDiving,
        value: "스쿠버다이빙",
      },
      {
        name: "서핑",
        icon: Surfing,
        value: "서핑",
      },
      {
        name: "수영",
        icon: Swimming,
        value: "수영/스노클링",
      },
      {
        name: "패들보드",
        icon: PaddleBoard,
        value: "패들보드",
      },
    ];

    // 액티비티 리스트 GET DATA
    const getSportData = async () => {
      const frm = new FormData();
      frm.append('mode', 'sport_list');
  
      axios
        .post('https://ai.semos.kr/semos_user/v3/activity/list', frm, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          },
        })
        .then(res => {
          const list = res.data.list;
          const status = res.data.status;
          if (status === 'success') {
            const arr = list.map(val => {
              const {sport, amount, img} = val;
              return {name: sport, amount: amount, img: img, selected: false};
            });
            setSportList(arr);
          } else {
          }
        })
        .catch(err => console.log('GETSPORT CONNECTION ERROR', err));
    };

    useEffect(() => {
      getSportData();
    }, []);

    const [nowSlide, setNowSlide] = useState(0);
    const slickRef = useRef();
    const settings = {
      draggable: false,
      className: "slider variable-width",
      dots: false,
      arrows: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
    };

    const goList = (cate) => {
      navigate("/activity_list", {
        state: {
          sport: cate,
        },
      });
    };

    const reviewCount = sportList?.length;

  return (
    <Container>
      <WrapperRow width="100%" jc="space-between" ai="center">
        <Text margin="24px 0 25.8px 0" size="18px" weight="600" color="#1A1C1F">
          어떤 스포츠를 찾으시나요?
        </Text>

        <Wrapper>
              <Wrapper
                cursor="pointer"
                onClick={() => {
                  slickRef.current.slickPrev();
                }}
                margin="0 18px 0 0"
              >
                <LeftArrowIcon fill={nowSlide === 0 ? "#dcdcdc" : "#1A1C1F"} />
              </Wrapper>
              <Wrapper
                cursor="pointer"
                onClick={() => {
                  if (nowSlide === reviewCount - 5) {
                    return;
                  } else {
                    slickRef.current.slickNext();
                  }
                }}
              >
                <RightArrowIcon fill={nowSlide === reviewCount - 5 ? "#dcdcdc" : "#1A1C1F"} />
              </Wrapper>
            </Wrapper>
      </WrapperRow>

      <Wrapper width="100%" padding="0 28px" style={{ overflow: "hidden" }}>
        <Slider
          beforeChange={(index, next) => {
            setNowSlide(next);
          }}
          ref={slickRef}
          {...settings}
        >
          {sportList.map((item, idx) => (
            <div>
              <WrapperColumn
                key={`sports_category_${idx}`}
                display="flex"
                cursor="pointer"
                jc="center"
                ai="center"
                width={categoryData.length < 8 ? `${(804 - categoryData.length * 20) / categoryData.length}px` : `151.2px`}
                height="133px"
                margin={categoryData.length < 8 ? "0 10px" : "0 12px 0 0"}
                onClick={() => goList(item.name)}
              >

                <Icon width="100px" height="100px" src={item.img} />

                <Text size="12px" weight="600" margin="12px 0 0 0">
                  {item.name}
                </Text>
              
              </WrapperColumn>
            </div>
          ))}
        </Slider>
      </Wrapper>
    </Container>
  );
};

export default Category;

const Container = styled.div`
  .slick-next::before {
    display: none;
  }

  .slick-prev::before {
    display: none;
  }
  margin-bottom: 36px;
`;
