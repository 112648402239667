import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import axios from "axios";

//components
import {
  Icon,
  Wrapper,
  Text,
  WrapperColumn,
  WrapperRow,
} from "./layout/layoutStorage";
import PhotoModal from "./photoModal";

//icon
import fullStar from "../icon/fullStar.svg";
import emptyStar from "../icon/emptyStar.svg";
import defaultProfile from "../icon/defaultProfile.png";

const ReviewItem = ({
  length,
  reviewList,
  idx,
  photo,
  setPhoto,
  reviewImg,
  setReviewImg,
  setStartImg,
  type,
  page,
  getData,
  where,
}) => {
  //리뷰 더보기
  const textRef = useRef();
  const [reviewMore, setReviewMore] = useState(false);
  const [moreText, setMoreText] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const height = document.getElementsByClassName("review_height");

      if (height[idx]?.offsetHeight > 40) {
        setMoreText(true);
      } else {
        setMoreText(false);
      }
    }, 5);
  }, []);

  //리뷰 사진 모달
  const [activityPhoto, setActivityPhoto] = useState(false);
  const [activityStartImg, setActivityStartImg] = useState(1);

  useEffect(() => {
    setReviewMore(false);
    setMoreText(false);
  }, [page]);

  const reportAction = (type) => {
    var token = localStorage.getItem("token");
    if (type == "report") {
      const frm = new FormData();
      frm.append("mode", "review_policy");
      frm.append("token", token);
      frm.append("partner_number", reviewList.center);
      frm.append("service_number", reviewList.activity);
      frm.append("number", reviewList.review_id);

      axios
        .post("https://ai.semos.kr/semos_user/service/review_new", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((result) => {
          const Value = result.data;
          const Status = Value.status;
          const StatusMessage = Value.status_message;

          window.alert(StatusMessage);
        });
    } else if (type == "block") {
      const frm = new FormData();
      frm.append("mode", "review_block");
      frm.append("token", token);
      frm.append("partner_number", reviewList.center);
      frm.append("service_number", reviewList.activity);
      frm.append("number", reviewList.review_id);

      axios
        .post("https://ai.semos.kr/semos_user/service/review_new", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((result) => {
          const Value = result.data;
          const Status = Value.status;
          const StatusMessage = Value.status_message;

          if (type !== "activity") {
            getData();
          } else {
            window.location.reload();
          }

          window.alert(StatusMessage);
        });
    }
  };

  return (
    <>
      <>
        <WrapperColumn margin="22px 0 0 0">
          <WrapperRow jc="space-between">
            <Wrapper ai="center">
              <Icon
                radius="22px"
                of="cover"
                src={
                  reviewList.review_profile == "가입미완" ||
                  !reviewList.review_profile
                    ? defaultProfile
                    : reviewList.review_profile
                }
                margin="0 14px 0 0"
                width="44px"
                height="44px"
              />
              <WrapperColumn gap="4px">
                <Wrapper>
                  <Text size="14px" weight="600" ls="-0.02em" color="#3C3E41">
                    {reviewList.review_user
                      ? reviewList.review_user
                      : "삭제된 유저"}
                  </Text>
                  <Text
                    ws="pre"
                    size="14px"
                    weight="600"
                    ls="-0.02em"
                    color="#ACAFB5"
                  >
                    {` 님`}
                  </Text>
                </Wrapper>
                <Wrapper gap="4px">
                  <WrapperRow gap="2px">
                    {Array.from(
                      Array(Math.floor(reviewList.review_rating)),
                      (_, i) => (
                        <Icon
                          src={fullStar}
                          key={i}
                          width="16px"
                          height="16px"
                        />
                      )
                    )}

                    {Array.from(
                      Array(Math.floor(5 - reviewList.review_rating)),
                      (_, i) => (
                        <Icon
                          src={emptyStar}
                          key={i}
                          width="16px"
                          height="16px"
                        />
                      )
                    )}
                  </WrapperRow>
                  <Text size="12px" weight="500" ls="-0.02em" color="#0E6DFF">
                    {reviewList.review_rating}점
                  </Text>
                </Wrapper>
              </WrapperColumn>
            </Wrapper>

            <WrapperColumn gap="5.5px" ai="flex-end">
              {localStorage.getItem("token") ? (
                <Wrapper ai="center">
                  <Text
                    cursor="pointer"
                    size="12px"
                    weight="500"
                    ls="-0.02em"
                    color="#ACAFB5"
                    onClick={() => reportAction("report")}
                  >
                    신고
                  </Text>
                  <Wrapper
                    margin="0 6px"
                    width="1px"
                    height="10px"
                    bgc="#ACAFB5"
                  />
                  <Text
                    cursor="pointer"
                    size="12px"
                    weight="500"
                    ls="-0.02em"
                    color="#ACAFB5"
                    onClick={() => reportAction("block")}
                  >
                    차단
                  </Text>
                </Wrapper>
              ) : (
                <Wrapper ai="center" height="17px"></Wrapper>
              )}
              <Text size="12px" weight="500" ls="-0.02em" color="#ACAFB5">
                {where ? reviewList.review_user_date : reviewList.review_date}
              </Text>
            </WrapperColumn>
          </WrapperRow>
          <WrapperColumn
            style={
              length == idx + 1
                ? { borderBottom: "none" }
                : { borderBottom: "2px solid #f3f3f3" }
            }
          >
            <Wrapper margin="24px 0 18px 0" gap="5px">
              {reviewList.review_img.map((el, id) => (
                <Icon
                  cursor="pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (type !== "activity") {
                      setStartImg(id);
                      setReviewImg(reviewList.review_img);
                      setPhoto(!photo);
                    } else {
                      setActivityPhoto(!activityPhoto);
                      setActivityStartImg(id);
                    }
                  }}
                  key={`${reviewList.review_id}_${id}_photo`}
                  src={el}
                  width="78px"
                  height="78px"
                />
              ))}
            </Wrapper>

            <ReviewText
              className="review_height"
              ref={textRef}
              moreText={moreText}
              reviewMore={reviewMore}
            >
              {where
                ? reviewList.review_user_comment
                : reviewList.review_comment}
            </ReviewText>

            {moreText ? (
              <Text
                cursor="pointer"
                margin="16px 0"
                jc="flex-end"
                size="14px"
                weight="500"
                ls="-0.02em"
                color="#ACAFB5"
                onClick={() => {
                  setReviewMore(!reviewMore);
                }}
              >
                {reviewMore ? "간략히 보기" : "더보기"}
              </Text>
            ) : (
              ""
            )}
          </WrapperColumn>
        </WrapperColumn>
      </>

      {/* 사진 모달 */}

      {activityPhoto && (
        <PhotoModal
          start={activityStartImg}
          list={reviewList.review_img}
          status={activityPhoto}
          setStatus={setActivityPhoto}
        />
      )}
    </>
  );
};

export default ReviewItem;

const ReviewText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #3c3e41;
  white-space: pre-line;
  margin: 0 0 16px 0;

  ${({ reviewMore, moreText }) =>
    moreText &&
    !reviewMore &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin: 0;
    `}
`;
