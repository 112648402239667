import React, { Component } from "react";
import { BrowserRouter as Router, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

// 이미지
import SearchIcon from "../icon/black_serch_icon.svg";
import BlackArrow from "../icon/black_arrow.svg";

// component

// css

const HeaderContent = styled.section`
  width: 80rem;
  height: 50px;
  background-color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
  z-index: 15;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
    padding-left: 18px;
    justify-content: flex-start;
  }
`;

const SpotHeader = styled.section`
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
`;

const HeaderPCTitle = styled.section`
  margin-bottom: 30px;
`;

const HeaderContentContainer = styled.span`
  width: 100%;
  padding: 0 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;

  @media screen and (min-width: 800px) {
  }
`;

const HeaderHistoryBack = styled.img`
  width: 12px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 1.8rem;
  transform: translate(0, -50%);
  cursor: pointer;
`;

const HeaderRightContent = styled.img`
  height: 1.8rem;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 1.8rem;
  transform: translate(0, -50%);
  cursor: pointer;
`;

const withParams = (Child) => {
  return (props) => {
    const params = useParams();
    const navigate = useNavigate();
    return <Child {...props} params={params} naviGate={navigate} />;
  };
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thisPage: window.location.pathname,
      mobileCheck: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
      appCheck: window.navigator.userAgent.indexOf("SemosAndroid") > -1 || window.navigator.userAgent.indexOf("SemosIosWebviewApp") > -1,
    };
  }

  goBack = () => {
    var canGoBack = window.sessionStorage.getItem("canGoBack") === "false";

    if (canGoBack) {
      window.location.href = "/home";
    } else {
      this.props.naviGate(-1);
    }
  };

  componentDidMount() {
    var history_back_uri = window.document.referrer;

    if (!history_back_uri) {
      var history_back_uri = window.localStorage.getItem("historyBack");
    }

    var userDevice = "";
    var device = "win16|win32|win64|mac|macintel";
    var page = this.state.thisPage.split("/")[1];
    var userToken = window.localStorage.getItem("token");

    if (history_back_uri) {
      if (history_back_uri.indexOf("naver") !== -1) {
        var site_type = "naver";
      } else if (history_back_uri.indexOf("instagram") !== -1) {
        var site_type = "instagram";
      } else if (history_back_uri.indexOf("facebook") !== -1) {
        var site_type = "facebook";
      } else if (history_back_uri.indexOf("semos") !== -1) {
        var site_type = "semos";
      } else if (history_back_uri.indexOf("google") !== -1) {
        var site_type = "google";
      } else {
        var site_type = "another";
      }
    } else {
      var site_type = "typing";
    }

    if (window.navigator.platform) {
      if (device.indexOf(window.navigator.platform.toLowerCase()) < 0) {
        if (this.state.appCheck) {
          userDevice = "APP";
        } else {
          userDevice = "MOBILE";
        }
      } else {
        userDevice = "PC";
      }
    }

    const frm = new FormData();
    frm.append("mode", "enter_log");
    frm.append("token", userToken);
    frm.append("device", userDevice);
    frm.append("site_type", site_type);
    frm.append("site", history_back_uri);
    frm.append("page", page);

    axios
      .post("https://ai.semos.kr/semos_user/profile/user_profile", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        window.localStorage.setItem("historyBack", this.state.thisPage);
      });
  }

  render() {
    var HeaderName = {
      partner: "더보기",
      product_type: "heelo",
      coaching_report_list: "레슨 리포트",
      coaching_report: this.props.name,
      my_medal: "MY 메달",
      my_review: "리뷰",
      my_lesson: "마이 세모스",
      my_lesson_specific: this.props.product_name,
      location: "지역",
      search: "카테고리",
      search_page: "카테고리",
      when_filter: "",
      search_by_sport: this.props.sport_type,
      search_by_location: this.props.sport_location,
      event: "기획전",
      log_book: "로그북",
      log_book_write: "로그북",
      log_book_intro: "로그북",
      log_book_revise: "로그북",
      faq: "자주 묻는 질문",
      payment: this.props.product_name,
      question_list: "문의",
      question_chat: this.props.partner_name,
      review_write: "리뷰 작성",
      review_revise: "리뷰 수정",
      jjim: "찜한 레슨",
      notice: "공지사항",
      notice_intro: "공지사항",
      profile_revise: "프로필 수정",
      product: this.props.name,
      my_point: "세모스 포인트",
      friends: "친구초대",
      semos_payment_info: "결제약관",
      semos_user_info_process: "개인정보 처리 방침",
      semos_ad_info: "[선택] 광고성 정보 수신 동의에 대한 약관",
      semos_using_info: "이용약관",
      club: "세모스 클럽",
      semospot: "세모스팟",
      partner_activity_register: `${localStorage.getItem("user_name")}님의 액티비티`,
      partner_sold: `${localStorage.getItem("user_name")}님의 판매내역`,
      location_list: `${localStorage.getItem("user_name")}님의 액티비티 장소`,
      partner_sold_detail: "액티비티 판매 상세 내역",
    };

    var headerContainer = [];

    if (this.state.thisPage.split("/")[1] == "!product" || this.state.thisPage.split("/")[1] == "!event") {
      headerContainer.push(
        <HeaderContent key={"header_1"} className="semos_big_content semos_font_weight_400">
          <HeaderContentContainer>
            {HeaderName[this.state.thisPage.split("/")[1]]}

            <HeaderHistoryBack onClick={this.goBack} src={BlackArrow} />

            <HeaderRightContent onClick={() => this.props.naviGate("/search")} src={SearchIcon} />
          </HeaderContentContainer>
        </HeaderContent>
      );
    } else if(this.state.thisPage.split("/")[1] == "semospot") {
        headerContainer.push(
              <SpotHeader key={"header_1"} className="semos_small_content semos_font_weight_700">
                <div>{HeaderName[this.state.thisPage.split("/")[1]]}</div>
              </SpotHeader>
        );
      } else {
      headerContainer.push(
        <>
          {this.state.mobileCheck ? (
            <HeaderContent key={"header_1"} className="semos_small_content semos_font_weight_700">
              <div>{HeaderName[this.state.thisPage.split("/")[1]]}</div>
            </HeaderContent>
          ) : (
            <HeaderPCTitle className="semos_small_content semos_font_weight_700">{HeaderName[this.state.thisPage.split("/")[1]]}</HeaderPCTitle>
          )}
        </>
      );
    }

    return <>{headerContainer}</>;
  }
}

export default withParams(Header);
