import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate, useParams } from "react-router-dom";

const BackgroundViewWhiteNotice = styled.section`
width : 100%;
height: 100vh;
height : calc(var(--vh, 1vh) * 100);
position : fixed;
top : 0;
left : 0;
z-index : 100;
background-color : #00000085;
display : inline-flex;
align-items : flex-end;
`

const BackgroundViewWhiteContent = styled.div`
width : 90%;
max-width : 38rem;
position : absolute;
display : inline-flex;
flex-wrap : wrap;
top : 50%;
left : 50%;
z-index : 102;
transform : translate(-50%, -50%);
background-color : #ffffff;
border-radius : 8px;
overflow : hidden;

`

const PayPopUpContainer = styled.div`
width : 100%;
padding : 4rem;
display : inline-block;
`

const PayPopUpClose = styled.span`
width : 100%;
padding : 2rem 0;
display : inline-flex;
align-items : center;
justify-content : center;
cursor : pointer;
border-radius : 0 0 8px 8px;

@media screen and (max-width: 800px) {
  padding : 1.5rem 0;
}
`


const PayPopUpYes = styled.span`
width : 50%;
padding : 2rem 0;
display : inline-flex;
align-items : center;
justify-content : center;
cursor : pointer;
border-radius : 0 0 0 8px;

@media screen and (max-width: 800px) {
  padding : 1.5rem 0;
}
`


const PayPopUpNO = styled.span`
width : 50%;
padding : 2rem 0;
display : inline-flex;
align-items : center;
justify-content : center;
cursor : pointer;
border-radius : 0 0 8px 0;

@media screen and (max-width: 800px) {
  padding : 1.5rem 0;
}
`

const PayPopUpContent = styled.span`
width : 100%;
margin-bottom : 2rem;
display : inline-flex;
align-items : center;
justify-content : center;
flex-wrap : wrap;
`

const PayPopUpCheckIcon = styled.img`
width : 5rem;
display : inline-block;  
`


const PayPopUpContentText = styled.span`
width : 100%;
display : inline-flex;
align-items : center;
justify-content : center;
`

const withParams = (Child) => {
    return (props) => {
      const params = useParams();
      const navigate = useNavigate();
      return <Child {...props} params={params} naviGate={navigate} />;
    };
};

class TossPayRedi extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          payPopup : "hide",

          confirmPopup : "hide",
          confirmType : "",
          confirmFunction : "",
          confirmLink : "",
          confirmText : "",
          confirmFunctionNo : ""
        }
            
    }    

    componentDidMount () { 

        const current = decodeURI(window.location.href);
        const search = current.split("?")[1];
        const params = new URLSearchParams(search);
        const redi_url = params.get('orderId');
        const redi_url2 = params.get('paymentKey');
        const redi_url3 = params.get('amount');

        var user_token_toss = window.localStorage.getItem('token');
   

        const frm = new FormData()

        frm.append('mode', 'toss_payment_success')
        frm.append('orderId', redi_url)
        frm.append('pk', redi_url2 )
        frm.append('amount', redi_url3)
        frm.append('user_token', user_token_toss)

      axios.post("https://ai.semos.kr/semos_user/service/toss_payment", frm, {
      headers: {'Content-Type': 'multipart/form-data'}
      }).then(result => {

          if(result.data.status == 'success') {

            var toss_pay_result = result.data.toss_pay
            this.setState({
              confirmPopup : "show",
              confirmText : "결제가 완료되었습니다.",
              confirmLink : "reload",
              confirmType : "",
            })
          window.location.replace('/pay_check_success/' + toss_pay_result.si + '/' + toss_pay_result.ut + '/service_name/toss' + '?pg_token=' + toss_pay_result.report_OID )

          } else if(result.data.status == 'fail') {
            /*this.setState({
                confirmPopup : "show",
                confirmText : "결제가 실패했습니다. 결제 정보를 확인해주세요",
                confirmLink : "back",
                confirmType : "",
              })
            
            return*/
          } else if(result.data.status == 'waring') {
            this.setState({
                confirmPopup : "show",
                confirmText : "잘못된 접근입니다!",
                confirmLink : "replace",
                confirmType : "",
              })
            return
          } 

      })
    }


    confirmHide = () => {
      this.setState({
          confirmPopup : 'hide',
          confirmText : "",
          confirmLink : "",
          confirmType : "",
      })
  }

  popupFunction = () => {

      if(this.state.confirmLink == 'replace') {
          window.location.replace('/')
      } else if(this.state.confirmLink == 'reload') {
          window.location.reload()
      } else if(this.state.confirmLink == 'hide') {
          this.confirmHide()
      } else if(this.state.confirmLink == 'back') {
          window.history.back()
      } else {
          this.props.naviGate(this.state.confirmLink,  { replace: true})
      }

  }



    render() {

  

        if (this.state.confirmType == 'confirm' ) {

            var PayPopup = [
                <BackgroundViewWhiteNotice>
      
                    <BackgroundViewWhiteContent>
      
                        <PayPopUpContainer>
      
                            <PayPopUpContent className='semos_color_black_real semos_font_weight_700 semos_big_content'>알림</PayPopUpContent>
      
                            {/*<PayPopUpContent>
                                <PayPopUpCheckIcon src={CheckBlueIcon}/>
                            </PayPopUpContent>*/}
      
                            <PayPopUpContent style={{ margin : '0', lineHeight : '1.4' }} className='semos_color_black_real semos_font_weight_400 semos_small_content'>
                                <PayPopUpContentText>{this.state.confirmText}</PayPopUpContentText>
                            </PayPopUpContent>
      
                        </PayPopUpContainer>
      
                        <PayPopUpYes onClick={this.confirmHide} style={ {borderRight : '.1rem solid #ffffff' } } className='semos_color_white semos_background_color_blue semos_small_content semos_font_weight_400'>
                            취소
                        </PayPopUpYes>
    
                        <PayPopUpNO onClick={this.reportUpdate}  className='semos_color_white semos_background_color_blue semos_small_content semos_font_weight_400'>
                            확인
                        </PayPopUpNO>
      
                    </BackgroundViewWhiteContent>
      
                </BackgroundViewWhiteNotice>
            ]  
    
        } else {
    
            var PayPopup = [
                <BackgroundViewWhiteNotice>
      
                    <BackgroundViewWhiteContent>
      
                        <PayPopUpContainer>
      
                            <PayPopUpContent className='semos_color_black_real semos_font_weight_700 semos_big_content'>알림</PayPopUpContent>
      
                            {/*<PayPopUpContent>
                                <PayPopUpCheckIcon src={CheckBlueIcon}/>
                            </PayPopUpContent>*/}
      
                            <PayPopUpContent style={{ margin : '0', lineHeight : '1.4' }} className='semos_color_black_real semos_font_weight_400 semos_small_content'>
                                <PayPopUpContentText>{this.state.confirmText}</PayPopUpContentText>
                            </PayPopUpContent>
      
                        </PayPopUpContainer>
      
                        <PayPopUpClose onClick={this.popupFunction}  className='semos_color_white semos_background_color_blue semos_small_content semos_font_weight_400'>
                            확인
                        </PayPopUpClose>
      
                    </BackgroundViewWhiteContent>
      
                </BackgroundViewWhiteNotice>
            ]
    
        }


        return (
            
                  <>
                   {this.state.confirmPopup == 'show' ? PayPopup : ""}
                  </>  
        )
    }

}


export default withParams(TossPayRedi);
