import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";

import { Icon, Wrapper, Text, WrapperColumn, WrapperRow, AbsoluteWrapper, Input, FixedWrapper, TextArea } from "../component/layout/layoutStorage";

import Back from "../icon/backbutton.png";
import Q from "../icon/faqQ.png";
import Arrow from "../icon/faqOpen.png";
import cs from "../icon/cs.png";

const channel_link = "https://semos.kr/kakao_channel";

const NewFaq = ({ setModalStatus, setOpen }) => {
  const [menu, setMenu] = useState("전체");
  const [list, setList] = useState([]);
  const [total, setTotal] = useState([]);
  var menuList = ["이용 안내", "회원 정보", "결제 및 환불"];

  const getData = () => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("token", token);
    frm.append("mode", "faq_list");

    axios
      .post("https://ai.semos.kr/semos_user/profile/profile_new", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((data) => {
        var Value = data.data;
        var List = Value.faq_list;
        var keys = Object.keys(List);
        var totalList = [];
        for (let i = 0; i < keys.length; i++) {
          totalList.push({ [keys[i]]: List[keys[i]] });
        }
        totalList = [totalList[1], totalList[0], totalList[2]];

        setList(List);
        setTotal(totalList);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <WrapperColumn width="100%">
      <Wrapper width="100%" height="60px" padding="17px 22px" jc="space-between" ai="center">
        <Icon
          src={Back}
          width="24px"
          height="24px"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setModalStatus("profile");
            setTimeout(() => {
              setOpen(true);
            }, 0.5);
          }}
        />
        <Text size="18px" color="#1A1C1F" weight="600">
          자주하는 질문
        </Text>
        <Icon src={Back} width="24px" height="24px" style={{ opacity: 0 }} />
      </Wrapper>

      <Wrapper width="100%" height="1px" bgc="#F5F5F5" />

      <Wrapper width="100%" padding="0 24px" style={{ boxShadow: `0px 3px 3px rgba(0, 0, 0, 0.25)` }}>
        <Wrapper width="25%" jc="center" padding="20px 0" bb={menu == "전체" ? "2px solid #0e6dff" : ""} cursor="pointer" onClick={() => setMenu("전체")}>
          <Text size="16px" color={menu == "전체" ? "#0e6dff" : "#818792"} weight="600">
            전체
          </Text>
        </Wrapper>

        <Wrapper
          width="25%"
          jc="center"
          padding="20px 0"
          bb={menu == "이용 안내" ? "2px solid #0e6dff" : ""}
          cursor="pointer"
          onClick={() => setMenu("이용 안내")}
        >
          <Text size="16px" color={menu == "이용 안내" ? "#0e6dff" : "#818792"} weight="600">
            이용안내
          </Text>
        </Wrapper>

        <Wrapper
          width="25%"
          jc="center"
          padding="20px 0"
          bb={menu == "회원 정보" ? "2px solid #0e6dff" : ""}
          cursor="pointer"
          onClick={() => setMenu("회원 정보")}
        >
          <Text size="16px" color={menu == "회원 정보" ? "#0e6dff" : "#818792"} weight="600">
            회원정보
          </Text>
        </Wrapper>

        <Wrapper
          width="25%"
          jc="center"
          padding="20px 0"
          bb={menu == "결제 및 환불" ? "2px solid #0e6dff" : ""}
          cursor="pointer"
          onClick={() => setMenu("결제 및 환불")}
        >
          <Text size="16px" color={menu == "결제 및 환불" ? "#0e6dff" : "#818792"} weight="600">
            결제 / 환불
          </Text>
        </Wrapper>
      </Wrapper>

      {menu !== "전체" ? (
        <WrapperColumn>
          <Text size="14px" weight="500" color="#818792" style={{ padding: "24px 24px 12px 24px" }}>
            {menu == "결제 및 환불" ? "결제 / 환불" : menu}
          </Text>

          {list[menu].map((el) => (
            <FaqCard item={el} />
          ))}
        </WrapperColumn>
      ) : (
        total.map((men, idx) => (
          <WrapperColumn>
            <Text size="14px" weight="500" color="#818792" style={{ padding: "24px 24px 12px 24px" }}>
              {menuList[idx] == "결제 및 환불" ? "결제 / 환불" : menuList[idx]}
            </Text>

            {men[menuList[idx]]?.map((el) => (
              <FaqCard item={el} />
            ))}
          </WrapperColumn>
        ))
      )}
      <FixedWrapper
        // margin="0 0 52px 0"
        width="100%"
        jc="flex-end"
        ai="center"
        bottom="24px"
        right={`24px`}
        z="3"
      >
        <Linker href={channel_link} target="_blank" style={{ textDecoration: "none" }}>
          <WrapperRow cursor="pointer" width="132px" height="36px" jc="center" ai="center" radius="27px" bgc="#E5F0FF">
            <Icon src={cs} margin="0 4px 0 0" width="20px" height="20px"></Icon>
            <Text size="14px" weight="600" ls="-0.02em" color="#0E6DFF">
              고객센터 연결
            </Text>
          </WrapperRow>
        </Linker>
      </FixedWrapper>
    </WrapperColumn>
  );
};

export default NewFaq;

const FaqCard = ({ item }) => {
  const [open, setOpen] = useState(false);

  return (
    <WrapperColumn bb="1px solid #dcdcdc">
      <Wrapper cursor="pointer" onClick={() => setOpen((prev) => !prev)} jc="space-between" ai="center" padding="16px 24px">
        <Wrapper ai="center">
          <Icon src={Q} width="20px" height="20px" style={{ marginRight: "8px" }} />
          <Text size="14px" weight="600" color="#3c3e41">
            {item.q}
          </Text>
        </Wrapper>

        <Icon
          src={Arrow}
          width="24px"
          height="24px"
          transform={open ? "rotate(-180deg)" : "rotate(0deg)"}
          transition="all 0.3s ease"
          style={{ marginLeft: "12px" }}
        />
      </Wrapper>
      {open && (
        <Wrapper padding="20px 24px" bgc="#f5f5f5">
          <Text size="12px" weight="500" color="#3c3e41" ws="pre-wrap">
            {`${item.a}`}
          </Text>
        </Wrapper>
      )}
    </WrapperColumn>
  );
};

const Linker = styled.a`
  text-decoration: none;
  outline: none;

  &:hover {
    text-decoration: none;
    outline: none;
  }
`;
