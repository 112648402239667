import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import { Icon, Wrapper, Text, WrapperColumn, WrapperRow } from "./layout/layoutStorage";

import checkDate from "../icon/checkDate.svg";
import maxPeople from "../icon/maxPeople.svg";
import clock from "../icon/clock.svg";

const Reconfirm = ({ setStatus, status, data }) => {
  const navigate = useNavigate();
  const ref = useRef(null);

  const CalenderClose = (e) => {
    if (status && (!ref.current || !ref.current.contains(e.target))) {
      setStatus(false);
    }
  };

  useEffect(() => {
    if (status) {
      window.addEventListener("click", CalenderClose);

      return () => {
        window.removeEventListener("click", CalenderClose);
      };
    }
  }, [status]);
  return (
    <Container display={status ? `inline-flex` : `none`}>
      <WrapperColumn style={{ boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.25)" }} ref={ref} radius="16px" bgc="#ffffff" padding="36px 24px" width="375px">
        <Text margin="0 0 8px 0" size="20px" weight="700" ls="-0.02em" color="#1A1C1F">
          일정을 확인해주세요!
        </Text>
        <Text size="14px" weight="500" ls="-0.02em" color="#818792">
          파트너님 확인 후 예약이 확정됩니다.
        </Text>
        <WrapperColumn margin="32px 0 0 0" gap="12px">
          <WrapperRow gap="12px" ai="center">
            <WrapperRow ai="center" width="75px" gap="6px">
              <Text ws="pre" size="14px" weight="500" ls="-0.02em" color="#0E6DFF">
                스포츠
              </Text>
              <Wrapper width="1px" height="10px" bgc="#818792" />
              <Text ws="pre" size="14px" weight="500" ls="-0.02em" color="#818792">
                {/* {` / 유형`} */}
                유형
              </Text>
            </WrapperRow>
            <WrapperRow width="calc(100% - 75px)" gap="6px" ai="center">
              <Text ws="pre-line" size="14px" weight="500" ls="-0.02em" color="#0E6DFF">
                {data.sport}
              </Text>
              <Wrapper width="1px" height="10px" bgc="#ACAFB5" />
              <Text ws="pre-line" size="14px" weight="500" ls="-0.02em" color="#ACAFB5">
                {data.duration}
              </Text>
              <Wrapper width="1px" height="10px" bgc="#ACAFB5" />
              <Text ws="pre-line" size="14px" weight="500" ls="-0.02em" color="#ACAFB5">
                {data.type}
              </Text>
            </WrapperRow>
          </WrapperRow>
          <WrapperRow gap="12px" ai="flex-start">
            <Text width="75px" ws="pre-line" size="14px" weight="500" ls="-0.02em" color="#818792">
              액티비티
            </Text>
            <Text width="calc(100% - 75px)" ws="pre-line" size="14px" weight="500" ls="-0.02em" color="#3C3E41">
              {data.name}
            </Text>
          </WrapperRow>
        </WrapperColumn>

        <WrapperRow padding="23px 28px" border="1px solid #dcdcdc" radius="8px" margin="32px 0 40px 0" ai="center" jc="space-between">
          <WrapperColumn jc="center" ai="center" width="90px" gap="8px">
            <Icon src={checkDate} width="32px" height="32px" />
            <Text ws="pre-line" size="12px" weight="500" ls="-0.02em" color="#3C3E41">
              {data.day}
            </Text>
          </WrapperColumn>
          <WrapperColumn jc="center" ai="center" width="90px" gap="8px">
            <Icon src={clock} width="32px" height="32px" />
            <Text ws="pre-line" size="12px" weight="500" ls="-0.02em" color="#3C3E41">
              {data.time}
            </Text>
          </WrapperColumn>
          <WrapperColumn jc="center" ai="center" width="90px" gap="8px">
            <Icon src={maxPeople} width="32px" height="32px" />
            <Text ws="pre-line" size="12px" weight="500" ls="-0.02em" color="#3C3E41">
              {data.people}인
            </Text>
          </WrapperColumn>
        </WrapperRow>
        <Wrapper
          onClick={() => navigate(`/payment/${data.activityId}/${data.scheduleId}/${data.date}/${data.people}`)}
          cursor="pointer"
          jc="center"
          padding="16.5px 0"
          width="100%"
          radius="8px"
          bgc="#0E6DFF"
        >
          <Text ws="pre-line" size="16px" weight="600" ls="-0.02em" color="#FFFFFF">
            결제하기
          </Text>
        </Wrapper>
      </WrapperColumn>
    </Container>
  );
};

export default Reconfirm;

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
