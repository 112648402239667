const MataTagChange = ({ 
    title="세모스 : 1등 스포츠 예약 플랫폼", 
    description="스포츠에 도전하기 망설여진다면, 세모스에서 함께할 파트너를 찾아보세요. 세모스에서 스포츠 레슨, 자격증, 이용권을 찾고 상담부터 예약까지 한번에! 1등 스포츠 예약 플랫폼 세모스!", 
    imageUrl="https://ai.semos.kr/img/RNAPP/SemosThumb.png" }) => {
    //set title

    if(!title) {
        title = "세모스 : 1등 스포츠 예약 플랫폼";
    }

    if(!description) {
        description = "스포츠에 도전하기 망설여진다면, 세모스에서 함께할 파트너를 찾아보세요. 세모스에서 스포츠 레슨, 자격증, 이용권을 찾고 상담부터 예약까지 한번에! 1등 스포츠 예약 플랫폼 세모스!";
    }

    if(!imageUrl) {
        imageUrl = "https://ai.semos.kr/img/RNAPP/SemosThumb.png";
    }

    const htmlTitle = document.querySelector("title");
    htmlTitle.innerText = title;

    document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", `${title}`);
    
    //set description
    document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", description);

    document
    .querySelector('meta[name="description"]')
    .setAttribute("content", description);
    
    //set images
    document
    .querySelector('meta[property="og:image"]')
    .setAttribute("content", imageUrl);
    
    //set url
    document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", window.location.href);
};

export default MataTagChange;