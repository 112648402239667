import React, { useState, useEffect } from "react";
import styled from "styled-components";

// img
import MapIcon from "../icon/semosMarker.png";
import { ReactComponent as RightArrowIcon } from "../icon/carouselArrowRight.svg";
import selectMap from "../icon/selectMap.svg";
import copyMap from "../icon/copyMap.svg";
import goMapSite from "../icon/goMapSite.svg";


import { Icon, Wrapper, Text, WrapperColumn, WrapperRow } from "./layout/layoutStorage";
import colors from "../styles/colors";

// css
const BackgroundViewWhiteNotice = styled.section`
  /* margin-bottom: 66.8px; */
`;

const KakaoMap = styled.div`
  width: 100%;
  max-height: calc((100vw / 8) * 5);
  border-radius: 8px;
  background-color: #ffffff;
`;

const KakaoMapHeaderImg = styled.div`
  width: 100%;
  max-height: calc((100vw / 8) * 5);
  display: inline-block;
  height: 35rem;
`;

const Mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

const Kakao_map = ({ locationList, map_address, map_address_etc, locationY, locationX, kakaoMapProps, update }) => {
  const [location, setLocation] = useState([]);
  const [show, setShow] = useState(false);

  let realLocation = location?.length > 2 ? (show ? location : location.slice(0, 2)) : location;

  const [selectLocation, setSelectLocation] = useState({
    x: "",
    y: "",
    address: "",
  });
  const [copy, setCopy] = useState(false);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  const onButtonClick = (location) => {
    // 새로운 주소 값 가져오기

    // 새로운 주소로 지오코딩
    const geocoder = new window.kakao.maps.services.Geocoder();
    geocoder.addressSearch(location, function (result, status) {
      if (status === window.kakao.maps.services.Status.OK) {
        const markerPosition = new window.kakao.maps.LatLng(result[0].y, result[0].x);

        // 기존에 생성한 지도 객체의 중심 좌표 변경
        if (map !== null) {
          map.setCenter(markerPosition);
        }

        // 기존에 생성한 마커 객체의 위치 변경
        if (marker !== null) {
          marker.setPosition(markerPosition);
        }
      }
    });
  };

  useEffect(() => {
    setLocation(locationList);
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://dapi.kakao.com/v2/maps/sdk.js?appkey=4233c0cfe93da40e79ffcd370034aa14&autoload=false&libraries=services";

    document.head.appendChild(script);

    if (map_address && map_address_etc && update) {
      setSelectLocation({
        x: locationX,
        y: locationY,
      });
      setTimeout(() => {
        if (window.kakao.maps) {
          window.kakao.maps.load(() => {
            let el = document.getElementById("map");
            var initialMap = new window.kakao.maps.Map(el, {
              center: new window.kakao.maps.LatLng(37.39531548247998, 126.63124919609585),
              level: 5,
            });
            setMap(initialMap);
            var geocoder = new window.kakao.maps.services.Geocoder();

            geocoder.addressSearch([map_address], function (result, status) {
              if (status === window.kakao.maps.services.Status.OK) {
                let markerPosition = new window.kakao.maps.LatLng(result[0].y, result[0].x);

                let imageSrc = MapIcon, // 마커이미지의 주소입니다
                  imageSize = new window.kakao.maps.Size(35, 48), // 마커이미지의 크기입니다
                  imageOption = {
                    offset: new window.kakao.maps.Point(20, 48),
                  };

                var markerImage = new window.kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);

                let newMarker = new window.kakao.maps.Marker({
                  map: initialMap,
                  position: markerPosition,
                  image: markerImage,
                });
                setMarker(newMarker);
                initialMap.setCenter(markerPosition);
                document.querySelector("a[href='http://map.kakao.com/']").id = "pagination_stop";
              }
            });
          });
        }
      }, 300);
    }
  }, [map_address, locationList]);

  return (
    <>
      <BackgroundViewWhiteNotice key={"kakao_map_container"}>
        <KakaoMap>
          <KakaoMapHeaderImg id="map" />
        </KakaoMap>
      </BackgroundViewWhiteNotice>
      {realLocation?.map((item, idx) => (
        <WrapperRow
          cursor="pointer"
          onClick={() => {
            onButtonClick(item.address);
            setSelectLocation({
              x: item.x,
              y: item.y,
            });
          }}
          jc="space-between"
          ai="center"
          style={{ borderBottom: "1px solid #F9F9F9" }}
          padding="16px 0"
          width="100%"
        >
          <WrapperColumn>
            <WrapperRow>
              {item.x === selectLocation.x && item.y === selectLocation.y && <Icon width="20px" height="20px" src={selectMap} />}
              <Text size={Mobile ? "12px" : "14px"} weight="600" ls="-0.02em" color={colors.Gr05}>
                {item.city} {item.district} 지점
              </Text>
            </WrapperRow>
            <Text ws="pre-line" margin="4px 0 0 0" size={Mobile ? "10px" : "14px"} weight="500" ls="-0.02em" color={colors.Gr03}>
              {item.address} {item.extra}
            </Text>
          </WrapperColumn>
          <WrapperRow gap="8px">
            <Icon
              onClick={() => {
                navigator.clipboard.writeText(item.address ? item.address : "");
                setCopy(true);
                setTimeout(() => {
                  setCopy(false);
                }, 2500);
              }}
              width="32px"
              height="32px"
              src={copyMap}
            />

            <a target="_blank" href={`https://map.kakao.com/link/search/${item.address}`} style={{ textDecoration: "none", width: "100%" }}>
              <Icon width="32px" height="32px" src={goMapSite} />
            </a>
          </WrapperRow>
        </WrapperRow>
      ))}
      {location?.length > 2 && (
        <Wrapper margin="12px 0 0 0" width="100%" jc="center" ai="center">
          <Wrapper
            onClick={() => {
              setShow(!show);
            }}
            cursor="pointer"
            border="1px solid #DCDCDC"
            radius="8px"
            width="327px"
            height="44px"
            jc="center"
            ai="center"
          >
            <Text ws="pre-line" margin="0 4px 0 0" size="14px" weight="500" ls="-0.02em" color={colors.Gr05}>
              {show ? "간략히 보기" : `${location.length - 2}곳의 장소 더 보기`}
            </Text>
            <RightArrowIcon
              fill={colors.Gr05}
              width="16px"
              height="16px"
              style={{ transition: "all 0.7s", transform: show ? "rotate(-90deg)" : "rotate(90deg)" }}
            />
          </Wrapper>
        </Wrapper>
      )}

      {copy && (
        <CopyModal>
          <Text size="14px" weight="500" ls="-0.02em" color="#ffffff">
            주소를 복사했어요
          </Text>
        </CopyModal>
      )}
    </>
  );
};

export default Kakao_map;

const CopyModal = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 99;
  bottom: 48px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 320px;
  height: 44px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
`;
