import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import { Icon, Wrapper, Text, WrapperColumn, WrapperRow, AbsoluteWrapper, Input, Label } from "../component/layout/layoutStorage";

import OkayBlue from "../icon/purchasecomplete.png";

const PhoneValid = ({ info, setInfo, type, verT, setVerT, phoneB, setPhoneB }) => {
  const [auth, setAuth] = useState("");

  const AuthCheck = () => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "verification_confirm");
    frm.append("verification_token", verT);
    frm.append("verification_number", auth);
    frm.append("phone_number", info.phone);
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_user/profile/profile_new", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.verification_status;

        if (Status == "success") {
          alert("인증이 완료되었습니다.");
          setPhoneB("finish");
        } else {
          alert("인증번호를 다시 확인해주세요.");
        }
      });
  };

  const [timer, setTimer] = useState(180);

  const min = "0" + Math.floor(timer / 60);
  const sec = timer % 60 < 10 ? "0" + (timer % 60) : timer % 60;

  useEffect(() => {
    if (timer !== 0 && phoneB == "send") {
      const time = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(time);
    }

    if (timer == 0) {
      window.alert("인증번호 입력시간이 지났습니다. 인증번호 재전송을 눌러서 다시 인증을 해주세요");
    }
  }, [timer, phoneB]);

  const phoneVerifier = () => {
    if (phoneB == "ready" || phoneB == "default") {
      if (info.phone.length == 13) {
        setPhoneB("ready");
      } else {
        setPhoneB("default");
      }
    }
  };

  useEffect(() => {
    phoneVerifier();
  }, [info.phone, phoneB]);

  const phoneVerificationNumberSend = () => {
    var token = localStorage.getItem("token");

    setAuth("");

    const frm = new FormData();

    frm.append("mode", "phone_number_confirm");
    frm.append("phoneNumber", info.phone);
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_user/profile/profile_new", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.phone_number_status;

        if (Status == "success") {
          setPhoneB("send");
          setVerT(Value.check_t);
          setTimer(180);
        }
      });
  };

  return (
    <WrapperColumn width={type === "signUp" && "100%"} padding={type === "signUp" ? "8px 24px" : "32px 0 0 0"}>
      {type !== "signUp" && (
        <Text size="14px" weight="500" color="#3c3e41">
          전화번호
        </Text>
      )}

      <Wrapper
        jc="space-between"
        margin="8px 0 0 0"
        border="1px solid #f5f5f5"
        radius="8px"
        bgc={phoneB == "send" || phoneB == "finish" ? "#F6F6F6" : "#ffffff"}
      >
        <Input
          maxLength="13"
          size="14px"
          color="#3c3e41"
          style={{ padding: "16px", borderRadius: "8px", width: "100%" }}
          value={info.phone}
          onChange={(e) => {
            setInfo((prev) => ({
              ...prev,
              phone: e.target.value
                .replace(/[^0-9]/g, "")
                .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
                .replace(/(\-{1,2})$/g, ""),
            }));
          }}
          disabled={phoneB == "send" || phoneB == "finish"}
          placeholder="전화 번호를 입력해 주세요"
          phColor="#ACAFB5"
        />

        <Wrapper margin="0 8px 0 0">
          {phoneB != "finish" ? (
            <AuthButton status={phoneB} onClick={() => info.phone.length == 13 && phoneB == "ready" && phoneVerificationNumberSend()}>
              {phoneB == "ready" || phoneB == "default" ? "인증번호 전송" : "전송 완료"}
            </AuthButton>
          ) : (
            <AuthButton
              status={"ready"}
              onClick={() => {
                setPhoneB("default");
                setAuth("");
                setTimer(180);
              }}
            >
              수정하기
            </AuthButton>
          )}
        </Wrapper>
      </Wrapper>

      {(phoneB == "send" || phoneB == "finish") && (
        <>
          <Wrapper
            jc="space-between"
            margin="8px 0"
            border="1px solid #f5f5f5"
            radius="8px"
            style={{
              backgroundColor: phoneB == "finish" ? "#F6F6F6" : "#ffffff",
            }}
          >
            <Input
              size="14px"
              color="#3c3e41"
              style={{
                padding: "16px",
                borderRadius: "8px",
                width: "100%",
              }}
              value={auth}
              onChange={(e) => setAuth(e.target.value)}
              disabled={phoneB == "finish"}
              placeholder="인증 번호를 입력해 주세요"
            />
            <Wrapper margin="0 8px 0 0">
              <Text size="14px" color="#3383ff" weight="600">{`${min}:${sec}`}</Text>

              <AuthNumButton status={phoneB} onClick={() => AuthCheck()}>
                인증 완료
              </AuthNumButton>
            </Wrapper>
          </Wrapper>
          <Wrapper jc="space-between">
            <Wrapper ai="center">
              <Icon src={OkayBlue} width="14px" height="14px" style={{ marginRight: "6px" }} />
              <Text size="12px" color="#66a2ff" weight="600">
                {phoneB == "send" ? "인증번호 전송이 완료되었습니다." : phoneB == "finish" ? "인증이 완료되었습니다." : ""}
              </Text>
            </Wrapper>
            {phoneB == "send" && <SpecialButton onClick={() => phoneVerificationNumberSend()}>인증번호 재전송</SpecialButton>}
          </Wrapper>
        </>
      )}
    </WrapperColumn>
  );
};

export default PhoneValid;

const AuthButton = styled.div`
  padding: 8px 12px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  background-color: ${({ status }) => (status == "ready" ? "#0e6dff" : "#dcdcdc")};
  cursor: ${({ status }) => (status == "ready" ? "pointer" : "default")};
  white-space: nowrap;
`;

const AuthNumButton = styled(AuthButton)`
  background-color: ${({ status }) => (status == "send" ? "#0e6dff" : "#dcdcdc")};
  cursor: ${({ status }) => (status == "send" ? "pointer" : "default")};
  margin-left: 8px;
`;

const SpecialButton = styled.span`
  padding-bottom: 4px;
  border-bottom: 1px solid #dcdcdc;
  font-size: 12px;
  color: #acafb5;
  align-self: end;
  cursor: pointer;
  margin-right: 8px;
`;
