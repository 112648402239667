import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

// kakao
const { Kakao } = window;

const KakaoShare = () => {
  const params = useParams();

  // func
  useEffect(() => {
    const frm = new FormData();
    frm.append("mode", "kakao_share");
    //   frm.append("partner_number", params.PN);
    frm.append("activity_number", params.AN);

    axios
      .post("https://ai.semos.kr/semos_user/service/activity_new", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var ShareData = Value.share_data;
        var status = Value.status;
        var status_message = Value.status_message;

        if (status == "success") {
          Kakao.Link.sendDefault({
            objectType: "feed",
            content: {
              title: `[${ShareData.sport}] ${ShareData.activity}`,
              description: `${ShareData.info}`,
              imageUrl: `${ShareData.profile}`,
              link: {
                androidExecutionParams: "",
                iosExecutionParams: "",
              },
            },
            buttons: [
              {
                title: "세모스앱 열기",
                link: {
                  androidExecutionParams: "",
                  iosExecutionParams: "",
                },
              },
            ],
          });

          setTimeout(() => {
            window.open("", "_self").close();
          }, 3000);
        } else if (status == "waring") {
          alert(status_message);

          setTimeout(() => {
            window.open("", "_self").close();
          }, 1000);
        }
      });

    /*Kakao.Channel.chat({
        channelPublicId: "_YxfVxfK",
      });*/

    /*setTimeout(() => {
        window.open('','_self').close();
      }, 5000);*/
  }, []);

  return <></>;
};

export default KakaoShare;
