import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Icon,
  Wrapper,
  Text,
  WrapperColumn,
  GmarketText,
  AbsoluteWrapper,
  WrapperRow,
} from "./layout/layoutStorage";

import ModalBox from "./modalBox";

import Exclam from "../icon/exclam.png";

const LogoutModal = ({ status, setStatus, setConfirm }) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    setConfirm(true);
    setStatus(false);
  };

  return (
    <ModalBox status={status} setStatus={setStatus} confirm={true}>
      <Wrapper padding="24px" bgc="#ffffff" radius="16px" width="375px">
        <WrapperColumn width="100%">
          <Text size="20px" weight="700" color="#1a1c1f">
            로그아웃 하시겠어요?
          </Text>

          <Wrapper height="8px" />

          <Text size="14px" weight="500" color="#818792">
            다시 로그인해서 세모스에 올 수 있어요!
          </Text>

          <Wrapper height="36px" />

          <Wrapper jc="center">
            <Icon src={Exclam} width="88px" height="88px" />
          </Wrapper>

          <Wrapper height="44px" />

          <Wrapper jc="space-between">
            <Wrapper
              padding="14.5px"
              jc="center"
              bgc="#E5F0FF"
              width="157px"
              radius="8px"
              cursor="pointer"
              onClick={(e) => {
                e.stopPropagation();
                logout();
              }}
            >
              <Text size="16px" weight="600" color="#0e6dff">
                로그아웃
              </Text>
            </Wrapper>

            <Wrapper
              padding="14.5px"
              jc="center"
              bgc="#0e6dff"
              width="157px"
              radius="8px"
              cursor="pointer"
              onClick={(e) => {
                e.stopPropagation();
                setStatus(false);
              }}
            >
              <Text size="16px" weight="600" color="#ffffff">
                취소
              </Text>
            </Wrapper>
          </Wrapper>
        </WrapperColumn>
      </Wrapper>
    </ModalBox>
  );
};

export default LogoutModal;
