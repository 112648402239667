import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate, useParams, Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";

import {
  Icon,
  Wrapper,
  Text,
  WrapperColumn,
  AbsoluteWrapper,
  WrapperRow,
} from "../component/layout/layoutStorage";

import Layout from "../component/layout/layout";
import PhotoModal from "../component/photoModal";
import { LoadingSpinner } from "../tool/loading";
import FloatingReview from "../component/floatingReview";
import MataTagChange from "../tool/MetaTag";
import AppModal from "../component/home/appModal";

import ArrowLeft from "../icon/carouselArrowLeftW.png";
import ArrowRight from "../icon/carouselArrowRightW.png";
import fullStar from "../icon/fullStar.svg";
import fullHeart from "../icon/fullHeart.png";
import checkPoint from "../icon/checkPoint.svg";
import BlueCheck from "../icon/bluecheck.png";
//import Arrow from "../icon/grayArrow.png";
import BlueArrow from "../icon/blueRightArrow.png";

const Mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

const PartnerDetail = () => {
  const { id } = useParams();
  const [menu, setMenu] = useState(1);
  const [info, setInfo] = useState("");
  const [sport, setSport] = useState([]);
  const [activityObj, setActivityObj] = useState("");
  const [scroll, setScroll] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  //리뷰 플로팅 박스
  const [review, setReview] = useState({
    range: "",
    list: [],
    count: 0,
    value: 0,
  });
  const [page, setPage] = useState(1);

  const [filter, setFilter] = useState("high_rate");

  const [values, setValues] = useState("");

  const [max, setMax] = useState(0);

  const [totalPages, setTotalPages] = useState(0);

  const [App, setApp] = useState(false);

  //리뷰 사진 모달
  const [photo, setPhoto] = useState(false);
  const [startImg, setStartImg] = useState(1);
  const [reviewImg, setReviewImg] = useState([]);

  const settings = {
    draggable: false,
    className: "slider variable-width",
    dots: false,
    arrows: true,
    infinite: false,
    // centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
  };

  useEffect(() => {
    setLoading(true);
    var token = localStorage.getItem("token");
    const frm = new FormData();
    frm.append("mode", "main_web");
    frm.append("partner", id);

    if (token) {
      frm.append("token", token);
    }

    axios
      .post("https://ai.semos.kr/semos_user/v4/activity/Partner", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Activities = data.data.partner_data.activity_list;
        var arr = [];

        setInfo(Value.partner_data);

        MataTagChange({
          title : `세모스 : 1등 스포츠 예약 플랫폼 - [${Value.partner_data.name}] 파트너`,
          description : `${Value.partner_data.info} | 스포츠 예약은 세모스!`,
          imageUrl : `${Value.partner_data.img_list ? Value.partner_data.img_list[0] : ""}`
        });

        for (let i = 0; i < Activities.length; i++) {
          var item = Activities[i];
          if (arr.indexOf(item.sport) == -1) {
            arr.push(item.sport);
          }
        }

        for (let i = 0; i < arr.length; i++) {
          var contents = [];

          Activities.map((x) => (x.sport == arr[i] ? contents.push(x) : ""));

          setActivityObj((prev) => ({ ...prev, [arr[i]]: contents }));
        }

        setSport(arr);
        setLoading(false);

        if(Mobile) {
          window.location.href = `semos://partner_detail/${id}`;
        }

      });

      const frm2 = new FormData();
      frm2.append('token', "");
      frm2.append('mode', 'view_history');
      frm2.append('activity_id', id);
      frm2.append('path', 'PartnerDetail');
      frm2.append('platform', 'WEB');
      
      axios
      .post('https://ai.semos.kr/semos_user/v3/wish_list/wish', frm2, {
          headers: {'Content-Type': 'multipart/form-data'},
        })
      .then(data => {
          var status = data.data.status;
          if (status === 'success') {
            console.log('RECENT CHECK SUCCESS');
          } else if (status === 'fail') {
            console.log('HISTORY CHECK FAIL');
          } else if (status === 'waring') {
            console.log('HISTORY CHECK WARNING');
          }
      });
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 79) {
      setScroll(true);
    } else {
      setScroll(false);
    }

    const isScrolledIntoView = (el) => {
      const rect = el.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;
      const isVisible = elemTop < window.innerHeight && elemBottom >= 155;
      return isVisible;
    };

    const activityInfo = document.getElementById("partnerInfo");
    // const activityLocation = document.getElementById("centerPartner");
    const activityReview = document.getElementById("partnerActivity");

    if (isScrolledIntoView(activityInfo)) {
      setMenu(1);
    } else if (isScrolledIntoView(activityReview)) {
      setMenu(2);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); //clean up
    };
  }, []);

  const scrollToTop = (id) => {
    const ele = document.getElementById(id);
    const elePosition = window.scrollY + ele.getBoundingClientRect().top - 155;
    window.scrollTo(0, elePosition);
  };

  const reviewFunction = () => {
    var token = localStorage.getItem("token");
    const frm = new FormData();

    frm.append("mode", "partner_review_web");
    frm.append("partner", id);
    frm.append("page", page);
    frm.append("filter", filter);

    if (token) {
      frm.append("token", token);
    }

    axios
      .post("https://ai.semos.kr/semos_user/service/partner", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;

        setReview({
          range: Value.review_range,
          list: Value.review_data,
          count: Value.review_count,
          value: Value.review_value,
        });

        if (Value.review_count) {
          setValues(Object.values(Value.review_range));
          setMax(Math.max(...Object.values(Value.review_range)));
          setTotalPages(Math.ceil(Value.review_count / 3));
        }
      });
  };

  useEffect(() => {
    reviewFunction();
  }, [page, filter]);

  const AppContinue = () => {
    setLoading(true)
    window.location.href = `semos://partner_detail/${id}`;

    setTimeout(() => {
      setApp(true);
      setLoading(false);
    }, 3000);
  }


  return (
      <Layout page={`partner_detail`}>
        {scroll ? (
          <Menu>
            <WrapperRow jc="flex-start" ai="flex-end" height={Mobile ? "60px" : "79px"} width="100%">
              <Wrapper
                cursor="pointer"
                onClick={() => scrollToTop("partnerInfo")}
                jc="center"
                ai="center"
                width="110px"
                height="60px"
                style={{
                  boxSizing: "border-box",
                  borderBottom: menu == 1 && "2px solid #0E6DFF",
                }}
              >
                <Text
                  size="16px"
                  weight="600"
                  color={menu == 1 ? "#0E6DFF" : "#818792"}
                >
                  파트너 정보
                </Text>
              </Wrapper>

              <Wrapper
                cursor="pointer"
                onClick={() => scrollToTop("partnerActivity")}
                jc="center"
                ai="center"
                width="110px"
                height="60px"
                style={{ borderBottom: menu == 2 && "2px solid #0E6DFF" }}
              >
                <Text
                  size="16px"
                  weight="600"
                  color={menu == 2 ? "#0E6DFF" : "#818792"}
                >
                  액티비티
                </Text>
                <Text
                  margin="0 0 0 6px"
                  size="12px"
                  weight="600"
                  color={menu == 2 ? "#0E6DFF" : "#ACAFB5"}
                >
                  {info.activity_count}
                </Text>
              </Wrapper>
            </WrapperRow>
          </Menu>
        ) : (
          ""
        )}

        <WrapperColumn width="100%">
          <Wrapper wrap={Mobile ? 'wrap' : 'nowrap'} ai="center">
            <Container>
              <Slider {...settings}>
                {info.img_list?.map((item, idx) => (
                  <div key={idx}>
                    <div style={{ position: "relative" }}>
                      <Icon of="cover" src={item} width={Mobile ? `100vw` : "461px"} height={Mobile ? `calc(100vw * (287/461))` : "287px"} />

                      <AbsoluteWrapper
                        bottom="24px"
                        right="24px"
                        bgc="rgba(0,0,0,0.4)"
                        radius="16px"
                        padding="2px 12px"
                      >
                        <Text size="12px" color="#ffffff" weight="600">
                          {idx + 1}&nbsp;
                        </Text>
                        <Text size="12px" color="#818792" weight="600">
                          {`/ ${info.img_list.length}`}
                        </Text>
                      </AbsoluteWrapper>
                    </div>
                  </div>
                ))}
              </Slider>
            </Container>

            <WrapperColumn padding={Mobile ? "0px 24px" : "0"} margin={Mobile ? "24px 0 0 0" : "0 0 0 24px"} width="100%">
              <Wrapper width="100%">
                <Icon
                  src={info.profile}
                  width={Mobile ? "96px" : "120px"}
                  height={Mobile ? "96px" : "120px"}
                  radius="50%"
                />

                <WrapperColumn margin="0 0 0 12px">
                  <Text size="20px" weight="700" color="#3c3e41">
                    {info.name}
                  </Text>
                </WrapperColumn>
              </Wrapper>

              <Wrapper
                border="1px solid #dcdcdc"
                padding="18px 32px"
                jc="center"
                ai="center"
                width="100%"
                margin="35px 0 0 0"
                radius="15px"
              >
                <Wrapper
                  width="100%"
                  jc="space-between"
                  ai="center"
                  padding="0 22px"
                >

                  <WrapperColumn ai="center">
                    <Text size="14px" weight="500" color="#3c3e41">
                      액티비티
                    </Text>

                    <Wrapper height="8px" />

                    <Text size="16px" weight="600" color="#0e6dff">
                      {`${info.activity_count}`}
                    </Text>
                  </WrapperColumn>
                  
                  <WrapperColumn ai="center">
                    <Text size="14px" weight="500" color="#3c3e41">
                      찜
                    </Text>

                    <Wrapper height="8px" />

                    <Wrapper>
                      <Icon
                        src={fullHeart}
                        width="16px"
                        height="16px"
                        margin="0 4px 0 0"
                      />
                      <Text size="16px" weight="600" color="#3c3e41">
                        {`${info.jjim_count}`}
                      </Text>
                    </Wrapper>
                  </WrapperColumn>

                  <WrapperColumn ai="center">
                    <Text size="14px" weight="500" color="#3c3e41">
                      리뷰&nbsp;
                      <Text size="13px" weight="500" color="#acafb5">
                        ({info.review_count})
                      </Text>
                    </Text>

                    <Wrapper height="8px" />

                    <Wrapper>
                      <Icon
                        src={fullStar}
                        width="16px"
                        height="16px"
                        margin="0 4px 0 0"
                      />
                      <Text size="16px" weight="600" color="#3c3e41">
                        {`${info.review_percent}`}
                      </Text>
                    </Wrapper>
                  </WrapperColumn>
                </Wrapper>
              </Wrapper>
            </WrapperColumn>
          </Wrapper>

          <Wrapper padding={Mobile ? "0px 24px" : "0"} wrap={Mobile ? "wrap" : "nowarp"} width={Mobile ? "100vw" : "100%"} height="100%" jc="space-between" ai="flex-start">
            
            <WrapperColumn margin="24px 0 0 0" width={Mobile ? "100vw" : "461px"}>
              <WrapperColumn id="partnerInfo">
                <Wrapper margin="0 0 24px 0">
                  <Text size="20px" weight="700" color="#3c3e41">
                    파트너 정보
                  </Text>
                </Wrapper>
                {
                  info.info ?
                    <>
                      <Wrapper margin="0 0 12px 0">
                        <Icon
                          src={checkPoint}
                          margin="0 8px 0 0"
                          width="24px"
                          height="24px"
                        />
                        <Text size="16px" weight="600" color="#3c3e41">
                          파트너 소개
                        </Text>
                      </Wrapper>

                      <Wrapper
                        border="1px solid #dcdcdc"
                        radius="14px"
                        padding="14px 18px"
                        bgc="#f9f9f9"
                      >
                        <Wrapper wrap={'wrap'} display={'inline-block'}>
                          {info.info?.split('\n').map((item, idx) => (
                            <span style={{
                              width : "100%",
                              display : "inline-block",
                              fontSize : 14,
                              fontWeight : 500,
                              color : "#3c3e41"
                            }} key={`partner_info_${idx}`}>{item}</span>
                          ))}
                        </Wrapper>
                      </Wrapper>
                    </>
                  : ""
                }

                {
                  info.career?.length ?
                    <>
                      <Wrapper margin="40px 0 0 0">
                        <Icon
                          src={checkPoint}
                          margin="0 8px 0 0"
                          width="24px"
                          height="24px"
                        />
                        <Text size="16px" weight="600" color="#3c3e41">
                          이력 / 경력
                        </Text>
                      </Wrapper>

                      <WrapperColumn
                        margin="8px 0 0 0"
                        border="1px solid #dcdcdc"
                        radius="14px"
                        padding="14px 12px"
                      >
                        {info.career?.map((item, idx) => (
                          <Wrapper
                            key={idx}
                            margin={idx == info.career.length - 1 ? "0" : "0 0 8px 0"}
                          >
                            <Icon
                              src={BlueCheck}
                              width="16px"
                              height="16px"
                              margin="0 8px 0 0"
                            />
                            <Text size="14px" weight="500" color="#3c3e41">
                              {item}
                            </Text>
                          </Wrapper>
                        ))}
                      </WrapperColumn>
                    </>
                  : ""
                }

              </WrapperColumn>

              <WrapperColumn id="partnerActivity">
                <Wrapper margin="40px 0 24px 0">
                  <Text size="20px" weight="700" color="#3c3e41">
                    {`액티비티`}&nbsp;
                  </Text>
                  <Text
                    size="16px"
                    weight="500"
                    color="#0e6dff"
                  >{`${info.activity_count}`}</Text>
                </Wrapper>

                {sport.map((cate, idx) => (
                  <WrapperColumn key={idx.toString()}>
                    <Wrapper padding="8px" bgc="#f9f9f9">
                      <Text size="12px" weight="500" color="#818792">
                        {`${cate} ${activityObj[cate].length}`}
                      </Text>
                    </Wrapper>

                    {activityObj[cate].map((item, i) => (
                      <ActivityCard
                        item={item}
                        key={i.toString()}
                        center={info.center_id}
                        navigate={navigate}
                        subscribe={info.subscribe}
                      />
                    ))}
                  </WrapperColumn>
                ))}
              </WrapperColumn>
            </WrapperColumn>

            <FloatingBox>
              <FloatingReview
                id={id}
                type="partner"
                filter={filter}
                setFilter={setFilter}
                page={page}
                setPage={setPage}
                values={values}
                setValues={setValues}
                max={max}
                setMax={setMax}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                review={review}
                setReview={setReview}
                reviewImg={reviewImg}
                setReviewImg={setReviewImg}
                phoro={photo}
                setPhoto={setPhoto}
                setStartImg={setStartImg}
                getData={reviewFunction}
              />
            </FloatingBox>
          </Wrapper>
        </WrapperColumn>
        {/* <ModalBox>dd</ModalBox> */}
        {photo && (
          <PhotoModal
            start={startImg}
            list={reviewImg}
            status={photo}
            setStatus={setPhoto}
          />
        )}

        <AppModal open={App} setOpen={setApp} Mobile={Mobile} page={'partner_detail'} />
        
        <LoadingSpinner loading={loading} />
      </Layout>
  );
};

export default PartnerDetail;

const ActivityCard = ({ item, center, navigate, subscribe }) => {
  const [open, setOpen] = useState(false);

  const ref = useRef();

  const handleClose = (e) => {
    if (open && (!ref.current || !ref.current.contains(e.target))) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [open]);

  return (
    <WrapperColumn onClick={() => {
      navigate(`/activity/${item.id}`)
    }} style={{ opacity: (item.status ? 1 : 0.3), cursor : 'pointer' }}>
      <Wrapper padding="16px 0" bb="1px solid #dcdcdc">
        <Icon
          src={item.image}
          width="96px"
          height="130px"
          of="cover"
          style={{
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
          }}
          margin="0 12px 0 0"
        />

        <WrapperColumn jc="space-between" height="130px" width="100%">
          <Wrapper jc={`flex-start`} ai={`flex-start`}  width="100%" style={{flexDirection : "column"}}>
            <Text size="16px" weight="500" color="#1a1c1f">
              {item.title}
            </Text>
            <Text size="18px" weight="600" color="#1a1c1f">
              {`${item.price}원`}
            </Text>
          </Wrapper>


          <WrapperColumn>
            <Wrapper margin="5px 0">
              {
                (subscribe == true )
                ?
                  <Wrapper
                    border="1px solid #3383ff"
                    radius="8px"
                    padding="3px 5px"
                    margin="0 5px 0 0"
                  >
                    <Text size="12px" weight="600" color="#3383FF">
                      #
                    </Text>
                    <Text size="12px" weight="600" color="#3383FF">
                      {`상담 가능`}
                    </Text>
                  </Wrapper>
                :
                  <></>
              }
              {item.tag.map((tag, idx) => (
                <Wrapper
                  key={idx}
                  border="1px solid #3383ff"
                  radius="8px"
                  padding="3px 5px"
                  margin="0 5px 0 0"
                >
                  <Text size="12px" weight="600" color="#3383FF">
                    #
                  </Text>
                  <Text size="12px" weight="600" color="#3383FF">
                    {tag}
                  </Text>
                </Wrapper>
              ))}
            </Wrapper>

            {item.status ? (
              <Wrapper ref={ref}>
                <Wrapper
                  width="100%"
                  padding="0px 8px"
                  //bgc="#f5f5f5"
                  jc="space-between"
                  ai="center"
                  //onClick={() => setOpen((prev) => !prev)}
                  //cursor="pointer"
                  radius="4px"
                >
                  <Wrapper>

                  </Wrapper>

                  <Icon
                    src={BlueArrow}
                    width="18px"
                    height="18px"
                    //transform={open ? "rotate(-90deg)" : "rotate(-90deg)"}
                    transition="all 0.3s ease"
                  />
                </Wrapper>

                {open ? (
                  <AbsoluteWrapper
                    top="38px"
                    left="0"
                    border="1px solid #dcdcdc"
                    radius="8px"
                    width="100%"
                    bgc="#ffffff"
                    z={4}
                  >
                    <WrapperColumn width="100%">
                      {item.location.map((loc, idx) => (
                        <Link
                          key={idx}
                          to={`/activity/${item.id}`}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <Wrapper
                            width="100%"
                            padding="10px"
                            bb={
                              item.location?.length - 1 == idx
                                ? ""
                                : "1px solid #f9f9f9"
                            }
                          >
                            <Text
                              size="12px"
                              weight="500"
                              color="#818792"
                            >{`${loc.si} ${loc.gu}`}</Text>
                          </Wrapper>
                        </Link>
                      ))}
                    </WrapperColumn>
                  </AbsoluteWrapper>
                ) : (
                  ""
                )}
              </Wrapper>
            ) : (
              ""
            )}
          </WrapperColumn>
        </WrapperColumn>
      </Wrapper>
    </WrapperColumn>
  );
};

const Menu = styled.div`
  width: 860px;
  position: fixed;
  background-color: #ffffff;
  top: 76px;
  align-items: flex-start;
  z-index: 30;

  @media screen and (max-width: 859px) {
    width : 100vw;
  }
`;

const Container = styled.div`
  .slick-slider {
    width: 461px;
    height: 287px;

    @media screen and (max-width: 859px) {
      width : 100vw;
      height : calc(100vw * (287/461));
    }
  }

  .slick-next::before {
    background-image: url(${ArrowRight});
    background-size: 32px;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    content: "";
  }
  .slick-prev::before {
    background-image: url(${ArrowLeft});
    background-size: 32px;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    content: "";
  }
`;

const RainbowBox = styled.span`
  border-radius: 20px;
  padding: 3px 10px 3px 10px;
  background: radial-gradient(
    100% 843.76% at 0% 52.38%,
    #ff6759 0%,
    #8d4ef2 44.79%,
    #0e6dff 100%
  );
  margin-bottom: 12px;
  align-self: baseline;
`;

const FloatingBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  border-radius: 8px;
  ${Mobile ?
  `margin: 0 0;
  width: 100vw;
  padding: 42px 0 36px 0;
  position: relative;`
  :
  `box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  margin: 40px 0 40px 0;
  width: 375px;
  padding: 42px 0 36px 0;
  position: sticky;
  top: 170px;`
  }
  background-color: #ffffff;
  overflow: auto;
`;
