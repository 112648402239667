import React from "react";

import Layout from "../component/layout/layout";
import ActivityDetail from "../component/activityDetail";

const Activity = () => {

  return (
      <Layout page={'activity_detail'}>
        <ActivityDetail />
      </Layout>
  );
};

export default Activity;
