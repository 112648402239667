import React, { useEffect } from "react";
import { Icon, Wrapper, Text, Input, Radio, WrapperColumn, WrapperRow } from "./layout/layoutStorage";

import colors from "../styles/colors";
import inputClose from "../icon/inputClose.svg";

const UserSpec = ({ PC, data, setData }) => {
  useEffect(() => {
    setData(data);
  }, []);

  // 숫자 입력
  const pattern = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
  const patternText = /^[가-힣a-zA-Z]+$/;

  // 성별 데이터
  const GENDER = [
    {
      label: "남",
      value: "1",
    },
    {
      label: "여",
      value: "2",
    },
  ];

  const changeHandle = (type, value) => {
    setData((prev) => ({ ...prev, [type]: value }));
  };

  return (
    <>
      <WrapperRow ai="center">
        <WrapperRow width="103px">
          <Text ws="pre" size="14px" weight="500" ls="-0.02em" color={colors.Gr04}>
            이용자 이름
          </Text>
        </WrapperRow>

        <WrapperColumn width="calc(100% - 100px)">
          <Wrapper padding="16px" border={"1px solid #F5F5F5"} radius="8px" height="52px">
            <Input
              ta="center"
              phColor="#ACAFB5"
              maxLength={10}
              value={data?.name}
              onChange={(e) => changeHandle("name", e.target.value)}
              width="100%"
              size="14px"
              weight="500"
              ls="-0.02em"
              placeholder="이용자 이름을 입력해 주세요"
            ></Input>

            {data?.name && (
              <Icon
                src={inputClose}
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  changeHandle("name", "");
                }}
                width="24px"
                height="24px"
              ></Icon>
            )}
          </Wrapper>
        </WrapperColumn>
      </WrapperRow>

      {/* 생년월일*/}
      <WrapperRow ai="center">
        <WrapperRow width="103px">
          <Text ws="pre" size="14px" weight="500" ls="-0.02em" color={colors.Gr04}>
            생년월일
          </Text>
        </WrapperRow>

        <WrapperColumn width="calc(100% - 100px)">
          <WrapperRow ai="center">
            <WrapperRow ai="center" padding="14px 16px" border={"1px solid #F5F5F5"} radius="8px" width={PC ? "66%" : "100%"} height="52px">
              <Input
                value={data?.birth}
                onChange={(e) => {
                  if (pattern.test(e.target.value)) changeHandle("birth", e.target.value);
                }}
                maxLength={6}
                ta="center"
                phColor="#ACAFB5"
                width="100%"
                size="14px"
                weight="500"
                ls="-0.02em"
                placeholder="YYMMDD"
              ></Input>
              {data?.birth && (
                <Icon
                  src={inputClose}
                  cursor="pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    changeHandle("birth", "");
                  }}
                  width="24px"
                  height="24px"
                ></Icon>
              )}
            </WrapperRow>
            <WrapperRow ai="center" jc="space-between" width={PC ? "33%" : "100%"}>
              {GENDER.map((item, idx) => (
                <WrapperRow key={idx} width="100%" ai="center">
                  <Text margin={"0 10px 0 13px"} size="14px" weight="500" ls="-0.02em" color={colors.Gr04}>
                    {item.label}
                  </Text>
                  <Radio
                    onClick={() => changeHandle("gender", item.value)}
                    cursor="pointer"
                    padding="3"
                    border={data?.gender === item.value ? "1px solid #0E6DFF" : "1px solid #ACAFB5"}
                    bgc={data?.gender === item.value && "#0E6DFF"}
                    width="16"
                  >
                    <div />
                  </Radio>
                </WrapperRow>
              ))}
            </WrapperRow>
          </WrapperRow>
        </WrapperColumn>
      </WrapperRow>

      {/* 키 */}
      <WrapperRow ai="center">
        <WrapperRow width="100px">
          <Text ws="pre" size="14px" weight="500" ls="-0.02em" color={colors.Gr04}>
            키 (cm)
          </Text>
        </WrapperRow>
        <WrapperColumn width="calc(100% - 100px)">
          <Wrapper padding="16px" border={"1px solid #F5F5F5"} radius="8px" height="52px">
            <Input
              ta="center"
              value={data?.height}
              onChange={(e) => {
                if (pattern.test(e.target.value)) changeHandle("height", e.target.value);
              }}
              maxLength={3}
              phColor="#ACAFB5"
              width="100%"
              size="14px"
              weight="500"
              ls="-0.02em"
              placeholder="숫자만 입력해 주세요."
            ></Input>
            {data?.height && (
              <Icon
                src={inputClose}
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  changeHandle("height", "");
                }}
                width="24px"
                height="24px"
              ></Icon>
            )}
          </Wrapper>
        </WrapperColumn>
      </WrapperRow>

      {/* 체중 */}
      <WrapperRow ai="center">
        <WrapperRow width="100px">
          <Text ws="pre" size="14px" weight="500" ls="-0.02em" color={colors.Gr04}>
            체중 (kg)
          </Text>
        </WrapperRow>
        <WrapperColumn width="calc(100% - 100px)">
          <Wrapper padding="16px" border={"1px solid #F5F5F5"} radius="8px" height="52px">
            <Input
              ta="center"
              value={data?.weight}
              onChange={(e) => {
                if (pattern.test(e.target.value)) changeHandle("weight", e.target.value);
              }}
              maxLength={3}
              phColor="#ACAFB5"
              width="100%"
              size="14px"
              weight="500"
              ls="-0.02em"
              placeholder="숫자만 입력해 주세요."
            ></Input>
            {data?.weight && (
              <Icon
                src={inputClose}
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  changeHandle("weight", "");
                }}
                width="24px"
                height="24px"
              ></Icon>
            )}
          </Wrapper>
        </WrapperColumn>
      </WrapperRow>

      <WrapperRow ai="center">
        <WrapperRow width="103px">
          <Text ws="pre" size="14px" weight="500" ls="-0.02em" color={colors.Gr04}>
            발 사이즈 (mm)
          </Text>
        </WrapperRow>
        <WrapperColumn width="calc(100% - 100px)">
          <Wrapper padding="16px" border={"1px solid #F5F5F5"} radius="8px" height="52px">
            <Input
              ta="center"
              value={data?.foot}
              onChange={(e) => {
                if (pattern.test(e.target.value)) changeHandle("foot", e.target.value);
              }}
              maxLength={3}
              phColor="#ACAFB5"
              width="100%"
              size="14px"
              weight="500"
              ls="-0.02em"
              placeholder="숫자만 입력해 주세요."
            ></Input>
            {data?.foot && (
              <Icon
                src={inputClose}
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  changeHandle("foot", "");
                }}
                width="24px"
                height="24px"
              ></Icon>
            )}
          </Wrapper>
        </WrapperColumn>
      </WrapperRow>
    </>
  );
};

export default UserSpec;
