import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useGoogleLogin } from "@react-oauth/google";

import { Text, Wrapper, Icon, WrapperColumn, Radio, GmarketText, AbsoluteWrapper, Input, WrapperRow } from "../component/layout/layoutStorage";
import colors from "../styles/colors";
import PhoneValid from "../component/phoneValid";
import { AppleLoginHandler } from "../component/basic/loginHandler";

//icon
import SemosLogo from "../icon/semosLogo.png";
import XCircle from "../icon/xCircle.svg";
import KaKao from "../icon/kakao.svg";
import Naver from "../icon/naver.svg";
import Google from "../icon/google.svg";
import inputClose from "../icon/inputClose.svg";
import Arrow from "../icon/dropdownArrowG.svg";
import Check from "../icon/checkCircle.svg";
import ModalImg from "../icon/pointModalImg.png";
import { ReactComponent as LeftArrowIcon } from "../icon/carouselArrowLeft.svg";
import { ReactComponent as Caution } from "../icon/caution.svg";



const { Kakao } = window;

const MobileLogin = () => {
  const { payId } = useParams();
  const navigate = useNavigate();

  const MobileCheck = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  useEffect(() => {
    if (!MobileCheck) {
      navigate(`/fac_info/${payId}`);
    }
  }, []);

  const [dup, setDup] = useState(false);

  const [signInPage, setSignInPage] = useState(0);
  const [nickName, setNickName] = useState("");
  const [age, setAge] = useState({
    show: "",
    value: "",
  });
  const [auth, setAuth] = useState("");
  const [info, setInfo] = useState({
    phone: "",
  });
  const [type, setType] = useState("");

  //성별 선택
  const [gender, setGender] = useState("");
  const GENDER = [
    {
      label: "남",
      value: "male",
    },
    {
      label: "여",
      value: "female",
    },
  ];

  // 닉네임 유효성검사
  const [nickNameConfirm, setNickNameConfirm] = useState(false);
  const [focus, setFocus] = useState(false);
  const [valid, setValid] = useState("");
  const [name, setName] = useState("");
  const [nameFocus, setNameFocus] = useState("");
  const [nameBorder, setNameBorder] = useState("");
  const [timer, setTimer] = useState(3);
  const [finish, setFinish] = useState("default");
  const [verT, setVerT] = useState("");
  const [phoneB, setPhoneB] = useState("default");

  //연령대 선택
  const [ageModal, setAgeModal] = useState(false);
  const ageModalRef = useRef();

  const handleClose = (e) => {
    if (ageModal && (!ageModalRef.current || !ageModalRef.current.contains(e.target))) {
      setAgeModal(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [ageModal]);

  const AgeList = [
    {
      text: "10대 (10~19)",
      show: "10대",
      value: "10~19",
    },
    {
      text: "20대 (20~29)",
      show: "20대",
      value: "20~29",
    },
    {
      text: "30대 (30~39)",
      show: "30대",
      value: "30~39",
    },
    {
      text: "40대 (40~49)",
      show: "40대",
      value: "40~49",
    },
    {
      text: "50대 (50~59)",
      show: "50대",
      value: "50~59",
    },
    {
      text: "60대 이상 (60~)",
      show: "60대",
      value: "60~69",
    },
  ];

  // const [Status, setValid] = useState("");
  let pattern = /^[가-힣a-zA-Z]+$/;

  useEffect(() => {
    var token = window.localStorage.getItem("token");
    if (nickName.length > 1 && nickName.length < 16) {
      const frm = new FormData();
      frm.append("mode", "nick_name_duplicate_new");
      frm.append("nickname", nickName);
      frm.append("token", token);

      axios
        .post("https://ai.semos.kr/semos_user/profile/register", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var check = data.data.nick_name_status;

          if (check === "success") {
            setNickNameConfirm("ok");
          } else {
            setNickNameConfirm("no");
          }
        });
    } else {
      setNickNameConfirm(false);
    }
  }, [focus, nickName]);

  useEffect(() => {
    // if (pattern.test(nickName) && nickName.length > 1) {
    //   setStatus("ok");
    // } else if (!nickName.length) {
    //   setStatus("");
    // } else {
    //   setStatus("no");
    // }

    if (nickName.length > 15) {
      setValid("15");
    } else if (!pattern.test(nickName) && nickName.length > 1) {
      setValid("incorrect");
    } else if (nickName.length === 1) {
      setValid("2");
    } else if (nickNameConfirm === "no") {
      setValid("exist");
    } else {
      setValid("");
    }
  }, [nickName, nickNameConfirm]);

  // border={nameFocus ? (pattern.test(name) ? "1px solid #66A2FF" : "1px solid #818792") : "1px solid #F5F5F5"}

  useEffect(() => {
    if (nameFocus && name.length == 0) {
      setNameBorder("#818792");
    } else {
      setNameBorder("#F5F5F5");
    }
    if (name.length > 0) {
      if (pattern.test(name)) {
        setNameBorder("#66A2FF");
      } else {
        setNameBorder("#FF9187");
      }
    }
  }, [nameFocus, name]);

  //카카오 로그인
  const signInWithKakao = (Token) => {
    const ThisLink = "semos.kr";

    if (Token) {
      const frm = new FormData();
      frm.append("mode", "kakao_login_check_new");
      frm.append("kakao_code", Token);
      frm.append("appCheck", "RNAPP");
      frm.append("url", ThisLink);

      axios
        .post("https://ai.semos.kr/semos_user/profile/auth", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var Value = data.data;
          var Status = Value.login_status;
          var UserData = Value.user_data;

          if (Status == "success") {
            window.localStorage.setItem("user_age", UserData.user_age);
            window.localStorage.setItem("user_email", UserData.user_email);
            window.localStorage.setItem("user_gender", UserData.user_gender);
            window.localStorage.setItem("user_name", UserData.user_name);
            window.localStorage.setItem("user_real_name", UserData.user_real_name);
            window.localStorage.setItem("user_phone_number", UserData.user_phone_number);
            window.localStorage.setItem("user_profile_img", UserData.user_profile_img);
            window.localStorage.setItem("lc", "yes");
            window.localStorage.setItem("token", Value.login_token);
            window.localStorage.setItem("login_type", "카카오");

            window.location.reload();
          } else if (Status == "not_complete") {
            window.localStorage.setItem("user_age", UserData.user_age);
            window.localStorage.setItem("user_email", UserData.user_email);
            window.localStorage.setItem("user_gender", UserData.user_gender);
            window.localStorage.setItem("user_name", "가입미완");
            window.localStorage.setItem("user_real_name", UserData.user_real_name);
            window.localStorage.setItem("user_phone_number", UserData.user_phone_number);
            window.localStorage.setItem("user_profile_img", UserData.user_profile_img);
            window.localStorage.setItem("lc", "no");
            window.localStorage.setItem("token", Value.login_token);
            window.localStorage.setItem("login_type", "카카오");

            // setStatus(true);
            // setTimeout(() => {
            setSignInPage(1);
            // }, 500);
          } else if (Status == "apple_user") {
            // navigate data 전달
            window.alert("이전에 가입한 애플 아이디가 있어요.\n 애플 아이디로 로그인해 주세요!");
            window.location.reload();
          } else if (Status == "fail") {
            // navigate data 전달
            window.alert("통신 오류로 로그인이 실패했어요.\n 다시 로그인해 주세요!");
            window.location.reload();
          }
        });
    } else {
      window.alert("카카오톡 오류로 로그인이 실패했어요.\n 다시 로그인해 주세요!");
    }
  };

  // const min = "0" + Math.floor(timer / 60);
  // const sec = timer % 60 < 10 ? "0" + (timer % 60) : timer % 60;

  const confirmSignUp = () => {
    var token = window.localStorage.getItem("token");
    const frm = new FormData();
    frm.append("mode", "register_check_new");
    frm.append("nickName", nickName);
    frm.append("realName", name);
    frm.append("ageRange", age.value);
    frm.append("gender", gender);
    frm.append("phoneNumber", info.phone);
    frm.append("verificationToken", verT);
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_user/profile/register", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((data) => {
        var result = data.data;
        var user_data = result.user_data;

        if (result.register_status === "success") {
          window.localStorage.setItem("user_age", user_data.user_age);
          window.localStorage.setItem("user_gender", user_data.user_gender);
          window.localStorage.setItem("user_name", user_data.user_name);
          window.localStorage.setItem("user_real_name", user_data.real_name);
          window.localStorage.setItem("user_phone_number", user_data.user_phone_number);
          window.localStorage.setItem("user_email", user_data.user_email);
          window.localStorage.setItem("lc", "yes");
          window.localStorage.setItem("token", token);

          setFinish("start");
          setSignInPage(3);
        }
      });
  };

  useEffect(() => {
    if (timer !== 0 && finish === "start") {
      const time = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(time);
    }

    if (timer == 0) {
      window.location.reload();
    }
  }, [timer, finish]);

  const NaverLogin = () => {
    var naverLogin = new window.naver.LoginWithNaverId({
      clientId: "D1wg21cq27Y8kl0QN6Rx", //내 애플리케이션 정보에 cliendId를 입력해줍니다.
      callbackUrl: "https://test.semos.kr/naver", // 내 애플리케이션 API설정의 Callback URL 을 입력해줍니다.
      isPopup: false,
      callbackHandle: true,
    });

    naverLogin.init();
  };

  useEffect(() => {
    NaverLogin();
  }, []);

  let naver_code = window.localStorage.getItem("naverToken");
  let log_type = window.localStorage.getItem("type");

  useEffect(() => {
    // if (naver_code && log_type == "네이버") {
    //   NaverAxios(naver_code);
    // }
  }, []);

  const NaverAxios = (access) => {
    const frm = new FormData();

    frm.append("mode", "naver_login_check_new");
    frm.append("naver_code", access);

    axios
      .post("https://ai.semos.kr/semos_user/profile/auth", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.login_status;
        var UserData = Value.user_data;
        if (Status == "success") {
          window.localStorage.setItem("user_age", UserData.user_age);
          window.localStorage.setItem("user_email", UserData.user_email);
          window.localStorage.setItem("user_gender", UserData.user_gender);
          window.localStorage.setItem("user_name", UserData.user_name);
          window.localStorage.setItem("user_real_name", UserData.user_real_name);
          window.localStorage.setItem("user_phone_number", UserData.user_phone_number);
          window.localStorage.setItem("user_profile_img", UserData.user_profile_img);
          window.localStorage.setItem("lc", "yes");
          window.localStorage.setItem("token", Value.login_token);
          window.localStorage.setItem("login_type", "네이버");

          window.location.reload();
        } else if (Status == "not_complete") {
          window.localStorage.setItem("user_age", UserData.user_age);
          window.localStorage.setItem("user_email", UserData.user_email);
          window.localStorage.setItem("user_gender", UserData.user_gender);
          window.localStorage.setItem("user_name", "가입미완");
          window.localStorage.setItem("user_real_name", UserData.user_real_name);
          window.localStorage.setItem("user_phone_number", UserData.user_phone_number);
          window.localStorage.setItem("user_profile_img", UserData.user_profile_img);
          window.localStorage.setItem("lc", "no");
          window.localStorage.setItem("token", Value.login_token);
          window.localStorage.setItem("login_type", "네이버");

          window.localStorage.removeItem("naverToken");
          window.localStorage.removeItem("type");

          // setStatus(true);
          // setTimeout(() => {
          setSignInPage(1);
          // }, 500);
        } else if (Status == "other_user") {
          // navigate data 전달
          window.alert("이전에 가입한 아이디가 있어요.\n다른 아이디로 로그인해 주세요!");
          window.location.replace(window.location.origin);
          window.localStorage.removeItem("naverToken");
          window.localStorage.removeItem("type");
        } else if (Status == "fail") {
          // navigate data 전달
          window.alert("통신 오류로 로그인이 실패했어요.\n다시 로그인해 주세요!");
          window.location.replace(window.location.origin);
          window.localStorage.removeItem("naverToken");
          window.localStorage.removeItem("type");
        }
      });
  };

  const GoogleLogin = useGoogleLogin({
    onSuccess: (response) => {
      axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${response.access_token}` },
        })
        .then((res) => {
          const frm = new FormData();

          frm.append("mode", "google_login_check_new_web");
          frm.append("google_code", res.data.sub);
          frm.append("google_email", res.data.email);
          frm.append("google_name", res.data.name);
          axios
            .post("https://ai.semos.kr/semos_user/profile/auth", frm, {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
              },
            })
            .then((data) => {
              var Value = data.data;
              var Status = Value.login_status;
              var UserData = Value.user_data;
              if (Status == "success") {
                window.localStorage.setItem("user_age", UserData.user_age);
                window.localStorage.setItem("user_email", UserData.user_email);
                window.localStorage.setItem("user_gender", UserData.user_gender);
                window.localStorage.setItem("user_name", UserData.user_name);
                window.localStorage.setItem("user_real_name", UserData.user_real_name);
                window.localStorage.setItem("user_phone_number", UserData.user_phone_number);
                window.localStorage.setItem("user_profile_img", UserData.user_profile_img);
                window.localStorage.setItem("lc", "yes");
                window.localStorage.setItem("token", Value.login_token);
                window.localStorage.setItem("login_type", "구글");
                window.location.reload();
              } else if (Status == "not_complete") {
                window.localStorage.setItem("user_age", UserData.user_age);
                window.localStorage.setItem("user_email", UserData.user_email);
                window.localStorage.setItem("user_gender", UserData.user_gender);
                window.localStorage.setItem("user_name", "가입미완");
                window.localStorage.setItem("user_real_name", UserData.user_real_name);
                window.localStorage.setItem("user_phone_number", UserData.user_phone_number);
                window.localStorage.setItem("user_profile_img", UserData.user_profile_img);
                window.localStorage.setItem("lc", "no");
                window.localStorage.setItem("token", Value.login_token);
                window.localStorage.setItem("login_type", "구글");
                // setStatus(true);
                // setTimeout(() => {
                setSignInPage(1);
                // }, 500);
              } else if (Status == "other_user") {
                // navigate data 전달
                window.alert("이전에 가입한 아이디가 있어요.\n다른 아이디로 로그인해 주세요!");
                window.location.replace(window.location.origin);
              } else if (Status == "fail") {
                // navigate data 전달
                window.alert("통신 오류로 로그인이 실패했어요.\n다시 로그인해 주세요!");
                window.location.replace(window.location.origin);
              }
            });
        });
    },
  });

  return (
    <>
      {signInPage == 0 ? (
        <WrapperColumn padding="71px 24px 86px 24px" ai="flex-start" jc="space-between" bgc="#ffffff" width="100%" height="100%">
          <WrapperColumn>
            <Icon src={SemosLogo} style={{ position: "relative", left: "-24px" }} width="124px" height="124px" />
            <>
              <WrapperRow>
                <Text margin="10px 0 0 0" style={{ lineHeight: "135%" }} ws="pre" size="24px" color="#0E6DFF" weight="500">
                  30초만에 회원가입
                </Text>
                <Text margin="10px 0 0 0" style={{ lineHeight: "135%" }} ws="pre" size="24px" color="#1A1C1F" weight="500">
                  하고
                </Text>
              </WrapperRow>
              <Text style={{ lineHeight: "135%" }} ws="pre" size="24px" color="#1A1C1F" weight="500">
                정보 입력하세요
              </Text>
              <WrapperColumn margin="7px 0 0 0">
                <Text style={{ lineHeight: "135%" }} ws="pre" size="14px" color="#818792" weight="500">
                  환영해요, 크루님!
                </Text>
                <Text style={{ lineHeight: "135%" }} ws="pre" size="14px" color="#818792" weight="500">
                  당신의 Sporty Lifestyle을 책임질
                </Text>
                <Text style={{ lineHeight: "135%" }} ws="pre" size="14px" color="#818792" weight="500">
                  레저 스포츠 큐레이터 세모스에요.
                </Text>
              </WrapperColumn>
              {/* <Wrapper>
                <Text style={{ lineHeight: "135%" }} ws="pre" size="24px" color="#0E6DFF" weight="700">
                  2,000P
                </Text>
                <Text style={{ lineHeight: "135%" }} ws="pre" size="24px" color="#1A1C1F" weight="700">
                  {` 바로 적립!`}
                </Text>
              </Wrapper> */}
            </>
          </WrapperColumn>

          <Wrapper fd="column" width="100%" jc="center">
            <Wrapper
              margin="32px 0 18.5px 0"
              position="relative"
              radius="19px"
              style={{ boxShadow: "0px 3px 17px rgba(0, 0, 0, 0.1)" }}
              bgc="#ffffff"
              width="283px"
              height="34px"
              ai="center"
              jc="center"
            >
              <GmarketText size="12px" color="#0E6DFF" weight="400">
                회원가입부터 로그인까지, 단 30초
              </GmarketText>
              <AbsoluteWrapper bottom="-10px">
                <Triangle />
              </AbsoluteWrapper>
            </Wrapper>
            <Wrapper gap="16px" margin="16px 0 0px 0">
              <Wrapper
                onClick={() => {
                  setType("카카오");
                  Kakao.Auth.login({
                    success: function (response) {
                      signInWithKakao(response.access_token);
                    },
                    fail: function (error) {
                      window.alert("카카오톡 오류로 로그인이 실패했어요.\n 다시 로그인해 주세요!");
                    },
                  });
                }}
                //   onClick={() => KakaoLoginV1()}
                cursor="pointer"
                jc="center"
                ai="center"
                width="56px"
                height="56px"
                bgc="#FEE500"
                radius="50%"
              >
                <Icon src={KaKao} width="24px" height="24px" />
              </Wrapper>

              <Wrapper id="naverIdLogin_loginButton" cursor="pointer" jc="center" ai="center" width="56px" height="56px" bgc="#03C75A" radius="50%">
                <Icon src={Naver} width="24px" height="24px" />
              </Wrapper>

              <Wrapper
                onClick={() => {
                  setType("구글");
                  GoogleLogin();
                }}
                cursor="pointer"
                jc="center"
                ai="center"
                width="56px"
                height="56px"
                bgc={colors.white}
                border="1px solid #DCDCDC"
                radius="50%"
              >
                <Icon src={Google} width="24px" height="24px" />
              </Wrapper>

              {/* <GoogleLogin
              style={{ display: "none" }}
              onSuccess={(credentialResponse) => {
                console.log(credentialResponse);
              }}
            /> */}
              <Wrapper cursor="pointer" bgc={colors.black} width="56px" height="56px" jc="center" ai="center" radius="50%">
                <AppleLoginHandler setSignInPage={setSignInPage} dup={dup} setDup={setDup} />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </WrapperColumn>
      ) : signInPage == 1 ? (
        <WrapperColumn jc="space-between" ai="center" bgc="#ffffff" width="100%" height="100%">
          <div>
            <Wrapper
              width="100%"
              style={{
                borderBottom: "1px solid #F5F5F5",
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
              }}
              padding="19px 24px"
              ai="center"
              jc="center"
            >
              <Text ws="pre" size="18px" color="#1A1C1F" weight="600">
                간편 30초 가입
              </Text>
              <AbsoluteWrapper left="24px">
                <LeftArrowIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setSignInPage(0);
                  }}
                  fill="#1A1C1F"
                  style={{ cursor: "pointer" }}
                  width="24px"
                  height="24px"
                />
              </AbsoluteWrapper>
            </Wrapper>

            <Wrapper width="100%" jc="space-between" padding="24px">
              <GmarketText ws="pre" size="16px" color="#3C3E41" weight="400">
                1. 원활한 세모스 사용을 위한 정보 기입
              </GmarketText>
              <div>
                <GmarketText ws="pre" size="16px" color="#0E6DFF" weight="400">
                  {signInPage}
                </GmarketText>
                <GmarketText ws="pre" size="16px" color="#ACAFB5" weight="400">
                  {/* /{type === "kakao" ? "1" : "2"} */}
                  /2
                </GmarketText>
              </div>
            </Wrapper>

            <WrapperColumn width="100%" padding="33px 24px 16px 24px" position="relative">
              <AbsoluteWrapper left="32px" top="16px" bgc="#FFFFFF" padding="10px" z="20">
                <Text style={{ lineHeight: "132%" }} ws="pre" size="10px" color="#3C3E41" weight="600">
                  닉네임
                </Text>
              </AbsoluteWrapper>
              <Wrapper
                width="100%"
                border={nickName.length == 0 ? "1px solid #F5F5F5" : valid ? "1px solid #FF9187" : "1px solid #66A2FF"}
                height="50px"
                radius="8px"
                padding="0 16px 0 18px"
              >
                <Input
                  value={nickName}
                  onChange={(e) => {
                    setNickName(e.target.value);
                  }}
                  phColor="#ACAFB5"
                  size="14px"
                  width="100%"
                  placeholder="2글자 이상 15글자 이하로 작성해 주세요!"
                  maxLength="15"
                ></Input>
                {/* <Icon src={Check} width="24px" height="24px" /> */}
                {nickName.length > 0 && <Icon cursor="pointer" onClick={() => setNickName("")} src={inputClose} width="24px" height="24px" />}
              </Wrapper>

              {valid && (
                <AbsoluteWrapper bottom="-4px">
                  <Caution fill="#FF9187" width="14px" height="14px" />
                  <Text margin="0 0 0 6px" size="12px" color="#FF9187" weight="600">
                    {/* 올바른 닉네임으로 작성해 주세요! */}
                    {valid === "exist"
                      ? "이미 존재하는 닉네임입니다."
                      : valid === "incorrect"
                      ? "올바른 닉네임으로 작성해 주세요!"
                      : Number(valid)
                      ? "닉네임은 2글자 이상 15글자 이하로 작성해주세요!"
                      : ""}
                  </Text>
                </AbsoluteWrapper>
              )}
            </WrapperColumn>

            <Wrapper width="100%" padding="16px 24px" position="relative">
              <Wrapper
                cursor="pointer"
                onClick={() => setAgeModal(!ageModal)}
                width="60%"
                jc="space-between"
                border="1px solid #dcdcdc"
                radius="8px"
                padding="15px 18px"
              >
                <Text size="14px" color="#818792" weight="700">
                  {age.show ? age.show : "연령대 선택"}
                </Text>
                <Icon width="24px" height="24px" src={Arrow} />
                {ageModal && (
                  <AbsoluteWrapper ref={ageModalRef} z="20" bgc="#ffffff" top="54px" left="0" width="100%" border="1px solid #DCDCDC" radius="8px">
                    <WrapperColumn width="100%">
                      {AgeList.map((item, idx) => (
                        <Wrapper
                          width="100%"
                          padding="12px 18px"
                          bb={idx + 1 != AgeList.length ? "1px solid #f5f5f5" : ""}
                          cursor="pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAge({
                              show: item.show,
                              value: item.value,
                            });
                            setAgeModal(false);
                          }}
                        >
                          <Text size="14px" weight="700" color="#818792">
                            {item.text}
                          </Text>
                        </Wrapper>
                      ))}
                    </WrapperColumn>
                  </AbsoluteWrapper>
                )}
              </Wrapper>

              {GENDER.map((item, idx) => (
                <div style={{ width: "13%", marginLeft: "28px", marginRight: "4px" }} key={idx}>
                  <Text size="14px" color="#1A1C1F" weight="700">
                    {item.label}
                  </Text>
                  <Radio
                    onClick={() => {
                      setGender(item.value);
                    }}
                    style={{ marginLeft: "10px" }}
                    cursor="pointer"
                    padding="3"
                    border={gender == item.value ? "1px solid #0E6DFF" : "1px solid #ACAFB5"}
                    bgc={gender == item.value && "#0E6DFF"}
                    width="16"
                  >
                    <div />
                  </Radio>
                </div>
              ))}
            </Wrapper>
          </div>
          <WrapperColumn ai="center" width="100%" padding="0 24px 32px 24px">
            <Wrapper
              margin="0 0 25px 0"
              position="relative"
              radius="19px"
              style={{ boxShadow: "0px 7px 12px rgba(0, 0, 0, 0.1)" }}
              bgc="#ffffff"
              width="283px"
              height="34px"
              ai="center"
              jc="center"
            >
              <GmarketText size="12px" color="#0E6DFF" weight="400">
                이제 딱 한 단계 남았어요!
              </GmarketText>
              <AbsoluteWrapper bottom="-10px">
                <Triangle />
              </AbsoluteWrapper>
            </Wrapper>
            <Wrapper
              onClick={(e) => {
                // e.stopPropagation();
                gender && pattern.test(nickName) && nickName.length > 1 && age && setSignInPage(2);
              }}
              cursor="pointer"
              ai="center"
              jc="center"
              width="100%"
              border="1px solid #dcdcdc"
              bgc={gender && pattern.test(nickName) && nickName.length > 1 && age ? "#0E6DFF" : "#F5F5F5"}
              radius="8px"
              padding="15px 18px"
            >
              <Text size="16px" color={gender && pattern.test(nickName) && nickName.length > 1 && age ? "#ffffff" : "#ACAFB5"} weight="600">
                {/* {type === "apple" ? "다음 단계로" : "🎉 가입 완료! 🎉"} */}
                다음 단계로
              </Text>
            </Wrapper>
          </WrapperColumn>
        </WrapperColumn>
      ) : signInPage == 2 ? (
        <WrapperColumn jc="space-between" ai="center" bgc="#ffffff" width="100%" height="100%">
          <div>
            <Wrapper
              width="100%"
              style={{
                borderBottom: "1px solid #F5F5F5",
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
              }}
              padding="19px 24px"
              ai="center"
              jc="center"
            >
              <Text ws="pre" size="18px" color="#1A1C1F" weight="600">
                간편 30초 가입
              </Text>
              <AbsoluteWrapper left="24px">
                <LeftArrowIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setSignInPage(1);
                  }}
                  fill="#1A1C1F"
                  style={{ cursor: "pointer" }}
                  width="24px"
                  height="24px"
                />
              </AbsoluteWrapper>
            </Wrapper>

            <Wrapper width="100%" jc="space-between" padding="24px">
              <GmarketText ws="pre" size="16px" color="#3C3E41" weight="400">
                2. 안전한 세모스 이용을 위한 본인 인증
              </GmarketText>
              <div>
                <GmarketText ws="pre" size="16px" color="#0E6DFF" weight="400">
                  {signInPage}
                </GmarketText>
                <GmarketText ws="pre" size="16px" color="#ACAFB5" weight="400">
                  {/* /{type === "kakao" ? "1" : "2"} */}
                  /2
                </GmarketText>
              </div>
            </Wrapper>

            <WrapperColumn width="100%" padding="16px 24px" position="relative">
              <Wrapper width="100%" border={` 1px solid ${nameBorder}`} height="52px" radius="8px" padding="0 16px">
                <Input
                  // ref = {focusRef}
                  onFocus={() => setNameFocus(true)}
                  onBlur={() => setNameFocus(false)}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  phColor="#ACAFB5"
                  size="14px"
                  width="100%"
                  placeholder="인증을 위해 본명을 입력해 주세요."
                  maxLength="15"
                ></Input>
                {!nameFocus && (
                  <>
                    {name.length > 0 ? (
                      pattern.test(name) ? (
                        <Icon width="24px" height="24px" src={Check} />
                      ) : (
                        <Caution fill="#ff9187" width="24px" height="24px" />
                      )
                    ) : (
                      ""
                    )}
                  </>
                )}
                {nameFocus && name.length > 0 && <Icon onClick={() => setName("")} width="24px" height="24px" src={inputClose} />}
              </Wrapper>
              {name.length > 0 && !pattern.test(name) && (
                <AbsoluteWrapper bottom="-4px">
                  <Caution fill="#FF9187" width="14px" height="14px" />
                  <Text margin="0 0 0 6px" size="12px" color="#FF9187" weight="600">
                    올바른 닉네임으로 작성해 주세요!
                  </Text>
                </AbsoluteWrapper>
              )}
            </WrapperColumn>

            <PhoneValid info={info} setInfo={setInfo} type="signUp" verT={verT} setVerT={setVerT} phoneB={phoneB} setPhoneB={setPhoneB} />
          </div>
          <WrapperColumn width="100%" padding="0 24px 32px 24px">
            <WrapperColumn padding="0 0 24px 0">
              <WrapperRow gap="4px">
                <Text
                  cursor="pointer"
                  style={{
                    borderBottom: "1px solid #ACAFB5",
                    lineHeight: "132%",
                  }}
                  size="14px"
                  weight="500"
                  ls="-0.02em"
                  color="#ACAFB5"
                  onClick={() => window.open("/semos_user_info_process", "_blank", "width=500, height=600, left=100, top=100")}
                >
                  개인정보 처리 방침,
                </Text>
                <Text
                  cursor="pointer"
                  style={{
                    borderBottom: "1px solid #ACAFB5",
                    lineHeight: "132%",
                  }}
                  size="14px"
                  weight="500"
                  ls="-0.02em"
                  color="#ACAFB5"
                  onClick={() => window.open("/semos_using_info", "_blank", "width=500, height=600, left=120, top=120")}
                >
                  이용약관
                </Text>
              </WrapperRow>
              <Text size="14px" weight="500" ls="-0.02em" color="#ACAFB5">
                위 내용을 모두 확인하였으며, 회원가입에 동의합니다.
              </Text>
            </WrapperColumn>
            {type === "apple" && (
              <Wrapper
                margin="0 0 25px 0"
                position="relative"
                radius="19px"
                style={{ boxShadow: "0px 7px 12px rgba(0, 0, 0, 0.1)" }}
                bgc="#ffffff"
                width="283px"
                height="34px"
                ai="center"
                jc="center"
              >
                <GmarketText size="12px" color="#0E6DFF" weight="400">
                  이제 딱 한 단계 남았어요!
                </GmarketText>
                <AbsoluteWrapper bottom="-10px">
                  <Triangle />
                </AbsoluteWrapper>
              </Wrapper>
            )}
            <Wrapper
              onClick={(e) => {
                name && phoneB == "finish" && confirmSignUp();
              }}
              cursor="pointer"
              ai="center"
              jc="center"
              width="100%"
              border="1px solid #dcdcdc"
              bgc={name && phoneB == "finish" ? "#0e6dff" : "#F5F5F5"}
              radius="8px"
              padding="15px 18px"
            >
              <Text size="16px" color={name && phoneB == "finish" ? "#ffffff" : "#ACAFB5"} weight="600">
                다음 단계로
              </Text>
            </Wrapper>
          </WrapperColumn>
        </WrapperColumn>
      ) : (
        <WrapperColumn ai="center" bgc="#ffffff" width="100%" height="100%">
          <Wrapper width="100%" padding="19px 24px 0 24px" jc="flex-end">
            <Icon
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/fac_info/${payId}`);
              }}
              cursor="pointer"
              src={XCircle}
              width="28px"
              height="28px"
            />
          </Wrapper>
          <WrapperColumn ai="center" margin="150px 0 20px 0">
            <Wrapper>
              <Text size="24px" color="#0E6DFF" weight="700">
                2,000P
              </Text>
              <Text size="24px" color="#1A1C1F" weight="500">
                가 즉시 적립되었어요!
              </Text>
            </Wrapper>
            <Text size="24px" color="#0E6DFF" weight="700">
              지금 바로 사용해 볼까요?
            </Text>
          </WrapperColumn>
          <Text size="14px" color="#818792" weight="500">
            세상의 모든 스포츠, 세모스와 함께해요!
          </Text>
          <Icon margin="36px 0 49px 0" cursor="pointer" src={ModalImg} width="160px" height="160px" />
          <Wrapper>
            <Text size="14px" color="#0E6DFF" weight="700">
              {timer}초
            </Text>
            <Text ws="pre" size="14px" color="#818792" weight="700">
              {` 후 창이 닫힙니다.`}
            </Text>
          </Wrapper>
        </WrapperColumn>
      )}
    </>
  );
};

export default MobileLogin;

const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-bottom: 12px solid #ffffff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  transform: rotate(-180deg);
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
`;
