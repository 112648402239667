import React, { Component } from "react";
import {
  BrowserRouter as Router,
  useParams,
  useNavigate,
  useLocation,
  useHistory,
  Link as domLink,
} from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

// 이미지
import semosLogo2 from "../icon/semos_logo2.png";
import BlackCloseIcon from "../icon/black_close_icon.png";
import WhiteCloseIcon from "../icon/white_delete_icon.svg";
import SearchIcon from "../icon/footer_search_gery.png";
import FooterEventGrey from "../icon/footer_event_grey.png";
import FooterCoachingGrey from "../icon/footer_coaching_grey.png";
import FooterInfoGrey from "../icon/footer_info_grey.png";
import SemoringTiger from "../icon/semoring_tiger.jpg";

// component

// css

const HeaderOuter = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: inline-block;
  width: 100%;
  z-index: 15;
  background-color: #ffffff;
`;

const Width80Container = styled.div`
  width: 100%;
  padding: 0 calc(50% - 40rem);
  display: inline-block;
  z-index : 15;
`;

const HeaderContent = styled.section`
  width: 100%;
  background-color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  flex-wrap: wrap;
  position: relative;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const DownLoadHeader = styled.div`
  height: 6rem;
  width: 80rem;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.8rem;
  background-color: #2c84ee;
`;

const DownLoadHeaderAppText = styled.span`
  width: calc(100% - 1.5rem);
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (min-width: 376px) {
    padding-left: 1rem;
  }
`;

const DownLoadHeaderAppTextContent = styled.span`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const DownLoadHeaderAppDelete = styled.img`
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;

const LoginHeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 3.2rem;
  display: inline-flex;
  justify-content: flex-end;
  background-color: #f4f4f4;
  padding: 0 1.8rem;
`;

const DesktopNavContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 20px 18px;
`;

const DesktopHeaderLinkGrey = styled(domLink)`
  margin-left: 2rem;
  display: inline-flex;
  align-items: center;
  font-size: 1.2rem;
  text-decoration: none;
  color: #707070 !important;
`;

const DesktopHeaderNavLink = styled(domLink)`
  display: inline-flex;
  flex-wrap: wrap;
  height: 5rem;
  align-items: strech;
  width: 4rem;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
`;

const DesktopHeaderNavLink2 = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  height: 5rem;
  align-items: strech;
  width: 4rem;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
`;

const DesktopHeaderNavLinkIcon = styled.img`
  display: inline-block;
  height: 25px;
  text-decoration: none;
`;

const DesktopHeaderNavLinkText = styled.span`
  display: inline-flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  color: #707070;
  font-size: 1.2rem;
  text-decoration: none;
`;

const DesktopHeaderSemosHome = styled(domLink)`
  display: inline-block;
  width: 9rem;
  text-decoration: none;
  cursor: pointer;
`;

const DesktopHeaderSemosLogo = styled.img`
  width: 9rem;
  display: inline-block;
`;

const SemosSearchContainer = styled.div`
  width: calc(100% - 29rem);
  display: inline-block;
  height: 50px;
  position: relative;
`;

const SemosSearchLink = styled.div`
  width: 100%;
  outline: none;
  text-decoration: none;
  height: 50px;
  padding: 0 18px 0 45px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #eeeeee;
  box-shadow: 1px 1px 6px #00000029;
  border-radius: 8px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  color : #000000 !important;
  cursor : pointer;
`;

const SemosSearchInput = styled.input`
  width: 100%;
  outline: none;
  text-decoration: none;
  height: 50px;
  padding: 0 18px 0 45px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #eeeeee;
  box-shadow: 1px 1px 6px #00000029;
  border-radius: 8px;
  position: relative;

`;

const SemosSearchIconWrapper = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background: transparent;
  padding-left: 18px;
`;

const SemosSearchIcon = styled.img`
  width: 18px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0, -50%);
`;

const SemosSearchIconReal = styled.img`
  width: 18px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0, -50%);

`;

const BackgroundViewWhiteNotice = styled.section`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #00000085;
  display: inline-flex;
  align-items: flex-end;
`;

const BackgroundViewWhiteClose = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  z-index: 101;
  overflow: hidden;
  cursor: pointer;
`;

const BackgroundViewWhiteContent = styled.div`
  width: 100%;
  max-width: 50rem;
  position: absolute;
  display: inline-flex;
  flex-wrap: wrap;
  top: 50%;
  left: 50%;
  z-index: 102;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 2.5rem;
  border-radius: 8px;
`;

const KakaoMapHeader = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1.5rem;
  display: inline-block;
`;

const KakaoMapHeaderClose = styled.span`
  width: 1.5rem;
  position: absolute;
  display: inline-block;
  top: 50%;
  right: 0.3rem;
  transform: translate(0, -50%);
  cursor: pointer;
`;

const KakaoMapHeaderIcon = styled.img`
  width: 100%;
  display: inline-block;
`;

const PushNoticeContainer = styled(domLink)`
  width: 100%;
  margin-top: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
`;

const PushNoticeImg = styled.img`
  width: 4.5rem;
  height: 4.5rem;
  display: inline-block;
`;

const PushNoticeArraw = styled.img`
  width: 2rem;
  display: inline-block;
  transform: rotate(180deg);
`;

const PushNoticeText = styled.span`
  width: calc(100% - 9rem);
  min-height: 4.5rem;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
`;

const PushNoticeTextContent = styled.span`
  width: 100%;
  display: inline-block;
`;

const PushNoticeTextLinkList = styled.div`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 25rem;
  padding-right: 0.3rem;
`;

const SearchContainer = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
  padding-left: 5rem;
`;

const SearchBackIcon = styled.img`
  width: 1.5rem;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
`;

const SearchGoIcon = styled.img`
  width: 2.5rem;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translate(0, -50%);
`;

const SearchContent = styled.input`
  width: 100%;
  padding: 1rem 1.5rem;
  display: inline-block;
  border-radius: 8px;
  border: 0.1rem solid #eeeeee;
  box-shadow: 1px 1px 6px #00000029;
  outline: none;
`;

const withParams = (Child) => {
  return (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    return <Child {...props} params={params} naviGate={navigate} PathLo={location} />;
  };
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appDownStatus: window.sessionStorage.getItem("appDownDesktop")
        ? window.sessionStorage.getItem("appDownDesktop") === "hide"
          ? "hide"
          : "show"
        : "show",
      thisPage: window.location.pathname.split("/")[1],
      noticePopupStatus: "hide",
      searchValue: "",
      searchText: this.props.searchText,
      pageTime: 0,
      appCheck : window.navigator.userAgent.indexOf("SemosAndroid") > -1 || window.navigator.userAgent.indexOf("SemosIosWebviewApp") > -1
    };
  }

  componentDidMount() {
    if (this.state.thisPage.length > 1) {
      var history_back_uri = document.referrer;

      if( !history_back_uri ) {
        var history_back_uri = window.localStorage.getItem("historyBack");
      }

      var userDevice = "";
      var device = "win16|win32|win64|mac|macintel";
      var page = this.state.thisPage;
      var userToken = window.localStorage.getItem("token");

      if (history_back_uri) {
        if (history_back_uri.indexOf("naver") !== -1) {
          var site_type = "naver";
        } else if (history_back_uri.indexOf("instagram") !== -1) {
          var site_type = "instagram";
        } else if (history_back_uri.indexOf("facebook") !== -1) {
          var site_type = "facebook";
        } else if (history_back_uri.indexOf("semos") !== -1) {
          var site_type = "semos";
        } else if (history_back_uri.indexOf("google") !== -1) {
          var site_type = "google";
        } else {
          var site_type = "another";
        }
      } else {
        var site_type = "typing";
      }

      if (window.navigator.platform) {
        if (device.indexOf(window.navigator.platform.toLowerCase()) < 0) {
          if( this.state.appCheck ) {
            userDevice = "APP";
          } else {
            userDevice = "MOBILE";
          }
        } else {
          userDevice = "PC";
        }
      }

      this.setState({
        pageTime: new Date().getTime(),
      });

      const frm = new FormData();
      frm.append("mode", "enter_log");
      frm.append("token", userToken);
      frm.append("device", userDevice);
      frm.append("site_type", site_type);
      frm.append("site", history_back_uri);
      frm.append("page", page);

      axios
        .post("https://ai.semos.kr/semos_user/profile/user_profile", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            window.localStorage.setItem("historyBack", this.state.thisPage);
        });
    }
  }

  componentWillUnmount() {
    if (this.state.thisPage.length > 1) {
      var beforeTime = this.state.pageTime;

      var device = "win16|win32|win64|mac|macintel";

      var nowTime = new Date().getTime();

      var StayTime = (nowTime - beforeTime) / 1000;

      var userToken = window.localStorage.getItem("token");

      var userDevice = "";

      var page = this.state.thisPage;

      if (StayTime > 300) {
        StayTime = 300;
      }

      if (window.navigator.platform) {
        if (device.indexOf(window.navigator.platform.toLowerCase()) < 0) {
          if( this.state.appCheck ) {
            userDevice = "APP";
          } else {
            userDevice = "MOBILE";
          }
        } else {
          userDevice = "PC";
        }
      }

      const frm = new FormData();
      frm.append("mode", "exit_log");
      frm.append("token", userToken);
      frm.append("device", userDevice);
      frm.append("page", page);
      frm.append("pageTime", StayTime);

      axios
        .post("https://ai.semos.kr/semos_user/profile/user_profile", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {});
    }
  }

  goBack = () => {
    this.props.naviGate(-1);
  };

  AppDownHeaderClose = () => {
    if (!this.state.thisPage) {
      this.props.AppDownStatus();
    }

    window.sessionStorage.setItem("appDownDesktop", "hide");
  };

  AppDownLoad = () => {
    var appDownLink = "https://reports.semos.kr";

    window.open(appDownLink, "_blank");
  };

  popupClose = (type) => {
    this.setState({
      [type]: "hide",
    });
  };

  searchContent = (value) => {
    this.setState({
      searchValue: value,
    });
  };

  searchGo = () => {
    if (this.state.searchValue) {
      this.props.naviGate(
        "/product/스포츠/언제/어디서/1/" + this.state.searchValue + "/review_all"
      );
    } else {
      this.props.naviGate("/product/스포츠/언제/어디서/1/all/review_all");
    }
  };

  searchTextGo = () => {
    if (
      this.state.searchText &&
      this.state.searchText !== "all" &&
      this.state.searchText !== "empty"
    ) {
      this.props.searchTextGo(this.state.searchText);
    } else {
      this.props.searchTextGo("all");
    }
  };

  enterKeyPress = (key, type) => {
    if (key == "Enter") {
      if (type == "search") {
        this.searchGo();
      } else if (type == "product") {
        this.searchTextGo();
      }
    }
  };

  loginCheck = (type, url, e) => {
    if (
      window.localStorage.getItem("lc") == "yes" &&
      window.localStorage.getItem("token").length > 5 &&
      window.localStorage.getItem("user_email").length > 5
    ) {
      //this.props.naviGate(url);
        this.clickEvent(type, url, e)
    } else {
      alert("로그인이 필요해요");
      this.props.naviGate("/");
      return;
    }
  };

  clickEvent = (type, link, e) => {

    e.preventDefault();

    var device = "win16|win32|win64|mac|macintel";

    if (window.navigator.platform) {
      if (device.indexOf(window.navigator.platform.toLowerCase()) < 0) {
        if( this.state.appCheck ) {
          userDevice = "APP";
        } else {
          userDevice = "MOBILE";
        }
      } else {
        var userDevice = "PC";
      }
    }

    var page = this.state.thisPage;
    var userToken = window.localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "click_log");
    frm.append("token", userToken);
    frm.append("device", userDevice);
    frm.append("page", page);
    frm.append("type", type);

    axios.post("https://ai.semos.kr/semos_user/profile/user_profile", frm, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((data) => {
      this.props.naviGate(link);
    });

  }

  render() {
    var mobileCheck =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    var appDownHeaderContainer = "";

    if (
      this.props.homeAppDown === "show" &&
      !mobileCheck &&
      !this.state.thisPage
    ) {
      var appDownHeaderContainer = [
        <Width80Container style={{ backgroundColor: "#2C84EE" }}>
          <DownLoadHeader key={"DownLoadHeader"}>
            <DownLoadHeaderAppText>
              <DownLoadHeaderAppTextContent
                onClick={this.AppDownLoad}
                className="semos_big_content weight_400 color_white "
              >
                나와 가장 가까운 운동 강사 세모스!{" "}
                <span style={{ fontWeight: "500" }}>&nbsp;지금 다운로드 {`>`}</span>
              </DownLoadHeaderAppTextContent>
            </DownLoadHeaderAppText>

            <DownLoadHeaderAppDelete
              onClick={this.AppDownHeaderClose}
              src={WhiteCloseIcon}
            />
          </DownLoadHeader>
        </Width80Container>,
      ];
    }

    if (
      this.state.thisPage == "coaching" ||
      !this.state.thisPage ||
      this.state.thisPage == "partner" ||
      this.state.thisPage == "product" ||
      this.state.thisPage == "event"
    ) {
      var headerLeft = "0";
    } else {
      var headerLeft = "0";
    }

    var login_header = [
      <Width80Container style={{ backgroundColor: "#F4F4F4" }}>
        <LoginHeaderContainer key={"LoginHeaderContainer"}>
          <DesktopHeaderLinkGrey
            className="color_grey "
            to="/"
          >
            로그인/회원가입
          </DesktopHeaderLinkGrey>
        </LoginHeaderContainer>
      </Width80Container>,
    ];

    var logout_header = [
      <Width80Container style={{ backgroundColor: "#F4F4F4" }}>
        <LoginHeaderContainer key={"LogoutHeaderContainer"}>
          <DesktopHeaderLinkGrey
            className="color_grey "
            to="/"
          >
            {window.localStorage.getItem("user_name")}님
          </DesktopHeaderLinkGrey>
        </LoginHeaderContainer>
      </Width80Container>,
    ];

    var noticePopUp = [
      <BackgroundViewWhiteNotice key={"BackgroundViewWhiteNotice"}>
        <BackgroundViewWhiteClose
          onClick={() => this.popupClose("noticePopupStatus")}
        />

        <BackgroundViewWhiteContent>
          <KakaoMapHeader className="semos_big_content color_black weight_700">
            알림이 왔어요!
            <KakaoMapHeaderClose>
              <KakaoMapHeaderIcon
                onClick={() => this.popupClose("noticePopupStatus")}
                src={BlackCloseIcon}
              />
            </KakaoMapHeaderClose>
          </KakaoMapHeader>

          <PushNoticeTextLinkList>
            <PushNoticeContainer to="">
              <PushNoticeImg src={SemoringTiger} />

              <PushNoticeText>
                <PushNoticeTextContent className="semos_small_content weight_500 color_black ">
                  환영합니다.
                </PushNoticeTextContent>

                <PushNoticeTextContent className="payment_terms_small weight_500 color_grey "></PushNoticeTextContent>
              </PushNoticeText>

              <PushNoticeArraw src="https://ai.semos.kr/아이콘_소스/공통_아이콘/뒤로가기_아이콘.svg" />
            </PushNoticeContainer>
          </PushNoticeTextLinkList>
        </BackgroundViewWhiteContent>
      </BackgroundViewWhiteNotice>,
    ];

    var DesktopHeaderBottom = [];

    if (this.state.thisPage == "product" || this.state.thisPage == "event") {
      DesktopHeaderBottom.push(
        <Width80Container>
          <DesktopNavContainer key={"DesktopNavContainer"}>
            {/*<DesktopHeaderNavLink2 onClick={() => this.setState({ noticePopupStatus : 'show' })}>
                          <DesktopHeaderNavLinkIcon src={semosNotice}/>
                          <DesktopHeaderNavLinkText>알림</DesktopHeaderNavLinkText>
                      </DesktopHeaderNavLink2>*/}

            <DesktopHeaderSemosHome to="/">
              <DesktopHeaderSemosLogo src={semosLogo2} />
            </DesktopHeaderSemosHome>

            <SemosSearchContainer>
              <SemosSearchInput
                onKeyPress={(e) => this.enterKeyPress(e.key, "product")}
                onChange={(e) => this.setState({ searchText: e.target.value })}
                value={
                  this.state.searchText == "all" ||
                  this.state.searchText == "empty"
                    ? ""
                    : this.state.searchText
                }
                placeholder="스포츠, 위치, 레슨명을 검색해보세요!"
                className="weight_400 color_black font_17"
              />

              <SemosSearchIconReal onClick={this.searchGo} src={SearchIcon} />
            </SemosSearchContainer>

            <DesktopHeaderNavLink2 onClick={(e) => this.clickEvent('event', "/event/스포츠/언제/어디서/1/all/review_all", e)}>
              <DesktopHeaderNavLinkIcon src={FooterEventGrey} />
              <DesktopHeaderNavLinkText>기획전</DesktopHeaderNavLinkText>
            </DesktopHeaderNavLink2>

            <DesktopHeaderNavLink2
              onClick={(e) => this.loginCheck('my_lesson', "/my_lesson", e)}
            >
              <DesktopHeaderNavLinkIcon src={FooterCoachingGrey} />
              <DesktopHeaderNavLinkText>내 레슨</DesktopHeaderNavLinkText>
            </DesktopHeaderNavLink2>

            <DesktopHeaderNavLink to="/profile">
              <DesktopHeaderNavLinkIcon src={FooterInfoGrey} />
              <DesktopHeaderNavLinkText>내 정보</DesktopHeaderNavLinkText>
            </DesktopHeaderNavLink>
          </DesktopNavContainer>
        </Width80Container>
      );
    } else {
      DesktopHeaderBottom.push(
        <Width80Container>
          <DesktopNavContainer key={"DesktopNavContainer"}>
            {/*<DesktopHeaderNavLink2 onClick={() => this.setState({ noticePopupStatus : 'show' })}>
                          <DesktopHeaderNavLinkIcon src={semosNotice}/>
                          <DesktopHeaderNavLinkText>알림</DesktopHeaderNavLinkText>
                      </DesktopHeaderNavLink2>*/}

            <DesktopHeaderSemosHome to="/home">
              <DesktopHeaderSemosLogo src={semosLogo2} />
            </DesktopHeaderSemosHome>

            <SemosSearchContainer>
              <SemosSearchLink
                className="font_17 weight_400 color_black"
                onClick={(e) => this.clickEvent('search', "/search", e)}
              >
                스포츠, 위치, 레슨명을 검색해보세요!
                  <SemosSearchIcon src={SearchIcon} />
              </SemosSearchLink>
            </SemosSearchContainer>

            <DesktopHeaderNavLink2 onClick={(e) => this.clickEvent('event', "/event/스포츠/언제/어디서/1/all/review_all", e)}>
              <DesktopHeaderNavLinkIcon src={FooterEventGrey} />
              <DesktopHeaderNavLinkText>기획전</DesktopHeaderNavLinkText>
            </DesktopHeaderNavLink2>

            <DesktopHeaderNavLink2
              onClick={(e) => this.loginCheck('my_lesson', "/my_lesson", e)}
            >
              <DesktopHeaderNavLinkIcon src={FooterCoachingGrey} />
              <DesktopHeaderNavLinkText>내 레슨</DesktopHeaderNavLinkText>
            </DesktopHeaderNavLink2>

            <DesktopHeaderNavLink to="/profile">
              <DesktopHeaderNavLinkIcon src={FooterInfoGrey} />
              <DesktopHeaderNavLinkText>내 정보</DesktopHeaderNavLinkText>
            </DesktopHeaderNavLink>
          </DesktopNavContainer>
        </Width80Container>
      );
    }

    return (
      <HeaderOuter>
        {this.state.noticePopupStatus == "show" ? noticePopUp : ""}

        <HeaderContent
          style={{ left: headerLeft }}
          className="semos_small_subject weight_500"
        >
          {/*appDownHeaderContainer*/}

          {window.localStorage.getItem("lc") === "yes" &&
          window.localStorage.getItem("user_name") &&
          window.localStorage.getItem("token")
            ? logout_header
            : login_header}

          {this.state.thisPage !== "search" ? (
            <>{DesktopHeaderBottom}</>
          ) : (
            <Width80Container>
              <DesktopNavContainer key={"DesktopNavContainer"}>
                <DesktopHeaderSemosHome to="/">
                  <DesktopHeaderSemosLogo src={semosLogo2} />
                </DesktopHeaderSemosHome>

                <SemosSearchContainer>
                  <SemosSearchInput
                    onKeyPress={(e) => this.enterKeyPress(e.key, "search")}
                    onChange={(e) => this.searchContent(e.target.value)}
                    value={this.state.searchValue}
                    type='search'
                    placeholder="스포츠, 위치, 레슨명을 검색해보세요!"
                    className="weight_400 font_17 color_black"
                  />

                  <SemosSearchIconReal
                    onClick={this.searchGo}
                    src={SearchIcon}
                  />
                </SemosSearchContainer>

                <DesktopHeaderNavLink2 onClick={(e) => this.clickEvent('event', "/event/스포츠/언제/어디서/1/all/review_all", e)}>
                  <DesktopHeaderNavLinkIcon src={FooterEventGrey} />
                  <DesktopHeaderNavLinkText>기획전</DesktopHeaderNavLinkText>
                </DesktopHeaderNavLink2>

                <DesktopHeaderNavLink2
                  onClick={(e) => this.loginCheck('my_lesson', "/my_lesson", e)}
                >
                  <DesktopHeaderNavLinkIcon src={FooterCoachingGrey} />
                  <DesktopHeaderNavLinkText>내 레슨</DesktopHeaderNavLinkText>
                </DesktopHeaderNavLink2>

                <DesktopHeaderNavLink to="/profile">
                  <DesktopHeaderNavLinkIcon src={FooterInfoGrey} />
                  <DesktopHeaderNavLinkText>내 정보</DesktopHeaderNavLinkText>
                </DesktopHeaderNavLink>
              </DesktopNavContainer>
            </Width80Container>
          )}
        </HeaderContent>
      </HeaderOuter>
    );
  }
}

export default withParams(Header);
