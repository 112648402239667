import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import { Loading } from "react-loading-dot";
import Sehorang from "../icon/sehorangDown.png";

const TossPay = () => {
  // 액티비티 id, 스케줄 id, 날짜 정보, 인원수, 금액, 인원 수, 포인트 사용, device,
  const {
    partner_number,
    activity_number,
    date_number,
    activity_name,
    amount,
    people,
    device,
    point,
    spec_token,
    pay_type,
  } = useParams();

  useEffect(() => {
    const user_token = window.localStorage.getItem("token");
    const randomText = Math.random().toString(36).substr(2, 11);
    axios
      .post(
        "https://ai.semos.kr/semos_user/service/app_payment",
        {
          token: user_token,
          partner_number: partner_number,
          activity_number: activity_number,
          date_number: date_number,
          activity_name: activity_name,
          amount: amount,
          people: people,
          device: device,
          point: point,
          user_spec: spec_token,
          tid: randomText,
          mode: "toss_ready",
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        const Value = data.data;
        const Status = Value.status;
        const StatusMessage = Value.status_message;
        const PaymentId = Value.payment_id;
        const PaymentOid = Value.payment_oid;
        const PaymentUser = Value.payment_user;
        const PaymentEmail = Value.payment_email;
        const PaymentPhone = Value.payment_phone;
        const ActivityName = Value.activity_name;

        if (Status == "success") {
          var TossPay = window.TossPayments(
            "live_ck_ODnyRpQWGrNP41pBjKg3Kwv1M9EN"
          );

          TossPay.requestPayment(pay_type, {
            amount: amount,
            orderId: randomText,
            orderName: ActivityName,
            customerName: PaymentUser,
            customerEmail: PaymentEmail,
            customerMobilePhone: PaymentPhone,
            successUrl:
              window.location.origin +
              `/pay_success/${PaymentId}/${spec_token}`,
            failUrl:
              window.location.origin +
              `/payment/${partner_number}/${activity_number}/normal/${date_number}/${people}/empty`,
            appScheme: "semos://",
            cashReceipt: {
              type: "소득공제",
            },
          });
        } else if (Status == "fail") {
          window.alert(StatusMessage);
        } else if (Status == "waring") {
          window.alert(StatusMessage);
        }
      });
  }, []);

  return (
    <CrossContainer>
      <LoadingPayment>
        <div className="content">
          <img className="sehorang" src={Sehorang} />

          <div className={`dot_container`}>
            <Loading background={`#80B2FF`} />
          </div>

          <div className="text_view">결제페이지로 이동하고 있습니다.</div>
          <div className="text_view">잠시만 기다려 주세요!</div>
        </div>
      </LoadingPayment>
    </CrossContainer>
  );
};

export default TossPay;

const CrossContainer = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #ffffff;

  position: relative;
  padding: 5rem 15px 8rem 15px;
  display: inline-block;
  overflow: hidden;
  overflow-y: auto;
  z-index: 15;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
`;

const LoadingPayment = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 16;

  .bhynco {
    position: unset !important;
    transform: unset !important;

    div {
      width: 8px !important;
      height: 8px !important;
    }
  }

  .dot_container {
    width: 100%;
    margin: 10px 0;
  }

  .content {
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 17;
  }

  .sehorang {
    display: inline-block;
    width: 123px;
  }

  .text_view {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    font-weight: 400;
    line-height: 22.72px;
    font-size: 16px;
    color: #cce0ff;
    justify-content: center;
  }
`;
