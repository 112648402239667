import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Icon, Wrapper, Text, WrapperColumn } from "./layout/layoutStorage";
import colors from "../styles/colors";

//icon
import calender from "../icon/calender_check.svg";

const UserSpecSuccess = ({ PC, successPageText, setStatus }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isAndroid = () => {
    const userAgent = navigator.userAgent;
    return /Android/.test(userAgent);
  };

  return (
    <>
      {PC ? (
        <WrapperColumn ai="center" padding="36px 24px" radius="16px" jc="space-between" bgc="#ffffff" width="375px" height="auto">
          {/* 이용자 이름 */}
          <WrapperColumn width="100%" height="100%">
            <Text margin="0 0 8px 0" ws="pre" size="20px" color={colors.Gr06} weight="700">
              {successPageText?.title}
            </Text>
            {successPageText?.content?.map((item, index) => (
              <Text id={index} ws="pre" size="14px" color={colors.Gr04} weight="600">
                {item}
              </Text>
            ))}
            <Wrapper width="100%" jc="center" ai="center">
              <Icon margin="36px 0" src={calender} width="88px" height="88px" />
            </Wrapper>
          </WrapperColumn>
          <Wrapper style={{ borderTop: "1px solid #f5f5f5", marginTop: "8px" }} width="100%" gap="12px" padding={"8px 0 0 0"}>
            <Wrapper
              onClick={() => {
                if (pathname === "/my_semos") {
                  setStatus(false);
                  window.history.replaceState({}, null, "/my_semos");
                } else {
                  navigate("/");
                  setStatus(false);
                }
              }}
              cursor="pointer"
              jc="center"
              ai="center"
              height="56px"
              width="100%"
              bgc={colors.Bl07}
              radius="8px"
            >
              <Text size="16px" color={colors.white} weight="600">
                {successPageText?.btnText}
              </Text>
            </Wrapper>
          </Wrapper>
        </WrapperColumn>
      ) : (
        <WrapperColumn ai="center" jc="space-between" bgc="#ffffff" width="100%" height="100%">
          {/* <Icon width="375px" height="347px" src={ReviewImg} /> */}

          {/* 이용자 이름 */}
          <WrapperColumn width="100%" height="100%">
            <Wrapper
              width="100%"
              style={{
                borderBottom: "1px solid #F5F5F5",
              }}
              height="58px"
              padding="19px 24px"
              ai="center"
              jc="center"
            >
              <Text ws="pre" size="18px" color="#1A1C1F" weight="600">
                정보 입력 완료!
              </Text>
            </Wrapper>

            <WrapperColumn ai="center" jc="center" width="100%" height="100%">
              <Icon src={calender} width="88px" height="88px" />

              <Text margin="20px 0 8px 0" ws="pre" size="18px" color={colors.Bl07} weight="600">
                {successPageText?.title}
              </Text>
              {successPageText.content?.map((item, index) => (
                <Text id={index} ws="pre" size="16px" color={colors.Gr05} weight="600">
                  {item}
                </Text>
              ))}
            </WrapperColumn>
          </WrapperColumn>
          <Wrapper
            style={PC && { borderTop: "1px solid #f5f5f5", marginTop: "338px" }}
            width="100%"
            gap="12px"
            padding={PC ? "8px 24px 36px 24px" : "12px 24px 44px 24px"}
          >
            <a
              href={
                isAndroid()
                  ? "https://play.google.com/store/apps/details?id=com.report.semos"
                  : "https://apps.apple.com/kr/app/%EC%84%B8%EB%AA%A8%EC%8A%A4-%EC%84%B8%EC%83%81%EC%9D%98-%EB%AA%A8%EB%93%A0-%EC%8A%A4%ED%8F%AC%EC%B8%A0-%EA%B0%95%EC%8A%B5%EC%9D%B4-%EB%82%B4-%EC%86%90-%EC%95%88%EC%97%90/id1578637889"
              }
              style={{ textDecoration: "none", width: "100%" }}
              target="_blank"
              aria-label="세모스 다운로드"
            >
              <Wrapper cursor="pointer" jc="center" ai="center" height="56px" width="100%" bgc={colors.Bl07} radius="8px">
                <Text size="16px" color={colors.white} weight="600">
                  {successPageText?.btnText}
                </Text>
              </Wrapper>
            </a>
          </Wrapper>
        </WrapperColumn>
      )}
    </>
  );
};

export default UserSpecSuccess;
