import React, { Component } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const BackgroundViewWhiteNotice = styled.section`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #00000085;
  display: inline-flex;
  align-items: flex-end;
`;

const BackgroundViewWhiteContent = styled.div`
  width: 90%;
  max-width: 38rem;
  position: absolute;
  display: inline-flex;
  flex-wrap: wrap;
  top: 50%;
  left: 50%;
  z-index: 102;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
`;

const PayPopUpContainer = styled.div`
  width: 100%;
  padding: 4rem;
  display: inline-block;
`;

const PayPopUpClose = styled.span`
width : 100%;
padding : 2rem 0;
display : inline-flex;
align-items : center;
justify-content : center;
cursor : pointer;
border-radius : 0 0 8px 8px;

@media screen and (max-width: 800px) {
  padding : 1.5rem 0;
}
`;

const PayPopUpYes = styled.span`
width : 50%;
padding : 2rem 0;
display : inline-flex;
align-items : center;
justify-content : center;
cursor : pointer;
border-radius : 0 0 0 8px;

@media screen and (max-width: 800px) {
  padding : 1.5rem 0;
}
`;

const PayPopUpNO = styled.span`
width : 50%;
padding : 2rem 0;
display : inline-flex;
align-items : center;
justify-content : center;
cursor : pointer;
border-radius : 0 0 8px 0;

@media screen and (max-width: 800px) {
  padding : 1.5rem 0;
}
`;

const PayPopUpContent = styled.span`
  width: 100%;
  margin-bottom: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;


const PayPopUpContentText = styled.span`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const withParams = (Child) => {
  return (props) => {
    const params = useParams();
    const navigate = useNavigate();
    return <Child {...props} params={params} naviGate={navigate} />;
  };
};

class Pay_redi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payPopup: "hide",

      confirmPopup: "hide",
      confirmType: "",
      confirmFunction: "",
      confirmLink: "",
      confirmText: "",
      confirmFunctionNo: "",
    };
  }

  componentDidMount() {
    const current = decodeURI(window.location.href);
    const search = current.split("?")[1];
    const params = new URLSearchParams(search);
    const redi_url = params.get("redi_url");
    const redi_url2 = params.get("pg_token");

    const redi_url3 = redi_url;

    const frm = new FormData();

    frm.append("ut", window.localStorage.getItem("token"));
    frm.append("mode", "request");
    frm.append("pg_token", redi_url2);
    frm.append("pay_num", this.props.params.key1);

    axios
      .post("https://ai.semos.kr/semos_user/service/kakao_new", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var pay_status = data.data.status;
        var status_message = data.data.status_message;

        if (pay_status == "success") {
          this.setState({
            confirmPopup: "show",
            confirmText: status_message,
            confirmLink: redi_url3,
            confirmType: "",
          });
        } else if (pay_status == "fail") {
          this.setState({
            confirmPopup: "show",
            confirmText: status_message,
            confirmLink: "reload",
            confirmType: "",
          });
        } else if (pay_status == "waring") {
          this.setState({
            confirmPopup: "show",
            confirmText: status_message,
            confirmLink: "replace",
            confirmType: "",
          });
        }
      });
  }

  confirmHide = () => {
    this.setState({
      confirmPopup: "hide",
      confirmText: "",
      confirmLink: "",
      confirmType: "",
    });
  };

  popupFunction = () => {
    var app_check = navigator.userAgent.indexOf("SemosAndroid");

    var app_check_ios = navigator.userAgent.indexOf("SemosIosWebviewApp");

    if (this.state.confirmLink == "replace") {
      if (app_check != -1 || app_check_ios != -1) {
        window.location.replace("/");
      } else {
        window.opener.parent.location.replace("/");
        window.self.close();
      }
    } else if (this.state.confirmLink == "reload") {
      if (app_check != -1 || app_check_ios != -1) {
        window.location.reload();
      } else {
        window.opener.parent.location.reload();
        window.self.close();
      }
    } else if (this.state.confirmLink == "hide") {
      this.confirmHide();
    } else if (this.state.confirmLink == "back") {
      window.history.back();
    } else {
      if (app_check != -1 || app_check_ios != -1) {
        window.location.replace(this.state.confirmLink);
      } else {
        window.opener.parent.location.replace(this.state.confirmLink);
        window.self.close();
      }
    }
  };

  render() {
    if (this.state.confirmType == "confirm") {
      var PayPopup = [
        <BackgroundViewWhiteNotice>
          <BackgroundViewWhiteContent>
            <PayPopUpContainer>
              <PayPopUpContent className="color_black weight_700 font_20">
                알림
              </PayPopUpContent>

              {/*<PayPopUpContent>
                                <PayPopUpCheckIcon src={CheckBlueIcon}/>
                            </PayPopUpContent>*/}

              <PayPopUpContent
                style={{ margin: "0", lineHeight: "1.4" }}
                className="color_black weight_400 font_17"
              >
                <PayPopUpContentText>
                  {this.state.confirmText}
                </PayPopUpContentText>
              </PayPopUpContent>
            </PayPopUpContainer>

            <PayPopUpYes
              onClick={this.confirmHide}
              style={{ borderRight: ".1rem solid #ffffff" }}
              className="color_white bg_blue_100 font_17 weight_400"
            >
              취소
            </PayPopUpYes>

            <PayPopUpNO
              onClick={this.reportUpdate}
              className="color_white bg_blue_100 font_17 weight_400"
            >
              확인
            </PayPopUpNO>
          </BackgroundViewWhiteContent>
        </BackgroundViewWhiteNotice>,
      ];
    } else {
      var PayPopup = [
        <BackgroundViewWhiteNotice>
          <BackgroundViewWhiteContent>
            <PayPopUpContainer>
              <PayPopUpContent className="color_black weight_700 font_20">
                알림
              </PayPopUpContent>

              {/*<PayPopUpContent>
                                <PayPopUpCheckIcon src={CheckBlueIcon}/>
                            </PayPopUpContent>*/}

              <PayPopUpContent
                style={{ margin: "0", lineHeight: "1.4" }}
                className="color_black weight_400 font_17"
              >
                <PayPopUpContentText>
                  {this.state.confirmText}
                </PayPopUpContentText>
              </PayPopUpContent>
            </PayPopUpContainer>

            <PayPopUpClose
              onClick={this.popupFunction}
              className="color_white bg_blue_100 font_17 weight_400"
            >
              확인
            </PayPopUpClose>
          </BackgroundViewWhiteContent>
        </BackgroundViewWhiteNotice>,
      ];
    }

    return <>{this.state.confirmPopup == "show" ? PayPopup : ""}</>;
  }
}

export default withParams(Pay_redi);
