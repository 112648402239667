import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Icon, Wrapper, Text, WrapperColumn } from "./layout/layoutStorage";

import ModalBox from "./modalBox";

import Exclam from "../icon/exclam.png";

const RetireModal = ({ status, setStatus, setConfirm }) => {
  const retireProcess = () => {
    var token = localStorage.getItem("token");

    const frm = new FormData();
    frm.append("mode", "retire");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_user/profile/profile_new", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;

        if (Value.status === "success") {
          localStorage.clear();
          setConfirm(true);
        } else {
          window.alert("회원 탈퇴에 실패했습니다. 잠시 후 다시 시도해주세요");
        }
      });
  };

  return (
    <ModalBox status={status} setStatus={setStatus} confirm={true}>
      <Wrapper padding="24px" bgc="#ffffff" radius="16px" width="375px">
        <WrapperColumn width="100%">
          <Text size="20px" weight="700" color="#1a1c1f">
            회원 탈퇴를 원하시나요?
          </Text>

          <Wrapper height="8px" />

          <Text size="14px" weight="500" color="#818792">
            즉시 탈퇴 처리가 진행돼요.
          </Text>

          <Text size="14px" weight="500" color="#818792">
            탈퇴 후에는&nbsp;
            <Text color="#FF6052">개인정보를 포함한 혜택 및 포인트가</Text>
          </Text>

          <Text size="14px" weight="500" color="#818792">
            <Text color="#FF6052">삭제</Text>
            &nbsp;되며&nbsp;
            <Text color="#FF6052">복구가 불가능</Text>
            &nbsp;해요.
          </Text>

          <Wrapper height="36px" />

          <Wrapper jc="center">
            <Icon src={Exclam} width="88px" height="88px" />
          </Wrapper>

          <Wrapper height="44px" />

          <Wrapper jc="space-between">
            <Wrapper padding="14.5px" jc="center" bgc="#E5F0FF" width="157px" radius="8px" cursor="pointer" onClick={() => retireProcess()}>
              <Text size="16px" weight="600" color="#0e6dff">
                세모스를 떠나기
              </Text>
            </Wrapper>

            <Wrapper padding="14.5px" jc="center" bgc="#0e6dff" width="157px" radius="8px" cursor="pointer" onClick={() => setStatus(false)}>
              <Text size="16px" weight="600" color="#ffffff">
                취소
              </Text>
            </Wrapper>
          </Wrapper>
        </WrapperColumn>
      </Wrapper>
    </ModalBox>
  );
};

export default RetireModal;
