import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Layout from "../component/layout/layout";
import UserSpecSuccess from "../component/userSpecSuccess";
import SignInModal from "../component/signInModal";
import FacInfoUserSpec from "../component/facInfoUserSpec";
import ModalBox from "../component/modalBox";

import ShareImg from "../img/shareProfile.png";

const FacInfo = () => {
  //mobile modal Text
  const [successPageText, setSuccessPageText] = useState({});

  const { payId } = useParams();
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [userSpecModal, setUserSpecModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signInModal, setSignInModal] = useState(false);
  const [ALD, setALD] = useState(false);

  // ogImage
  useEffect(() => {
    const ogImage = document.head.querySelector('meta[property="og:image"]');
    const originalImage = ogImage.getAttribute("content");
    ogImage.setAttribute("content", ShareImg);

    return () => {
      // 페이지 이탈 시 원래 이미지로 복원
      ogImage.setAttribute("content", originalImage);
    };
  }, []);

  const getData = (spec) => {
    setLoading(true);
    var token = localStorage.getItem("token");
    const frm = new FormData();
    frm.append("mode", "share_info");
    frm.append("token", token);
    frm.append("payment", payId);

    axios
      .post("https://ai.semos.kr/semos_user/v3/profile/index", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        if (Status === "success") {
          //mobile text
          setSuccessPageText({
            title: "정보입력 완료!",
            content: ["이제 세모스 앱 - 마이 세모스에서", "친구와 함께 예약을 확인할 수 있어요!"],
            btnText: "세모스 앱에서 예약 확인하기",
          });
          if (Value.data.id !== "new") {
            // 이미 초대 & 입력 완료된 액티비티
            // PC
            if (MobileCheck) {
              setSuccess(true);
            } else {
              // Mobile
              navigate("/my_semos", {
                state: {
                  id: payId,
                  userSpec: true,
                  successPageText: {
                    title: "정보입력 완료!",
                    content: ["이제 세모스 앱 - 마이 세모스에서", "친구와 함께 예약을 확인할 수 있어요!"],
                    btnText: "예약 확인하기",
                  },
                },
              });
            }
          }
        } else if (Status === "waring") {
          //올바르지 않은 액티비티
          window.alert(StatusMessage);
          navigate("/");
        } else if (Status === "done") {
          //만료
          setSuccessPageText({
            title: "입력이 완료된 액티비티예요",
            content: ["세모스에서 더 많은 액티비티를", "확인해보세요"],
            btnText: "세모스 앱 다운받기",
          });
          setSuccess(true);
          setUserSpecModal(true);
        }

        setLoading(false);
      });
  };

  const MobileCheck = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  useEffect(() => {
    let token = window.localStorage.getItem("token");

    if (!token) {
      if (MobileCheck) {
        navigate(`/login/${payId}`);
      } else {
        setSignInModal(true);
      }
    } else {
      getData();
    }
  }, []);

  return (
    <>
      {MobileCheck ? (
        // Mobile
        <>
          {success ? (
            <UserSpecSuccess successPageText={successPageText} success={success} setSuccess={setSuccess} />
          ) : (
            <FacInfoUserSpec setSuccess={setSuccess} />
          )}
        </>
      ) : (
        // PC
        <>
          <Layout>
            <FacInfoUserSpec setSuccess={setSuccess} PC={true} />
          </Layout>
          {signInModal ? <SignInModal page="userSpec" status={signInModal} setStatus={setSignInModal} dup={ALD} setDup={setALD} /> : ""}
          {/* 입력 완료된 액티비티 */}
          {userSpecModal && (
            <ModalBox confirm={true} status={userSpecModal} setStatus={setUserSpecModal}>
              <UserSpecSuccess
                PC={true}
                setStatus={setUserSpecModal}
                successPageText={{
                  title: "입력이 완료된 액티비티예요",
                  content: ["세모스에서 더 많은 액티비티를", "확인해보세요"],
                  btnText: "액티비티 둘러보기",
                }}
              />
            </ModalBox>
          )}
        </>
      )}
    </>
  );
};

export default FacInfo;
