import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import GoogleMapReact from 'google-map-react';
import axios from "axios";

// components
import { LoadingSpinner } from "../tool/loading";

// style
import colors from "../styles/colors";

import QR1 from "../img/QR_1.png";
import QR2 from "../img/QR_2.png";

const GoogleMap = () => {

    const [map, setMap] = useState();
    const [maps, setMaps] = useState();
    const [bounds, setBounds] = useState();
    const [center, setCenter] = useState();
    const [loading, setLoading] = useState(false);
    const [place, setPlace] = useState();
    const [service, setService] = useState();
    const [infowindow, setInfowindowh] = useState();

    const handleApiLoaded = async (map, maps) => {
        setMap(map);
        setMaps(maps);
    }

    const SearchPlace = () => {
        let Sports = 'Helicopter Tour';

        var request = {
            query: Sports,
            //fields: ['name', 'geometry', 'place_id', 'formatted_address', 'icon', 'types'],
            //locationBias: {north: bounds.ne.lat, south: bounds.sw.lat, east: bounds.ne.lng, west: bounds.sw.lng},
            //location: maps.LatLng(36.12876, -115.15151),
            //radius: '5000',
            bounds: {north: bounds.ne.lat, south: bounds.sw.lat, east: bounds.ne.lng, west: bounds.sw.lng},
        };

        var service = new maps.places.PlacesService(map);

        //service.findPlaceFromQuery(request, function(results, status, pagination) {
        service.textSearch(request, function(results, status, pagination) {
            if (status === maps.places.PlacesServiceStatus.OK) {
                let Count = 0;

                for(let i=0; i < results.length; i++) {

                    let Data = results[i];

                    let Geometry = Data.geometry;
                    let Location = Geometry.location.toJSON();
                    let FormattedAddress = Data.formatted_address.split(', ');

                    let Name = Data.name;
                    let Types = Data.types;
                    let Icon = Data.icon;
                    let Pid = Data.place_id;
                    let Lat = Location.lat;
                    let Lng = Location.lng;
                    let Address = Data.formatted_address;

                    let Country = FormattedAddress[3];
                    let State = FormattedAddress[2];
                    let City = FormattedAddress[1];
                    let Street = FormattedAddress[0];

                    if(FormattedAddress?.length == 4) {
                        if(Country != 'United States' && Country != 'USA') { 
                            Country = 'United States';
                            State = FormattedAddress[3];
                            City = FormattedAddress[2];
                            Street = `${FormattedAddress[0]}, ${FormattedAddress[1]}`;
                        }
                    }

                    if(FormattedAddress?.length > 4) {
                        Country = FormattedAddress[4];
                        State = FormattedAddress[3];
                        City = FormattedAddress[2];
                        Street = `${FormattedAddress[0]}, ${FormattedAddress[1]}`;
                    }

                    if(FormattedAddress?.length == 3) {
                        Country = FormattedAddress[2];
                        if(Country == 'United States' || Country == 'USA') {
                            State = FormattedAddress[1];
                            City = FormattedAddress[0];
                            Street = ``;
                        } else {
                            Country = 'United States';
                            State = FormattedAddress[2];
                            City = FormattedAddress[1];
                            Street = FormattedAddress[0];
                        }
                    }

                    if(FormattedAddress?.length == 2) {
                            Country = 'United States';
                            State = FormattedAddress[1];
                            City = FormattedAddress[0];
                            Street = ``;
                    }

                    Count = Count + 1;

                    axios
                        .post(
                            "https://ai.semos.kr/semos_user/v4/activity/Partner",
                            {
                                mode : "sports_place_google",
                                name : Name,
                                type : Types,
                                icon : Icon,
                                pid : Pid,
                                lat : Lat,
                                lng : Lng,
                                address : Address,
                                country : Country,
                                state : State,
                                city : City,
                                street : Street,
                                sports : Sports
                            },
                            {
                                headers: { "Content-Type" : "application/x-www-form-urlencoded" },
                            }
                        )
                        .then((result) => {
                            const Value = result.data;
                            const Status = Value.status;
                
                            if (Status == "success") {
                                console.log(`${Name} 추가 성공`);
                            } else if (Status == "fail") {
                                console.log(`${Name} 추가 실패`);
                            } else {
                                console.log(`${Name} 이미 추가된 장소`);
                            }
                
                        }).catch(error => {
                            if (error.response) {
                                window.alert(error.response.status)
                                // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                            } else if (error.request) {
                                console.log(error.request);
                            } else {
                                // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
                                console.log('Error', error.message);
                            }
                            console.log(error.config);
                        });
                }

                console.log(`Finish : [전체] ${Count}`)

                if (pagination && pagination.hasNextPage) {
                    pagination.nextPage();
                }
            } 
        });
    }

    const DetailInfo = async () => {

        let Count = 0;

        for await (const pl of place) { 

            Count = Count + 1;

            let PlaceId = pl.place_id;

            var request = {
                placeId: PlaceId,
                fields: ['photo']
            };
    
            let service = new maps.places.PlacesService(map);
    
            await service.getDetails(request, async (place, status) => {
                if (status == maps.places.PlacesServiceStatus.OK) {

                    let Data = place;
    
                    let IconBackgroundColor = Data.icon_background_color;
                    let InternationalPhoneNumber = Data.international_phone_number;
                    let Url = Data.url;
                    let OpeningHours = Data.opening_hours;
                    let Photos = Data.photos;
                    let PhotoList = [];
                    
                    if(Photos?.length) {
                        for await (const Photo of Photos) {
                            let GetPhoto = Photo.getUrl();
                            PhotoList.push(GetPhoto);
                        }
                    }

                    axios
                    .post(
                        "https://ai.semos.kr/semos_user/v4/activity/Partner",
                        {
                            mode : "sports_place_google_revise",
                            place_id : PlaceId,
                            /*icon_color : IconBackgroundColor,
                            phone : InternationalPhoneNumber,
                            url : Url,
                            schedule : OpeningHours,*/
                            image_list : PhotoList
                        },
                        {
                            headers: { "Content-Type" : "application/x-www-form-urlencoded" },
                        }
                    )
                    .then((result) => {
                        const Value = result.data;
                        const Status = Value.status;
            
                        if (Status == "success") {
                            console.log(`${PlaceId} 추가 성공`);
                        } else if (Status == "fail") {
                            console.log(`${PlaceId} 추가 실패`);
                        }
            
                    }).catch(error => {
                        if (error.response) {
                            window.alert(error.response.status)
                            // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    })
                }
            });
    
        }

        console.log(`FINISH : ${Count}`)
    }
    
        const LoadData = () => {
            setLoading(true);
    
            const frm = new FormData();
            frm.append("mode", "sports_place_google");
            frm.append("la", center.lat);
            frm.append("lo", center.lng);
            frm.append("swa", bounds.sw.lat);
            frm.append("swo", bounds.sw.lng);
            frm.append("nea", bounds.ne.lat);
            frm.append("neo", bounds.ne.lng);
            
            axios
                .post("https://ai.semos.kr/semos_user/v4/activity/SportsPlace", frm, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((data) => {
                    let Value = data.data;
                    let Status = Value.status;
                    let List = Value.list;
    
                    if(Status == 'success') {
                        setPlace(List);
                    }
    
                    setLoading(false);
                })
        }

    useEffect(() => {
        if(bounds && center) {
            LoadData();
        }
    }, [bounds, center]);
    

    return (
        <Container id={`map`}>
            <div
                style={{
                        position : "absolute",
                        top : 0,
                        left : 0,
                        width : 500,
                        display : "inline-block",
                        zIndex : 1000
            }}>
                <img
                    src={QR1}
                    style={{
                        height : 500,
                        width : "auto",
                        display : "inline-block",
                        zIndex : 1000
                    }}
                />

                <span
                    style={{
                        fontSize : 60,
                        fontWeight : 600,
                        width : 500,
                        textAlign : "center",
                        display : "inline-block",
                        marginTop : 15,
                        color : colors.Bl07
                    }}>
                    Introduction
                </span>
            </div>

            <div
                style={{
                        position : "absolute",
                        top : 660,
                        left : 0,
                        width : 500,
                        display : "inline-block",
                        zIndex : 1000
            }}>
                <img
                    src={QR2}
                    style={{
                        height : 500,
                        width : "auto",
                        display : "inline-block",
                        zIndex : 1000
                    }}
                />
                <span
                    style={{
                        fontSize : 60,
                        fontWeight : 600,
                        width : 500,
                        textAlign : "center",
                        display : "inline-block",
                        marginTop : 15,
                        color : colors.Bl07
                    }}
                >
                    Download
                </span>
            </div>

            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_TOKEN,
                    language: 'en',
                    region: 'US',
                    libraries:['places', 'core'],
                }}
                defaultCenter={{lat: 36.12876, lng: -115.15151}}
                center={{lat: 36.12876, lng: -115.15151}}
                defaultZoom={11}
                yesIWantToUseGoogleMapApiInternals
                margin = {[20, 20, 20, 20]}
                //options={''}
                //onChange={''}
                //onChildClick={''}
                onChange={({ center, zoom, bounds, marginBounds }) => {
                    setBounds(bounds)
                    setCenter(center)
                }}
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >
                <Marker
                    lat={36.12876} 
                    lng={-115.15151}
                    //onClick={() => DetailInfo()}
                >
                    {/*<span>
                        Here
                    </span>*/}

                    <div className="dot"/>
                </Marker>

                {
                    place?.map((item, idx) => (
                        <Marker
                            key={`place_${idx}`}
                            lat={item.y} 
                            lng={item.x}
                        >
                            {/*<span>
                                {item.title}
                            </span>*/}
        
                            <img style={{
                                //backgroundColor : item.icon_color
                            }} alt={item.title} src={item.icon} className="icon"/>
                        </Marker>
                    ))
                }
            </GoogleMapReact>

            <LoadingSpinner loading={loading} />
        </Container>
    );

};


export default GoogleMap;

const Container = styled.div`
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    position: relative;
`;

const Marker = styled.div`
    padding : 0 0 5px 0;
    display : inline-block;
    position : relative;

    span {
        display : inline-block;
        max-width: 10rem;
        font-size: 2rem;
        font-weight: 700;
        color : ${colors.Bl07};
        //text-decoration: underline;
        text-shadow: .3rem .3rem .3rem ${colors.B30};
        position : relative;
        z-index: 19;
    }

    .dot {
        display : inline-block;
        width : 2rem;
        height : 2rem;
        border-radius: 50%;
        background-color: ${colors.Bl07};
        border: 2px solid ${colors.White};
        position : absolute;
        bottom : -2rem;
        right : 50%;
        transform: translate(50%, 0);
        box-shadow: .3rem .3rem .3rem ${colors.B30};
        z-index: 20;
    }

    .icon {
        display : inline-block;
        width : 1.2rem;
        height : 1.2rem;
        position : absolute;
        bottom : -1.2rem;
        right : 50%;
        transform: translate(50%, 0);
        box-shadow: .3rem .3rem .3rem ${colors.B30};
        z-index: 10;
        background-color: ${colors.Bl02};
        opacity: .7;
        border-radius: 30%;
    }
`;