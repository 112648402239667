import React from "react";

import {
  Text,
  Wrapper,
  Icon,
  WrapperRow,
  Input,
  AbsoluteWrapper,
  WrapperColumn,
} from "../component/layout/layoutStorage";

const ProfileCoupon = () => {
  return (
    <WrapperColumn>
      <WrapperColumn>
        <Wrapper padding="16px 24px">
          <Text size="13px" color="#818792" weight="500">
            쿠폰 등록하기
          </Text>
        </Wrapper>

        <Wrapper
          jc="space-between"
          padding="0 24px 32px 24px"
          bb="2px solid #f5f5f5"
        >
          <Wrapper border="1px solid #dcdcdc" radius="4px" width="224px">
            <Input
              placeholder="쿠폰 코드를 입력해주세요."
              size="14px"
              color="#3c3e41"
              style={{
                width: "100%",
                padding: "6px 0 6px 10px",
                borderRadius: "4px",
              }}
            />
          </Wrapper>

          <Wrapper
            bgc="#0e6dff"
            padding="7px 32px"
            radius="8px"
            cursor="pointer"
            onClick={() => alert("등록 할 수 없는 쿠폰 코드입니다.")}
          >
            <Text size="14px" weight="700" color="#ffffff">
              등록
            </Text>
          </Wrapper>
        </Wrapper>
      </WrapperColumn>
    </WrapperColumn>
  );
};

export default ProfileCoupon;
