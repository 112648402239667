import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

import {
  Icon,
  Wrapper,
  Text,
  WrapperColumn,
  WrapperRow,
  AbsoluteWrapper,
  Input,
} from "../component/layout/layoutStorage";

import Back from "../icon/backbutton.png";

const NewAnnounceDetail = ({ id, setModalStatus, setOpen }) => {
  const [info, setInfo] = useState();

  const getData = () => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "notice_intro");
    frm.append("token", token);
    frm.append("notice_id", id);

    axios
      .post("https://ai.semos.kr/semos_user/service/notice", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        if (Value.status === "success") {
          setInfo(Value.notice_list[0]);
        }
      });
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <WrapperColumn width="100%">
      <Wrapper
        width="100%"
        height="60px"
        padding="17px 22px"
        jc="space-between"
        ai="center"
      >
        <Icon
          src={Back}
          width="24px"
          height="24px"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setModalStatus("announce");
            setTimeout(() => {
              setOpen(true);
            }, 0.5);
          }}
        />
        <Text size="18px" color="#1A1C1F" weight="600">
          공지사항
        </Text>
        <Icon src={Back} width="0px" height="0px" />
      </Wrapper>

      <Wrapper width="100%" height="1px" bgc="#F5F5F5" />

      <Wrapper padding="23px 24px 8px 24px">
        <RainbowBox style={{ marginRight: "14px" }}>
          <Text size="10px" weight="600" color="#ffffff">
            세모스 ONLY
          </Text>
        </RainbowBox>
        <Text size="18px" weight="600" color="#1a1c1f">
          {info?.notice_name}
        </Text>
      </Wrapper>

      <Wrapper width="100%" padding="0 24px 22px 24px" jc="flex-end">
        <Text size="12px" weight="500" color="#818792">
          {info?.notice_date}
        </Text>
      </Wrapper>

      <Icon src={info?.notice_content} width="100%" />
    </WrapperColumn>
  );
};

export default NewAnnounceDetail;

const RainbowBox = styled.div`
  border-radius: 20px;
  padding: 3px 7px;
  background: radial-gradient(
    100% 843.76% at 0% 52.38%,
    #ff6759 0%,
    #8d4ef2 44.79%,
    #0e6dff 100%
  );
`;
