import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";

//components
import ModalBox from "./modalBox";
import { Wrapper, Icon, AbsoluteWrapper } from "./layout/layoutStorage";

import ArrowLeft from "../icon/carouselArrowLeftW.png";
import ArrowRight from "../icon/carouselArrowRightW.png";
import PhotoClose from "../icon/photoClose.png";

const Mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

const PhotoModal = ({ start, list, status, setStatus }) => {
  const slickRef = useRef();
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    initialSlide: start,
  };
  return (
    <ModalBox status={status} setStatus={setStatus}>
      <PhotoModalWrap>
        <Slider ref={slickRef} {...settings}>
          {list.map((item, idx) => (
            <Wrapper width={Mobile ? "100vw" : "585px"} height={Mobile ? "" : ""} key={idx}>
              <Icon src={item} width={Mobile ? "100vw" : "585px"} height={Mobile ? "" : ""}></Icon>
            </Wrapper>
          ))}
        </Slider>
        {/* 닫기 버튼 */}
        <AbsoluteWrapper cursor="pointer" top="24px" right="24px" onClick={() => setStatus(false)}>
          <Icon src={PhotoClose} width="24px" height="24px" />
        </AbsoluteWrapper>
        {list.length > 1 && (
          <>
            {/* 화살표 */}
            <AbsoluteWrapper transform={"translate(0, 50%)"} cursor="pointer" left="20px" bottom={'50%'} onClick={() => slickRef.current.slickPrev()}>
              <Icon src={ArrowLeft} width="32px" height="32px" />
            </AbsoluteWrapper>
            <AbsoluteWrapper transform={"translate(0, 50%)"} cursor="pointer" right="20px" bottom={'50%'} onClick={() => slickRef.current.slickNext()}>
              <Icon src={ArrowRight} width="32px" height="32px" />
            </AbsoluteWrapper>
          </>
        )}
      </PhotoModalWrap>
    </ModalBox>
  );
};

export default PhotoModal;

const PhotoModalWrap = styled.div`
  position: relative;
  width: 585px;
  height: 585px;

  @media screen and (max-width: 859px) {
    width : 100vw;
    height : "";
  }
`;
