import React from "react";
import axios from "axios";
import styled from "styled-components";

// tool
// import { IPad, Android } from "./davice_check";
import { SaveMany } from "./localstorage";
import { Icon, Wrapper, Text } from "../layout/layoutStorage";

// image
import Apple from "../../icon/apple.svg";

export const AppleLoginHandler = ({ width, setSignInPage, dup, setDup }) => {
  if (!dup) {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/ko_KR/appleid.auth.js";
    document.head.appendChild(script);
    const thisLink = window.location.href.split("/")[2];

    const AppleLoginCheck = (data) => {
      var apple_token = data.detail.authorization.id_token;
      var apple_first = data.detail.user;

      const frm = new FormData();

      if (apple_first) {
        var user_firstName = data.detail.user.name.firstName;
        var user_lastName = data.detail.user.name.lastName;
        var user_name = user_lastName + user_firstName;
        var user_email = data.detail.user.email;

        frm.append("mode", "apple_login_check_new");
        frm.append("apple_code", apple_token);
        frm.append("user_name", user_name);
        frm.append("user_email", user_email);
      } else {
        frm.append("mode", "apple_login_check_new");
        frm.append("apple_code", apple_token);
      }

      axios
        .post("https://ai.semos.kr/semos_user/profile/auth", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var Status = Value.login_status;
          var Token = Value.login_token;
          var UserData = Value.user_data;

          if (Status === "success") {
            var nickname = UserData.user_name ? UserData.user_name : "가입미완";
            var name = UserData.user_real_name ? UserData.user_real_name : "가입미완";
            var age = UserData.user_age;
            var profile = UserData.profile_img;
            var phone = UserData.user_phone_number;
            var gender = UserData.user_gender;
            var email = UserData.user_email;

            var LocalStorageData = [
              { type: "user_name", value: nickname },
              { type: "user_real_name", value: name },
              { type: "user_age", value: age },
              { type: "user_profile_img", value: profile },
              { type: "user_phone_number", value: phone },
              { type: "user_gender", value: gender },
              { type: "user_email", value: email },
              { type: "token", value: Token },
              { type: "login_type", value: "애플" },
            ];

            if (SaveMany(LocalStorageData)) {
              window.location.reload();
            }
          } else if (Status === "not_complete") {
            var nickname = UserData.user_name ? UserData.user_name : "가입미완";
            var name = UserData.user_real_name ? UserData.user_real_name : "가입미완";
            var age = UserData.user_age;
            var profile = UserData.profile_img;
            var phone = UserData.user_phone_number;
            var gender = UserData.user_gender;
            var email = UserData.user_email;

            var LocalStorageData = [
              { type: "user_name", value: nickname },
              { type: "user_real_name", value: name },
              { type: "user_age", value: age },
              { type: "user_profile_img", value: profile },
              { type: "user_phone_number", value: phone },
              { type: "user_gender", value: gender },
              { type: "user_email", value: email },
              { type: "token", value: Token },
              { type: "login_type", value: "애플" },
            ];

            if (SaveMany(LocalStorageData)) {
              setSignInPage(1);
            }
          } else if (Status === "kakao_user") {
            window.alert("동일한 이메일로 가입된 카카오 계정이 있습니다.\n해당 아이디로 로그인해주세요!");
            window.location.reload();
          } else {
            window.alert("로그인이 실패했습니다.\n로그인 실패가 반복된다면 고객센터로 문의해주세요!");
            window.location.reload();
          }
        });
    };

    script.onload = () => {
      window.AppleID.auth.init({
        clientId: "com.reports.semosL",
        scope: "name email",
        redirectURI: `https://${thisLink}/home`,
        state: "report_osi",
        usePopup: true, //or false defaults to false
      });

      document.addEventListener("AppleIDSignInOnSuccess", (data) => AppleLoginCheck(data));
    };

    setDup(true);
  }
  return (
    <Wrapper
      // cursor="pointer"
      // jc="center"
      // ai="center"
      // width={"50px"}
      // height="50px"
      // margin="16px 0 0 0"
      // bgc="#000000"
      // radius="8px"
      // data-type="sign-in"
      // data-height="40"

      id="appleid-signin"
      data-mode="logo-only"
      data-color="black"
      data-border="false"
      data-border-radius="50"
      data-size="35"
      language="Korean"
    >
      <Icon src={Apple} width="28px" height="28px" />
      {/* <Text margin="0 0 0 15px" size="16px" color="#FFFFFF" weight="500">
        Apple로 시작하기
      </Text> */}
    </Wrapper>
  );
};

const Button = styled.div`
  width: 100%;
  padding: 6px 0;
  margin-bottom: 16px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 22.72px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
`;
