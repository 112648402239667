import { useEffect, useState } from "react";
import AppModal from "../component/home/appModal";
import axios from "axios";


function AppPage() {

  const [App, setApp] = useState(true);

  const Mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

  const goApp = (scheme) => {
    window.location.href = scheme;
  }
  

  useEffect(() => {
    const MobileCheck =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    const SchemaLog = async (platform, link, id, action) => {

      let history_back_uri = window.document.referrer;

      if (history_back_uri) {
        if (history_back_uri.indexOf("naver") !== -1) {
          var history_type = "naver";
        } else if (history_back_uri.indexOf("instagram") !== -1) {
          var history_type = "instagram";
        } else if (history_back_uri.indexOf("facebook") !== -1) {
          var history_type = "facebook";
        } else if (history_back_uri.indexOf("semos") !== -1) {
          var history_type = "semos";
        } else if (history_back_uri.indexOf("google") !== -1) {
          var history_type = "google";
        } else {
          var history_type = "another";
        }
      } else {
        var history_type = "typing";
      }

      const frm = new FormData()
      frm.append( 'mode', 'app_schema_page' )
      frm.append( 'platform', platform )
      frm.append( 'link', link )
      frm.append( 'id', id )
      frm.append( 'history', history_back_uri )
      frm.append( 'history_type', history_type )

      await axios.post("https://ai.semos.kr/semos_user/v4/profile/MyProfile", frm, {
          headers: {'Content-Type': 'multipart/form-data'}
      }).then( data => {

          const Value = data.data;
          const Status = Value.status;

          console.log(platform)
          console.log(link)
          console.log(id)
          console.log(history_back_uri)
          console.log(history_type)

          setTimeout(() => {
            action();
          }, 500);
      })
    }

    if (MobileCheck) {
      const current = decodeURI(window.location.href);
      const search = current.split("?")[1];
      const params = new URLSearchParams(search);
      const scheme = params.get("scheme");

      let splitScheme = scheme.split('/');
      let appPath = splitScheme[2];
      let appId = splitScheme[3]; 

      if(appPath == 'activity_detail') {
        SchemaLog('APP', "ActivityDetail", appId, window.location.replace(`/activity/${appId}`));
      } else if(appPath == 'partner_detail') {
        SchemaLog('APP', "PartnerDetail", appId, window.location.replace(`/partner_detail/${appId}`));
      } else {
        goApp(scheme);
      }

    } else {
      const current = decodeURI(window.location.href);
      const search = current.split("?")[1];
      const params = new URLSearchParams(search);
      const scheme = params.get("scheme");

      let splitScheme = scheme.split('/');
      let appPath = splitScheme[2];
      let appId = splitScheme[3]; 

      if(appPath == 'activity_detail') {
        SchemaLog('WEB', "ActivityDetail", appId, window.location.replace(`/activity/${appId}`));
      } else if(appPath == 'partner_detail') {
        SchemaLog('WEB', "PartnerDetail", appId, window.location.replace(`/partner_detail/${appId}`));
      } else {
        window.location.replace(`/`);
      }

    }
  }, []);


  return <AppModal open={App} setOpen={setApp} Mobile={Mobile} />;
}

export default AppPage;
