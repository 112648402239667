import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Text, Wrapper, Icon, WrapperRow, Input, AbsoluteWrapper, WrapperColumn, Radio } from "../../component/layout/layoutStorage";

import { ReactComponent as FilterArrow } from "../../icon/filterArrow.svg";
import { ReactComponent as Xbutton } from "../../icon/modalx.svg";
import { ReactComponent as Reset } from "../../icon/modalreset.svg";

const WhereFilter = ({ data, setData, name, value, filter }) => {
  if (data == "어디서") {
    var LOSID = "어디서";
    var LOVD = "전체";
  } else {
    var LOSID = data.split("_")[0];
    var LOVD = data.split("_")[1];
  }
  const ref = useRef();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [category, setCategory] = useState(LOSID);
  const [location, setLocation] = useState(LOVD);

  const handleClose = (e) => {
    if (open && (!ref.current || !ref.current.contains(e.target))) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [open]);

  const CategoryHandler = (type) => {
    setCategory(type);
    setLocation("전체");
  };

  const CategoryReset = () => {
    setCategory("어디서");
    setLocation("전체");
  };

  const setting = () => {
    if (category === "어디서") {
      navigate("/activity_list", {
        state: {
          sort: filter.sort,
          sport: filter.sport ? filter.sport : "",
          where: "어디서",
          when: filter.when ? filter.when : "",
          type: filter.type ? (filter.type == "유형" ? "액티비티" : filter.type) : "",
          duration: filter.duration ? filter.duration : "",
          price: filter.price ? filter.price : "",
          facility: filter.facility ? filter.facility : "",
          search: filter.search ? filter.search : "",
          semos: filter.semos,
          near: false,
          full: filter.full,
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 2);
    } else {
      navigate("/activity_list", {
        state: {
          sort: filter.sort,
          sport: filter.sport ? filter.sport : "",
          where: `${category}_${location}`,
          when: filter.when ? filter.when : "",
          type: filter.type ? (filter.type == "유형" ? "액티비티" : filter.type) : "",
          duration: filter.duration ? filter.duration : "",
          price: filter.price ? filter.price : "",
          facility: filter.facility ? filter.facility : "",
          search: filter.search ? filter.search : "",
          semos: filter.semos,
          near: filter.near,
          full: filter.full,
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 2);
    }
    setOpen(false);
  };

  return (
    <Wrapper margin="0 8px 8px 0" ref={ref}>
      <Wrapper
        padding="5px 11px"
        border={data != "어디서" ? "1px solid #3383FF" : "1px solid #dcdcdc"}
        bgc={data != "어디서" ? "#F2F7FF" : "transparent"}
        cursor="pointer"
        radius="10px"
        onClick={() => setOpen((prev) => !prev)}
      >
        <Text size="13px" weight="500" color={data != "어디서" ? "#3383FF" : "#818792"}>
          {data !== "어디서" ? `${LOSID} ${LOVD}` : "어디서"}
        </Text>

        <FilterArrow
          fill={data != "어디서" ? "#3383FF" : "#818792"}
          width="14px"
          height="18px"
          style={{
            marginLeft: "4px",
            transform: open ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "all 0.3s ease",
          }}
        />
      </Wrapper>

      {open ? (
        <AbsoluteWrapper
          padding="24px"
          top="40px"
          width="375px"
          height="676px"
          bgc="#ffffff"
          radius="8px"
          border="1px solid #dcdcdc"
          style={{ zIndex: 2 }}
          ai="flex-start"
        >
          <WrapperColumn width="100%" height="100%" jc="space-between">
            <WrapperColumn width="100%">
              <Wrapper jc="space-between">
                <Wrapper>
                  <Text size="18px" color="#1a1c1f" weight="600">
                    지역 선택
                  </Text>

                  <Wrapper width="12px" />

                  <Wrapper ai="center" padding="2px 0 0 0" cursor="pointer" onClick={() => CategoryReset()}>
                    <Text size="14px" weight="600" color="#0e6dff">
                      선택 초기화
                    </Text>
                    <Wrapper width="2px" />
                    <Reset width="20px" height="20px" />
                  </Wrapper>
                </Wrapper>

                <Xbutton width="28px" height="28px" onClick={() => setOpen(false)} style={{ cursor: "pointer" }} />
              </Wrapper>

              <Wrapper height="21px" />

              <Wrapper width="100%" ai="flex-start">
                <WrapperColumn width="40%" height="452px" style={{ overflow: "auto" }}>
                  {name
                    ? name.map((item, index) => (
                        <Wrapper
                          key={`${index}_location_si`}
                          padding="16px 18px"
                          bb={item.value == category ? "1px solid #0e6dff" : "1px solid #dcdcdc"}
                          onClick={() => CategoryHandler(item.value)}
                          cursor="pointer"
                        >
                          <Text size="14px" weight="600" color={item.value == category ? "#0e6dff" : "#818792"}>
                            {item.text}
                          </Text>
                        </Wrapper>
                      ))
                    : ""}
                </WrapperColumn>

                <WrapperColumn width="60%" height="452px" style={{ overflow: "auto" }}>
                  {value[category]
                    ? value[category].map((item, index) => (
                        <Wrapper
                          key={`${index}_loc_value`}
                          padding="16px 0 16px 18px"
                          bb={item.location_value == location ? "1px solid #0e6dff" : "1px solid #f5f5f5"}
                          jc="space-between"
                          onClick={() => setLocation(item.location_value)}
                          cursor="pointer"
                        >
                          <Text size="14px" weight="600" color={item.location_value == location ? "#0e6dff" : "#818792"}>
                            {item.location_category}
                          </Text>

                          <Radio
                            width="16"
                            padding="4"
                            border={item.location_value == location ? "1px solid #0e6dff" : "1px solid #dcdcdc"}
                            radius="50%"
                            margin="0 4px 0 0"
                            bgc={item.location_value == location ? "#0e6dff" : "#ffffff"}
                          >
                            <div />
                          </Radio>
                        </Wrapper>
                      ))
                    : ""}
                </WrapperColumn>
              </Wrapper>
            </WrapperColumn>

            <Wrapper width="100%" bgc="#0e6dff" padding="16px 0" radius="8px" jc="center" margin="0 0 18px 0" cursor="pointer" onClick={() => setting()}>
              <Text size="16px" weight="600" color="#ffffff">
                선택한 스포츠 찾기
              </Text>
            </Wrapper>
          </WrapperColumn>
        </AbsoluteWrapper>
      ) : (
        ""
      )}
    </Wrapper>
  );
};

export default WhereFilter;
