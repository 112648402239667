import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

import {
  Icon,
  Wrapper,
  Text,
  WrapperColumn,
  WrapperRow,
  AbsoluteWrapper,
  Input,
} from "../component/layout/layoutStorage";

import Back from "../icon/backbutton.png";
import BlueExclamation from "../icon/blueWarning.png";
import RightArrow from "../icon/rightButton.png";

const NewAnnounce = ({ setModalStatus, setOpen, setAnnounce }) => {
  const [list, setList] = useState([]);
  const getData = () => {
    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("mode", "notice_list");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_user/service/notice", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        if (Value.status === "success") {
          setList(Value.notice_list);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <WrapperColumn width="100%">
      <Wrapper
        width="100%"
        height="60px"
        padding="17px 22px"
        jc="space-between"
        ai="center"
      >
        <Icon
          src={Back}
          width="24px"
          height="24px"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setModalStatus("profile");
            setTimeout(() => {
              setOpen(true);
            }, 0.5);
          }}
        />
        <Text size="18px" color="#1A1C1F" weight="600">
          공지사항
        </Text>
        <Icon src={Back} width="0px" height="0px" />
      </Wrapper>

      <Wrapper width="100%" height="1px" bgc="#F5F5F5" />

      <Wrapper width="100%" padding="24px 24px 12px 24px" jc="space-between">
        <Wrapper ai="center">
          <Icon
            src={BlueExclamation}
            width="20px"
            height="20px"
            style={{ marginRight: "4px" }}
          />
          <Text size="14px" weight="500" color="#818792">
            공지사항
          </Text>
        </Wrapper>
        <Text size="14px" weight="500" color="#0e6dff">
          총 {list.length}개
        </Text>
      </Wrapper>

      {list.map((el, i) => (
        <Wrapper
          width="100%"
          padding="16px 24px"
          bb="2px solid #f5f5f5"
          jc="space-between"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setAnnounce(el.notice_id);
            setModalStatus("announce_detail");
            setTimeout(() => {
              setOpen(true);
            }, 0.5);
          }}
        >
          <Wrapper width="100%">
            <RainbowBox style={{ marginRight: "14px" }}>
              <Text size="10px" weight="600" color="#ffffff">
                세모스 ONLY
              </Text>
            </RainbowBox>
            <WrapperColumn>
              <Text size="14px" color="#3c3e41" weight="600">
                {el.notice_name}
              </Text>
              <Wrapper height="6px" />
              <Text size="12px" color="#818792" weight="500">
                {el.notice_date}
              </Text>
            </WrapperColumn>
          </Wrapper>

          <Icon src={RightArrow} width="24px" height="24px" />
        </Wrapper>
      ))}
    </WrapperColumn>
  );
};

export default NewAnnounce;

const RainbowBox = styled.div`
  border-radius: 20px;
  padding: 3px 7px;
  background: radial-gradient(
    100% 843.76% at 0% 52.38%,
    #ff6759 0%,
    #8d4ef2 44.79%,
    #0e6dff 100%
  );
`;
